import React from 'react';

import { ClassyWidget } from 'reactform/classywidget';
import WidgetRender from './custom_widget_render';
import { withStyles } from '@material-ui/core/styles';
import { BaseUploader } from 'baseuploader';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ErrorList } from 'errorlist';
import MuiTypography from 'dash/MuiTypography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    progressColor: theme.palette.primary.dark,
});

class BigFileUpload extends ClassyWidget {
    /* select control to choose already-uploaded media files */
    static defaultProps = {
        ...ClassyWidget.defaultProps,
        uploader_props: { // BaseUploader properties
            url: '/uploads/upload_chunk/',
            purpose: 'media',
        },
    }
    mounted = true;
    state = {
        uploader: null,
    }
    componentWillUnmount() {
        this.mounted = false;
        this.state.uploader && this.state.uploader.cancel();
    }

    handleProgress = (base_uploader) => {
        const percent = base_uploader.percent();
        const written = base_uploader.state.written;
        const { file } = base_uploader.props;
        console.log(`Progress on ${file.name} ${percent}% (${Math.round(written / 1024)}KiB)`);
        if (this.mounted) {
            this.setState({ uploader: base_uploader, error: null, messages: null });
        }
    }
    handleFinished = (base_uploader) => {
        console.log(`Finished upload of ${base_uploader.props.file.name}`);
        if (this.mounted) {
            this.handleChange(base_uploader.state.upload_id);
            this.setState({
                'file': null,
            });
        }
    }
    handleUploadCancel = (evt) => {
        this.state.uploader && this.state.uploader.cancel();
    }
    onUploadCancel = (evt) => {
        // Upload has been cancelled in the base uploader...
        if (this.mounted) {
            this.setState({
                'file': null,
                'uploader': null,
            });
            this.handleChange(null);
        }
    }
    handleError = (base_uploader, err) => {
        const messages = (err && err.message) || [err];
        if (this.mounted) {
            this.setState({
                'error': true,
                'messages': messages,
            });
        }
    }
    createUploader = (file) => {
        console.log(`Starting upload of ${file.name}`);
        const uploader = new BaseUploader({
            ...this.props.uploader_props,
            ...this.widget_data('uploader_props'),
            onFinished: this.handleFinished, /* called when we finish */
            onError: this.handleError, /* called if we encounter any error */
            onCancel: this.onUploadCancel, /* called if we explicitly cancel the upload */
            onProgress: this.handleProgress, /* called every time we upload a chunk successfully */
            file: file,
        });
        uploader.next_chunk();
        return uploader;
    }
    handleFileChange = (evt) => {
        var files = evt.target.files;
        if (files.length) {
            const file = files[0];
            if (this.state.uploader) {
                console.log(`Cancelling previous upload ${this.state.uploader.description()}`);
                this.state.uploader.cancel();
            }
            if (this.mounted) {
                this.setState({
                    'uploader': this.createUploader(file),
                });
            }
        }
    }
    wsUpload = (file) => {
        const socket = new WebSocket(`ws://localhost:8001/uploads/${file.name}`);
        const block_size = 1024 * 512
        let start = 0
        let stop = false;
        let wait_count = 1;
        const wait_and_send = () => {
            if (socket.bufferedAmount > 1024 * 10) {
                console.log(`Waiting... ${wait_count}`);
                wait_count += 1;
                window.setTimeout(wait_and_send, 5);
                return;
            }
            send_file();
        };
        const send_file = (evt) => {
            let end = start + block_size;
            if (end >= file.size) {
                end = file.size;
                stop = true;
            }
            try {
                socket.send(file.slice(start, end));
            } catch (e) {
                stop = true;
                console.error(`Error during send: ${e}`);
            }
            start = end;
            if (!stop) {
                console.log(`${end / file.size * 100}% sent`);
                window.setTimeout(wait_and_send, 20);
            } else {
                console.log('Finished upload');
            }
        };
        socket.addEventListener('open', send_file);

    }
    testNewUploader = (evt) => {
        for (let i = 0; i < evt.target.files.length; i++) {
            this.wsUpload(evt.target.files[i]);
        }
    }
    render() {
        const { show_label, classes, uploader_props } = this.props;
        const current = this.current_value();
        const field = this.get_field();
        const base_field = field.field;
        const { uploader, error, messages, file } = this.state;
        const description = uploader && uploader.description();


        return <WidgetRender form_field={field} widget_class="select-widget" show_label={show_label} >
            {(!uploader) && <input type="file" className="file-upload" onChange={this.testNewUploader} />}
            {
                uploader && (
                    <LinearProgress
                        key='progress'
                        variant="buffer"
                        value={uploader.percent()}
                        color="primary"
                        valueBuffer={uploader.percent_buffer()}
                    />
                )
            }
            {description && (
                <MuiTypography key='description'>
                    <IconButton key='cancel' onClick={this.handleUploadCancel} title="Cancel/delete">
                        <Icon>close</Icon>
                    </IconButton>
                    {description}
                </MuiTypography>
            )}
            {error && messages && <ErrorList errors={messages} />}
        </WidgetRender>;
    }
}

export default withStyles(styles)(BigFileUpload);