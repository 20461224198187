import React from 'react';
import { GroupEditTable, group_specific_storage } from './groupedittable';
import { providing_nearest_form } from 'formprovider';
import with_resolve_focus from 'dash/resolvefocus';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { ReactForm } from 'reactform';
import { with_focus } from 'dash/focusprovider';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import { services_storage, ServicesTable } from './servicetable';
import { EditIntent } from 'editintent';
import { ServiceGroupsTable, service_groups_storage } from 'shogun/servicegrouptable';
import WithCurrentUser from 'withcurrentuser';

class BaseServiceGroupsView extends React.Component {
    render() {
        const { user } = this.props;
        const storage_props = service_groups_storage(null);
        const servicegroup_actions = [
            user && user.has_permission('shogunconf.edit_content') && <EditIntent
                key="add-service-group"
                target={{
                    'type': 'ServiceGroup',
                    'id': null,
                }}
                context={{
                }}
                action='Create a new Config Package'
                label='Add Config Package'
                help_text='Adds a new collection of services that can be deployed across clusters/nodes'
            />
        ].filter(x => !!x);
        return <React.Fragment>
            <EditingCard key='services' title='Config Packages' no_wrapper={true} actions={servicegroup_actions} >
                <ServiceGroupsTable
                    key={`servicegroups-table`}
                    storage_props={storage_props}
                    group={null}
                    {...this.props}
                />
            </EditingCard>
            {/* <EditingCard
                title={`All Services`}
                no_wrapper={true}
                collapsing='all-services-table'
            >
                <ServicesTable storage_props={{
                    default_context: {
                    },
                    forms_key: 'shogunconf_forms',
                    form_key: 'ShogunServices',
                    // editing_forms: ['ServiceDisplay'],
                    form_keys: ['ServiceDisplay'],

                    quick_settings: {
                    },
                }} exclude={['notes']} />
            </EditingCard> */}
        </React.Fragment>;
    }
}
const ServiceGroupsView = providing_nearest_form(
    WithCurrentUser(BaseServiceGroupsView),
    'shogunconf_forms'
);

export default ServiceGroupsView;
export { BaseServiceGroupsView, ServiceGroupsView };