import React from 'react';
import { providing_nearest_form } from 'formprovider';
import { with_resolve_target, with_target } from 'dash/resolvetarget';
import { with_focus } from 'dash/focusprovider';
import EditingCard from 'dash/editingcard';
import { EditIntent } from 'editintent';
import { fa_icon } from 'faicon';
import EPGTable from './epgtable';
import EPGThemeTable from './epgthemetable';
import DataSourceTable from './datasourcetable';
import { WithToolbar } from 'shogun/shogunpromotebar';
import { Link } from 'react-router-dom';

class BaseEPGView extends React.Component {
    render() {
        const epg_table_actions = [
            <EditIntent
                key="add-epg"
                form_key="AddEPG"
                target={{
                    '__type__': 'EPG',
                    'id': null,
                }}
                context={{
                }}
                action='Add EPG'
                label='Add EPG'
                help_text='Add new electronic program guide'
            />,
        ];
        const datasource_table_actions = [
            <EditIntent
                key="add-datasource"
                target={{
                    '__type__': 'DataSource',
                    'id': null,
                }}
                context={{
                }}
                action='Add Data Source'
                label='Add Data Source'
                help_text='Add new electronic program guide data source'
            />,
            <Link
                key="view-datasource-log"
                to='/sys/log/pull-schedules'
            >{fa_icon('history')} Log</Link>
        ];
        const theme_table_actions = [
            <EditIntent
                key="add-theme"
                target={{
                    '__type__': 'EPGTheme',
                    'id': null,
                }}
                context={{
                }}
                action='Add Theme'
                label='Add Theme'
                help_text='Add new electronic program guide theme'
            />,
        ];
        return <React.Fragment>
            <EditingCard title='Electronic Program Guides' actions={epg_table_actions} no_wrapper={true} key={`epgs`} collapsing={`epg-tables`}>
                <EPGTable
                    key='epg-table'
                />
            </EditingCard>
            <EditingCard title='Electronic Program Guide Themes' actions={theme_table_actions} no_wrapper={true} key={`epgthemes`} collapsing='epg-themes'>
                <EPGThemeTable
                    key='epg-theme-table'
                />
            </EditingCard>
            <EditingCard title='Electronic Program Guide Data Sources' actions={datasource_table_actions} no_wrapper={true} key={`datasources`} collapsing='epg-datasources'>
                <DataSourceTable
                    key='datasource-table'
                    exclude={['user', 'password']}
                />
            </EditingCard>
        </React.Fragment>;
    }
}
const EPGView = WithToolbar(providing_nearest_form(
    with_resolve_target(
        with_target(
            BaseEPGView
        )
    ),
    'epgconfig_forms'
));

export default EPGView;
export { BaseEPGView, EPGView };
