import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import MuiLoader from 'dash/MuiLoader';
import MuiTable from 'dash/MuiTable';
import PropertyTable from 'dash/PropertyTable';
import { current_user } from 'storeregistry';
import { human_bytes } from 'humanize';
import { requiring_system_os, requiring_system_store } from 'systemstorage';

const styles = theme => ({});

const TemperatureTable = ({ os }) => {
    if (!os.sensors.sensors.length) {
        return <div>{os.sensors.message}</div>;
    }

    const rows = os.sensors.sensors.map(sensor => ({
        key: sensor.name,
        value: <span>{sensor.current}</span>
    }));

    return <PropertyTable rows={rows} />;
};

const DiskUsageTable = ({ os }) => {
    const config = {
        columns: [
            {
                name: 'Partition',
                key: 'mount'
            },
            {
                name: 'Used',
                key: 'used'
            },
            {
                name: 'Total',
                key: 'total'
            },
            {
                name: 'Percent',
                key: 'percent'
            },
            {
                name: 'Device',
                key: 'device'
            }
        ]
    };

    return <MuiTable config={config} data={os.disks.mounts} />;
};

const DiskHealthTable = ({ os }) => {
    const config = {
        columns: [
            {
                name: 'Device',
                key: 'name'
            },
            {
                name: 'Device',
                get_value: device => {
                    return  device.error
                        ? <div className='error'>'Errors'</div>
                        : <div>{device.overall}</div>
                }
            },
            {
                name: 'Serial',
                key: 'serial'
            },
        ]
    };

    return <MuiTable config={config} data={os.disks.devices} />;
};

const get_identity_rows = ({ os, current_user }) => {
    if (!current_user().has_permission('config.scary_debugging')) {
        return [];
    }

    let identity_list = os.identity.filter(item => {
        if (Array.isArray(item[1])) {
            return item[1][0] != 'not extracted'
        } else {
            return item[1] && item[1] != 'not extracted'
        }
    })

    return identity_list.map(item => {
        const value =
            item[1] === 'Processor Version' ? (
                <div>{item[1].map((x, i) => <div key={i}>{x}</div>)}</div>
            ) : (
                <span>{item[1] || 'None'}</span>
            );

        return {
            key: item[0],
            value,
            'factory_only': true,
        };
    });
};

const system_os_table_config = props => {
    const { os, system, storage } = props;

    let config = [
        ...get_identity_rows({os, current_user}),
        {
            key: 'Firmware Release',
            value: <span>{system.sku.release}</span>
        },
        {
            key: 'Load',
            value: (
                <div>
                    <div>{os.load.load.join(', ')}</div>
                    {os.load.message && <div>this is a message</div>}
                </div>
            )
        },
        {
            key: 'Total Memory',
            value: (
                <div>
                    {os.memory.error ? (
                        <div>{os.memory.message}</div>
                    ) : (
                        <div>
                            <div>Total: {human_bytes(os.memory.total)}</div>
                            <div>Available: {human_bytes(os.memory.available)}</div>
                        </div>
                    )}
                </div>
            )
        }
    ];

    if (os.sensors.success) {
        config.push(
            {
                key: 'Temperature',
                value: <TemperatureTable {...props} />
            }
        );
    }

    config = config.concat([
        {
            key: 'Uptime',
            value: <span>{os.uptime}</span>
        },
        {
            key: 'Disk Usage',
            value: <DiskUsageTable {...props} />
        }
    ])

    if (os.disks.devices.some(device => device.success)) {
        config.push(
            {
                key: 'Disk Health (SMART)',
                value: <DiskHealthTable {...props} />
            }
        );
    }
    if (props.system_status_callback) {
        config = props.system_status_callback(config,{os,system,storage});
    }

    return config;
};

class SystemOsStatus extends React.Component {
    render() {
        const { system, os } = this.props;

        if (!os || !system) {
            return <MuiLoader />;
        }

        return (
            <Paper classes={{}}>
                <PropertyTable rows={system_os_table_config(this.props)} />
            </Paper>
        );
    }
}

export default withStyles(styles)(requiring_system_store(requiring_system_os(SystemOsStatus)));
