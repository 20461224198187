import React from 'react';
import { BaseStorage } from 'storages/basestorage';
import useStorage from 'storages/usestorage';
import ATXTable from 'atxtable'
import TabularStorage from 'storages/tabularstorage';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import EditIntent from 'editintent';

const EPGUploads = (props) => {
    /* Renders the factory datasources into the GUI */
    // const content = { "success": true, "datasources": [{ "ETag": "1683224440", "Modified": "Thu, 04 May 2023 18:20:40 GMT", "channels": 4936, "programs": 27739, "schedules": { "minimum": 1593088200, "maximum": 1593129900, "minimum_display": "2020-06-25 12:30 UTC", "maximum_display": "2020-06-26 00:05 UTC", "count": 306458 }, "id": 1 }, { "ETag": "1683168347", "Modified": "Thu, 04 May 2023 02:45:47 GMT", "id": 3 }, { "ETag": "1683252803", "Modified": "Fri, 05 May 2023 02:13:23 GMT", "channels": 15, "programs": 5618, "schedules": { "minimum": 1683158400.0, "maximum": 1683160200.0, "minimum_display": "2023-05-04 00:00 UTC", "maximum_display": "2023-05-04 00:30 UTC", "count": 5618 }, "id": 4 }] };
    const content = null;
    const [uploadState, setUploadState] = React.useState(content);
    const [configState, setConfigState] = React.useState(null);
    useStorage(
        BaseStorage({
            url: "/epgdata/api/", // Maps to `epguploads` service
            period: 15,
            debug: true,
            refresh_on_post: false,
        }),
        setUploadState,
    );
    useStorage(
        TabularStorage({
            forms_key: 'epgconfig_forms',
            form_key: 'DataSources',
        }),
        setConfigState,
    );
    const configMap = {};
    if (configState && configState.DataSources) {
        configState.DataSources.map(ds => {
            configMap[ds.__pk__] = ds;
        })
    }
    const dataset = (uploadState && uploadState.datasources) || [];
    dataset.map(status => {
        status.config = configMap[status.id];
    })

    return(
    <>
    <ATXTable
        keyField={"id"}
        columns={[
            {
                key: "id",
                accessor: "id",
                Header: "ID",
                Cell: (props) => {
                    const record = configMap[props.value];
                    if (record !== undefined && record != null) {
                        return <EditIntent
                            target={record}
                            label={record.title}
                            storage='epgconfig_forms'
                        />;
                    } else {
                        return <EditIntent
                            target={props.value}
                            label={"Unknown DataSource"}
                            storage='epgconfig_forms'
                        />;
                    }
                }
            },
            {
                key: "owner",
                accessor: "config.owner_display",
                Header: "Owners",
            },
            {
                key: "modified",
                accessor: "Modified",
                Header: "Upload Date",
            },
            {
                key: "channels",
                accessor: "channels",
                Header: "Channel Count",
            },
            {
                key: "programs",
                accessor: "programs",
                Header: "Program Count",
            },
            {
                key: "schedules",
                accessor: "schedules",
                Header: "Schedules",
                columns: [
                    {
                        key: "count",
                        accessor: "schedules.count",
                        Header: "Schedule Count"
                    },
                    {
                        key: "minimum",
                        accessor: "schedules.minimum_display",
                        Header: "First Schedule"
                    },
                    {
                        key: "maximum",
                        accessor: "schedules.maximum_display",
                        Header: "Last Schedule"
                    },
                ]
            },
        ]}
        data={dataset}
    />
    <EditIntent
            key={`epg-audit-report-download`}
            target={configMap}
            form_key='EPGReport'
            storage='shogunconf_forms'
            label='Download Audit Report'
            action='Download Audit Report'
            title="Download report"
            variant='contained'
            color='primary'
            icon={<GetAppRoundedIcon />}
            buttons={['cancel']}
        /> 
    </>
    )
}

export default EPGUploads;