import React from 'react';
import { WithTabularStorage } from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import { ReactFormTable } from 'reactform';
import { ReactForm } from 'reactform';
import { with_focus } from 'dash/focusprovider';
import { with_align_div } from 'reactform/typerenderer';
import { delete_target_action } from 'reactform/actionmenu';
import { CellRender } from 'reactform/typerenderer';

class BaseTuningTables extends React.Component {
    render() {
        const { form_details, mapping, storage } = this.props;
        if (!(form_details)) {
            return <MuiLoader />;
        }
        return <ReactFormTable
            // link_function={this.on_view_group.bind(this)}
            form_keys={this.props.editing_forms}
            form_details={form_details}
            editable={false}
            overall_target={mapping}
            defaultSorting={[
                { 'id': 'name', 'desc': false, },
            ]}
            field_actions={{
                // 'source_channel_number': (target, context, menu) => {
                //     return [
                //         delete_target_action(target, context, menu),
                //     ];
                // }
            }}
            widgets={{
                // 'datasource': (props) => {
                //     const {value,record,field,alignment,classes} = props;
                //     return <div className='data-source' title={record && record.datasource_display}>#{value}</div>;
                // },
                ...(this.props.widgets || {}),
            }}
            editable={true}
            preference_key={this.props.preference_key || 'tuningtables.table'}
            preference_default={{
                hiddenColumns: [
                    'time_format', 'date_format',
                    'current_time_interval',
                    'switch_before',
                ]
            }}
            link_to_edit={true}
            column_widths={{
                name: 'medium',
                hours_displayed: 'short',
                block_scroll_interval: 'short',
                current_time_interval: 'short',
                switch_before: 'short',
            }}
            {...this.props}
            exclude={[
                'lineup',
                'messages',
                ...(this.props.exclude || []),
            ]}
        />;
    }
}

const TuningTables = (props) => {
    const final_props = {
        storage_props: {
            default_context: {},
            forms_key: 'shogunconf_forms',
            form_key: 'TuningTables',
            quick_settings: {
            },
            debug: false,
        },
        ...props
    };
    return <WithTabularStorage
        key={`tunings`}
        Component={with_focus(BaseTuningTables)}
        editing_forms={['TuningTable']}
        exclude={[]}
        {...final_props}
    />;
};
export default TuningTables;
export { BaseTuningTables, TuningTables };
