/* Network configuration view */
import React from 'react';

import {NetStatus} from 'systemstatus';
import ATXTable from 'atxtable';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import RenderBoolean from 'widgets/renderboolean';
import { withStyles } from '@material-ui/core';


const styles = theme => ({
    IPv4: {
        color: 'green',
    },
    IPv6: {
        color: 'teal',
    },
    MAC: {
        color: 'light-grey',
    }
});

const render_address = (address, styles) => {
    var formatted = '';
    if (address.type == 'IPv4') {
        formatted = `${address.addr}/${address.netmask}`;
    } else if (address.type == 'IPv6') {
        formatted = `${address.addr}/${address.prefix}`;
    } else if (address.type == 'MAC') {
        formatted = `${address.addr} (MAC)`;
    } else {
        return null;
    }
    return <span className={styles[address.type]}>{formatted}</span>;
};


class InterfaceStatusTable extends React.Component {
    static defaultProps = {
        'irecords': [],
        'classes': {},
    }
    render() {
        const {records, classes} = this.props;
        if (records && records.length) {
            // console.log(records[0]);
            return <ATXTable
                data={records}
                pageSize={records.length}
                showPagination={false}
                columns={[
                    {'Header':'Interface','accessor':'iface.interface'},
                    {
                        'Header':'Link','accessor':'current.link',
                        Cell: (props) => {
                            const {value} = props.cell;
                            return <RenderBoolean
                                align='center'
                                value={value}
                                true_title='Link is Up'
                                false_title='Link is Down'
                            />;
                        }
                    },
                    {
                        'Header': 'Up', 'accessor': 'current.stats.operstate',
                        Cell: (props) => {
                            const {value} = props.cell;
                            return <RenderBoolean
                                align='center'
                                value={value === 'up'}
                                true_title='Interface is Up'
                                false_title='Interface is Down'
                            />;
                        }
                    },
                    {
                        'Header': 'Recv',
                        'filterable': false,
                        sortable: false,
                        id: 'recv_graph',
                        accessor: row => {
                            if (row && row.bandwidth && row.iface) {
                                return row.bandwidth['bytes_recv'];
                            } else {
                                return null;
                            }
                        },
                        width: 60,
                        Cell: (props) => {
                            var iface = props.cell.row.original.iface;
                            const {value} = props.cell;
                            var acc = value;
                            return <NetStatus
                                interface_name={iface.interface}
                                property_name='bytes_recv'
                                show_label={false}
                                height={20}
                                width={50}
                                accumulator={acc}
                            />;
                        }
                    },
                    {
                        Header: 'Sent',
                        id: 'sent_graph',
                        'filterable': false,
                        sortable: false,
                        accessor: row => {
                            if (row && row.bandwidth && row.iface) {
                                return row.bandwidth['bytes_sent'];
                            } else {
                                return null;
                            }
                        },
                        width: 60,
                        Cell: props => {
                            var iface = props.cell.row.original.iface;
                            const {value} = props.cell;
                            var acc = value;
                            if (acc) {
                                return <NetStatus
                                    interface_name={iface['interface']}
                                    property_name='bytes_sent'
                                    show_label={false}
                                    height={20}
                                    width={50}
                                    interface_Name={iface['interface']}
                                    accumulator={acc}
                                />;
                            } else {
                                return null;
                            }
                        }
                    },


                    {
                        'Header': 'Addresses',
                        'accessor': 'current.addresses',
                        Cell:(props) => {
                            const {value} = props.cell;
                            if (! value) {
                                return null;
                            }
                            return <List dense={true}>
                                {value.map((addr,i) => <ListItem key={`${i}`} >{render_address(addr, classes)}</ListItem>)}
                            </List>;
                        }
                    },
                    {
                        'Header': 'Routes',
                        'accessor': 'current.routes',
                        Cell: (props) => {
                            const {value} = props.cell;
                            if (!value) {
                                return null;
                            }
                            return <List dense={true}>
                                {value.map((route, i) => <ListItem key={`${i}`} >{route}</ListItem>)}
                            </List>;
                        }
                    },
                ]}
            />;
        } else {
            return null;
        }
    }
}

export default withStyles(styles)(InterfaceStatusTable);
