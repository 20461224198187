import React from 'react';

import PowerForm from 'dash/PowerForm';
import { ReactForm } from 'reactform';
import { SysemailConfig, SysemailSummary } from 'sysemailconfig';
import PowerSummary from 'dash/powersummary';
import FirmwarePage from 'firmware/FirmwarePage';
import FirmwareSummary from 'firmware/FirmwareSummary';
import LicenseTable from 'dash/licensetable';
import MuiButton from 'dash/MuiButton';
import Icon from 'dash/MuiIcon';
import { default_save_button } from 'reactform/constructcontrols';
import Config from 'config';

// TODO: a lot of this is basic every-product support, so it should be in a
// base set that then gets used from an individual product
const forms = (props) => {
    const base = [
        // props.product_name formatted into a message
        // props.system_prepend prepended to system page set
        // props.system_append appended to system page set
        ...(props.system_prepend || []),
        {
            'title': 'System Location',
            'key': 'location',
            'details': <span>
                The system timezone controls the time used by the
                system internally. The Physical Location field is
                used within this GUI and monitoring systems to provide
                a friendly identifier for the machine.
            </span>,
            'get_summary': system => {
                const { location, timezone } = system.system;

                return location && timezone
                    ? `${location} in ${timezone}`
                    : null;
            },
            'render_component': props => (
                <ReactForm
                    target={props.system}
                    form_key={'LocationForm'}
                    use_dialog={false}
                />
            ),
            'permission': 'config.location'
        },
        {
            'title': 'System Date',
            'key': 'date',
            'details': <span>
                Temporarily sets the system date to work around
                a missing or misconfigured NTP server.
            </span>,
            'get_summary': system => {
                return system.time_formatted;
            },
            'render_component': props => (
                <ReactForm
                    target={props.system}
                    form_key={'DateForm'}
                    use_dialog={false}
                    default_messages={{
                        'success': 'System time overridden',
                        'error': 'Unable to override system time',
                    }}
                />
            ),
            'permission': 'config.location'
        },
        {
            'title': 'Power',
            'key': 'power',
            'get_summary': system => <PowerSummary system={system} />,
            'render_component': props => (
                <PowerForm target={props.system} />
            ),
            'permission': 'config.shutdown'
        },
        {
            'title': 'License Server',
            'key': 'license-server',
            'details': <span>
                Licensing servers issue license certificates which are
                valid for a few days at a time, or for the expected lifetime
                of your device. Your {Config.product_family} will
                download license certificates from the upstream licensing
                server every day. If you have an in-house licensing server
                you should provide its URL here.
            </span>,
            'permission': 'config.licensing',
            'get_summary': system => system.system.license_server,
            'render_component': props => <React.Fragment>
                <ReactForm
                    target={props.system}
                    form_key={'LicenseClientForm'}
                    use_dialog={false}
                />
                <LicenseTable />
            </React.Fragment>
        },
        {
            'title': 'Monitoring/Alerts',
            'key': 'snmp',
            'get_summary': system => {
                const { snmp_community, snmp_trap_sink, snmp_trap_sink_port } = system.snmp;
                return `${snmp_trap_sink}:${snmp_trap_sink_port} (${snmp_community})`;
            },
            'render_component': props => (
                <ReactForm
                    target={props.system}
                    form_key={'SNMPForm'}
                    use_dialog={false}
                    buttons={[
                        'save',
                        (form) => <MuiButton
                            variant="contained"
                            onClick={(evt) => {
                                window.location = '/ptheme/PRODUCT-MIBS.tar.gz';
                            }}
                            key="download-mibs"
                        ><Icon name='download' /> MIBs</MuiButton>,
                        (form) => default_save_button(form, {
                            busy_message: 'Testing',
                            action_message: 'Test',
                            'send_test': 'on',
                            key: 'test',
                            title: 'Save and send testing traps'
                        }),
                    ]}
                />
            ),
            'permission': 'config.snmp'
        },
        {
            'title': 'SMTP Configuration',
            'key': 'smtp',
            'get_summary': () => <SysemailSummary />,
            'render_component': props => (
                <SysemailConfig />
            ),
            'permission': 'sysemail.edit_smtp',
        },
        {
            'title': 'Firmware',
            'key': 'firmware',
            'get_summary': system => <FirmwareSummary {...system} />,
            'render_component': props => (
                <FirmwarePage />
            )
        },
        ...(props.system_append || []),
    ];
    return base.filter(
        (item) => (
            (!props.system_skip) ||
            (props.system_skip && props.system_skip.indexOf(item.key) == -1)
        )
    );
};

export default forms;
