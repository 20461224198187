import React from 'react';

class StaticConfig extends React.Component {
    static defaultProps = {
        'address': '0.0.0.0',
        'netmask': null,
        'prefix': null,
        'className': 'static-config'
    }
    render() {
        var mask = null;
        if (this.props.netmask) {
            mask = <span className="netmask">/{this.props.netmask}</span>;
        } else if (this.props.prefix) {
            mask = <span className="prefix">/{this.props.prefix}</span>;
        }
        return <span className={this.props.className}>
            <span className="address">{this.props.address}</span>
            {mask}
        </span>;
    }
}

export default StaticConfig;