import React from 'react';
import LineupRender from './LineupRender';
import { ClassyWidget } from 'reactform/classywidget';
import WidgetRender from './custom_widget_render';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

const station_label = (record) => {
    const station = record.station ? record.station : record;
    return `${station[1]} #${station[0]} ${station[2]} ${station[3]}`;
};
const channel_sort = (first, second) => {
    if (!first.channel) {
        return -1;
    } else if (!second.channel) {
        return 1;
    }
    const f_channel = first.channel.split('-');
    const s_channel = second.channel.split('-');
    for (let i = 0; i < f_channel.length; i++) {
        if (i >= second.length) {
            return 1; //first longer
        }
        const f_parsed = parseInt(f_channel[i]);
        const s_parsed = parseInt(s_channel[i]);
        if (f_parsed < s_parsed) {
            return -1;
        } else if (f_parsed > s_parsed) {
            return 1;
        }
    }
    if (first.length < second.length) {
        return -1;
    }
    return 0;
};
const styles = theme => ({
    chipHolder: {
        width: '100%',
        minHeight: '2em',
        borderBottomColor: theme.roles.shading,
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
    },
    empty: {
        color: theme.roles.disabled,
    },
    customHolder: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    customField: {
        flex: 1,
    }
});


class LineupWidget extends ClassyWidget {
    static defaultProps = {
        ...ClassyWidget.defaultProps,
        from_server: (base) => {
            if (typeof base == 'string') {
                if (!base.length) {
                    return [];
                } else if (base.length && base[0] == '[') {
                    return JSON.parse(base);
                } else {
                    console.warning(`Unexpected lineup description: ${base}`);
                    return [];
                }
            } else if (base === null || base === undefined) {
                return [];
            } else {
                return base;
            }
        },
        to_server: (base) => {
            if (Array.isArray(base)) {
                return JSON.stringify(base);
            } else {
                return base;
            }
        },
    }
    remove_callback = (record, index) => {
        const old_records = this.render_value();
        const new_records = [
            ...old_records.slice(0, index),
            ...old_records.slice(index + 1, old_records.length),
        ];
        if (old_records.length <= new_records.length) {
            console.warn("Remove callback did not remove any elements");
        }
        this.handleChange(new_records);
    }
    max_current_channel = () => {
        var maximum = 0;
        const current = [...this.render_value()];
        current.sort(channel_sort);
        if (current.length) {
            return current[current.length - 1].channel;
        }
        return '';
    }
    increment_channel = (channelset) => {
        const fragments = channelset.split('/').map(channel => {
            const segments = channel.split('-');
            return [
                ...segments.slice(0, segments.length - 1),
                `${parseInt(segments[segments.length - 1] || '0') + 1}`
            ].join('-');
        });
        return fragments.join('/');
    }
    add_callback = (event, station) => {
        // console.log(`Add callback ${JSON.stringify(station)}`);
        if (station) {
            const channel_number = this.increment_channel(this.max_current_channel());
            const channel = {
                'channel': channel_number,
                'id': channel_number,
                'station': station,
            };
            const current = [...this.render_value()];
            current.push(channel);
            current.sort(channel_sort);
            this.handleChange(current);
        }
    }
    update_callback = (original_channel, new_channel, finished = false) => {
        const current = this.render_value().map(x => x === original_channel ? new_channel : x);
        if (finished) {
            current.sort(channel_sort);
        }
        this.handleChange(current);
    }

    render_selected = () => {
        return <LineupRender
            edit={true}
            value={this.render_value() || []}
            remove_callback={this.remove_callback}
            update_callback={this.update_callback}
        />;
    }
    lookup_station = (station_id) => {
        const stations = this.get_stations().filter(
            station => station[0] == station_id
        );
        return stations.length ? stations[0] : null;
    }
    get_stations = () => {
        const stations = this.widget_data('stations') || [];
        // console.log(`Loaded: ${stations} station records`);
        return stations;
    }
    render_choices = () => {
        /* Render the choices as a combobox */
        var field = this.get_field();
        return <Autocomplete
            options={this.get_stations()}
            getOptionLabel={station_label}
            onChange={this.add_callback}
            clearOnEscape={true}
            disableOpenOnFocus={true}
            renderInput={
                (params) => <TextField
                    {...params}
                    label={'Add Station'}
                    variant='standard'
                    placeholder='🔍 Search'
                    InputLabelProps={{
                        shrink: true
                    }}
                    style={{
                        width: '100%',
                    }}
                />
            }
        />;
    }
    render_custom = () => {
        /* Render button and form for creating new internal channel */
        const { classes } = this.props;
        const { custom_channel } = this.state;
        return <div className={classes.customHolder}>
            <div className={classes.customField}>
                <WidgetRender
                    show_label={true}
                    form_field={{
                        field: {
                            'label': 'Local Channel Name',
                        }
                    }}
                >
                    <Input
                        variant='standard'
                        placeholder='Name'
                        style={{
                            width: '100%',
                        }}
                        error={this.state.custom_channel && this.state.custom_channel.callsign_error}
                        onChange={
                            (evt) => {
                                this.setState({
                                    'custom_channel': {
                                        ...this.state.custom_channel,
                                        name: evt.target.value,
                                    }
                                });
                            }
                        }
                    />
                </WidgetRender>
            </div>
            <div className={classes.customField}>
                <WidgetRender
                    show_label={true}
                    form_field={{
                        field: {
                            'label': 'Local Channel Call Sign',
                        }
                    }}
                >
                    <Input
                        variant='standard'
                        placeholder='Call Sign'
                        error={this.state.custom_channel && this.state.custom_channel.callsign_error}
                        style={{
                            width: '100%',
                        }}
                        onChange={
                            (evt) => {
                                this.setState({
                                    'custom_channel': {
                                        ...this.state.custom_channel,
                                        callsign: evt.target.value,
                                    }
                                });
                            }
                        }
                    />
                </WidgetRender>
            </div>
            <IconButton
                size='small'
                disabled={!(this.state.custom_channel && this.state.custom_channel.name && this.state.custom_channel.callsign)}
                onClick={(evt) => {
                    const cur_customs = this.render_value().filter(channel => channel.station[0][0] == '_').map(
                        channel => parseInt(channel.station[0].slice(1))
                    );
                    cur_customs.push(-1);
                    const next_custom = Math.max(...cur_customs) + 1;

                    this.add_callback(evt, [
                        `_${next_custom}`,
                        this.state.custom_channel.name,
                        this.state.custom_channel.callsign,
                        '',
                    ]);
                }}
            ><Icon>add</Icon></IconButton>
        </div>;
    }
    render() {
        const { show_label, classes } = this.props;
        var field = this.get_field();
        return <WidgetRender
            form_field={field}
            show_label={show_label}
        >
            {this.render_selected()}
            {this.render_choices()}
            {this.render_custom()}
        </WidgetRender>;
    }
}

export default withStyles(styles)(LineupWidget);
