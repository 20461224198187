/* ChildSelect with Tabular Drop-Down */
import React from 'react';
import { PAGE_FOCUS } from 'storages';
import { makeStyles } from '@material-ui/core/styles';
import { reverse } from 'dash/navigationUtils';
import redirect from 'redirect';
import { useHistory } from 'react-router-dom';
import icon_for_type from 'typeicon';
import { GroupStorageContext, children, tree_child } from 'withgrouphierarchy';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Popover } from '@material-ui/core';
import classNames from 'classnames';
import VirtualList from 'virtuallist';

const useStyles = makeStyles(theme => ({
    childSelectRoot: {
        border: 'thin solid #ccc',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    disabled: {
    },
    childSelectInput: {
        flex: 1,
    },
    optionWrapper: {
        marginLeft: '.25rem',
        marginRight: '.25rem',
        // minHeight: '25vh',
        maxHeight: '50vh',
        minWidth: '25vw',
        maxWidth: '50vw',
        width: 'fit-content',
        justifyItems: 'left',

        display: 'grid',
        gridTemplateColumns: '1fr 10rem',
        gridGap: '.2rem',
        alignItems: 'baseline',

    },
    option: {
        fontSize: '1rem',
        lineHeight: '1.25rem',
    },
    clickable: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.hint,
        }
    },

    spacer: {
        width: '.5em',
        height: '1rem',
        lineHeight: '1rem',
    },
    icon: {
        width: '.5em',
        height: '1rem',
    },
    title: {
        fontSize: '1rem',
        lineHeight: '1.25rem',
        width: '25rem',
    },
    serial: {
        fontSize: '1rem',
        lineHeight: '1.25rem',
        width: '15rem',
        color: theme.palette.grey[400],
    },

    focussed: {
    },

}));

const indent_for = (option, base, indent) => {
    const delta = (option.tree.level - base);
    let padding = '\u00A0'.repeat(indent * delta);
    if (delta >= 1) {
        padding = <span style={{ 'whiteSpace': 'pre' }}>{padding}<ArrowRight fontSize='small' /></span>;
    }
    return padding;
};
const same_record = (first, second) => {
    if ((!first) && (!second)) {
        return true;
    } else if (first && !second) {
        return false;
    } else if (second && !first) {
        return false;
    }
    return first.__type__ == second.__type__ && first.__pk__ == second.__pk__;
}

const CssGrid = React.forwardRef((props, ref) => {
    const { children, ...restProps } = props;
    const classes = useStyles();
    return <div className={classNames(classes.optionWrapper, 'css-grid-wrapper')} ref={ref} {...restProps}>{children}</div>;
});



const ChildSelect = (props) => {
    const { indent = 2 } = props;
    const classes = useStyles();
    const history = useHistory();
    const baseRef = React.useRef();
    const [currentTyping, setCurrentTyping] = React.useState("");
    const [showOptions, setShowOptions] = React.useState(false);
    const groupTree = React.useContext(GroupStorageContext);
    const focus = props.target || PAGE_FOCUS.get_focus();

    if (!focus) {
        return null;
    }
    const base = (focus.tree && focus.tree.level) || 0;
    let descendants = children(focus, groupTree);
    let use_search = true;

    if (descendants.length == 1 && same_record(descendants[0], focus)) {
        let descendant = focus;
        return <div className={classes.disabled}>
            {icon_for_type(descendant.sku) || icon_for_type(descendant.__type__)}
            &nbsp;
            {descendant.title}
        </div>;
    }


    const on_selected = (target) => {
        const { activeRoute, focus, user, routes, match } = props;
        // const url = generic_pagefocus_link_url_focus_required(activeRoute, target, user, routes, match);
        const url = reverse({
            ...props,
            target: target,
        });
        if (url) {
            console.log(`Redirecting to ${url}`);
            redirect(url, history);
        }
    };


    let options = null;
    let options_key = `results-${focus.__type__}-${focus.__pk__}`;
    if (showOptions || currentTyping) {
        let rendered_options = [];
        if (currentTyping) {
            const test = currentTyping.toLowerCase();
            const previous = descendants.length;
            descendants = descendants.filter(descendant => {
                return descendant && (
                    descendant.title && descendant.title.toLowerCase().indexOf(test) > -1
                ) || (
                        descendant.serial_number && descendant.serial_number.toLowerCase().indexOf(test) > -1
                    ) || (
                        descendant.sku && descendant.sku.toLowerCase().indexOf(test) > -1
                    );
            });
            console.log(`Filtered ${previous} element to ${descendants.length} with ${test}`);
        }
        /* Is the list too long? If so, see if we can filter down to just groups */
        if ((!currentTyping) && descendants.length > 40) {
            let groups = descendants.filter(descendant => {
                return descendant.__type__ == 'Group';
            });
            if (groups.length > 10) {
                descendants = groups;
            }
        }

        const render_index = (index) => {
            const descendant = descendants[index];
            const clickable = !same_record(descendant, focus);
            return <React.Fragment key={`child-${index}`}>
                <div
                    className={classNames(classes.option, classes.title, descendant.__pk__ === focus.__pk__ ? classes.focussed : null, clickable ? classes.clickable : null)}
                    key={`title-${index}`}
                    onClick={clickable ? ((evt) => on_selected(descendant)) : null}
                >
                    {indent_for(descendant, base, indent)}
                    {icon_for_type(descendant.sku) || icon_for_type(descendant.__type__)}
                    &nbsp;
                    {descendant.title}
                </div>
                <div
                    className={classNames(classes.option, classes.serial, descendant.__pk__ === focus.__pk__ ? classes.focussed : null, clickable ? classes.clickable : null)}
                    key={`serial-${index}`}
                    onClick={clickable ? ((evt) => on_selected(descendant)) : null}
                >
                    {descendant.serial_number || ''}
                </div>
            </React.Fragment>;
        };

        options = <Popover
            id={options_key}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={true}
            anchorEl={baseRef.current}
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
            onClose={(evt) => {
                setShowOptions(false);
                setCurrentTyping('');
            }}
        >
            <VirtualList
                totalCount={descendants.length}
                itemContent={render_index}
                wrapperClass={classes.optionWrapper}
                key={`child-select-vlist`}
            >
                {rendered_options}
            </VirtualList>
        </Popover >;
    }

    return <div
        className={classes.childSelectRoot}
        key={`child-select-${focus.__pk__}`}
        ref={baseRef}
    >
        <input
            aria-autocomplete="list"
            className={classes.childSelectInput}
            value={currentTyping}
            placeholder={focus.title}
            onChange={(evt) => {
                setCurrentTyping(evt.target.value);
            }}
            onClick={(evt) => {
                if (!currentTyping) {
                    setShowOptions(!showOptions);
                }
            }}
        /><ExpandMore
            aria-expanded={options === null ? false : true}
            aria-controls={options_key}
            aria-label="Browse Children in Scrolling List"
            onClick={(evt) => {
                setShowOptions(!showOptions);
            }}
        />
        {options}
    </div >;
};

export default ChildSelect;
