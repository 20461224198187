import React from 'react';
import MuiIcon from 'dash/MuiIcon';
import MuiTypography from 'dash/MuiTypography';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AlignDiv from 'reactform/aligndiv';
import UnitValue from 'dash/unitvalue';

const styles = {
    numeric: {
        textAlign: 'center',
    },
};


function scaled_integer_component(scale, units, rounding=2) {
    class RenderScaledInteger extends React.Component {
        static defaultProps = {
            value: null,
            record: null,
            field: null,
            scale: scale,
            units: units,
            rounding: rounding,
        }
        render() {
            const {value,field,record,classes} = this.props;
            if (value === null || value === undefined) {
                return null;
            } else {
                const scaled = value/scale;
                const final = rounding !== undefined? Math.round(scaled*(10**rounding))/(10**rounding): scaled;
                const title = scale !== 1? `Unscaled value ${value}`:null;
                return <AlignDiv
                    alignment={this.props.alignment}
                    className='boolean-display scaled-integer'
                >
                    <UnitValue value={final} title={title} units={units} />
                </AlignDiv>;
                // return <MuiTypography className={classNames(classes.numeric,'integer','scaled-integer')}>
                //     <span className={classes.value} title={`Unscaled value ${value}`}>{final}</span>
                //     {
                //         units && <span className={classes.unit}>{units}</span>
                //     }
                // </MuiTypography>;
            }
        }
    }
    return withStyles(styles)(RenderScaledInteger);
}

export default scaled_integer_component;
