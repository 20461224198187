import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { stores } from 'storeregistry';
import { providing_nearest_form } from 'formprovider';
import { ReactForm } from 'reactform';
import { default_save_button } from 'reactform';

import Paper from '@material-ui/core/Paper';
import FirmwareTable from './FirmwareTable';
import FirmwareUpgradeForm from './FirmwareUpgradeForm';
import MuiLoader from 'dash/MuiLoader';
import WithCurrentUser from 'withcurrentuser';
import redirect from 'redirect';

import { requiring_system_store } from 'systemstorage';
const styles = theme => ({
    table: {
        marginBottom: theme.spacing(2)
    }
});

class FirmwarePage extends React.Component {
    state = {};
    onSave = (evt) => {
        const { history } = this.props;
        redirect('/sys/debugging/upgrade-progress/', history);
    }
    onChange = (form, field, value) => {
        if (field === 'firmware' || (field && field.name === 'firmware')) {
            this.setState({
                'firmware': value,
            });
        }
    }
    render() {
        const { classes, system, storage, user } = this.props;

        if (!system) {
            return <MuiLoader />;
        }
        const buttons = [
        ];
        buttons.push((self) => default_save_button(self, {
            'name': 'Upgrade',
            'action_message': 'Upgrade',
            'busy_message': 'Upgrading',
            'title': 'Upgrade firmware',
            'disabled': !(this.state.firmware),
            // 'icon': 'hdd-o',
        }));

        return <React.Fragment>
            <div className={classes.table}>
                <FirmwareTable />
            </div>
            {user && user.has_permission('config.upgrade') && <ReactForm
                form_key='FirmwareForm'
                target={system}
                use_dialog={false}
                onSave={this.onSave}
                onChange={this.onChange}
                buttons={buttons}
            />}
        </React.Fragment >;
    }
}

export default requiring_system_store(
    providing_nearest_form(
        withRouter(
            WithCurrentUser(
                withStyles(styles)(FirmwarePage)
            )
        ),
        'system_forms',
    ),
);
