import React from 'react';
import ATXTable from 'atxtable';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';

const channel_matcher = /^\w*\d+([-]\d+)?\w*([/]\w*\d+([-]\d+)?\w*)?$/;
class LineupRender extends React.Component {
    static defaultProps = {
        empty_label: 'Empty, search below',
        remove_callback: null,
        update_callback: null,
        label_callback: null,
        editing: true,
        value: [],
    }

    render() {
        const { value, remove_callback, editing } = this.props;
        const editing_controls = editing ? [
            {
                'Header': ' ',
                'id': 'delete',
                'width': 64,
                Cell: (props) => {
                    const { original } = props;
                    return <IconButton
                        onClick={(evt) => {
                            return remove_callback(original, props.row.index);
                        }}
                        size='small'
                    ><Icon>close</Icon></IconButton>;
                }
            },
        ] : null;

        return <ATXTable
            data={value}
            keyField={'channel'}
            columns={[
                {
                    'Header': 'Channel',
                    'id': 'channel',
                    'accessor': 'channel',
                    'width': 150,
                    'Cell': (props) => {
                        const { value } = props.cell;
                        let valid_channel = channel_matcher.exec(value);
                        if (this.props.editing) {
                            const send_update = (new_value, finished = false) => {
                                this.props.update_callback(
                                    props.cell.row.original,
                                    {
                                        ...props.cell.row.original,
                                        'channel': new_value ? new_value.trim() : '',
                                    },
                                    finished,
                                );
                            };
                            return <TextField
                                defaultValue={value}
                                error={!valid_channel}
                                onBlur={(evt) => {
                                    const new_value = evt.target.value;
                                    const match = channel_matcher.exec(new_value);
                                    if (match) {
                                        send_update(new_value, true);
                                    } else {
                                        send_update(props.cell.row.original.channel, true);
                                    }
                                    // evt.target.setState({value:null});
                                }}
                                onChange={(evt) => {
                                    const new_value = evt.target.value;
                                    const match = channel_matcher.exec(new_value);
                                    // if (match) {
                                    //     send_update(new_value,false);
                                    // }
                                }}
                            />;
                        } else {
                            return <span className='error'>{value}</span>;
                        }
                    }
                },
                {
                    'Header': '',
                    'id': 'icon',
                    'accessor': 'station.0',
                    'width': 48,
                    'Cell': (props) => {
                        const { value } = props.cell;
                        if (value && value.length > 0 && value[0] != '_') {
                            return <img alt="Icon if available" src={`/icon//imageserver/guide/${value}`} width={16} height={16} />;
                        } else {
                            return null;
                        }
                    },
                },
                {
                    'Header': 'TMSID',
                    'id': 'tmsid',
                    'accessor': 'station.0',
                },
                {
                    'Header': 'Name',
                    'id': 'name',
                    'accessor': 'station.1',
                },
                {
                    'Header': 'Call Sign',
                    'id': 'callsign',
                    'accessor': 'station.2',
                },
                {
                    'Header': 'Location',
                    'id': 'location',
                    'accessor': 'station.3',
                },
                ...editing_controls
            ]}
        />;
    }
}

export default LineupRender;
