import React from 'react';
import {WithTabularStorage} from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import {ReactFormTable} from 'reactform';
import {ReactForm} from 'reactform';
import {with_focus} from 'dash/focusprovider';
import { delete_target_action } from 'reactform/actionmenu';
import {CellRender} from 'reactform/typerenderer';

class BaseDataSourceTable extends React.Component {
    render() {
        const {form_details,mapping,storage} = this.props;
        if (!(form_details)) {
            return <MuiLoader />;
        }
        return <ReactFormTable
            // link_function={this.on_view_group.bind(this)}
            form_keys={this.props.editing_forms}
            form_details={form_details}
            editable={true}
            overall_target={mapping}
            field_actions={{
                // 'source_channel_number': (target, context, menu) => {
                //     return [
                //         delete_target_action(target, context, menu),
                //     ];
                // }
            }}
            widgets={{
            }}
            preference_key={this.props.preference_key||'datasource.table'}
            // inPlaceEditing={true}
            column_widths={{
                name: 'short',
            }}
            {...this.props}
            exclude={[
                ...(this.props.exclude || []),
            ]}
        />;
    }
}

const DataSourceTable = (props) => {
    const final_props = {
        storage_props: {
            default_context: {},
            forms_key:'epgconfig_forms',
            form_key:'DataSources',
            quick_settings: {
            },
            debug: false,
        },
        ...props
    };
    return <WithTabularStorage
        key={`epgs`}
        Component={with_focus(BaseDataSourceTable)}
        editing_forms={['DataSource']}
        exclude={[
            'user',
            'password',
        ]}
        {...final_props}
    />;
};
export default DataSourceTable;
export {BaseDataSourceTable, DataSourceTable};
