import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import MuiButton from 'dash/MuiButton';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    login: {
        backgroundColor: theme.palette.red,
        color: theme.palette.common.white,
    }
});
const login_redirect = (history) => {
    history.push('/accounts/login');
    return true;
};

const LoginButton = (props) => {
    const { classes, history, login_callback, className } = props;
    const final_callback = (login_callback || login_redirect);
    return <MuiButton
        className={classNames(classes.login, className)}
        icon="user"
        variant="contained"
        onClick={(evt) => final_callback(history)}
    >Not Logged In</MuiButton>;
};

export default withRouter(withStyles(styles)(LoginButton));
