import React from 'react';
import {
    HorizontalBarSeries,
    HorizontalBarSeriesCanvas,
    LineSeries,
    LineMarkSeries,
    Crosshair,
    XAxis,
    XYPlot,
    YAxis,
    Hint,
} from 'react-vis';
import {
    filter_exclude,
    group_by_accessor,
    extract_series_grouped,
    extract_data,
    enumeration_order,
    metric_dataset,
    grouping_function,
    format_accessors,
} from 'dash/charts/extractdataset';
import extract_redblack from 'metrics/extractredblack';
import ZoomableTimeline from 'metrics/timeline';
import format_date_ticks from 'metrics/format_date_ticks';
import apply_accessor from './accessor';
import { graph_metric } from './chartmodel';

const HOUR = 1000 * 60 * 60;
const DAY = 86400000;


export default class RedBlack extends React.Component {
    /* Renders an array of data-series (prop series) as a sequence of coloured bars
 
 
 
    */
    static defaultProps = {
        width: 600,
        height: 300,
        useCanvas: false,
        tickFormat: format_date_ticks,
        series: null,
        graph: null,
        metric: null,
        color_map: null,
        initialStop: null,
        initialDuration: 2 * HOUR,
    };
    state = {
        lastDrawLocation: null,
        tooltip: null,
    };
    render() {
        let { series, width, height, tickFormat, graph, metric, initialDuration, initialStop } = this.props;
        let { tooltip = null } = this.state;
        if ((!series) || (!series.length)) {
            return null;
        }
        const first_series = series[0];
        if ((!first_series) || (!first_series.length)) {
            return null;
        }


        const resource_and_service = grouping_function(graph.group_by || ['resource_id.id', 'stream_id']);
        const resource_and_service_label = (record) => {
            return format_accessors(record, graph.group_label || ['', 'resource_id.title', ': ', 'stream_name']);
        };

        const grouped = group_by_accessor(
            first_series[0].value,
            metric,
            resource_and_service, //graph.group_by || 'resource_id.id',
            resource_and_service_label, //graph.group_title || 'resource_id.title',
        );
        const data_series = extract_redblack(grouped, metric, graph);
        const timestamp = initialStop ? (initialStop * 1000) : (new Date()).getTime();

        const heightMargin = this.props.heightMargin || Math.round(height / 30);
        const widthMargin = this.props.widthMargin || Math.round(width / 15 * 2);
        let min_height = 40 * data_series.length;
        let hint = null;
        const tooltip_format_field = (key, value) => {
            /* Format a specific field into the table for viewing */
            const do_not_show = {
                'service_id': true,
                'stream_id': true,
                'subresource_id': true,
                'time': true,
            };
            if (do_not_show[key]) {
                return null;
            }
            if (key == 'resource_id') {
                return {
                    title: 'Resource',
                    value: (value && value.title) || value,
                };
            }
            if (key == 'service_id') {
                return {
                    title: 'Service',
                    value: (value && value.title) || value,
                };
            }
            if (typeof value == 'number') {
                value = Math.round(value * 1000) / 1000;
            }
            return { title: key, value: value };
        };
        if (tooltip) {
            hint = <Hint
                key='hint'
                align={{ horizontal: 'auto', vertical: 'auto' }}
                value={tooltip}
                format={tooltip => ([
                    { title: 'Source', 'value': tooltip.Source },
                    { title: 'Value', 'value': tooltip.Value },
                    { title: 'Time', 'value': `${(new Date(tooltip.original.time * 1000))}` },
                    ...Object.keys(tooltip.original).map(key => {
                        const value = tooltip.original[key];
                        return tooltip_format_field(key, value);
                    }).filter(x => !!x),
                ])}
                marginLeft={0}
                marginTop={30}
            />;
        }

        return (
            <ZoomableTimeline
                initial_stop={timestamp}
                initial_start={timestamp - initialDuration}
                margin={{
                    left: Math.max(widthMargin, 130),
                    right: Math.min(widthMargin, 30),
                    top: heightMargin,
                    bottom: Math.min(Math.max(heightMargin * 2, 60), 80),
                }}
                width={width}
                height={Math.max(height, min_height)}
                xType="time"
                yType="ordinal"
                onMouseOut={(event) => {
                    this.setState({ 'tooltip': null });
                }}
            >
                <XAxis
                    title="Date/Time"
                    tickLabelAngle={-90}
                    tickFormat={tickFormat}
                />
                <YAxis
                    tickFormat={(value, index, scale, tickTotal) => {
                        /* Get the resource label as the value... */
                        return grouped[index].label;
                    }}
                />
                {hint}
                {data_series.map((props, index) => <LineMarkSeries
                    {...props}
                    key={`data-series-${index}`}
                    opacity={.8}
                    onValueMouseOver={(event) => {
                        console.log(`Nearest to ${event.label}`);
                        const tooltip = {
                            'Source': resource_and_service_label(event.original),
                            'Value': apply_accessor(event.original, metric.accessor),
                            'original': event.original,
                            'x': event.x,
                            'y': event.y,
                        };
                        this.setState({
                            'tooltip': tooltip,
                        });
                    }}
                    onValueMouseOut={(event) => {
                        this.setState({ 'tooltip': null });
                    }}

                />)}
            </ZoomableTimeline>
        );
    }
}

