import React from 'react';
import { useHistory } from 'react-router-dom';
import redirect from 'redirect';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

const SettingsLink = (props) => {
    /* Configuration control for choosing/setting dashboards */
    const history = useHistory();
    const on_click = (evt) => {
        redirect(props.url, history);
    };
    return <IconButton size="small" onClick={on_click}><MoreVertIcon /></IconButton>;
};

export default SettingsLink;