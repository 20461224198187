function ne(a,b) { return a != b; }
function lt(a,b) { return a < b; }
function gt(a,b) { return a > b; }
function eq(a,b) { return a == b; }

const COMPARE_FUNCTIONS = [
    {
        suffix: '__ne',
        compare: ne,
    },
    {
        suffix: '__lt',
        compare: lt,
    },
    {
        suffix: '__gt',
        compare: gt,
    },
    {
        suffix: '__eq',
        compare: eq,
    },
];

function field_comparison(key) {
    var compare = eq;
    COMPARE_FUNCTIONS.map((record) => {
        if (key.endsWith(record.suffix)) {
            key = key.slice(0,key.length-record.suffix.length);
            compare = record.compare;
        }
    });
    return { key: key, compare: compare};
}

export default field_comparison;