import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { ClassyWidget } from 'reactform/classywidget';
import WidgetRender from './custom_widget_render';
import TransferList from './TransferList';
import { ReactForm } from 'reactform';
import { POST_SIGNAL } from 'storages/ajaxobservables';

const styles = theme => ({
    formControl: {
        minWidth: '120px'
    },
    label: {
        whiteSpace: 'nowrap',
        fontSize: '12px' // not sure why this is needed
    },
    select: {
        width: '100%',
        border: `1px solid ${theme.roles.divider}`,
        overflowY: 'auto'
    },
    option: {
        padding: theme.spacing(1)
    }
});

class TransferListWidget extends ClassyWidget {
    static defaultProps = {
        ...ClassyWidget.defaultProps,
        from_server: (value, widget) => {
            if (Array.isArray(value)) {
                return value;
            } else if (typeof value === 'string') {
                if (value[0] == '[') {
                    return JSON.parse(value);
                } else {
                    return value.split(',');
                }
            } else {
                return value;
            }
        },
    }
    onTransferChange = choices => {
        console.log(`Transfer changed value ${JSON.stringify(choices)}`);

        this.handleChange(choices.map(this.resolve_value));
    }
    resolve_value = (value) => {
        if (Array.isArray(value)) {
            return value[0];
        }
        if (value && value.__pk__) {
            return value.__pk__;
        } else if (value && value.id) {
            return value.id;
        }
        return value;
    }
    onAddRecord = (evt) => {
        /* User has asked to add a new record to the set */
        const creation_form = this.widget_data('creation_form');
        this.setState({
            'sub_form': creation_form,
        });
    }
    onAddRecordClose = (evt) => {
        this.setState({
            'sub_form': null,
        });
        POST_SIGNAL.send(null);
    }
    set_value = (value) => {
        /* External change of value, need to pass to the widget */
        this.setState({});
    }
    render() {
        const { classes, show_label } = this.props;
        const { sub_form } = this.state;
        const field = this.get_field();
        const creation_form = this.widget_data('creation_form');
        const base_field = field.field;
        // console.log(`Choices: ${JSON.stringify(base_field.choices)}`);
        let value = this.render_value();
        let sub_form_rendered = null;
        if (sub_form) {
            sub_form_rendered = <ReactForm onSave={this.onAddRecordClose} onClose={this.onAddRecordClose} key='record-creation-form' {...sub_form} />;
        }

        // const clean_value = (value || []).map(this.resolve_value);
        return <WidgetRender form_field={field} widget_class="select-multiple-widget" show_label={show_label}>
            <TransferList
                items={base_field.choices}
                resolve_value={this.resolve_value}
                value={value || []}
                onAddRecord={creation_form ? this.onAddRecord : null}
                // ref={this.controlRef}
                onChange={this.onTransferChange}
                className={classes.select}
            />
            {sub_form_rendered}
        </WidgetRender>;
    }
}

export default withStyles(styles)(TransferListWidget);
