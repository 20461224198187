import $ from 'fakequery';
import { error_report } from "debugfail";
import { Signal } from "signals";

var LocalStorageBacking = function (state) {
    var self = {
        'change': Signal(),
        'web_storage': window.localStorage,
        'current': null
    };
    $.extend(self, state);
    $.extend(self, {
        get: function () {
            var store = self.web_storage;
            if (self.current === null) {
                var mapping = {};
                var key, value;
                for (var i = 0; i < store.length; i++) {
                    key = store.key(i);
                    value = store.getItem(key);
                    if (value !== null && value !== undefined) {
                        try {
                            mapping[key] = JSON.parse(value);
                        } catch (err) {
                            error_report(err, "Unable to load localStorage key: " + key + " " + value);
                        }
                    }
                }
                self.current = mapping;
            }
            var result = {};
            $.extend(result, self.current);
            return result;
        },
        items: function () {
            /* retrieve all current keys */
            var current = self.get();
            return Object.keys(current).map(key => [key, current[key]]);
        },
        filter: function (prefix) {
            return self.items().filter((record) => record[0].startsWith(prefix));
        },
        getItem: function (key) {
            if (self.current === null) {
                return self.get()[key];
            } else {
                return self.current[key];
            }
        },
        set: function (key, value) {
            if (value === undefined || value === null) {
                self.web_storage.removeItem(key);
                delete self.current[key];
            } else {
                self.web_storage.setItem(key, JSON.stringify(value));
                self.current[key] = value;
            }
            self.change.send(self.current);
        }
    });
    return self;
};

var _cache = null;
var DEFAULT_LOCALSTORAGE = () => {
    if (_cache === null) {
        _cache = LocalStorageBacking({ web_storage: window.localStorage });
    }
    if (!_cache) {
        console.error('LocalStorageBacking is null');
    }
    return _cache;
};

export default LocalStorageBacking;
export { DEFAULT_LOCALSTORAGE };