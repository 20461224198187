/* Timeline implementation for panning/zooming of datasets */
import React from 'react';
import wraps from 'wraps';
import { Signal } from 'signals';

const MIN_ZOOM = 1000 * 60 * 5;
const MAX_ZOOM = 1000 * 60 * 60 * 24 * 366;

const range_signal = () => {
    /* Create a new signal that will emit events when the domain range changes 
    
    Pass range_signal.send_if_changed into a WithDomainZoom component to have
    the signal fire whenever there's a new range for the zoom...
    */
    const signal = new Signal();
    return signal;
};

const WithDomainZoom = (Component) => {
    return wraps((props) => {
        /* Performs zooming based on domain-ranges in one dimension */
        const {
            initial_start = null,
            initial_stop = null,
            initial_pan_follow = true,
            min_zoom = MIN_ZOOM,
            max_zoom = MAX_ZOOM,
            rangeChanged = null
        } = props;
        const [zoom, setZoom] = React.useState({
            start: initial_start,
            stop: initial_stop,
            pan_follow: initial_pan_follow,
        });
        const timestamp = (new Date()).getTime();
        const follow_stop = Math.max(zoom.stop, timestamp);
        const follow_zoom = zoom.pan_follow ? {
            stop: follow_stop,
            start: follow_stop - (zoom.stop - zoom.start),
            pan_follow: zoom.pan_follow,
        } : zoom;
        if (follow_zoom.stop !== zoom.stop || follow_zoom.start !== zoom.start && rangeChanged) {
            window.setTimeout(() => (rangeChanged && rangeChanged(follow_zoom, 10)));
        }
        console.log(`Follow offset (enabled=${zoom.pan_follow}): ${(timestamp - follow_zoom.stop) / 1000}`);
        const doZoom = (center = .5, fraction = 1.25) => {
            console.log(`Zoom around ${center} by ${fraction}`);
            const total = (zoom.stop - zoom.start) || 1;
            const new_total = Math.min(Math.max(fraction * total, min_zoom), max_zoom); // min scale of 5 minutes
            const zoom_center = zoom.start + (total * center);
            const new_start = zoom_center - (new_total * center);
            const new_stop = new_start + new_total;
            const new_zoom = {
                ...zoom,
                start: new_start,
                stop: new_stop,
                pan_follow: stop >= (new Date()).getTime() - (1000 * 60 * 5),
            };
            setZoom(new_zoom);
            rangeChanged && rangeChanged(new_zoom);
        };
        const doPan = (fraction = .2) => {
            let { start, stop } = zoom;
            let total = stop - start;
            let delta = total * fraction;
            const new_zoom = {
                ...zoom,
                start: start - delta,
                stop: stop - delta,
                pan_follow: stop >= (new Date()).getTime() - (1000 * 60 * 5),
            };
            setZoom(new_zoom);
            rangeChanged && rangeChanged(new_zoom);
        };
        const doFollow = (follow = true) => {
            const new_zoom = {
                ...zoom,
                pan_follow: follow,
            };
            setZoom(new_zoom);
        };
        return <Component {...props} doZoom={doZoom} doPan={doPan} zoom={follow_zoom} />;
    }, Component, 'WithDomainZoom');
};

export default WithDomainZoom;

export { WithDomainZoom, range_signal };