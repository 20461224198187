/* User management table */
import React from 'react';
import { EditIntent, delete_intent } from 'editintent';
import { requiring_store } from 'storages/requiringstore';
import { default_user_storage_callback } from 'storages/userstorage';
import Toolbar from '@material-ui/core/Toolbar';
import { current_user } from 'storeregistry';
import { ReactFormTable } from 'reactform/reactformtable';
import { WithTabularStorage } from 'storages/tabularstorage';
import { ReactForm } from 'reactform';

import { DenseList, DenseListItem } from 'dash/denselist';
import { DefaultRender } from 'reactform/typerenderer';

import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import icon_for_type from 'typeicon';
import { PersonOutline as Person } from '@material-ui/icons';
import { LockOutlined as Lock } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    editableGroup: {
        '&:hover': {
            color: 'blue',
            // textDecoration: 'underline',
        },
    },
    currentUser: {
        display: 'flex',
        flexDirection: 'row',
        color: 'darkgreen',
        alignItems: 'baseline',
    },
}))

class BaseUserTable extends React.Component {
    static defaultProps = {
        'data': [],
    }
    render() {
        const { storage, ...childProps } = this.props;
        return <ReactFormTable
            {...childProps}
        />;
    }
}
class UserTableView extends React.Component {
    static defaultProps = {
        'users': []
    }
    render() {
        const user = current_user();
        return <div className="user-table-view">
            <BaseUserTable users={this.props.users} />
            {
                user.has_permission('auth.create_user') &&
                <Toolbar position="fixed" bottom="true" >
                    <EditIntent target={{ '__type__': 'User' }} label='Add User' />
                    <EditIntent target={{ '__type__': 'Group' }} label='Add User Group' />
                </Toolbar>
            }
        </div>;
    }
}
class BaseUserSummary extends React.Component {
    render() {
        let { users } = this.props;
        var count = users.length;
        return `${count} Local Users`;
    }
}


function with_user_store(Component) {
    return requiring_store(
        Component,
        {
            storage_key: 'system_accounts',
            create_function: default_user_storage_callback(),
            forms_key: 'system_accounts_forms',
            prop_function: (props, userstore) => {
                props.users = userstore.users;
                return props;
            }
        }
    );

}
const group_widget = (props) => {
    const classes = useStyles();
    const { value, record, field, alignment } = props;
    const user = current_user();
    const [editing, setEditing] = React.useState(null);
    const closeEditor = React.useCallback((evt) => {
        setEditing(null);

    }, [record, value]);
    const suppress_bubble = React.useCallback((evt) => {
        evt.stopPropagation();
        return false;
    }, [record]);

    let group_editor = null;
    let group_editable_class = null;
    if (user.has_permission('auth.create_group')) {
        group_editable_class = classes.editableGroup;

    }
    if (editing) {
        group_editor = <ReactForm
            storage='system_accounts_forms'
            target={editing}
            onClose={closeEditor}
            onSave={closeEditor}
            buttons={editing.permission ? ['cancel', 'save'] : ['cancel', 'delete', 'save']}
        />;
    }
    return <div className='suppress-link' onClick={suppress_bubble}>
        <DenseList dense={true} className="group-list">
            {
                props.value.map(group => {
                    let icon = group.permission ? <Lock fontSize="small" titleAccess='Permissions Group' /> : <Person fontSize="small" titleAccess='Ownership group' />;
                    return <DenseListItem key={group.name} dense={true} disableGutters={true}>
                        <div className={group_editable_class} onClick={(evt) => {
                            if (group_editable_class) {
                                setEditing(group);
                                evt.preventDefault();
                                return false;
                            }
                        }} title={group.help}>{icon}{group.name}</div>
                    </DenseListItem>;
                })
            }
        </DenseList>
        {group_editor}
    </div>;
};

const UserTable = (props) => {
    /* Wraper user table such that it gets the required tabular storage */
    const classes = useStyles();
    const final_props = {
        key: 'local-users',
        editing_forms: ['User'],
        default_context: props.default_context || {},
        forms_key: 'system_forms',
        form_key: 'Users',
        exclude: ['password', 'confirm', 'current_password'],
        extra_fields: {
            // 'User': [
            //     {
            //         'name': 'permissions',
            //         'label': 'Permissions',
            //         'field': {
            //             'label': 'Permissions',
            //             'label_id': 'id_group',
            //             'help_text': 'Permission groups to which the user belongs',
            //         },
            //     }
            // ],
        },
        column_callback: null,
        preference_key: 'users.table',
        ...props,
    };
    const user = current_user();
    if (user.has_permission('auth.delete_user')) {
        final_props.column_callback = (columns, table) => {
            columns.push(
                {
                    Header: 'Delete',
                    id: 'delete-btns',
                    sortable: false,
                    filterable: false,
                    minWidth: 20,
                    Cell: ({ row }) => {
                        if (!row.original[0].deletable)
                            return null;
                        return delete_intent(row.original[0]);
                    },
                }
            )
            return columns;
        };
    }
    final_props.form_keys = final_props.editing_forms;
    final_props.storage_props = {
        forms_key: final_props.forms_key,
        form_key: final_props.form_key,
        editing_forms: final_props.editing_forms,
    };
    final_props.widgets = {
        'groups': group_widget,
        'username': (props) => {
            const base = <DefaultRender {...props} />;
            if (props.value == user.username) {
                return <div className={classes.currentUser}>{icon_for_type('User')} {base}</div>
            }
            return base;
        },
        ...final_props.widgets
    };
    return <WithTabularStorage
        {...final_props}
        Component={BaseUserTable}
    />;
};

const UserSummary = with_user_store(BaseUserSummary);
export default UserTable;
export { UserTable, UserSummary, with_user_store };
