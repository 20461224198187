/* The Focus of the page is the group/node which the user is currently configuring 

The focus should generally remain the same through page changes
unless the user specifically navigates through the hierarchy,
such as clicking on a node or group link.

The focus should always be one of a Node or a Group, it should
never be a regular config object.
*/
import React from 'react';
const FocusContext = React.createContext();
import { withRouter } from 'react-router-dom';
import { PAGE_FOCUS } from 'storages';
import wraps from 'wraps';
import redirect from 'redirect';
import ResolveTarget from './resolvetarget';

const FocusProvider = (props) => {
    /* Top level wrapper to re-render when focus changes */
    const [focus, setFocus] = React.useState(PAGE_FOCUS.get_focus());
    const on_new_focus = (storage) => {
        const new_focus = PAGE_FOCUS.get_focus()
        setFocus(new_focus);
        // console.log(`Focus is now ${JSON.stringify(new_focus)}`);
    };
    React.useEffect(() => {
        PAGE_FOCUS.change.listen(on_new_focus);
        return () => {
            PAGE_FOCUS.change.ignore(on_new_focus);
        }
    });
    return <FocusContext.Provider value={focus}>
        {props.children}
    </FocusContext.Provider>;
};

const ResolveFocus = (props) => {
    return <ResolveTarget {...props} as_focus={true} ContextClass={FocusContext}>
        {props.children}
    </ResolveTarget>
};
const WithFocus = (Component, key = 'target') => {
    wraps((props) => {
        const focus = useContext(FocusContext);
        const extra_props = {};
        extra_props[key] = focus;
        return <Component {...props} {...extra_props} />;
    }, Component, 'WithFocus');
};


const with_focus = (Component) => {
    /* Legacy API that provides a `focus` parameter that has a small api */
    return withRouter(wraps((props) => {
        const old_focus_shim = {
            /* Shim for use with older components that had context-based focus */
            clear_history: () => {
                PAGE_FOCUS.set_focus(null);
            },
            get_focus: () => PAGE_FOCUS,
            set_focus: (focus, url) => {
                redirect(url, props.history);
                PAGE_FOCUS.set_focus(focus);
                return focus;
            },
            redirect: (url) => {
                redirect(url, props.history);
            }
        };
        return <Component focus={old_focus_shim} {...props} />;
    }, Component, 'with_focus'));
};
const with_bare_focus = with_focus;


export {
    with_focus,
    with_bare_focus,

    FocusProvider,
    ResolveFocus,
    WithFocus,
    FocusContext,
};
