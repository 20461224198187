import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { ClassyWidget } from 'reactform/classywidget';
import { construct_choices } from 'reactform/construct_choices';
import WidgetRender from './custom_widget_render';

const styles = theme => ({
    formControl: {
        minWidth: '120px'
    },
    label: {
        whiteSpace: 'nowrap',
        fontSize: '12px' // not sure why this is needed
    },
    select: {
        width: '100%',
        border: `1px solid ${theme.roles.divider}`,
        overflowY: 'auto'
    },
    option: {
        padding: theme.spacing(1)
    }
});

class SelectMultiple extends ClassyWidget {
    onSelectChange = evt => {
        const options = [...evt.target.options];

        const values = options
            .filter(option => option.selected)
            .map(option => option.value);
        console.log(`Multiple select final value ${JSON.stringify(values)}`);
        this.handleChange(values);
    }
    resolve_value = (value) => {
        if (value && value.__pk__) {
            return value.__pk__;
        } else if (value && value.id) {
            return value.id;
        }
        return value;
    }
    render() {
        const { classes, show_label } = this.props;
        const field = this.get_field();
        const base_field = field.field;
        const value = this.render_value();
        const clean_value = (value || []).map(this.resolve_value);
        return <WidgetRender form_field={field} widget_class="select-multiple-widget" show_label={show_label}>
            <select
                value={clean_value || []}
                name={field.name}
                id={base_field.label_id}
                ref={this.controlRef}
                onChange={this.onSelectChange}
                className={classes.select}
                multiple='multiple'
            >
                {construct_choices(base_field.choices, classes.option)}
            </select>
        </WidgetRender>;
    }
}

export default withStyles(styles)(SelectMultiple);
