/* SFP Status view */
import React from 'react';

// import {NetStatus} from 'systemstatus';
import ATXTable from 'atxtable';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
import RenderBoolean from 'widgets/renderboolean';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
});


class SFPStatusTable extends React.Component {
    static defaultProps = {
        'sfp': null,
    }
    columns = () => {
        return [
            {
                'Header':'Identity',
                'columns': [
                    {'Header':'SFP','accessor':'name'},
                    {
                        'Header':'Present','accessor': 'present',
                        Cell: (props) => {
                            const {value} = props.cell;
                            return <RenderBoolean
                                align='center'
                                value={value}
                                true_title='SFP Detected'
                                false_title='No SFP Detected'
                            />;
                        },
                    },
                ],
            },
            {
                'Header':'Vendor',
                'columns': [
                    {'Header':'Name','accessor':'vendor.name'},
                    // {'Header':'OUI','accessor':'vendor.oui'},
                    {'Header':'Part Number','accessor':'vendor.part'},
                    {'Header':'Serial','accessor':'vendor.serial'},
                    {'Header':'Revision','accessor':'vendor.rev'},
                    {'Header':'Date Code','accessor':'vendor.datecode'},
                ],
            },
            {
                'Header':'Link Speed',
                'columns': [
                    {
                        'Header':'Config','accessor':'linkspeed.config',
                        Cell: (props) => {
                            const {value} = props.cell;
                            if (value == 'auto' || value == 'unknown' || value===undefined || value===null) {
                                return value;
                            }
                            return value.toUpperCase();
                        }
                    },
                    {
                        'Header':'Current','accessor':'linkspeed.current',
                        Cell: (props) => {
                            const {value} = props.cell;
                            if (value == 'auto' || value == 'unknown' || value===undefined || value===null) {
                                return value;
                            }
                            return value.toUpperCase();
                        }
                    },
                    {
                        'Header':'Advertise','accessor':'linkspeed',
                        Cell: (props) => {
                            if (! props.cell.value) {
                                return 'unkonwn';
                            }
                            const {advertise_1g,advertise_10g} = props.cell.value;
                            if (advertise_10g) {
                                return '10G';
                            } else if (advertise_1g) {
                                return '1G';
                            } else {
                                return 'unknown';
                            }
                        },
                    },
                ],
            },
        ];
    }
    state = {
        'columns': this.columns(),
    }

    render() {
        const {sfp,...childProps} = this.props;
        if (sfp && sfp) {
            const records = Object.entries(sfp).map(
                ([key,value]) => {
                    return {
                        ...value,
                        name: key,
                    };
                }
            );
            if (records.length) {
                return <ATXTable
                    data={records}
                    columns={this.state.columns}
                    pageSize={records.length}
                    showPagination={false}
                />;

            }
        } else {
            return null;
        }
    }
}

export default withStyles(styles)(SFPStatusTable);
