import {
    FORMATS,
    SYSTEM_MEASUREMENTS,
    SYSTEM_GRAPHS,
    DEFAULT_MEASUREMENTS,
    DEFAULT_GRAPHS,
    graph_format,
    graph_metric,
    graph_key,
    unix_start_time,
} from 'dash/charts/chartmodel';

const NODE_MEASUREMENTS = [
    {
        'type': 'bool',
        'accessor': 'connected',
        'dataset_accessor': 'Metrics',
        'title': 'Connected',
        'colors': {
            "true": "green",
            "false": "#777700",
            'null': 'white',
        },
        'labels': {
            'true': 'Connected',
            'false': 'Disconnected',
            'null': 'Unknown',
            '__excluded__': 'Unknown',
        },
        '__key__': `connected`,
        'id': (record, id) => id,
    },
    {
        'type': 'bool',
        'accessor': 'error',
        'dataset_accessor': 'Metrics',
        'title': 'Error',
        'colors': {
            "true": "red",
            "false": "#007700",
            'null': 'white',
        },
        'labels': {
            'true': 'Error',
            'false': 'No Error',
            'null': 'Unknown',
            '__excluded__': 'Unknown',
        },
        '__key__': `error`,
        'id': (record, id) => id,
    },
    {
        'type': 'enum',
        'accessor': 'state',
        'dataset_accessor': 'Metrics',
        'title': 'Status',
        'colors': {
            'null': 'white',
        },
        'labels': {
            'null': 'Unknown',
            '__excluded__': 'Unknown',
        },
        '__key__': `state`,
        'id': (record, id) => id,
    },

];

const NODE_GRAPHS = [
    {
        'format': 'enum.pie',
        'metric': 'connected',
        'title': 'Connected',
        'description': 'Devices connected/disconnected',
    },
    {
        'format': 'enum.pie',
        'metric': 'error',
        'title': 'Error',
        'description': 'Devices are in error state',
    },
    {
        'format': 'enum.pie',
        'metric': 'state',
        'title': 'State',
        'description': 'Short description of the device state',
    },
];

export { NODE_MEASUREMENTS, NODE_GRAPHS };