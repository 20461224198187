import React from 'react';
import {WithTabularStorage} from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import {ReactFormTable} from 'reactform';
import {ReactForm} from 'reactform';
import {with_focus} from 'dash/focusprovider';
import { delete_target_action } from 'reactform/actionmenu';
import {CellRender} from 'reactform/typerenderer';

class BaseRFSourceMapTable extends React.Component {
    on_edit_map = (props) => {
        /* Customise the RFSource for for cross-field links */
        const before_change = (form, field, new_value, post) => {
            const new_post = {...post};
            const change_mode = (mode) => {
                new_post.follow_mode = mode;
                form.current_widget('follow_mode').set_value(mode,true);
            };
            if (field.name == 'source_name') {
                change_mode(1);
            } else if (field.name == 'source_id') {
                change_mode(0);
            } else if (field.name == 'source_channel_number') {
                change_mode(3);
            } else if (field.name == 'source_frequency' || field.name == 'source_program_number') {
                change_mode(2);
            }
            return new_post;
        };
        return <ReactForm
            {...props}
            beforeChange={before_change}
        />;
    };
    render() {
        const {form_details,mapping,storage} = this.props;
        if (!(form_details)) {
            return <MuiLoader />;
        }
        return <ReactFormTable
            // link_function={this.on_view_group.bind(this)}
            form_keys={this.props.editing_forms}
            form_details={form_details}
            editable={true}
            overall_target={mapping}
            onForm={this.on_edit_map}
            field_actions={{
                'source_channel_number': (target, context, menu) => {
                    return [
                        delete_target_action(target, context, menu),
                    ];
                }
            }}
            widgets={{
                'sink': (props) => {
                    const sinks = props.record.sinks;
                    return <CellRender {...props} value={sinks} />;
                }
            }}
            {...this.props}
            exclude={[
                'mapping',
                'notes',
                ...this.props.exclude,
            ]}
        />;
    }
}

const mapping_specific_storage = (mapping) => {
    /* Get a group-specific storage for given group *or* nodes/non-nodes */
    const context = {};
    if (mapping) {
        context.mapping = mapping.id || mapping;
    }
    return {
        default_context: context,
        forms_key:'shogunconf_forms',
        form_key:'ShogunRFSourceMaps',
        quick_settings: {
        },
    };
};


const RFSourceMapTable = (props) => {
    return <WithTabularStorage
        key={`rfsources-for-${props.mapping.id}`}
        storage_props={props.storage_props}
        Component={with_focus(BaseRFSourceMapTable)}
        editing_forms={['RFSource']}
        quick_settings={{
            'follow_mode': 'QuickSettingFollowMode',
            'enabled': 'QuickSettingRFSourceEnabled',
        }}
        {...props}
    />;
};
export default RFSourceMapTable;
export {mapping_specific_storage, BaseRFSourceMapTable, RFSourceMapTable};
