import React from 'react';
/* Display a stat-block for a given metric */
import useMetricData from './usemetricdata';

const BaseStatBlock = (props) => {
    const { description, target, metric } = props;
    const { data, resources, form_details } = useMetricData({
        query_type: 'last_status',
        metric: metric,
        target: target,
    });
    return `${JSON.stringify(data)}`;
};



export default BaseStatBlock;