import $ from 'fakequery';
import { stores } from 'storeregistry';
import { ReactForm } from 'reactform';
import { using_nearest_form } from 'formprovider';
import React from 'react';
import EditIntentRender from 'EditIntentRender';
import getIcon from 'getIcon';

class IntentTrigger extends React.Component {
    /* Takes two things, a trigger and children, it shows children when triggered */
    static defaultProps = {
        trigger: null,
        signal: null,
        className: 'intent-trigger',
        children: null
    };
    render() {
        return (
            <div className={this.props.className}>
                <a
                    className="flat-button clickable"
                    onClick={this.props.onTrigger}
                >
                    {this.props.trigger}
                </a>
                {this.props.editing ? this.props.children : null}
            </div>
        );
    }
}

class _EditIntent extends React.Component {
    static defaultProps = {
        debug: false,
        target: null,
        as_link: false,
        form_key: null,
        label: null,
        icon: null,
        form: null,
        help_text: null,
        className: null,
        action: 'Edit',
        context: null, // Dictionary to pass to form-retrieval request to fill in data...
        initial_post: null, // Dictionary to post on initial (single-shot forms...)
        type_name: null, // if provided, adds a label (normally for adds)
        describe: function (target, self) {
            /* function to describe the target */
            if (target.title) {
                return target.title;
            }
            if (target.name) {
                return target.name;
            }
            if (target.description) {
                return target.description;
            }
            if (target.friendly_description) {
                return target.friendly_description;
            }
            if (self.props && self.props.type_name) {
                return self.props.type_name;
            }
            if (target.type_name) {
                return target.type_name;
            }
            return target.type_name || target.type || target.__type__;
        },
        context: null,
        onSave: null,
        onClose: null,
        storage: stores.forms
    };

    state = {
        editing: false
    };

    handleClick = evt => {
        this.setState({ editing: !this.state.editing });
        evt.stopPropagation();
    };

    handleDialogSave = (form, result) => {
        if (this.props.onSave) {
            var result = this.props.onSave(this, result, form);
            if (result === false) {
                return;
            }
        }
        this.setState({ editing: false });
    };

    handleKeyUp = evt => {
        /* TODO: do global event capture to make escape work properly */
        if (evt.keyCode == 27) {
            // escape
            evt.stopPropagation();
            this.handleDialogClose(evt);
        }
    };

    handleDialogClose = () => {
        if (this.props.onClose) {
            this.props.onClose(this);
        }
        this.setState({ editing: false });
    };

    render() {
        var target = this.props.target;
        var className = this.props.className || '';
        if (!target) {
            target = {
                name: 'New',
                id: '',
                type: this.props.form_key
            };
        }
        var form = null;
        var form_props = { ...this.props };
        form_props.onSave = this.handleDialogSave;
        form_props.onClose = this.handleDialogClose;
        if (this.state.editing) {
            form_props.className =
                (form_props.className || '') +
                ' edit-intent-dialog ' +
                ' type-' +
                (this.props.form_key || target.type);
            if (this.props.form) {
                form = this.props.form(form_props);
            } else {
                form = <ReactForm {...form_props} />;
            }
        }
        var title = this.props.help_text;
        if (!title) {
            title = this.state.editing
                ? 'Click to close, discarding ' + this.props.action
                : this.props.help_text ||
                'Click to ' +
                this.props.action +
                ' ' +
                this.props.describe(this.props.target, this);
        }
        var type_name = this.props.label;
        if (type_name === undefined || type_name === null) {
            type_name = this.props.type_name;
        }
        var icon = this.props.icon;
        if (icon === false) {
            icon = null;
        } else if (icon === undefined || icon === null) {
            icon = target.id ? getIcon('edit') : getIcon('add');
        }
        if (typeof icon === 'string') {
            icon = getIcon(icon);
        }
        className += ' edit-intent inline-block';
        if (type_name) {
            //className += ' no-break';
        }
        className += ' type-' + (this.props.form_key || target.type);
        className += target.id ? ' instance-' + target.id : ' new';
        return (
            <EditIntentRender
                {...this.props}
                handleClick={this.handleClick}
                handleKeyUp={this.handleKeyUp}
                editing={this.state.editing}
                icon={icon}
                title={title}
                className={className}
                type_name={type_name}
                form={form}
            />
        );
    }
}
var EditIntent = using_nearest_form(_EditIntent);
function EditIntentFactory(props) {
    /* Allows old pre-jsx code to continue to run */
    return <EditIntent {...props} />;
}

var delete_intent = function (instance, form_key, type_name, label, onDelete = null) {
    var id = instance.__pk__ || instance.pk || instance.id;
    var typ = form_key || instance.type || instance.__type__;
    if (!id) {
        return null;
    } else if (!typ) {
        return null;
    }
    // if label is provided, adds a label (normally for adds)
    return (
        <EditIntent
            key={'delete-' + typ + '-' + id}
            style={{
                'display': 'flex',
            }}
            form_key={typ}
            action='Delete'
            type_name={label || null}
            icon={getIcon('close')}
            target={instance}
            className={'delete-intent'}
            context={{
                'delete-instance': true
            }}
            onSave={function (result) {
                if (onDelete) {
                    return onDelete(result);
                }
                return result;
            }}
            help_text={'Delete the ' + (instance.type_name || typ)}
        />
    );
};

export {
    delete_intent,
    using_nearest_form,
    EditIntent,
    EditIntentFactory,
    IntentTrigger
};
export default EditIntent;
