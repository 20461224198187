import React from 'react';
import { GroupEditTable, group_specific_storage } from './groupedittable';
import { providing_nearest_form } from 'formprovider';
import { with_resolved_target } from 'dash/resolvetarget';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { ReactForm } from 'reactform';
import { with_focus } from 'dash/focusprovider';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import { EditIntent } from 'editintent';
import { service_group_specific_storage, Q2AEditTable } from './q2aedittable';
import { AnalogMapTable, mapping_specific_storage } from 'shogun/analogmaptable';
import FocusLink from 'dash/focuslink';

class BaseAnalogMappingView extends React.Component {
    render() {
        const instance = this.props.target;
        if (!instance) {
            return 'No target';
        }
        const storage_props = mapping_specific_storage(instance);
        storage_props.debug = true;
        const table_actions = [
            <EditIntent
                key="add-map"
                target={{
                    'type': 'AnalogMap',
                    'id': null,
                }}
                context={{
                    'enabled': true,
                    'mapping': instance.id,
                }}
                action='Add Program'
                label='Add Program'
                help_text='Add a new program id to analog output map'
            />,
            <FocusLink
                focus_on={instance}
                key={`map-sources`}
                to={`/m/source-map/${instance.id}`}
                title='Import a (selection of) sources from those available to a given Device'
            >Map Available Sources</FocusLink>
        ];
        return <React.Fragment>
            <EditingCard title={'Analog Output Mappings'} actions={table_actions} no_wrapper={true} >
                <AnalogMapTable
                    key='analog-mapping-${mapping}'
                    mapping={instance}
                    storage_props={storage_props}
                />
            </EditingCard>
            <EditingCard title={`${instance.title} Metadata`} collapsing='analog-mapping-summary'>
                {instance.summary_points && <ul className="mapping-summary">
                    {instance.summary_points.map((summary, i) => (
                        <li key={`summary-${i}`}>{summary}</li>
                    ))}
                </ul>}
                <ReactForm target={instance} use_dialog={false} />
            </EditingCard>
        </React.Fragment>;
    }
}
const AnalogMappingView = providing_nearest_form(
    with_resolved_target(
        BaseAnalogMappingView
    ),
    'shogunconf_forms'
);

export default AnalogMappingView;
export { BaseAnalogMappingView, AnalogMappingView };