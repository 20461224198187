/* Central registry for storages

This module is intentionally *not* pulling in all of the machinery
of the BaseStorage, as it's intended to allow modules to import
and use the stores and current_user entry points without needing
to import e.g. jquery or other unfriendly dependencies.
*/
var stores = {};

var current_user = function() {
    var user = null;
    Object.keys(stores).map(function(key) {
        var store = stores[key];
        if (store.user && ! user) {
            user = user || store.current_user();
        }
    });
    if (! user ) {
        Object.keys(stores).map(function(key) {
            var store = stores[key];
            try {
                user = user || store.current_user();
            } catch (e) {
                console.debug("No user in store "+store.key);
            }
        });
    }
    return user;
};


export default stores;
export {current_user, stores};