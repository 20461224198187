/* Resolve a reference to an instance before rendering children */
import React from 'react';
import { using_nearest_form } from 'formprovider';
import MuiLoader from 'dash/MuiLoader';
import { ErrorList } from 'errorlist';
import { POST_SIGNAL } from "storages/ajaxobservables";
import wraps from 'wraps';
import { PAGE_FOCUS } from 'storages';

const TargetContext = React.createContext();

const load_instance = (props, callback) => {
    /* Load instance using storage and parameters */
    const { __type__, __pk__, context, target, storage, target_context = TargetContext } = props;
    // console.log(`Request going onu`);

    return storage.get_form(__type__, __pk__, context).then(
        result => {
            if (result && result.success) {
                if (result.instance) {
                    return result.instance;
                } else {
                    const err = new Error();
                    err.messages = [`Unexpected format: ${JSON.stringify(result, null, 2)}`];
                    throw (err);
                }
            } else if (result && result.error) {
                const err = new Error(JSON.stringify(result.messages));
                err.messages = result.messages;
                throw err;
            }
        }
    );
};

const null_load = props => {
    /* If true, there's nothing required to load */
    const { __type__, __pk__ } = props;
    if (__type__ === null || __pk__ === null || __type__ === '' || __pk__ === '') {
        return true;
    } else {
        return false;
    }
};
const same_target = props => {
    /* If true, the target is the thing referenced by the load */
    const { __type__, __pk__, target } = props;
    if (target && target.__type__ == __type__ && ((target.__pk__ == __pk__) || (target.id == __pk__))) {
        return true;
    }
    return false;
};

class BaseResolveTarget extends React.Component {
    static defaultProps = {
        '__type__': null, // Type/Form name...
        '__pk__': null, // Primary key
        'context': null, // Context for the retrieval
        'as_focus': false, // If true, this is the page's overall focus for headers and the like...
        'target': null, // Provide an already-resolved record...
        'storage': null, // FormStorage
        'ContextClass': TargetContext, // Which context to set when rendering...
        debug: false,
    };
    active = true;
    state = {
        'loaded': false,
        'error': false,
        'messages': [],
        'target': this.props.target,
    };
    mark_loaded = (target) => {
        if (this.active) {
            if (this.props.debug) {
                console.log(`Loaded ${target && target.__key__}`);
            }
            this.setState({
                'loaded': true, 'error': false, 'messages': [], 'target': target,
            });
            if (this.props.as_focus) {
                PAGE_FOCUS.set_focus(target);
            }
        }
    };
    mark_unloaded = () => {
        if (this.active) {
            this.setState({
                'loaded': false, 'error': false, 'target': null,
            });
        }
    };
    refresh = () => {
        const { __type__, __pk__, context, target, storage } = this.props;
        if (!this.active) {
            return;
        }
        if (__pk__ === 0 || __pk__ === '0') {
            if (this.props.debug) {
                console.log(`PK of 0, clearing target/focus`);
            }
            this.mark_loaded(null);
            return;
        }
        if (null_load(this.props)) {
            this.mark_loaded(null);
            return;
        } else if (same_target(this.props)) {
            this.mark_loaded(target);
            return;
        }

        const expected_key = `${__type__}.${__pk__}`;
        if (this.props.debug) {
            console.log(`Resolve the target key: ${expected_key}`);
        }

        load_instance(this.props).then(
            instance => {
                if (!this.active) {
                    return;
                }
                if (!instance) {
                    return;
                }
                this.mark_loaded(instance);
            }
        ).catch(e => {
            console.error(`Failure loading instance ${__type__}.${__pk__}`);
            console.info(`Error loading ${expected_key}, will retry`);
            window.setTimeout(this.reload, 5000);
            if (this.active) {
                this.setState({
                    'loaded': false,
                    'error': true,
                    'messages': e.messages || e.message || [
                        `Error loading: ${e}`
                    ],
                });
            }
        });

    };
    reload = () => {
        if (this.active && !this.state.loaded) {
            this.refresh();
        }
    };

    componentDidMount() {
        if (!null_load(this.props)) {
            this.reload();
        }
        POST_SIGNAL.listen(this.refresh);
    }
    componentWillUnmount() {
        this.active = false;
        POST_SIGNAL.ignore(this.refresh);
    }
    render() {
        const { ContextClass } = this.props;
        const { loaded, error, messages, target } = this.state;
        if (null_load(this.props)) {
            return <React.Fragment>{this.props.children}</React.Fragment>;
        } else if (loaded) {
            // console.log(`Setting target on ${getDisplayName(this)}`);
            return <ContextClass.Provider value={this.state.target}>
                {this.props.children}
            </ContextClass.Provider>;
        } else if (error) {
            const array_messages = Array.isArray(messages) ? messages : [messages];
            return <ErrorList errors={array_messages} />;
        } else {
            return <MuiLoader />;
        }
    }
}
const ResolveTarget = using_nearest_form(BaseResolveTarget, false);
ResolveTarget.displayName = 'ResolveTarget';
const with_resolve_target = (Component, ContextClass = TargetContext, as_focus = false) => {
    ContextClass = ContextClass || TargetContext;
    const with_resolve_target = wraps((props) => {
        const { __type__, __pk__, storage, context, target, ...childProps } = props;
        if (__type__ && __pk__ && storage) {
            return <ResolveTarget storage={storage} __type__={__type__} __pk__={__pk__} context={context} target={target} ContextClass={ContextClass} as_focus={as_focus}>
                <Component storage={storage} __type__={__type__} __pk__={__pk__} context={context} {...childProps} />
            </ResolveTarget>;
        } else {
            return <Component {...props} />;
        }
    }, Component, 'with_resolve_target');
    return with_resolve_target;
};
const with_target = (Component, ContextClass = TargetContext, key = 'target') => {
    ContextClass = ContextClass || TargetContext;
    const with_target = wraps((props) => {
        return <ContextClass.Consumer>
            {(target) => {
                const final_props = { ...props };
                final_props[key] = target;
                return <Component {...final_props} />;
            }}
        </ContextClass.Consumer>;
    }, Component, 'with_target');
    return with_target;
};
const with_resolved_target = (Component, ContextClass = TargetContext, key = 'target', as_focus = false) => {
    ContextClass = ContextClass || TargetContext;
    const RComponent = with_target(Component, ContextClass, key);
    const with_resolved_target = wraps((props) => {
        const { __type__, __pk__, context, target, ...childProps } = props;
        return <ResolveTarget __type__={__type__} __pk__={__pk__} context={context} target={target} ContextClass={ContextClass} as_focus={as_focus}>
            <RComponent __type__={__type__} __pk__={__pk__} context={context} {...childProps} />
        </ResolveTarget>;
    }, RComponent, 'with_resolved_target');
    return with_resolved_target;
};

export default ResolveTarget;
export { with_resolve_target, with_target, with_resolved_target, ResolveTarget, null_load, TargetContext };
