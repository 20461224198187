/* Provide "nearest form-set" resolution for low-level components */
import React from 'react';
const FormContext = React.createContext();
import stores from "storeregistry";
import getDisplayName from 'react-display-name';
import { FormStorage } from 'storages/basestorage';
import wraps from 'wraps';

const with_resolved_form_storage = (Component) => {
    /* Iff we have a form_storage or storage that is a form-storage provide as nearest */
    return wraps((props) => {
        let { storage } = props;
        if (storage) {
            if (typeof storage == 'string') {
                storage = store_by_key(props.storage, Component);
                if (!(storage.get_form && storage.get_form_template)) {
                    return <div className='error'>Specified storage {props.storage} is not a form storage</div>;
                }
            }
            if (storage && storage.get_form && storage.get_form_template) {
                return <FormContext.Provider value={storage}>
                    <Component {...props} storage={storage} />
                </FormContext.Provider>;
            }
        }
        return <Component {...props} />;
    }, Component, 'with_resolved_form_storage');
};

const using_nearest_form = (Component, required = true) => {
    return wraps(with_resolved_form_storage(React.forwardRef((props, ref) => {
        return <FormContext.Consumer {...props}>
            {(storage) => {
                if (storage || (!required)) {
                    return <Component  {...props} storage={storage} ref={ref} />;
                } else {
                    return <div className="error missing-form-storage">
                        Missing form storage context for this component {getDisplayName(Component) || Component}
                        <Component {...props} ref={ref} />
                    </div>;
                }
            }}
        </FormContext.Consumer>;
    })), Component, 'using_nearest_form');
};

const store_by_key = (storage_key, Component) => {
    if (!stores[storage_key]) {
        try {
            stores[storage_key] = FormStorage({
                key: storage_key,
                debug: false,
            });
        } catch (e) {
            console.log(`providing_nearest_form() referencing an undefined storage: ${storage_key} for ${Component} did you register it in reactforms ${e}`);
            return <div className="error">
                Missing form storage {storage_key}
            </div>;
        }
        if (!stores[storage_key].get_form) {
            console.warn(
                `The storage key ${storage_key} references a non-form storage (no get_form method); check your providing_nearest_form() call`
            );
            return <div className="error">
                Non-form storage {storage_key} encountered in form-storage requiring element
            </div>;
        }
    }
    return stores[storage_key];
};

function providing_nearest_form(Component, storage_key) {
    if (!Component) {
        console.error("Got a null component for key " + storage_key);
        throw ("Null component");
    }
    if (!storage_key) {
        console.error("Passed a null key for providing_nearest_form");
        throw ("Null storage key");
    }
    class WithNearestForm extends React.Component {
        static displayName = `providing_nearest_form.${storage_key}(${getDisplayName(Component)})`;
        render() {
            const storage = store_by_key(storage_key, Component);
            if (storage && storage.get_form) {
                return <FormContext.Provider value={stores[storage_key]}>
                    <Component {...this.props} />
                </FormContext.Provider>;
            } else {
                return storage;
            }
        }
    }
    return WithNearestForm;
}

export { FormContext, using_nearest_form, providing_nearest_form, store_by_key };
