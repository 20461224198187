import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MuiIcon from 'dash/MuiIcon';
import { NavLink } from 'react-router-dom';
import { ActiveRouteContext, get_page_url, current_user_can_view } from 'dash/navigationUtils';
import { FocusContext } from 'dash/focusprovider';
import { useCurrentUser } from 'withcurrentuser';
import Sysinfo from 'dash/sidebarinfo';
import PAGE_FOCUS from 'storages/pagefocus';
import redirect from '../redirect';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    subNav: {
        paddingLeft: theme.spacing(theme.contentSpacing),
        paddingRight: theme.spacing(theme.contentSpacing),
        paddingTop: theme.spacing(theme.contentSpacing),
    },
    subNavActive: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        marginRight: theme.spacing(.5),
    },
    subNavRegular: {
        background: '#fafdff',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        marginRight: theme.spacing(.5),
    }
}));
const LinkTab = (props) => {
    /* Note: needs to be *called* because the Tabs item deals directly with its child elements */
    const { page, user, routes, ...restProps } = props;
    const target_url = get_page_url(page, focus, user, routes);
    return <Tab key={page.path} value={page.path} to={target_url} label={page.header} component={NavLink} {...restProps} />
}
const isExpanded = (path, activeRoutes) => {
    return !!(activeRoutes.filter(route => path === route.path).length);
}

const NavigationTabs = (props) => {
    /* Horizontal navigation tabs for a group of routes */
    const { routes, current_page, current_value, user, classes, ...restProps } = props;
    const focus = React.useContext(FocusContext);

    const tabs = routes.map((page, index) => {
        const tabClasses = {};
        if (page.path == current_page.path && classes) {
            tabClasses.root = classes.indicator;
        } else if (classes) {
            tabClasses.root = classes.regular;
        }
        return LinkTab({ page: page, key: `tab-${index}`, user: user, routes: routes, focus: focus, classes: tabClasses });
    });
    return <Tabs value={current_value} {...restProps}>
        {tabs}
    </Tabs>;
}

const AppTabBar = (props) => {
    /* Horizontal variant of the AppSideBar */
    const { routes } = props;
    let subtabs = null;
    const { activeRoutes } = React.useContext(ActiveRouteContext);
    const classes = useStyles();
    const user = useCurrentUser();
    const visible_routes = routes
        .filter(page => !page.hideInNav)
        .filter(page => current_user_can_view(page, user));
    let current_value = visible_routes.length > 0 ? visible_routes[0].path : '';
    let current_page = null;
    routes.map((page, index) => {
        if (isExpanded(page.path, activeRoutes)) {
            current_value = page.path;
            current_page = page;
        }
    });

    // Now, IFF the *current* item has sub-routes, then render the secondary tabs...
    if (current_page && current_page.routes) {
        const visible_children = current_page.routes
            .filter(page => !page.hideInNav)
            .filter(page => current_user_can_view(page, user));
        let current_child = null;
        let current_child_value = null;
        visible_children.map((page, index) => {
            if (isExpanded(page.path, activeRoutes)) {
                current_child_value = page.path;
                current_child = page;
            }
        });
        if (visible_children.length) {
            subtabs = <div className={classes.subNav}>
                <NavigationTabs
                    routes={visible_children}
                    current_value={current_child_value}
                    current_page={current_child}
                    classes={{
                        'indicator': classes.subNavActive,
                        'regular': classes.subNavRegular,
                    }}
                />
            </div>;
        }
    }

    return <React.Fragment>
        <Paper key="main-nav-highlight" elevation={8}>
            <NavigationTabs routes={visible_routes} current_value={current_value} current_page={current_page} user={user} />
        </Paper>
        {subtabs}
    </React.Fragment>;

}
export default withRouter(AppTabBar);
