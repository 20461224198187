import React from 'react';
import { providing_nearest_form } from 'formprovider';
import { with_resolved_target } from 'dash/resolvetarget';
import { ReactForm, ReactFormTable } from 'reactform';
import { with_focus } from 'dash/focusprovider';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import { EditIntent } from 'editintent';
import { Link } from 'react-router-dom';
import FocusLink from 'dash/focuslink';
import { url_for_key } from 'storages';
import { WithToolbar } from 'shogun/shogunpromotebar';
import ListView from 'shogun/listview';
import { WithTabularStorage } from 'storages/tabularstorage';
import { ServiceCSVDownloader, ServiceCSVUploader } from 'shogun/servicecsv';


const CacheServiceEditor = (props) => {
    const { target, editable, storage, excludes = ['notes'], ...restProps } = props;

    const actions = [
        (target && target.id) && <ServiceCSVUploader
            target={target}
        />,
        (target && target.id) && <ServiceCSVDownloader
            target={target}
        />,
    ].filter(x => !!x);
    return <ListView
        __type__={'CacheMap'}
        storage={'shogunconf'}
        actions={actions}
        inPlaceEditing={editable}
        overall_target={target}
        column_widths={{
            'enabled': 'small',
            'host_name': 'wide',
            'host_ip': 'wide',
        }}
        defaultSorting={[
            { id: 'host_name', desc: false },
        ]}
        preference_key='gui.cache-service-edit-table'
        preference_default={{
            hiddenColumns: [
                'mapping',
            ]
        }}
        {...restProps}
        editable={editable}
        context={{
            'mapping': target.id,
        }}
        creation_context={{
            'mapping': target.id,
        }}
    />;
};


class BaseCacheServiceView extends React.Component {
    render() {
        const instance = this.props.target;
        if (!instance) {
            return null;
        }
        const children_context = {
            mapping: instance.__pk__ || instance.id,
        };
        const children_creation_context = {
            ...children_context,
            enabled: true,
        };
        return <React.Fragment>
            <WithTabularStorage
                Component={CacheServiceEditor}
                storage_props={{
                    default_context: children_context,
                    forms_key: 'shogunconf_forms',
                    form_key: 'CacheMaps',
                    debug: true,
                }}
                key={`hosts`}
                target={instance}
                editable={true}
            />
            <EditingCard title={`${instance.title} Metadata`} key={`metadata-card-${instance.id}`}>
                <ReactForm target={instance} use_dialog={false} key='cache-service-metadata' />
            </EditingCard>
        </React.Fragment>;
    }
}
const CacheServiceView = WithToolbar(providing_nearest_form(
    with_resolved_target(
        BaseCacheServiceView
    ),
    'shogunconf_forms'
));

export default CacheServiceView;
export { BaseCacheServiceView, CacheServiceView };
