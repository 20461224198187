import React from 'react';
import { WithTabularStorage } from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import { ReactFormTable } from 'reactform';
import { ReactForm } from 'reactform';
import { with_focus } from 'dash/focusprovider';
// import {problem_table_field} from 'shogun/problems';
import { delete_target_action } from 'reactform/actionmenu';

const analog_map_form = (props) => {
    const before_change = (form, field, new_value, new_post) => {
        /* User has changed a value on the form, update linked fields if necessary */
        if (field.name == 'source_id' || field.name == 'source_name') {
            // Update name and frequency as though from outside...
            const choice = field.field.choices.find(choice => `${choice[0]}` == `${new_value}`);
            if (choice) {
                const [local, remote] = choice[1].split('\u21d2').map(s => s.trim());
                const other = field.name === 'source_name' ? 'source_id' : 'source_name';
                console.debug(`Updating ${other} to ${remote} on choice of ${local} for ${field.name}`);
                const control = form.current_widget(other);
                if (control) {
                    if (other == 'source_id') {
                        control.set_edit_value(parseInt(remote));
                    } else {
                        control.set_edit_value(remote);
                    }
                }
                new_post[other] = remote;
            }
        }
        return new_post;
    };
    return <ReactForm {...props} beforeChange={before_change} />;
};

class BaseAnalogMapTable extends React.Component {
    on_edit_map = (form_props, table) => {
        return analog_map_form(form_props);
    }
    render() {
        const { form_details, mapping, storage } = this.props;
        if (!(form_details)) {
            return <MuiLoader />;
        }
        return <ReactFormTable
            // link_function={this.on_view_group.bind(this)}
            form_keys={this.props.editing_forms}
            form_details={form_details}
            editable={true}
            overall_target={mapping}
            exclude={[
                'mapping',
                'follow_value',
                'audio_channels',
                'with_cc',
                'deinterlace_tff',
                'source',
            ]}
            field_actions={{
                'follow_mode': (target, context, menu) => {
                    return [
                        delete_target_action(target, context, menu),
                    ];
                }
            }}

            extra_fields={{
                'AnalogMap': [
                    {
                        'name': 'sources', 'label_id': 'id_source', 'label': 'Sources',
                        'widget': 'DisplayWidget',
                        'field': {
                            'help_text': 'Displays the inputs feeding this output',
                        }
                    },
                    // problem_table_field(),
                ]
            }}
            onForm={this.on_edit_map}
            {...this.props}
        />;
    }
}

const mapping_specific_storage = (mapping) => {
    /* Get a group-specific storage for given group *or* nodes/non-nodes */
    const context = {
        mapping: (mapping && mapping.id) || mapping || '__default__',
    };
    return {
        default_context: context,
        forms_key: 'shogunconf_forms',
        form_key: 'ShogunAnalogMaps',
    };
};


const AnalogMapTable = (props) => {
    return <WithTabularStorage
        key={`analog-maps-for-${props.mapping.id}`}
        storage_props={props.storage_props}
        Component={with_focus(BaseAnalogMapTable)}
        editing_forms={['AnalogMap']}
        quick_settings={{
            'enabled': 'QuickSettingAnalogEnabled',
            'cropping_automatic': 'QuickSettingCroppingAutomatic',
            'aspect_ratio_control': 'QuickSettingAspectRatio',
            'audio_volume': 'QuickSettingAudioVolume',
            'audio_volume_sap': 'QuickSettingAudioVolumeSap',
            'v_carrier': 'QuickSettingVCarrier',
            'v_channel': 'QuickSettingVChannel',
        }}
        {...props}
    />;
};
export default AnalogMapTable;
export { mapping_specific_storage, BaseAnalogMapTable, AnalogMapTable };
