import React from 'react';
import { WithTabularStorage } from 'storages/tabularstorage';
import { EditIntent } from 'editintent';
import MuiLoader from 'dash/MuiLoader';
import { ReactFormTable } from 'reactform';
import { Link } from 'react-router-dom';
import { fa_icon } from 'faicon';
import { with_target, with_resolve_target } from 'dash/resolvetarget';
import { delete_target_action } from 'reactform/actionmenu';
import { CellRender } from 'reactform/typerenderer';
import { useCurrentUser } from 'withcurrentuser';

class BaseEPGGenTable extends React.Component {
    render() {
        const { form_details, mapping, storage, editable, user } = this.props;
        if (!(form_details)) {
            return <MuiLoader />;
        }
        const field_actions = {
            'number': editable ? ((target, context, menu) => {
                return [
                    editable ? delete_target_action(target, context, menu) : null,
                ];
            }) : null,
        };
        return <ReactFormTable
            // link_function={this.on_view_group.bind(this)}
            form_keys={this.props.editing_forms}
            form_details={form_details}
            editable={editable}
            inPlaceEditing={editable}
            overall_target={mapping}
            preference_key={this.props.preference_key || 'epggen.table'}
            defaultSorting={[
                { 'id': 'number', 'desc': false, },
            ]}
            // onForm={this.on_edit_map}
            field_actions={field_actions}
            widgets={{
                'link': (props) => {
                    const sinks = props.record.sinks;
                    return <CellRender {...props} value={sinks} />;
                }
            }}
            column_widths={{
                number: 'small',
                logs: 'small',
            }}
            column_callback={(columns, table) => {
                if (user && user.has_permission('config.scary_debugging')) {
                    columns.push({
                        'Header': 'Logs',
                        'id': 'logs',
                        'size': 'medium',
                        'accessor': 'number',
                        'Cell': (props) => {
                            return <div>
                                <Link
                                    key="view-generator-log"
                                    to={`/sys/log/xsourcebin-${props.row.values.number}`}
                                >{fa_icon('history')} Gen</Link>
                                {' '}
                                <Link
                                    key="view-client-log"
                                    to={`/sys/log/epg-client-${props.row.values.number + 10}`}
                                >{fa_icon('history')} Render</Link>
                            </div>;
                        }
                    });
                }
                return columns;
            }}
            {...this.props}
            exclude={[
                'mapping',
                'notes',
                ...(this.props.exclude || []),
            ]}
        />;
    }
}

const mapping_specific_storage = (mapping) => {
    /* Get a group-specific storage for given group *or* nodes/non-nodes */
    const context = {

    };
    if (mapping) {
        context.mapping = mapping.__pk__ || mapping.id || mapping;
    }
    return {
        default_context: context,
        forms_key: 'shogunconf_forms',
        form_key: 'EPGGens',
        debug: false,
        quick_settings: {
        },
    };
};
const DecoratedEPGGenTable = with_resolve_target(with_target(BaseEPGGenTable));

const EPGGenTable = (props) => {
    const user = useCurrentUser();
    const final_props = { ...props, user };

    if (!props.storage_props) {
        final_props.mapping = {
            __pk__: props.__pk__,
            __type__: 'EPGGenMapping'
        };
        final_props.storage_props = mapping_specific_storage(props.__pk__);
    }
    return <WithTabularStorage
        key={`epggens-for-${final_props.mapping.id}`}

        __type__='EPGGenMapping'
        __pk__={props.__pk__ || '__default__'}

        storage_props={final_props.storage_props}
        Component={DecoratedEPGGenTable}
        editing_forms={['EPGGen']}
        exclude={[]}
        {...final_props}
    />;
};
export default EPGGenTable;
export { mapping_specific_storage, BaseEPGGenTable, EPGGenTable };
