import {current_user} from "storeregistry";
import {fa_icon} from "faicon";

/* report tracebacks to the console if they can be extracted */
var error_report = function( err, message ) {
    if (console) {
        console.log(message);
        if (err && err.stack) {
            console.log( err.stack );
        }
    }
};

/* Provide crash reporting and suppress rendering failures in (render) methods */
var log_render_errors = function( target ) {
    if (target.has_debug_fail) {
        return target;
    }
    var with_debugfail = function() {
        try {
            return target.apply(this,arguments);
        } catch (err) {
            var message = 'Caught error rendering:\n';
            error_report( err, message );
            if (current_user().has_permission('config.scary_debugging')) {
                return <div className='error rendering-failure'>{err.stack||message}</div>;
            } else {
                return fa_icon( 'bug error', 'An error was encountered while rendering this directive' );
            }
        }
    };
    with_debugfail.has_debug_fail=true;
    try {
        target.name = 'render';
    } catch (e) {
        //console.debug("Render function has no name, setting to 'render'");
    }
    return with_debugfail;
};

export {log_render_errors, error_report};
