import React from 'react';

import LinkStatus from 'netconfig/LinkStatus';
import StaticConfig from './StaticConfig';
import { EditIntentFactory } from 'editintent';
import { icon_column } from 'faicon';
import { NetStatus } from 'systemstatus';
import getIcon from 'getIcon';

function bool_render(props) {
    var icon = null;
    const { value } = props.cell;
    if (value) {
        icon = getIcon('check', props.column.name);
    } else {
        icon = getIcon('remove', props.column.name);
    }
    return <span className={props.column.id}>{icon}</span>;
}

function tokenize_name(name) {
    var result = [];
    var token = null;
    while (name && name.length) {
        // Note: this is a search, so it skips '.' characters...
        token = /(\d+)|([a-z]+)/.exec(name);
        if (token.length && token[0].length) {
            var fragment = token[0];
            var numeric = Number.parseInt(fragment);
            if (!isNaN(numeric)) {
                fragment = numeric;
            }
            result.push(fragment);
            name = name.slice(token.index + token[0].length);
        } else {
            break;
        }
    }
    return result;
}

const interfaceTableDefaultColumns = (options = {}) => {
    const { minWidthSm = 20, minWidthMed = 50 } = options;

    return [{
        Header: 'Identity',
        columns: [
            {
                Header: 'Interface',
                id: 'interface',
                accessor: 'iface.interface',
                Cell: props => {
                    var iface = props.cell.row.original.iface;
                    const { value } = props.cell;
                    var name = (
                        <span className="interface-name">
                            {iface['interface']}
                        </span>
                    );
                    if (props.cell.row.original.editable) {
                        name = icon_column(
                            name,
                            EditIntentFactory({
                                form_key: iface.__type__,
                                target: iface
                            })
                        );
                    }
                    return name;
                },
                minWidth: minWidthMed,
                sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    var token_a = tokenize_name(a);
                    var token_b = tokenize_name(b);

                    var index = 0;
                    while (index < token_a.length || index < token_b.length) {
                        var first = token_a[index];
                        var second = token_b[index];
                        if (first == undefined) {
                            if (second == undefined) {
                                return 0;
                            }
                            return -1;
                        } else if (second == undefined) {
                            return 1;
                        }
                        if (first < second) {
                            return -1;
                        } else if (second < first) {
                            return 1;
                        }
                        index += 1;
                    }
                    return 0;
                }
            },
            {
                Header: 'Type',
                id: 'type',
                accessor: 'iface.type',
                Cell: props => {
                    return props.cell.row.original.iface.type_display;
                },
                className: 'trim-text',
                minWidth: minWidthSm
            },
            {
                Header: 'Role',
                id: 'role',
                accessor: 'iface.role',
                Cell: props => {
                    return props.cell.row.original.iface.role_display;
                },
                minWidth: minWidthSm
            },
            {
                Header: 'Link',
                id: 'link',
                accessor: 'current.link',
                Cell: props => {
                    var link = null;
                    if (props.cell.row.original.iface.interface_type === 'phys') {
                        link = (
                            <LinkStatus
                                current={props.cell.row.original.current}
                                key="link"
                            />
                        );
                    }
                    var operstate = null;
                    if (
                        props.cell.row.original.current &&
                        props.cell.row.original.current.stats &&
                        props.cell.row.original.current.stats.operstate == 'up'
                    ) {
                        operstate = getIcon(
                            'thumbUp',
                            'Operational state UP'
                        );
                    }
                    return (
                        <span className="current-state-summary">
                            {link}
                            {operstate}
                        </span>
                    );
                },
                minWidth: minWidthSm
            },
            {
                Header: 'Recv',
                id: 'recv_graph',
                sortable: false,
                accessor: row => {
                    if (row && row.bandwidth && row.iface) {
                        return row.bandwidth['bytes_recv'];
                    } else {
                        return null;
                    }
                },
                width: 60,
                Cell: props => {
                    var iface = props.cell.row.original.iface;
                    const { value } = props.cell;
                    var acc = value;
                    if (acc) {
                        return <NetStatus
                            interface_name={iface['interface']}
                            property_name='bytes_recv'
                            show_label={false}
                            height={20}
                            width={50}
                            interface_Name={iface['interface']}
                            accumulator={acc}
                        />;
                    } else {
                        return null;
                    }
                }
            },
            {
                Header: 'Sent',
                id: 'sent_graph',
                sortable: false,
                accessor: row => {
                    if (row && row.bandwidth && row.iface) {
                        return row.bandwidth['bytes_sent'];
                    } else {
                        return null;
                    }
                },
                width: 60,
                Cell: props => {
                    var iface = props.cell.row.original.iface;
                    const { value } = props.cell;
                    var acc = value;
                    if (acc) {
                        return <NetStatus
                            interface_name={iface['interface']}
                            property_name='bytes_sent'
                            show_label={false}
                            height={20}
                            width={50}
                            interface_Name={iface['interface']}
                            accumulator={acc}
                        />;
                    } else {
                        return null;
                    }
                }
            }
        ]
    },
    {
        Header: 'IPv4',
        columns: [
            {
                Header: 'IPv4',
                id: 'ipv4_config',
                accessor: 'iface.ip_address',
                Cell: props => {
                    var conf = props.cell.row.original.iface;
                    if (conf.ip_address) {
                        return (
                            <StaticConfig
                                className="configured-ip static-config ipv4"
                                address={conf.ip_address}
                                netmask={conf.netmask}
                            />
                        );
                    } else {
                        return null;
                    }
                }
            },
            {
                Header: 'DHCP',
                id: 'dhcp_config',
                accessor: 'iface.dhcp',
                Cell: bool_render,
                minWidth: 30
            }
        ]
    },
    {
        Header: 'IPv6',
        ipv6_only: true,
        columns: [
            {
                Header: 'IPv6',
                id: 'ipv6_config',
                accessor: 'iface.ip_address_v6',
                ipv6_only: true,
                Cell: props => {
                    var conf = props.cell.row.original.iface;
                    if (conf.ip_address_v6) {
                        return (
                            <StaticConfig
                                className="configured-ip static-config ipv6"
                                address={conf.ip_address_v6}
                                prefix={conf.prefix_v6}
                            />
                        );
                    } else {
                        return null;
                    }
                }
            },
            {
                Header: 'SLAAC',
                id: 'slaac_v6',
                accessor: 'iface.slaac_v6',
                ipv6_only: true,
                Cell: bool_render,
                minWidth: 30
            },
            {
                Header: 'DHCPv6',
                id: 'dhcp_v6',
                accessor: 'iface.dhcp_v6',
                ipv6_only: true,
                Cell: bool_render,
                minWidth: 30
            }
        ]
    },
    {
        Header: 'Ports',
        columns: [
            {
                Header: 'SNMP',
                id: 'snmp',
                accessor: 'iface.snmp_port',
                Cell: bool_render,
                minWidth: 30
            },
            {
                Header: 'Manage',
                id: 'manage',
                accessor: 'iface.management_port',
                Cell: bool_render,
                minWidth: 30
            },
            {
                Header: 'Ping',
                id: 'ping',
                accessor: 'iface.pingable',
                Cell: bool_render,
                minWidth: 30
            },
            {
                Header: 'Support',
                id: 'support',
                accessor: 'iface.support_port',
                Cell: bool_render,
                minWidth: 30
            }
        ]
    }]
};

export default interfaceTableDefaultColumns;
