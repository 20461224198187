import $ from 'fakequery';
import {BaseStorage,current_user,stores} from "storages/basestorage";

var InterfaceStorage = function( state ) {
    var storage = BaseStorage( {
        'key':'net_interfaces',
        'url': '',
        'promote_url':'',
        'refresh_on_post': true,
        'interfaces': [],
        'status': null,
        'period': 5.0,
        'debug': true,
        'id_map': {}
    });
    $.extend( storage, state || {});
    $.extend( storage, {
        ensure_keys: function() {
            var id_map = {};
            $.map( storage.interfaces, function(iface) {
                id_map[iface['interface']] = iface;
            });
            storage.id_map = id_map;
        },
        interface_records: function() {
            /* Create a set of interface records from the netconfig storage */
            var store = this;
            if (store.interfaces) {
                var editable = current_user().has_permission('config.change_network');
                var addresses = store.current ? store.current.current_ips: {};
                var system = stores.system_status && stores.system_status.system || null;
                var historic = (system? system.bandwidth: {}) || {};
                
                var children = store.interfaces.map(function(iface) {
                    var int_record = historic[iface['interface']];
                    if (iface.role !== 'slave') {
                        return {
                            iface: iface,
                            editable: editable,
                            bandwidth: int_record,
                            settings: store.settings,
                            current: addresses[iface.interface],
                            key: iface['interface']
                        };
                    } else {
                        return null;
                    }
                }).filter((r) => r !== null);
                return children;
            } else {
                return [];
            }
        }
    });
    return storage;
};

export {
    InterfaceStorage
};
