import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from '@material-ui/core/Dialog';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Modal from '@material-ui/core/Modal';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import classNames from 'classnames';
import MuiTypography from 'dash/MuiTypography';
import {withStyles} from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import 'dash/renderreactdialogfix.css';

const styles = (theme) => ({
    header: {
        backgroundColor: theme.roles.headers.card,
        fontSize: 12,
        zIndex: 4,
        boxShadow: theme.shadows[4],
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
    noBottomMargin: {
        marginBottom: 0,
    },
    spacedCard: {
        marginTop: 0,
        marginBottom: 0,
        '&:first-of-type': {
            marginTop: 0,
            marginBottom: 0,
        }
    },
    dialog: {
    },
});

class RenderReactDialog extends React.Component {
    static defaultProps = {
        'title': null,
        'key': null,
        'controls': null,
        'className': null,
        'rootClassName': null,
        'onClose': null,
    };
    state = {
        open: true,
    }
    render(){ 
        const {title, controls, children, rootClassName, classes, maxWidth} = this.props;
        const {open} = this.state;
        return <Dialog maxWidth={maxWidth||'md'} fullWidth={true} open={open} onClose={this.handleClose} className={classes.dialog} scroll='paper'>
            <EditingCard actions={[controls]} classes={classes} className={classNames(classes.noBottomMargin,classes.dialogBase)} title={title}>
                {children}
            </EditingCard>            
        </Dialog>;
        // return <Dialog className={rootClassName} open={true}>
        //     <EditingCard actions={controls && controls.props.actions} title={title}>
        //         {children}
        //     </EditingCard>
        // </Dialog>;
    }
    handleClose = (evt) => {
        if (this.props.onClose) {
            this.props.onClose(this);
        }
        this.setState({open:false});
        evt.stopPropagation();
    }
}
export default withStyles(styles)(RenderReactDialog);