/* Storage for overall page focus */
import { Signal } from 'signals';

class FocusTracker {
    constructor(initial_focus = null) {
        this.focus = initial_focus;
        this.change = new Signal();
    }
    get_focus = () => {
        return this.focus;
    }
    set_focus = (focus) => {
        if (focus && !(focus.__type__ == 'Node' || focus.__type__ == 'Group')) {
            console.debug(`Set page focus to ${JSON.stringify(focus)}`);
            // throw new Error(`Should not set page focus to anything other than node/group, got ${JSON.stringify(focus)}`);
        } else {
            if (focus !== this.focus) {

                this.focus = focus;
                this.change.send(focus);
            }
        }
    }
    reset = () => {
        this.set_focus(null);
    }
}

const PAGE_FOCUS = new FocusTracker(null);

export default PAGE_FOCUS;
export { PAGE_FOCUS };