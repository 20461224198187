var FieldStorageBacking = function( state ) {
    var backing = {
        'input':null,
        'default_state': null,
        'revert_state': null,
        'default': null
    };
    $.extend(backing,state);
    $.extend(backing,{
        set: function( state ) {
            backing.field.val( JSON.stringify( state ));
            return state;
        },
        get: function( ) {
            var value = backing.field.val();
            if (value) {
                backing.revert_state = value;
                return {
                    'success':true,
                    'data': JSON.parse( value )
                };
            } else {
                backing.revert_state = backing.default_state;
                return backing.default_state;
            }
        }
    });
    return backing;
};

export default FieldStorageBacking;