import React from 'react';
import $ from 'fakequery';

var construct_choices = function( choices, optionClassName = '' ) {
    /* Construct set of choices from Django choice-set */
    var construct_choice = function ( choice ) {
        var value = choice[0];
        var label = choice[1];
        if (Array.isArray(label)) {
            return <optgroup key={value} label={value}>
                {$.map( label, construct_choice )}
            </optgroup>;
        }
        var rvalue = (value === undefined || value === null)? '': `${value}`;
        return <option key={''+choice[0]} value={rvalue} label={choice[1]} className={optionClassName}>
            {choice[1]}
        </option>;
    };
    return $.map( choices || [],construct_choice);
};

export {
    construct_choices
};
export default construct_choices;