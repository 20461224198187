/* Storage of GUI state as a cross-cutting concern */
import React from 'react';
import { DEFAULT_PREFERENCES } from 'dash/userprefs';

const useGUIState = (Component) => {
    /* Add prop useGUIState to props passed to component *IFF* preference_key defined 
    
    Preferences as stored on the client in localStorage
    and are indexed by key. In the component:

        import useGUIState from 'dash/guistate';
        export default useGUIState((props) => {
            const [currentGUIState,setGUIState] = props.useGUIState();
            ...
        });
    
    any JSON serialisable value can be passed to setGUIState
    and the result of deserialising will be returned.

    Defaults:

        if you pass `preference_default` property,
        then the value will be used as the value
        IFF there is no key in the localStorage for
        that value.

        if you call `setGUIState(null)` and `preference_default`
        is set, then `preference_default` will be set.

    setGUIState(null) 
    */
    return (props) => {
        const [current, setState] = React.useState(null);
        const final_props = { ...props };
        const {
            preference_key,
            preferences = DEFAULT_PREFERENCES,
            preference_default
        } = final_props;
        if (preference_key) {
            // console.log(`Using preference key ${preference_key}`);
            const getGUIState = () => {
                const specific = preferences.get_key(preference_key);
                if (specific !== undefined && specific !== null) {
                    return specific;
                }
                if (preference_default) {
                    return preference_default;
                }
                return null;
            };
            const setGUIState = (state) => {
                console.log(`Update preferences for ${preference_key}`);
                if ((state === undefined || state === null) && preference_default != undefined) {
                    state = preference_default;
                }
                preferences.set_key(preference_key, state);
                setState({});
            };
            final_props.useGUIState = () => [getGUIState(), setGUIState];
        }
        return <Component {...final_props} />;
    };
};

export default useGUIState;
