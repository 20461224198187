import React from 'react';
import ATXTable from 'atxtable';
import {WithTabularStorage} from 'storages/tabularstorage';

const BaseLicenseTable = (props) => {
    const {licenses} = props;
    return <ATXTable
        data={licenses}
        columns={[
            {
                'Header': 'Issued',
                'accessor': 'date',
            },
            {
                'Header': 'Type',
                'accessor': 'type_name',
            },
            {
                'Header': 'Valid Until',
                'accessor': 'valid_until',
            },
        ]}
    />;
};

const LicenseTable = (props) => {
    return <WithTabularStorage
        Component={BaseLicenseTable}
        storage_props={{
            forms_key:'system_forms',
            form_key:'LicenseClientLicenses',
            prop_function:(props,storage) => {
                return {
                    ...props,
                    licenses: storage.verified || [],
                };
            },
            debug: true,
            period: 90,
        }} 
    />;
};

export default LicenseTable;