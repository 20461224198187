import $ from 'fakequery';
import React from 'react';

import Uploader from 'reactform/uploader';

class FileUploadTrigger extends React.Component {
    static defaultProps = {
        purpose: 'media',
        url: '/uploads/upload_chunk/'
    }
    state = {
        uploads: [],
    }
    handleFileChange = ( evt ) => {
        var self = this;
        var files = evt.target.files;
        if (files.length) {
            var uploads = self.state.uploads.slice(0,self.state.uploads.length);
            $.map( files, (file) => {
                uploads.push( 
                    <Uploader 
                        onProgress={this.props.onProgress} 
                        onFinished={this.props.onFinished} 
                        file={file} 
                        url={self.props.url} 
                        purpose={self.props.purpose} 
                    /> 
                );
            });
            self.setState( {uploads: uploads});
        }
    }
    render() {
        var uploads = [];
        if (this.state.uploads.length) {
            uploads = <ul className="uploads-in-progress no-bullets compact-list">
                {$.map( this.state.uploads, function(upload,index) {
                    return <li key={'upload-'+index}>{upload}</li>;
                })}
            </ul>;
        }
        return <div className="file-upload-control">
            <input type="file" className="file-upload" onChange={this.handleFileChange.bind(this)} />
            {uploads}
        </div>;
    }
}

export default FileUploadTrigger;
