import React from 'react';
import { ReactForm } from 'reactform';
import EditingCard from 'dash/editingcard';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import MuiTypography from 'dash/MuiTypography';
import { store_by_key } from 'formprovider';
import { StandAloneTuningTableEditor } from './ipgclientview'
import ReactFormButton from 'widgets/ReactFormButton';
import { default_cancel_button } from '../../shared_modules/reactform/constructcontrols';
import { saveAs } from 'file-saver';

const styles = (theme) => ({
    linkButton: {
        backgroundColor: '#00447c',
        color: '#fff',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        '&:hover': {
            backgroundColor: '#757ce8'
        }
    }
});

const LOCAL = "LOCAL"
const MANAGED = "MANAGED"

function sortingHelper(value = [], key = "") {
    value.sort((a, b) => {
        if (a[key].toLowerCase() < b[key].toLowerCase()) {
            return -1;
        } else if (a[key].toLowerCase() > b[key].toLowerCase()) {
            return 1;
        } else {
            return 0;
        }
    })
    return value
}

const AuthGroupSelector = (props) => {

    const { authGroups, setauthGroups } = props;
    const [userGroupsList, setuserGroupsList] = React.useState(null);

    const storage = store_by_key('shogunconf_forms');

    React.useEffect(() => {
        if (!userGroupsList) {
            //api call to fetch all groups to which user has access to
            storage.get_form('getAllOwners').then(response => {
                if (response.success) {
                    let value = sortingHelper(response.userVisibleGroups, "name")
                    setuserGroupsList(value);
                } else {
                    console.error(`Unable to load user visible groups: ${response.messages}`);
                }
            });
        }
    }, [authGroups]);

    let items = [];
    if (userGroupsList) {
        items = userGroupsList.map(group => {
            return <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>;
        });
    }

    const onSelect = (evt) => {
        const id = evt.target.value;
        userGroupsList.map(group => {
            if (group.id == id) {
                setauthGroups(
                    { 'id': group.id, 'name': group.name }
                );
            }
        });
    };

    return <div title="Allow for selecting vsbb configs created for applying it offline">
        <FormLabel>Select</FormLabel>
        {' '}
        <Select onChange={onSelect} value={(authGroups && authGroups.id) || 0} style={{ minWidth: 200 }} >
            <MenuItem value={0} key='empty'>Select a auth Group</MenuItem>
            {items}
        </Select>
    </div >;
}


const ServiceSelector = (props) => {

    const { setSelectedService, authGroups } = props;
    const [serviceSet, setServiceSet] = React.useState(null);

    const storage = store_by_key('shogunconf_forms');

    React.useEffect(() => {
        //api call to fetch the offline configs already created
        storage.get_form('OfflineVsbbConfigChoices', authGroups.id).then(response => {
            if (response.success) {
                let value = sortingHelper(response.ServiceCreateChoices, "label");
                setServiceSet(value);
            } else {
                console.error(`Unable to load service creation choices: ${response.messages}`);
            }
        });
    }, [authGroups]
    );

    let items = [];
    if (serviceSet) {
        items = serviceSet.map(service => {
            return <MenuItem key={service.key} value={service.key}>{service.label}</MenuItem>;
        });
    }

    const onSelect = (evt) => {
        const key = evt.target.value;
        serviceSet.map(service => {
            if (service.key == key) {
                setSelectedService(
                    { 'service_type': service, 'resource_type': null, 'role': '', 'name': service.label, 'new': false }
                );
            }
        });
    };

    return <div title="Allow for selecting vsbb configs created for applying it offline">
        <FormLabel>Select</FormLabel>
        {' '}
        <Select onChange={onSelect} value={0} style={{ minWidth: 200 }} >
            <MenuItem value={0} key='empty'>Select a VSBB Config</MenuItem>
            <MenuItem value="new" key='new' onClick={() => setSelectedService({ 'new': true })}>Create new VSBB Config</MenuItem>
            {items}
        </Select>
    </div >;

};


const RenderEditor = (props) => {
    const { selectedService, setSelectedService, form_key, authGroups, buttons = [] } = props;

    if (selectedService.new) {
        return (
            <React.Fragment>
                <MuiTypography key={`selected-key-${null}-vsbb`}>Adding: VSBB Config</MuiTypography>
                <ReactForm key={`creation-form-root-${form_key}`} target={{
                    type: form_key,
                    id: null,
                }}
                    form_key={form_key}
                    storage={'shogunconf_forms'}
                    onSave={evt => {
                        setSelectedService(null);
                    }}
                    context={{
                        enabled: true,
                        groups: null,
                        owner: authGroups.id,
                        authGroupId: authGroups.id,
                        authGroupName: authGroups.name,
                        service_role: '',
                        service_type_key: 'vsbb',
                    }}
                    use_dialog={false}
                    buttons={[
                        'save',
                        (form) => {
                            return default_cancel_button(form, {
                                key: 'clear-selected-service',
                                onClick: () => setSelectedService(null),
                            })
                        }
                    ]}
                />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <ReactForm target={selectedService.service_type.target} storage='shogunconf_forms' use_dialog={false} form_key={form_key} buttons={['save', ...buttons, (form) => {
                return default_cancel_button(form, {
                    key: 'clear-selected-service',
                    onClick: () => setSelectedService(null),
                })
            }]}
                onSave={evt => {
                    setSelectedService(null);
                }} />

        </React.Fragment>

    )
}

const VsbbSettings = (props) => {
    const { selectedService, setSelectedService, form_key, buttons, authGroups, setauthGroups } = props;

    const chooseConfig = ((!selectedService) && <React.Fragment><AuthGroupSelector setauthGroups={setauthGroups} authGroups={authGroups} /> {(authGroups) && <ServiceSelector
        setSelectedService={setSelectedService}
        authGroups={authGroups}
    />}</React.Fragment>) || null;

    const selectedConfig = (selectedService && authGroups && <RenderEditor
        form_key={form_key}
        buttons={buttons}
        selectedService={selectedService}
        setSelectedService={setSelectedService}
        authGroups={authGroups}
    />) || null;

    return <EditingCard title='VSBB Settings' key={`vsbbsettings`} collapsing='vsbb-settings'>
        {
            chooseConfig || selectedConfig || 'Loading Configs'
        }
    </EditingCard>;
}

const BuilderModeComponent = (props) => {
    const { builderMode = null, selectedService, setSelectedService, authGroups, setauthGroups } = props
    switch (builderMode) {
        case LOCAL: return (
            <React.Fragment>
                <VsbbSettings selectedService={selectedService} setSelectedService={setSelectedService} authGroups={authGroups} setauthGroups={setauthGroups} form_key="OfflineIPGClient" buttons={["delete"]} />
                {selectedService && !selectedService.new && <StandAloneTuningTableEditor
                    target={selectedService.service_type.target}
                    form_key={'IPGClientMapping'}
                    storage={'shogunconf_forms'} />}
            </React.Fragment>
        )
        case MANAGED: return (
            <React.Fragment>
                <VsbbSettings selectedService={selectedService} setSelectedService={setSelectedService} authGroups={authGroups} setauthGroups={setauthGroups} form_key="OfflineIPGClientLineupForm" />
                {selectedService && !selectedService.new && <StandAloneTuningTableEditor
                    target={selectedService.service_type.target}
                    form_key={'IPGClientMapping'}
                    storage={'shogunconf_forms'} />}
            </React.Fragment>
        )
        default: return (null)
    }
}

const OfflineConfigVsbb = (props) => {
    const [builderMode, setBuilderMode] = React.useState(0);
    const [selectedService, setSelectedService] = React.useState(null);
    const [authGroups, setauthGroups] = React.useState(null);

    const { classes } = props;
    const storage = store_by_key('shogunconf_forms');

    return (
        <React.Fragment>
            <EditingCard title='Builder Mode' key={`buildermode`} collapsing='builder-mode'>
                <div title="Allow selection of local or manage linup choices">
                    <FormLabel>Select</FormLabel>
                    {' '}
                    <Select onChange={(evt) => { setBuilderMode(evt.target.value); setSelectedService(null) }} value={builderMode} style={{ minWidth: 200 }} >
                        <MenuItem value={0} key='empty'>Select builder mode type</MenuItem>
                        <MenuItem key={'local'} value={LOCAL}>Local</MenuItem>
                        <MenuItem key={'linup'} value={MANAGED}>Managed/Lineup Only</MenuItem>
                    </Select>
                </div >
            </EditingCard>

            <BuilderModeComponent selectedService={selectedService} setSelectedService={setSelectedService} authGroups={authGroups} setauthGroups={setauthGroups} builderMode={builderMode} />

            {(selectedService && !selectedService.new)
                && <React.Fragment>
                    {' '}
                    <ReactFormButton className={classes.linkButton} onClick={() => {
                        let config_id = selectedService.service_type.target.id

                        //get the data to be downloaded
                        storage.get_form('DownloadOfflineVsbbConfig', config_id ? config_id : "").then(response => {
                            if (response.success) {
                                let formattedChannels = JSON.stringify({ "channels": response.data.channels })
                                const blob = new Blob([formattedChannels], { type: "application/json;charset=utf-8" });
                                saveAs(blob, `channelplan.json`);
                                if (builderMode == LOCAL) {
                                    let formattedOSD = JSON.stringify(response.data.standalone)
                                    const blob2 = new Blob([formattedOSD], { type: "application/json;charset=utf-8" });
                                    saveAs(blob2, `standalone.json`);
                                }

                            } else {
                                console.error(`Unable to download offline vsbb config: ${response.messages}`);
                            }
                        });
                    }}>
                        Download offline config
                    </ReactFormButton>
                </React.Fragment> || null
            }

        </React.Fragment>
    )
}

export default withStyles(styles)(OfflineConfigVsbb);