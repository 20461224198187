import $ from 'fakequery';
import React from 'react';
import { DelayTimer } from './delaytimer';

const widget_data = (field, key) => {
    let result = undefined;
    [field.field, field].map((source) => {
        if (source && source.widget_data && source.widget_data[key] !== undefined) {
            result = source.widget_data[key];
        }
    })
    return result;
}

class ClassyWidget extends React.Component {
    constructor(props) {
        super(props);
        this.controlRef = React.createRef();
    }
    state = {
        'edit_value': undefined,
        'edit_delay': new DelayTimer({}),
    }
    static defaultProps = {
        'form': null,
        'field': null,
        'onChange': null,
        'edit_delay_secs': .5,
        'form_field': null,
        'classes': {},
        'from_server': null,
        'to_server': null,
        'autoFocus': false,
    }
    widget_data(key) {
        /* get widget data from our field or field.field */
        if (!key) {
            console.error(`Requested null widget_data key on ${this}`);
        }
        const field = this.get_field();
        return widget_data(field, key);
    }
    get_form() {
        return this.props.form;
    }
    other_field(name) {
        /* get a ReactFormField for the given name if defined */
        return this.props.form.current_widget(name);
    }
    other_field_def(name) {
        /* get the form-field definition for a *different* field */
        return this.props.form.get_field(name);
    }
    form_field() {
        return this.props.form_field;
    }
    get_field() {
        if (this.props.field) {
            return this.props.field;
        }
        return this.form_field().props.field;
    }
    render_value() {
        /* get the currently-rendered value, which may be partial/unfinished */
        var self = this;
        var value = self.current_value();
        if (self.state.edit_value !== undefined) {
            value = self.state.edit_value;
        }
        return value;
    }
    current_value() {
        const base = this.props.widget_get_value();
        if (this.props.from_server) {
            return this.props.from_server(base, this);
        }
        return base;
    }
    set_value(value) {
        /* programatically set the value currently displayed */
        if (this.controlRef.current) {
            this.controlRef.current.value = '' + value;
        } else if (this.controlRef.set_value) {
            this.controlRef.set_value(value);
        } else {
            console.debug("No control reference " + this.get_field().name);
        }
    }
    set_edit_value(value) {
        this.setState({ 'edit_value': value });
    }
    handleChange = (new_value, immediate, new_state = null) => {
        var self = this;
        self.setState({ 'edit_value': new_value, ...(new_state || {}) });
        let converted = new_value;
        if (this.props.to_server) {
            converted = this.props.to_server(converted, this);
        }
        if (immediate || (!this.state.edit_delay)) {
            self.props.onChange(converted);
        } else {
            this.state.edit_delay.after(self.props.edit_delay_secs || .5, function () {
                self.props.onChange(converted);
            });
        }
    }
}

export { ClassyWidget, widget_data };
