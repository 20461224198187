/* Signal and Dispatcher implementations */
import $ from 'fakequery';
import { error_report } from 'debugfail';

var js_key = function (record) {
    /* calculate routing __key__ for a given record */
    var __key__ = record.__key__;
    if (__key__ === undefined || __key__ === null) {
        console.log("No __key__ in " + JSON.stringify(record));
        return null;
    }
    return __key__;
};
var shallow_props = function (record) {
    /* create a copy of record just with the shallow properties */
    var shallow = {};
    for (var prop in record) {
        var value = record[prop];
        if ((value !== null && value !== undefined) && value.__key__) {
            value = value.__key__;
        } else if (Array.isArray(value)) {
            value = value.map(function (v) {
                if (v !== null && v !== undefined && v.__key__) {
                    return v.__key__;
                } else {
                    return v;
                }
            });
        }
        shallow[prop] = value;
    }
    return shallow;
};
var _array_equal = function (first, second) {
    if (first.length !== second.length) {
        return false;
    }
    for (var i = 0; i < first.length; i++) {
        if (!shallow_compare(first[i], second[i])) {
            return false;
        }
    }
    return true;
};

var is_simple_type = function (first) {
    if (
        first === null ||
        first === undefined
    ) {
        return true;
    }
    var typ = typeof first;
    return { "number": true, "string": true, "boolean": true }[typ] || false;
};

var shallow_compare = function (first, second, ignore = {}, debug = false) {
    /* Do a "shallow" comparison where records with keys changing are *not* recursed

    first, second -- objects to compare
    ignore -- object whose keys should be ignored
    debug -- print out debugging information during testing

    * arrays are recursed
    * objects that are *not* arrays and don't have keys are recursed

    returns false if a difference is found, true if no differences detected

    */

    if (is_simple_type(first)) {
        return first === second;
    } else if (is_simple_type(second)) {
        // Can't be the same, since different type
        return false;
    } else if (first['__identity_compare_only__'] || second['__identity_compare_only__']) {
        return first === second;
    }
    var _has_all_props = function (first, second) {
        /* does second have all props in first? */
        var prop;
        for (prop in first) {
            if (ignore && ignore[prop] !== undefined) {
                continue;
            }
            var f_value = first[prop];
            var s_value = second[prop];
            if (Array.isArray(f_value) && Array.isArray(s_value)) {
                if (!_array_equal(f_value, s_value)) {
                    debug && console.debug(prop + ' differs ' + f_value + ' => ' + s_value);
                    return false;
                }
            } else {
                if (!shallow_compare(f_value, s_value, ignore, debug)) {
                    debug && console.debug(prop + ' differs ' + f_value + ' => ' + s_value);
                    return false;
                }
            }
        }
        return true;
    };
    try {
        if (
            _has_all_props(first, second)
            && _has_all_props(second, first)
        ) {
            return true;
        }
        return false;
    } catch (e) {
        console.log(`Error on comparison between ${e} on ${JSON.stringify(first)} and ${JSON.stringify(second)}`);
        return false;
        throw (e);
    }
};


var Signal = function (state) {
    /* An observable signal to which receivers may subscribe */
    var self = {
        'listeners': [],
        'debug': false,
        'last_signal': undefined,
        '__identity_compare_only__': true,
    };
    $.extend(self, state);
    $.extend(self, {
        listen: function (listener, context) {
            self.ignore(listener, context);
            self.listeners.push([listener, context]);
            if (self.last_signal !== undefined) {
                listener(self.last_signal, context);
            }
        },
        listener_count: function () {
            return self.listeners.length;
        },
        send_if_changed: function (signal) {
            /* Only send the signal if it doesn't match the last-send signal */
            if (shallow_compare(self.last_signal, signal)) {
                if (self.debug) {
                    console.log(`Duplicate signal: ${JSON.stringify(signal)}`);
                }
                return;
            }
            return self.send(signal);
        },
        send: function (signal) {
            /* send signal to all listeners */
            self.last_signal = signal;
            var to_remove = [];
            $.map(self.listeners, function (l) {
                var listener = l[0];
                var context = l[1];
                try {
                    listener(signal, context, self);
                } catch (e) {
                    to_remove.push(l);
                    error_report(
                        e,
                        "Failure processing signal " + signal + ' for listener ' + listener + ' with context ' + context + ' ' + e
                    );
                }
            });
            $.map(to_remove, function (l) {
                // yes, inefficient
                self.ignore(l[0], l[1]);
            });
        },
        ignore: function (listener, context) {
            /* ignore signal in the future */
            self.listeners = $.grep(self.listeners, function (l) {
                if (l[0] === listener && l[1] === context) {
                    return false;
                }
                return true;
            });
        }
    });
    return self;
};
var Dispatcher = function (state) {
    /* Routed dispatcher (based on routing keys) for observing changes */
    var dispatcher = {
        signals: {}
    };
    $.extend(dispatcher, state || {});
    $.extend(dispatcher, {
        listen: function (__key__, listener, context) {
            /* register to receive updates when signal "__key__" is sent */
            var signal = dispatcher.signals[__key__];
            if (signal === undefined || signal === null) {
                signal = dispatcher.signals[__key__] = Signal({
                    name: __key__,
                    description: 'Signal sent when object with __key__ ' + __key__ + ' updated'
                });
            }
            return signal.listen(listener, context);
        },
        send: function (__key__, to_send) {
            /* Send signal to all receivers registered for __key__ */
            var signal = dispatcher.signals[__key__];
            if (signal) {
                return signal.send(to_send);
            }
        },
        ignore: function (__key__, listener, context) {
            /* De-register listener from signal associated with __key__ */
            var signal = dispatcher.signals[__key__];
            if (signal) {
                var result = signal.ignore(listener, context);
                if (!signal.listener_count) {
                    dispatcher.signals[__key__] = undefined;
                }
                return result;
            }
            return null;
        }
    });
    return dispatcher;
};

var ObjectSignals = function (state) {
    /* Dispatcher which provides automated "only on update" messages */
    var base_state = {
        debug: true,
        ignore_props: {
            timestamp: true
        },
        '__identity_compare_only__': true,
    };
    $.extend(base_state, state);
    var dispatcher = Dispatcher(base_state);
    dispatcher.debug = true;
    $.extend(dispatcher, {
        records: {},
        update: function (record) {
            var __key__ = record.__key__;
            if (__key__) {
                var shallow_record = shallow_props(record);
                var previous = dispatcher.records[__key__];
                if (previous && shallow_compare(previous, shallow_record, dispatcher.ignore_props)) {
                    // it hasn't changed at this level...
                    if (dispatcher.debug) {
                        //console.log( 'No change to '+ __key__);
                    }
                    return;
                } else if (previous) {
                    if (dispatcher.debug) {
                        console.log(`Updated record ${__key__}`);
                    }
                }
                dispatcher.records[__key__] = shallow_record;
                dispatcher.send(__key__, record);
            }
        }
    });
    return dispatcher;
};

export {
    Signal,
    ObjectSignals,
    js_key,
    is_simple_type,
    shallow_compare,
    Dispatcher
};
