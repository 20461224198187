/* HOC that loads Form Definitions from the server for each form display */
import React from 'react';
import {ErrorList} from 'errorlist';
import PropTypes from 'prop-types';
import formFromTarget from './formfromtarget';

/* HOC ServerLoaded wrapper that loads form from server once and then shares with all instances */
const serverTemplated = ( WrappedComponent ) => {
    class ServerTemplated extends React.Component {
        state = {
            form_details: null
        }
        getFormKey() {
            if (this.props.form_key) {
                return this.props.form_key;
            }
            return this.props.target.__type__ || this.props.target.type;
        }
        /* construct form on the client side from template form_details and an instance dictionary */
        loadFormDetails() {
            var storage = (this.props.storage || stores.forms);
            const form_key = this.getFormKey();
            return storage.get_form_template(form_key).then(
                ( result ) => {
                    if (result.form) {
                        var form_details = null;
                        if (this.props.formFromTarget) {
                            form_details = this.props.formFromTarget(this.props.target,result.form);
                        } else {
                            form_details = formFromTarget(this.props.target,result.form);
                        }
                        this.safeSetState({
                            form_details:form_details,
                            errors:null
                        });
                    } else {
                        console.log("Failed to load form, should clean up" );
                    }
                },
                ( err ) => {
                    console.warn(`Failure loading form details ${this.getFormKey()} from storage ${storage.url}`);
                    if (this.handleError) {
                        this.handleError( err, 'Loading form description' );
                    }
                    window.setTimeout(function() {
                        this.loadFormDetails();
                    }, 5000);
                }
            );
        }
        componentDidMount() {
            this.mounted = true;
            if (! this.props.form_details ) {
                this.loadFormDetails();
            }
        }
        componentWillUnmount() {
            this.mounted = false;
        }
        safeSetState = (state) => {
            if (this.mounted) {
                this.setState(state);
            }
        }
        get_form_details() {
            return this.state.form_details || this.props.form_details;
        }
        render() {
            const details = this.get_form_details();
            if (details) {
                return <WrappedComponent form_details={details} {...this.props} />;
            } else {
                return <div className="spinner form-loading"></div>;
            }
        }
    }
    return ServerTemplated;
}


export default serverTemplated;