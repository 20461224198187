import React from 'react';
import { providing_nearest_form } from 'formprovider';
import { with_resolved_target } from 'dash/resolvetarget';
import EditingCard from 'dash/editingcard';
import { ReactForm } from 'reactform';
import ListView from 'shogun/listview';


class BaseFirmwareDeployEditor extends React.Component {
    render() {
        const { target } = this.props;
        return <React.Fragment>
            <EditingCard title='Firmware Requirements' actions={[]} key={`firmwaredeploy-config`} collapsing={`firmwaredeploy-editor`}>
                {target && <ReactForm
                    target={target}
                    form_key='FirmwareDeploy'
                    use_dialog={false}
                    buttons={['save', 'delete']}
                />}
            </EditingCard>
            <ListView
                __type__='FirmwareTarget'
                storage='shogunconf_forms'
                title='Firmware Targets'
                dialog_edit={true}
                context={{
                    mapping: target.id || target.__pk__,
                }}
                creation_context={{
                    mapping: target.id || target.__pk__,
                }}
            />
        </React.Fragment>;
    }
}
const FirmwareDeployEditor = providing_nearest_form(
    with_resolved_target(BaseFirmwareDeployEditor),
    'shogunconf_forms'
);

export default FirmwareDeployEditor;
export { BaseFirmwareDeployEditor, FirmwareDeployEditor };
