import React from 'react';
import { providing_nearest_form } from 'formprovider';
import with_resolve_focus from 'dash/resolvefocus';
import { VSBBDevicesCard } from './vsbbtables';
import GroupsView from 'shogun/groupsview';
import { WithToolbar } from 'shogun/shogunpromotebar';
import LogView from 'dash/debugging/LogView';
import { withRouter } from 'react-router-dom';
import redirect from 'redirect';

class BaseVSBBView extends React.Component {
    static defaultProps = {
        'withGroups': true,
    }
    onViewGroup = ({ target, form, table }) => {
        const url = `/m/groups/${target.id}`;
        redirect(url, this.props.history);
    }
    // wrap_data = (record) => {
    //     return [{
    //         ...record,
    //         __url__: `/m/vsbbs/${record.id}`
    //     }];
    // }
    render() {
        const { target, withGroups } = this.props;
        const log_name = (target && target.__pk__) ? `group-log-${target.__pk__}` : null;
        return <React.Fragment>
            {withGroups && <GroupsView
                link_function={this.onViewGroup}
            />}
            <VSBBDevicesCard
                title={target ? `Devices in ${target.title}` : 'Devices'}
                root={!target}
                key={`device-table-${(target && target.__pk__) || 'root'}`}
            />
        </React.Fragment>;
    }
}


const VSBBView = providing_nearest_form(
    with_resolve_focus(
        WithToolbar(
            withRouter(BaseVSBBView),
        ),
    ),
    'shogunconf_forms'
);

export default VSBBView;
export { BaseVSBBView, VSBBView };
