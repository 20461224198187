/* Provides renderer for node's SKU as registered in nodeviews.register(sku, Component)

Defaults to the original vsbb card-set. The NodeView is wrapped 
with the toolbar, so you currently can only customise the values
within the regular editing window.
*/
import React from 'react';
import { with_target } from 'dash/resolvetarget';
import EditingCard from 'dash/editingcard';
import { ReactForm } from 'reactform';
import { node_specific_storage, ResourceEditTable } from './resourceedittable';
import { WithToolbar } from 'shogun/shogunpromotebar';
import NodeConfigTable from 'shogun/nodeconfigtable';
import {
    default_save_button,
} from 'reactform';
import { lookup } from './nodeview_registry';

const statBlockStyles = (theme) => ({
    statBlock: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '.25rem',
        backgroundColor: theme.roles.info.background,
        color: theme.roles.info.foreground,
        padding: '1rem',
        boxShadow: '2px 2px 2px #f0f0ff',
    },
    statProperties: {
        flex: '1',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    statProperty: {
        minWidth: '25%',
        maxWidth: '33%',
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
    },
    statPropertyName: {
        paddingRight: '.5rem',
        display: 'inline-block',

    },
    statMeta: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '1rem',
        minWidth: '20%',
        textAlign: 'center',
    },
    error: {
        color: theme.palette.error.main,
    },

});


const NodeMetadataCard = (props) => {
    const { target, buttons = ['save', 'delete'], form_key = 'Node', storage = 'shogunconf_forms' } = props;
    const id = target.__pk__;
    return <EditingCard title='Device Metadata' key={`metadata-${id}`} collapsing='node-metadata'>
        <ReactForm
            form_key={form_key}
            target={target}
            use_dialog={false}
            storage={storage}
            buttons={buttons}
        />
    </EditingCard>;
};
const NodeServiceAssignmentCard = (props) => {
    const { target } = props;
    const id = target.__pk__;
    return <EditingCard title='Device Config Packages' key={`metadata-service-groups-${id}`} collapsing='node-service-groups'>
        <ReactForm
            form_key={'GroupServiceGroups'}
            target={target}
            storage='shogunconf_forms'
            context={{
                'groups': [id],
            }}
            use_dialog={false}
            buttons={['save']}
        />
    </EditingCard>
};
const NodeResourcesCard = (props) => {
    const { target } = props;
    const id = target.__pk__;
    return <EditingCard collapsing='node-resources' title="Device Resources" key={`resources-${id}`} no_wrapper={true}>
        <ResourceEditTable
            key={`resource-table-${id}`}
            storage_props={node_specific_storage(target)}
            include_node={false}
        />
    </EditingCard>
};
const NodeApplyForm = (props) => {
    const { target } = props;
    const id = target.__pk__;
    return <EditingCard title={`Device Apply`} collapsing='node-apply' key={`node-apply-${id}`}>
        <ReactForm
            target={target}
            form_key={'Promote'}
            storage='shogunconf_forms'
            use_dialog={false}
            default_messages={{
                'success': 'Device apply complete',
                'errors': 'Cannot proceed with the application',
            }}
            buttons={[
                (self) => default_save_button(self, {
                    'name': 'Apply Changes',
                    'action_message': 'Apply',
                    'busy_message': `Applying to ${target.title}`,
                    'title': `Apply our current changes to ${target.title}`,
                }),
            ]}
        />
    </EditingCard>;
};
const NodeAppliedConfigsForm = (props) => {
    const { target } = props;
    const id = target.__pk__;
    return <EditingCard title="Applied Configurations" no_wrapper={true} collapsing='node-configurations'>
        <NodeConfigTable node={target} />
    </EditingCard>;
};

const BaseNodeEditor = (props) => {
    /* Render registered node view *or* the default nodeview */
    const CustomNodeView = lookup(props.target.sku);
    if (CustomNodeView) {
        return <CustomNodeView {...props} />;
    } else {
        return <React.Fragment>
            <NodeMetadataCard {...props} key={`metadata`} />
            <NodeServiceAssignmentCard {...props} key={`service-assignment`} />
            <NodeResourcesCard {...props} key={`resources`} />
            <NodeApplyForm {...props} key={`apply`} />
            <NodeAppliedConfigsForm {...props} key={`applied`} />
        </React.Fragment>
    }
};

const NodeEditor = with_target(BaseNodeEditor);

const NodeView = WithToolbar(NodeEditor);

export default NodeView;
export {
    NodeView, NodeEditor,
    BaseNodeEditor,
    NodeMetadataCard, NodeServiceAssignmentCard, NodeResourcesCard, NodeApplyForm, NodeAppliedConfigsForm,
    statBlockStyles,
};
