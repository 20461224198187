// import {fa_icon} from 'faicon';
import { widget } from './widget';
import React from 'react';

import ReactFormFieldRender from 'widgets/ReactFormFieldRender';

class ReactFormField extends React.Component {
    static displayName = 'ReactFormField';

    static defaultProps = {
        'should_show': true,
        'form': null,
        'widgets': null,
        'autoFocus': false,
        'initialClick': false,
        'debug': false,
        'changed': undefined, // timestamp of last change, optional
        'show_label': true,
        'get_form_field': null, // get reference to *any* field...
        'use_dialog': true,
        'field': null,
        'onKeyDown': null,
    };

    state = {
        // edit_value: undefined
        changed: false,
        widgetRef: React.createRef(),
    };
    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    handleChange = (new_value) => {
        var self = this;
        if (self.state && self.state.value !== undefined) {
            if (new_value == self.state.value) {
                /* suppress change messages */
                return;
            }
        } else if (new_value == self.props.field.value) {
            /* value is the original value */
            return;
        }
        if (this.mounted) {
            self.setState({ 'value': new_value, 'changed': true });
        } else {
            console.log(`Update on unmounted field ${this.props.field.name}`);
        }
        if (self.props.onChange) {
            self.props.onChange(new_value);
        }
    };

    set_value = (new_value, external) => {
        var self = this;
        if (this.mounted) {
            console.log(`Field set value to ${new_value} external=${external}`);
            self.setState({ 'value': new_value, 'changed': external ? false : true });
        }
        if (this.state.widgetRef.current && this.state.widgetRef.current.set_value) {
            // console.debug(`Updating current widget with set_value on ${this.props.field.name}`);
            this.state.widgetRef.current.set_value(new_value);
        }
        if ((!external) && self.props.onChange) {
            self.props.onChange(new_value);
        }
    };

    set_edit_value = (new_value) => {
        var current = this.widgetRef.current;

        if (current && current.set_edit_value) {
            current.set_edit_value(new_value);
        }
    };

    current_value = () => {
        var self = this;
        if (this.state.widgetRef.current && this.state.widgetRef.current.current_value) {
            return this.state.widgetRef.current.current_value();
        }
        return undefined;
    };

    widget_get_value = () => {
        if (this.state.value !== undefined) {
            return this.state.value;
        }
        return this.props.field.value;
    }

    form_current_value = (field) => {
        /* Retrieve the current value of given field-name from the form
        
        This is the value that the form currently understand to have been
        committed, i.e. not a mid-typing value, but a value that has been
        accepted as the new value to be passed to the backend.
        */
        return this.props.form.currentValue(field);
    }


    render() {
        const field = this.props.field;
        const base_field = field.field;

        const widget_cls = field_widget(field, this.props);

        var widget_inst = null;
        // Yuck!
        if (field.name == 'content-audio_channel_groups') {
            console.log('Widget wide? ' + widget_cls.wide);
        }
        if (widget_cls) {
            widget_inst = widget_cls({
                form_field: this,
                ref: this.widgetRef,
                onChange: this.handleChange,
                onKeyDown: this.props.onKeyDown,
                onClick: this.props.onClick,
                onBlur: this.props.onBlur,
                field: field,
                form: this.props.form,
                show_label: this.props.show_label,
                autoFocus: this.props.autoFocus,
                initialClick: this.props.initialClick,
                widget_get_value: this.widget_get_value,
                form_current_value: this.form_current_value,

                key: field.name + '-widget'
            });
        } else {
            widget_inst = 'No widget for ' + base_field.widget;
        }

        const classNames = (
            'form-field field-' + field.name +
            (base_field.required ? ' required' : '') +
            (this.state.changed ? ' changed' : '') +
            (this.props.should_show ? '' : ' hidden')
        );
        // console.log(`${field.name} render ${this.props.should_show} => ${classNames}`);
        return (
            <div
                className={classNames}
                title={base_field.help_text}
                key={field.name}>
                <ReactFormFieldRender
                    {...this.props}
                    widget_inst={widget_inst}
                    field={field}
                />
            </div>
        );
    }
}

function field_widget(field, parent_props = {}) {
    var base_field = field.field;
    var widget_name = field.widget || base_field.widget;
    var widget_cls;
    if (parent_props.widgets && parent_props.widgets[field.name]) {
        widget_name = parent_props.widgets[field.name];
    }
    if (
        base_field && (
            base_field.disabled ||
            base_field.readonly ||
            parent_props.editable === false
        )
    ) {
        widget_name = 'DisplayWidget';
    }

    if (typeof widget_name == 'string' || widget_name instanceof String) {
        widget_cls = widget(widget_name);
    } else if (!widget_name) {
        widget_cls = null;
    } else {
        widget_cls = widget_name;
    }
    return widget_cls;
}

export default ReactFormField;
export { ReactFormField, field_widget };