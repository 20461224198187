import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { current_user, stores } from 'storeregistry';

import MuiIcon from 'dash/MuiIcon';
import MuiTypography from 'dash/MuiTypography';
import ScrollContainer from 'dash/ScrollContainer';
import SoftwareEndUserLicenseAgreement from './SoftwareEndUserLicenseAgreement';
import { form_action, construct_controls } from 'reactform';
import { ReactForm } from 'reactform';

const styles = theme => ({
    agreement: {
        padding: theme.spacing(2)
    },
    accepted: {
        display: 'flex',
        alignItems: 'center',
        color: theme.roles.success
    },
    acceptedText: {
        marginLeft: theme.spacing(1)
    },
    eula_warning: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.red
    },
});

export class AcceptLicenseForm extends React.Component {
    handleResponse = () => {
        const { storage } = this.props;
        storage.get();
    };

    getFormActions = (form) => {
        const sign_license_button = form_action({
            key: 'accept_license',
            name: 'Accept License',
            help_text: '',
            color: 'primary',
            callback: this.handleResponse
        }).render(form);

        return (
            <div className="form-actions">
                {construct_controls(form, [sign_license_button])}
            </div>
        );
    };

    render() {
        const { classes, config } = this.props;
        const user = current_user();
        let who_has_permission = [];
        if (stores.system_accounts && stores.system_accounts.users) {
            who_has_permission = stores.system_accounts.users
                .filter(item => item.permissions.includes('config.accept_eula'))
                .map(item => item.username)
                ;
        }
        return (
            <div>
                <MuiTypography gutterBottom={true} variant="h5">
                    Software End User Licence Agreement
                </MuiTypography>
                <ScrollContainer className={classes.agreement}>
                    <SoftwareEndUserLicenseAgreement />
                </ScrollContainer>
                {config && config.eula_accepted ? (
                    <MuiTypography className={classes.accepted}>
                        <MuiIcon name="check_circle" />
                        <span id="accepted-text" className={classes.acceptedText}>
                            License accepted
                            {config && config.eula_accepted && (
                                <span> on {moment(config.eula_accepted).format('LLL')}</span>
                            )}
                        </span>
                    </MuiTypography>
                ) : (
                        user.has_permission('config.accept_eula') ?
                            <ReactForm
                                form_actions={this.getFormActions}
                                target={config}
                                icon="edit"
                                form_key="EULAForm"
                                use_dialog={false}
                                onSave={this.props.onSave}
                                form_details={this.props.EULAFormDetails} // Only used for testing...
                            />
                            : (
                                <MuiTypography className={classes.eula_warning}>
                                    <MuiIcon name="remove_circle" />
                                    <span id="accepted-text" className={classes.acceptedText}>
                                        {who_has_permission.length > 0 ?
                                            <span>Only the following users can accept the End User License Agreement: {who_has_permission.join(", ")}</span> :
                                            <span>There are no users on the system that can accept the End User License Agreement.</span>
                                        }
                                    </span>
                                </MuiTypography>
                            )
                    )}
            </div>
        );
    }
}

AcceptLicenseForm.propTypes = {
    classes: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired
};

export default withStyles(styles)(AcceptLicenseForm);
