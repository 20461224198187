/* HOC Helper that annotates Component with a displayName tag */
import getDisplayName from 'react-display-name';
import { isValidElementType } from 'react-is';

const wraps = (target, Component, tag) => {
    if (!target) {
        throw new Error(`Null target for wraps(${target},${Component},${tag})`);
    }
    if (!Component) {
        throw new Error(`Null Component for wraps(${target},${Component},${tag})`);
    }
    if (!isValidElementType(Component)) {
        throw new Error(`Non-component type ${Component} passed to ${tag}`);
    }
    target.displayName = `${tag}(${getDisplayName(Component)})`;
    return target;
};
const with_name = (target, name) => {
    target.displayName = name;
    return target;
};

export default wraps;
export { wraps, with_name };