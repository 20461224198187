import React from 'react';

import MuiButton from 'dash/MuiButton';

const ReactFormButton = props => (
    <MuiButton variant="contained" {...props}>
        {props.children}
    </MuiButton>
);

export default ReactFormButton;
