import React from 'react';
import { ClassyWidget } from 'reactform/classywidget';
import PatientTextField from 'patienttextfield';
import TextField from '@material-ui/core/TextField';
import WidgetRender from './custom_widget_render';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { generic_filter } from 'reactform/genericfilter';
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = (theme) => ({
    'suggestions': {
        zIndex: theme.zIndex.modal,
        maxHeight: theme.spacing(40),
        overflowY: 'scroll',
        backgroundColor: 'white',
        position: 'absolute',
    },
    'suggestionHolder': {
        position: 'relative',
    },
    'inputComponent': {
        width: '100%',
    }
});


class BaseComboBoxWidget extends ClassyWidget {
    on_text_update = (value) => {
        this.handleChange(value);
        const field = this.get_field();
        const base_field = field.field;
        let choices = [];
        if (base_field.choices && value && value.length) {
            if (this.props.filterChoices) {
                choices = this.props.filterChoices(value, base_field.choices);
            } else {
                const filt = {
                    'value': value,
                };
                choices = base_field.choices.filter((record) => generic_filter(filt, record));
            }
        }
        this.setState({ 'choices': choices });
    }
    option_filter = (options, _ref) => {
        var inputValue = _ref.inputValue;
        const test = inputValue.trim().toLowerCase();
        var filteredOptions = options.filter(function (option) {
            return option.some((v) => `${v}`.toLowerCase().indexOf(test) > -1);
        });
        const has_exact = options.filter(option => `${option[0]}`.toLowerCase() === test).length;
        const createNewOption = this.get_new_option();
        if (createNewOption && !has_exact) {
            const newOption = [inputValue.trim(), createNewOption[1]];
            filteredOptions = [
                newOption,
                ...filteredOptions,
            ];
        }
        return filteredOptions;
    };
    get_new_option = () => {
        return this.widget_data('create_new_option') || this.props.createNewOption;
    }
    on_blur = (evt) => {
        const value = evt.target.value;
        var field = this.get_field();
        var base_field = field.field;
        if (!(base_field.choices.filter(x => x[0] == value).length)) {
            window.setTimeout(() => {
                this.setState({
                    'custom_option': [value, value],
                    'edit_value': value,
                });
            }, 5);

            this.handleChange(value);
        }
    }

    render() {
        const { classes, show_label = true, autoSelect = false } = this.props;
        var field = this.get_field();
        var base_field = field.field;
        const current_value = this.render_value();

        const createNewOption = this.get_new_option();
        let choices = base_field.choices;
        if (createNewOption) {
            choices = [
                createNewOption,
                ...choices,
            ];
        }
        let selected = choices.filter(x => x[0] == current_value);
        let current_choice = null;
        if (selected.length) {
            current_choice = selected[0];
        } else if (current_value) {
            current_choice = [current_value, current_value];
        }
        return <WidgetRender form_field={field} widget_class="combo-box" show_label={false}>
            <Autocomplete
                options={choices}
                classes={{
                    option: classes.option,
                }}
                value={current_choice}
                freeSolo={true}
                fullWidth={true}
                autoSelect={autoSelect}
                // multiple={false}
                autoHighlight
                blurOnSelect={false}
                selectOnFocus={true}
                clearOnBlur={false}
                handleHomeEndKeys={true}
                filterOptions={this.option_filter}
                getOptionSelected={
                    (option, value) => value == option[0]
                }
                getOptionLabel={(option) => (option[1] || "")}
                onChange={(evt, option, reason) => {
                    this.setState({ 'edit_value': option[0] });
                    return this.handleChange(option[0]);
                }}
                onBlur={this.on_blur}
                renderOption={(option) => (
                    <React.Fragment>
                        {option[1]}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        value={current_value}
                        label={base_field.label}
                        variant="standard"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-tmsid', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </WidgetRender >;


        // return <WidgetRender form_field={field} widget_class="combo-box" show_label={show_label}>
        //     <PatientTextField
        //         title={base_field.title}
        //         autoFocus={this.props.autoFocus}
        //         onChange={this.on_text_update}
        //         value={current_value}
        //     />
        // </WidgetRender>;
    }
}
const ComboBoxWidget = withStyles(styles)(BaseComboBoxWidget);
export default ComboBoxWidget;
