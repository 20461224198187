import $ from 'fakequery';
import React from 'react';
import { DelayTimer } from './delaytimer';

class ComboBox extends React.Component {
    /* combines an editing box with a drop-down to select choices */
    static defaultProps = {
        onChange: null,
        inputClass: null,
        value: null,
        inputType: 'text', // input component types, text and search being the common ones
        get_id: (record, widget) => record[0], // given a record, retrieve the id
        get_label: (record, widget) => record[1], // given a record, get the label
        choices: []
    }
    constructor(props) {
        super(props);
        var delay = new DelayTimer({});
        var blur_delay = new DelayTimer({});
        var scroll_delay = new DelayTimer({});
        // NOTE: not using setState because then we would not have the values for the first render
        this.state = {
            edit_value: undefined,
            edit_delay: delay,
            blur_delay: blur_delay,
            scroll_delay: scroll_delay,
            current_suggestions: null,
            highlighted_suggestion: null,
            current_candidate: null,
            inputRef: React.createRef(),
            selectedRef: React.createRef(),
        };

    }
    componentDidMount = () => {
        this.wanted = true;
    }
    componentWillUnmount = () => {
        this.wanted = false;
    }
    findMatches = (value, choices) => {
        /* Find any matches in choices, return the first one */
        const { get_id } = this.props;
        var first_match = null;
        choices.map(choice => {
            if (get_id(choice) == value) { // Note: with conversions...
                if (first_match === null) {
                    first_match = choice;
                }
            }
        });
        return first_match;
    }
    choiceByValue = (value) => {
        /* retrieve choice record by value */
        return this.findMatches(value, this.props.choices);
    }
    suggest = (input) => {
        /* given input, see if we have suggestions */
        const { get_label, choices } = this.props;
        var suggestions = [];
        for (let i = 0; i < choices.length; i++) {
            const choice = choices[i];
            const label = get_label(choice);
            if (label.toLowerCase().indexOf(input) > -1) {
                suggestions.push(choice);
                if (suggestions.length > 50) {
                    break;
                }
            }
        }
        return suggestions;
    }
    handleSelect = (choice) => {
        const { get_id, get_label } = this.props;
        console.log(`Selected: ${JSON.stringify(choice)}`);
        this.props.onChange(get_id(choice));
        this.setState({
            'highlighted_suggestion': null,
            'edit_value': get_label(choice),
            'current_suggestions': null
        });
    }
    handleClick = (evt) => {
        var suggestions = this.state.current_suggestions;
        if (!suggestions) {
            suggestions = this.props.choices;
            this.setState({ 'current_suggestions': suggestions });
        } else {
            this.setState({ 'current_suggestions': null, 'edit_value': undefined });
        }
        this.state.inputRef.current.select();
        return true;
    }
    scrollToSelected = () => {
        this.state.scroll_delay.after(0.01, () => {
            if (this.state.selectedRef.current) {
                this.state.selectedRef.current.scrollIntoView({
                    behavior: 'auto',
                    inline: 'start',
                });
            }
        });
    }
    handleBlur = (evt) => {
        this.state.blur_delay.after(0.2, () => {
            var current = this.state.highlighted_suggestion;
            if (current !== null && current !== undefined) {
                this.handleSelect(current);
            } else {
                this.setState({ 'current_suggestions': null });
            }
        });
        return true;
    }
    handleCancel = (evt) => {
        /* user cancels update/suggestions, revert to previous */
        this.setState({ 'edit_value': undefined, 'current_suggestions': null });
    }
    handleKeyUp = (evt) => {
        const { get_id, get_label } = this.props;
        if (evt.key === 'ArrowUp' || evt.key === 'ArrowDown') {
            var suggestions = this.state.current_suggestions;
            if (!suggestions) {
                suggestions = this.props.choices;
            }
            /* only case where it means something */
            var current_index = null;
            const highlight = this.state.highlighted_suggestion || this.findMatches(this.state.edit_value, suggestions);
            if (highlight) {
                suggestions.map((choice, i) => {
                    if (current_index === null && get_id(choice) == get_id(highlight)) {
                        current_index = i;
                    }
                });

            }
            if (current_index === null) {
                current_index = 0;
            }
            if (evt.key === 'ArrowUp') {
                current_index -= 1;
            } else {
                current_index += 1;
            }
            current_index = (current_index + suggestions.length) % suggestions.length;
            this.setState({
                'edit_value': get_label(suggestions[current_index]),
                'highlighted_suggestion': suggestions[current_index],
                'current_suggestions': suggestions,
            });
            this.scrollToSelected();
            return false;
        } else if (evt.key === 'Enter') {
            if (this.state.highlighted_suggestion) {
                this.handleSelect(this.state.highlighted_suggestion);
            } else if (this.state.current_suggestions && this.state.current_suggestions.length) {
                this.handleSelect(this.state.current_suggestions[0]);
            }
            return false;
        } else if (evt.key === 'Escape') {
            this.handleCancel();
        }
        return false;
    }


    handleInput = (evt) => {
        const { get_id, get_label } = this.props;
        var new_value = evt.target.value;
        let new_state = { 'edit_value': new_value };
        if (!(new_value && new_value.length)) {
            new_state = {
                ...new_state,
                'current_suggestions': null,
                'highlighted_suggestion': null,
            };
            if (!this.props.field.required) {
                this.state.edit_delay.after(.2, () => {
                    this.props.onChange(null);
                });
            }
        } else {
            this.state.edit_delay.after(.2, () => {
                var suggestions = this.suggest(this.state.edit_value);
                var new_highlight = this.findMatches(new_value, suggestions) || suggestions[0];
                new_state = {
                    'current_suggestions': suggestions,
                    'highlighted_suggestion': new_highlight
                };
                // Note: this is happening .2s *later* than the caller
                this.setState(new_state);
            });
        }
        this.setState(new_state);
    }
    render() {
        const value = this.state.value || this.props.value;
        const { get_id, get_label, show_label, field } = this.props;

        var choice = this.choiceByValue(value);
        var suggestions = null;
        if (this.state.current_suggestions && this.state.current_suggestions.length) {
            var highlighted = this.state.highlighted_suggestion;
            if (highlighted === null || highlighted === undefined) {
                highlighted = this.props.value;
            }
            suggestions = this.state.current_suggestions;
        }
        var text_value = this.state.edit_value;
        if (text_value === undefined || text_value === null) {
            if (choice) {
                text_value = get_label(choice);
            } else {
                text_value = '';
            }
        }
        const current_label = choice && get_label(choice);
        const Component = this.props.renderer;
        return <Component
            inputRef={this.state.inputRef}
            selectedRef={this.state.selectedRef}
            value={text_value}
            inputClass={this.props.inputClass}
            inputType={this.props.inputType}
            field={field}
            show_label={show_label}
            current_label={current_label}

            onBlur={(evt) => this.handleBlur(evt)}
            onChange={(evt) => this.handleInput(evt)}
            onKeyUp={(evt) => this.handleKeyUp(evt)}
            onClick={(evt) => this.handleClick(evt)}

            onSelect={(value) => this.handleSelect(value)}
            onClose={(evt) => this.handleCancel(evt)}

            highlighted={highlighted}
            suggestions={suggestions}

        />;
    }
}

export default ComboBox;
