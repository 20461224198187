import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { requiring_system_store } from 'systemstorage';
import WidgetRender from 'widgets/custom_widget_render';


const styles = theme => ({
    sidebar: {
        width: '80%',
        marginLeft: '10%',
        overflow: 'hidden',
    },
    card: {
        width: '100%',
        overflow: 'hidden',
    },
});
const field_render = (id, label, value) => {
    if (value === null || value === undefined || value === '') {
        return null;
    }
    return <WidgetRender
        key={id}
        form_field={{
            name: id,
            field: {
                label_id: id,
                label: label,
            },
        }}
        show_label={true}
        widget_class='DisplayWidget'
    ><span title={value}>{value}</span></WidgetRender>;
};

class Sysinfo extends React.Component {
    render() {
        const { classes, system, sidebar = true } = this.props;
        // console.info(`Sysinfo status ${JSON.stringify(system)}`);
        if (!system) {
            return null;
        }
        return <div className={classNames(sidebar ? classes.sidebar : classes.card)}>
            {field_render('location', 'Location', system.system.location)}
            {/* {field_render('timezone','Timezone',system.system.timezone)} */}
            {field_render('serial', 'Serial Number', system.system.serial)}
            {/* {field_render('order','Order Number',system.system.order_number)}
            {field_render('customer','Customer',system.system.customer_id)} */}
            {/* {field_render('product','Product',system.sku.sku_name)} */}
            {field_render('release', 'Release', system.sku.release)}
        </div>;
    }
}

Sysinfo.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default requiring_system_store(withRouter(withStyles(styles)(Sysinfo)));
