import React from 'react';
// import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import { ClassyWidget } from 'reactform/classywidget';
import WidgetRender from './custom_widget_render';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


const styles = theme => ({
    formControl: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerLabel: {
        marginTop: '1em',
    },
    containerNoLabel: {

    },
    dateTimeEditor: {
        display: 'flex',
        flexDirection: 'column',
    },
    selectSet: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1,
    },
    dateTimeGroup: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: '1rem',
        flex: 1,
    }
});

const is_leap_year = (year) => {
    if (!(year % 4)) {
        if (!(year % 400)) {
            return true;
        } else if (!(year % 100)) {
            return false;
        }
        return true;
    }
    return false;
}
const range = (start, stop = null) => {
    if (stop == null) {
        stop = start;
        start = 0;
    }
    const result = [];
    for (let index = start; index < stop; index++) {
        result.push(index);
    }
    return result;
}
const format_iso_date = (date) => {
    return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`
};
const format_iso_time = (date, seconds = false) => {
    const seconds_formatted = seconds ?
        `:${`${date.getUTCSeconds()}`.padStart(2, '0')}` :
        ''
        ;
    return `${`${date.getUTCHours()}`.padStart(2, '0')}:${`${date.getUTCMinutes()}`.padStart(2, '0')}${seconds_formatted}`;
};

const LENGTH_MAP = {
    9: 30,
    4: 30,
    6: 30,
    11: 30,
    2: (year) => {
        if (is_leap_year(year)) {
            return 29;
        } else {
            return 28;
        }
    },
};

const DateEditor = withStyles(styles)((props) => {
    const {
        value,
        classes,

        date = true,
        time = true,
        local = true,
        seconds = false,
        format = 'locale', // 'iso','locale'
        onChange = null,
    } = props;
    if (typeof value == 'number') {
        const d = new Date();
        d.setTime(value * 1000);
        value = d;
    }
    if (!seconds) {
        value.setSeconds(0);
        value.setMilliseconds(0);
    }

    const change_handler = (evt, method) => {
        const next_value = new Date();
        next_value.setTime(value.getTime());
        if (method == 'setDay') { // sigh
            next_value.setMonth(next_value.getMonth(), evt.target.value);
        } else {
            next_value[method](evt.target.value);
        }

        if (onChange) {
            console.log(`New value ${format_iso_date(next_value)} ${format_iso_time(next_value)}`);
            onChange(next_value);
        } else {
            console.log(`No onChange provided, ignoring changed value ${next_value.toISOString()}`);
        }
    };


    const this_year = value.getFullYear();
    const this_month = value.getMonth();
    const year_choices = range(this_year - 20, this_year + 21).map(year => <MenuItem key={`year-${year}`} value={year}>{`${year}`}</MenuItem>);
    const month_choices = range(12).map(month => <MenuItem key={`month-${month}`} value={month}>{`${month + 1}`.padStart(2, '0')}</MenuItem>);
    let day_choices = range(1, 32).map(month => <MenuItem key={`day-${month}`} value={month}>{`${month}`.padStart(2, '0')}</MenuItem>);

    let hour_choices = range(24).map((x, i) => i).map(minute => <MenuItem key={`hour-${minute}`} value={minute}>{`${minute}`.padStart(2, '0')}</MenuItem>)
    let minute_choices = range(60).map((x, i) => i).map(minute => <MenuItem key={`minute-${minute}`} value={minute}>{`${minute}`.padStart(2, '0')}</MenuItem>)


    let trimmer = LENGTH_MAP[this_month];
    if (trimmer) {
        if (typeof trimmer == 'function') {
            trimmer = trimmer(this_year);
        }
        day_choices = day_choices.slice(0, trimmer);
    }
    const LOCALE_DATE = new Intl.DateTimeFormat('en-CA', {
        'year': 'numeric',
        'month': 'short',
        'day': 'numeric',
        'hour': 'numeric',
        'hour12': true,
        'minute': '2-digit',
        'hour12': true,
        'second': seconds ? '2-digit' : undefined,
        'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'timeZoneName': 'short',
    });
    let date_formatted = LOCALE_DATE.format(value);
    let iso_formatted = `${format_iso_date(value)} ${format_iso_time(value, seconds)} UTC`;

    return <div className={classes.dateTimeEditor}>
        <div className={classes.selectSet}>
            <div className={classes.dateTimeGroup}>
                <Select
                    label={'Year'}
                    value={value.getFullYear()}
                    onChange={(evt) => change_handler(evt, 'setYear')}
                    classes={{}}
                >
                    {year_choices}
                </Select>
                <Select
                    label={'Month'}
                    value={value.getMonth()}
                    onChange={(evt) => change_handler(evt, 'setMonth')}
                    classes={{}}
                >
                    {month_choices}
                </Select>
                <Select
                    label={'Day'}
                    value={value.getDate()}
                    onChange={(evt) => change_handler(evt, 'setDate')}
                    classes={{}}
                >
                    {day_choices}
                </Select>
            </div>
            {' '}
            <div className={classes.dateTimeGroup}>
                <Select
                    label={'Hour'}
                    value={value.getHours()}
                    onChange={(evt) => change_handler(evt, 'setHours')}
                    classes={{}}
                >
                    {hour_choices}
                </Select>
                {':'}
                <Select
                    label={'Minute'}
                    value={value.getMinutes()}
                    onChange={(evt) => change_handler(evt, 'setMinutes')}
                    classes={{}}
                >
                    {minute_choices}
                </Select>
            </div>
        </div>
        <div className={classes.currentValueDisplay} title={format == 'iso' ? date_formatted : iso_formatted}>
            {format == 'iso' ? iso_formatted : date_formatted}
        </div>
    </div>;

});



class DateTimeWidget extends ClassyWidget {
    defaultProps = {
        ...ClassyWidget.defaultProps,
        seconds: false,
        format: 'iso', // 'iso','locale'
    }
    constructor(props) {
        super(props);
        this.state = {
            dialog: false,
            edit_value: null
        };
    }
    current_value() {
        var base = super.current_value();
        if (base === 0 || base === null || base === undefined) {
            base = new Date();
            return (base / 1000.0) | 0.0;
        }
        return base;
    }

    on_editor_change = (date) => {
        this.handleChange(date.getTime() / 1000, true);
    }

    render() {
        const { format, seconds } = this.props;
        let raw_value = this.current_value();
        const field = this.get_field();
        var { show_label, classes } = this.props;
        var popup = null;
        const current_value = new Date();
        if (raw_value) {
            current_value.setTime(raw_value * 1000);
        }
        return <React.Fragment>
            <WidgetRender form_field={field} widget_class="date-time-widget" show_label={show_label}>
                <DateEditor value={current_value} onChange={this.on_editor_change} format={format} seconds={seconds} />
            </WidgetRender>
        </React.Fragment>;
    }
}


export default withStyles(styles)(DateTimeWidget);
