import React from 'react';
import { providing_nearest_form } from 'formprovider';
import { null_load } from 'dash/resolvetarget'
import EditingCard from 'dash/editingcard';
import { ReactForm } from 'reactform';
import MuiLoader from 'dash/MuiLoader';
// import { with_resolve_focus, with_focus_target } from 'dash/resolvefocus';
import { with_resolved_target } from 'dash/resolvetarget';
import { useLocation, useHistory } from 'react-router-dom';
import useQuery from 'storages/usequery';


const BaseDetailEditor = (props) => {
    /* Generic form-based editor for a resolved target from shogunconf */
    let { target, __type__, __pk__, storage, ...childProps } = props;
    const location = useLocation();
    const query = useQuery(location.search);
    if (!(null_load(props) || target)) {
        return <MuiLoader />;
    }
    let forms_key = query.get('forms_key') || 'shogunconf_forms';
    let buttons = ['save', 'delete'];
    if (target == null || target == undefined) {
        target = {
            __type__: __type__,
            __pk__: __pk__,
            key: `${__type__}.${__pk__}`,
            title: `Create ${__type__}`,
        };
        buttons = ['save'];
    }
    return <React.Fragment>
        <EditingCard title={target.title} actions={[]} key={`detail-${target.key}`}>
            <ReactForm
                {...childProps}
                target={target}
                use_dialog={false}
                buttons={buttons}
                storage={forms_key}
            />
        </EditingCard>
    </React.Fragment>;
};
const DetailEditor = providing_nearest_form(
    with_resolved_target(BaseDetailEditor),
    'shogunconf_forms'
);

export default DetailEditor;
export { BaseDetailEditor, DetailEditor };
