/* Material-ui sample code for a transfer list */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ArrowRightAlt from '@material-ui/icons/ArrowRight';
import ArrowLeftAlt from '@material-ui/icons/ArrowLeft';
import Add from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(.5),
    },
    leftColumn: {
        flex: 1,
    },
    rightColumn: {
        flex: 1,
    },
    controlHolder: {
        flex: 0,
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },

    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        maxHeight: '12em',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));


const default_render_item = (item) => {
    if (Array.isArray(item)) {
        return item[1];
    }
    return item && (item.title || item.name || `${item}`);
};


const TransferList = (props) => {
    const {
        items,
        value,
        resolve_value,
        render_item = default_render_item,
        onChange,
        onAddRecord = null,
    } = props;
    const classes = useStyles();

    const [renderedItems, setRenderedItems] = React.useState({ items, value });
    const key_map = {};
    items.map(item => {
        if (item !== null && item !== undefined) {
            key_map[resolve_value(item)] = item;
        }
    });
    const right_map = {};
    const left_map = {};
    value.map(item => {
        const key = resolve_value(item);
        const record = key_map[key];
        if (record !== null && record !== undefined) {
            right_map[key] = record;
        }
    });
    items.map(item => {
        const key = resolve_value(item);
        if (!right_map[key]) {
            const record = key_map[key];
            left_map[key] = record;
        }
    });

    const items_in_mapping = (mapping) => {
        return items.filter(item => !!(mapping[resolve_value(item)]));
    };


    const [checked, setChecked] = React.useState({});
    const [left, setLeft] = React.useState(left_map);
    const [right, setRight] = React.useState(right_map);

    if (renderedItems.items != items || renderedItems.value != value) {
        setLeft(left_map);
        setRight(right_map);
        setRenderedItems({ items, value });
    }


    const handleToggle = (value) => () => {
        /* Toggle the value checked/unchecked */
        const key = resolve_value(value);
        const newChecked = {
            ...checked,
        };
        newChecked[key] = !checked[key];
        setChecked(newChecked);
    };

    const numberOfChecked = (items) => items.filter(item => checked[resolve_value(item)]).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked({});
        } else {
            const newChecked = {};
            items.map(item => {
                newChecked[resolve_value(item)] = true;
            })
            setChecked(newChecked);
        }
    };

    const handleCheckedRight = () => {
        const newRight = {
            ...right,
        };
        const newLeft = {};
        const newChecked = { ...checked };
        Object.entries(left).map(([key, value]) => {
            if (checked[key]) {
                newRight[key] = value;
                newChecked[key] = false;
            } else {
                newLeft[key] = value;
            }
        });
        setRight(newRight);
        onChange && onChange(Object.values(newRight).map(x => x));
        setLeft(newLeft);
        setChecked(newChecked);
    };

    const handleCheckedLeft = () => {
        const newRight = {};
        const newLeft = {
            ...left,
        };
        const newChecked = { ...checked };
        Object.entries(right).map(([key, value]) => {
            if (checked[key]) {
                newLeft[key] = value;
                newChecked[key] = false;
            } else {
                newRight[key] = value;
            }
        })
        setRight(newRight);
        onChange && onChange(Object.values(newRight));
        setLeft(newLeft);
        setChecked(newChecked);
    };
    const customList = (title, mapping) => {
        const listItems = items_in_mapping(mapping);
        const numberChecked = numberOfChecked(listItems);
        return <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(listItems)}
                        checked={numberChecked === listItems.length && listItems.length !== 0}
                        indeterminate={numberChecked !== listItems.length && numberChecked !== 0}
                        disabled={listItems.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberChecked}/${listItems.length} selected`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {listItems.map((item) => {
                    const key = resolve_value(item);
                    const labelId = `transfer-list-all-item-${key}-label`;
                    const label = render_item(item);

                    return (
                        <ListItem key={`item-${key}`} role="listitem" button onClick={handleToggle(item)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={!!checked[key]}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={label} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>;
    };
    const countChecked = (items) => {
        let areChecked = 0;
        Object.keys(items).map(key => {
            if (checked[key]) {
                areChecked += 1;
            }
        });
        return areChecked;
    };
    const leftChecked = countChecked(left);
    const rightChecked = countChecked(right);



    return (
        <div className={classes.root}>
            <div className={classes.leftColumn}>
                {customList('Available', left)}
            </div>
            <div className={classes.controlHolder}>
                <IconButton
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedRight}
                    disabled={leftChecked === 0}
                    aria-label="Add checked to the selection set"
                >
                    <ArrowRightAlt color={leftChecked === 0 ? "disabled" : "secondary"} variant="outlined" />
                </IconButton>
                <IconButton
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedLeft}
                    disabled={rightChecked === 0}
                    aria-label="Remove checked from the selection set"
                >
                    <ArrowLeftAlt color={rightChecked === 0 ? "disabled" : "secondary"} variant="outlined" />
                </IconButton>
                {onAddRecord ? <IconButton
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={onAddRecord}
                    aria-label="Create a new linked record"
                >
                    <Add color="secondary" variant="outlined" />
                </IconButton> : null}
            </div>
            <div className={classes.rightColumn}>
                {customList('Selected', right)}
            </div>
        </div>
    );
};
export default TransferList;