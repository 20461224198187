import React from "react";

/* Registration point for node views */
const NODE_VIEW_REGISTRY = {};

const register = (sku, viewComponent) => {
    /* Register the given view as the node-view for the given sku */
    if (!viewComponent) {
        delete NODE_VIEW_REGISTRY[sku];
    }
    NODE_VIEW_REGISTRY[sku] = viewComponent;
    return viewComponent;
};
const lookup = (sku) => {
    /* Lookup the sku in the custom node-view registry */
    return NODE_VIEW_REGISTRY[sku];
};

export {register,lookup};
