import React from 'react';
import TextField from '@material-ui/core/TextField';

import { ClassyWidget } from 'reactform/classywidget';
import TextInputWidget from './TextInputWidget';

class PasswordInputWidget extends React.Component {
    render() {
        return <TextInputWidget
            autoFocus={this.props.autoFocus}
            {...this.props}
            type="password"
        />;
    }
}

export default PasswordInputWidget;
