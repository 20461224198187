import React from 'react';
import TextField from '@material-ui/core/TextField';

import { ClassyWidget } from 'reactform/classywidget';

class URLWidget extends ClassyWidget {
    render() {
        const { show_label } = this.props;
        const field = this.get_field();
        const base_field = field.field;

        return (
            <TextField
                type="text"
                placeholder='https://example.com:8080/path/to/folder/'
                label={show_label && field.label}
                value={this.render_value() || ''}
                id={base_field.label_id}
                name={field.name}
                onChange={event => this.handleChange(event.target.value)}
                className={this.props.className || ''}
                autoFocus={this.props.autoFocus}
                classes={{}}
                ref={this.controlRef}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
            />
        );
    }
}

export default URLWidget;
