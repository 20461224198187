import React from 'react';
import { WithTabularStorage } from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import { ReactFormTable } from 'reactform';
import { with_focus } from 'dash/focusprovider';
import EditingCard from 'dash/editingcard';
import { providing_nearest_form } from 'formprovider';
import { with_name } from 'wraps';

const node_url = (node) => {
    if (node.parent && node.parent.id) {
        return `/m/nodes/${node.parent.id}/${node.id}`
    } else {
        return `/m/nodes/0/${node.id}}`
    }
}

class BaseVSBBTable extends React.Component {
    render() {
        const { form_details, mapping, storage } = this.props;
        if (!(form_details)) {
            return <MuiLoader />;
        }
        return <ReactFormTable
            // link_function={this.on_view_group.bind(this)}
            form_keys={this.props.editing_forms}
            form_details={form_details}
            editable={false}
            overall_target={mapping}
            defaultSorting={[
                { 'id': 'name', 'desc': false, },
            ]}
            wrap_data={
                (record) => {
                    return [
                        {
                            ...record.node,
                            __url__: node_url(record.node),
                        },
                        {
                            ...record,
                            __url__: node_url(record.node),
                        },
                    ];
                }
            }
            field_actions={{
                // 'source_channel_number': (target, context, menu) => {
                //     return [
                //         delete_target_action(target, context, menu),
                //     ];
                // }
            }}
            widgets={{
                // 'datasource': (props) => {
                //     const {value,record,field,alignment,classes} = props;
                //     return <div className='data-source' title={record && record.datasource_display}>#{value}</div>;
                // },
                ...(this.props.widgets || {}),
            }}
            editable={true}
            preference_key={this.props.preference_key || 'vsbbtable.table'}
            preference_default={{
                hiddenColumns: [
                    'tags',
                    'slot_name',
                    'resource_type_key',
                    'serial_number',
                ]
            }}
            link_to_edit={true}
            link_only={true}
            column_widths={{
                name: 'medium',
                customer_id: 'medium',
                site_id: 'small',
            }}
            {...this.props}
            exclude={[
                'notes',
                // 'name',
                'disabled_slots',
                'capacity',
                ...(this.props.exclude || []),
            ]}
        />;
    }
}

const VSBBTable = with_focus((props) => {
    const { resource_type_key, root = true } = props;
    const node = ((!root) && props.focus.get_focus().focus) || null;
    // Requires that we know the node/groups's metadata at this point...
    // console.log(`Devices table for root=${root} with ${node && node.__pk__}`);
    const context = (node && node.tree_id) ? {
        node__tree_id: node.tree_id,
        node__lft__gte: node.lft,
        node__rght__lte: node.rght,
    } : {};
    if (resource_type_key) {
        context['resource_type_key'] = resource_type_key;
    }
    // console.log(`Render table with context ${JSON.stringify(context)}`);

    const final_props = {
        storage_props: {
            default_context: {
                // 'resource_type_key__in': ['vsbb.v1', 'vsbb.monitor.v1'],
                ...context,
            },
            forms_key: 'shogunconf_forms',
            form_key: 'VSBBs',
            quick_settings: {
            },
            debug: false,
        },
        downloads: true,
        ...props
    };

    return <WithTabularStorage
        key={`vsbbs-${(node && node.id) || 'all'}`}
        Component={BaseVSBBTable}
        editing_forms={['VSBBNode', 'SimpleResourceTable',]}
        form_titles={["Node", "Daemon",]}
        exclude={[]}
        preference_default={{
            hiddenColumns: [
                'sku',
                'owner',
                'ip_addresses',
                'mac_address',
                'resource_type_key',
                ...((props.preference_default && props.preference_default.hiddenColumns) || []),
            ],
        }}

        {...final_props}
    />;
});

const VSBBDevicesCard = providing_nearest_form(
    with_name((props) => {
        const { title = 'Devices', actions = [], collapsing = 'vsbb-tables', ...restProps } = props;
        return <EditingCard
            title={title}
            actions={actions}
            no_wrapper={true}
            collapsing={collapsing}
        >
            <VSBBTable {...restProps} />
        </EditingCard>;
    }, 'VSBBDevicesCard'),
    'shogunconf_forms'
);

export default VSBBTable;
export { BaseVSBBTable, VSBBTable, VSBBDevicesCard };
