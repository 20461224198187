import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    card: {
        margin: theme.spacing(2)
    },
    compactActions: {
        /* Applied to CardActions iff compactActions is true */
        padding: Math.round(theme.spacing(1) / 2),
        textAlign: 'center',
    }
});

function MuiCard(props) {
    const { classes, children, actions, fullWidth, className = '', compactActions = false } = props;

    const actionClasses = { root: classNames(compactActions ? classes.compactActions : '.MuiCardActions-root') };
    return (
        <Card className={classNames(classes.card, className)}>
            {fullWidth}
            {children && children.length && <CardContent>{children}</CardContent>}
            {actions && <CardActions classes={actionClasses}>{actions}</CardActions>}
        </Card>
    );
}

MuiCard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MuiCard);
