import { error_report } from "debugfail";
import { Signal } from "signals";
import { Cookies } from "react-cookie";
import { POST_SIGNAL } from "storages/ajaxobservables";
import 'whatwg-fetch';
import 'url-search-params-polyfill';

class AjaxStorageBacking {
    constructor(state) {
        Object.assign(this, {
            'input': null,
            'getting': null,
            'debug': false,
            'error': null,
            'loading': Signal(),
            'putting': null,
            'putting_queue': [],
            'default': null,
            'cookies': new Cookies()
        });
        Object.assign(this, state);
    }
    csrfSafeMethod(method) {
        return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
    }
    csrf_token() {
        var token = this.cookies.get('csrftoken');
        if (!token || !token.length) {
            Array.from(document.getElementsByName('csrfmiddlewaretoken')).map((element) => {
                const el_value = element.getAttribute('value');
                if (el_value && el_value.length) {
                    console.debug("CSRF Cookie unavailable, using element");
                    token = el_value;
                }
            });
            if (!token) {
                console.error("Null csrf token, somehow we didn't get a cookie or a form element");
            }
        }
        return token;
    }
    needs_form_data = (parameters) => {
        return Object.getOwnPropertyNames(parameters).filter((name) => {
            const value = parameters[name];
            return (
                value !== null
                && value !== undefined
                && (
                    (window.File && value.__proto__ instanceof window.File)
                    || (window.Blob && value.__proto__ instanceof window.Blob)
                )
            );
        }).length;
    }
    as_post = (parameters) => {
        if (true || this.needs_form_data(parameters)) {
            var form_data = new FormData();
            Object.getOwnPropertyNames(parameters).map(function (name) {
                var value = parameters[name];
                if (value instanceof Blob) {
                    console.log(`Setting blob for ${name}`);
                    form_data.append(name, value, value.name);
                    // const reader = new FileReader();
                    // reader.onload = () => {
                    //     console.log(`Read value: ${reader.result}`);
                    //     // this.handleChange(reader.result, true);
                    // };
                    // reader.readAsText(value, 'utf-8');
                    return;
                }
                if (Array.isArray(value)) {
                    if (value.length) {
                        value = value.map(x => `${x}`);
                    } else {
                        return;
                    }
                } else if ('' + value === '[object Object]') {
                    value = JSON.stringify(value);
                }
                form_data.append(name, value);
            });
            return form_data;
        } else {
            return JSON.stringify(parameters);
        }
    }
    post(parameters, url, options = null) {
        var backing = this;
        url = url || this.url;
        if (!url) {
            error_report(null, 'No URL specified, cannot post to server');
            return;
        }
        if (backing.putting) {
            if (backing.debug) {
                console.log("Already doing a post request");
            }
            return this.push_putting_queue(parameters, url, options);
        }
        if (backing.debug) {
            console.log('POST ' + url + ' ' + JSON.stringify(parameters));
        }
        const form_data = this.as_post(parameters);
        const post_options = {
            'method': 'POST',
            'mode': 'same-origin',
            'cache': 'no-cache',
            'credentials': 'same-origin',
            'headers': {
                'X-CSRFToken': this.csrf_token(),
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
            'redirect': 'follow',
            'referrerPolicy': 'origin',
            'body': form_data
        };
        if (!(form_data instanceof window.FormData)) {
            post_options.headers['Content-Type'] = 'application/json';
        }
        backing.putting = Promise.resolve(fetch(url, post_options)).then(
            (result) => result.json()
        ).then((data) => {
            return this.handle_post_success(data, url, parameters, options);
        }).catch((err) => {
            return this.handle_post_failure(err, url, parameters, options);
        });
        return backing.putting;
    }
    handle_post_success(result, url, parameters, options) {
        /* clear our state and trigger next */
        var backing = this;
        if (backing.debug) {
            console.log('POST RESPONSE ' + url + ' ' + JSON.stringify(result));
        }
        if (result && result.success) {
            /* send signal so that configuration messages can update */
            window.setTimeout(function () {
                try {
                    if (!(options && options.no_signal)) {
                        POST_SIGNAL.send(backing);
                    }
                } catch (err) {
                    error_report(err, 'Failure sending post success message');
                }
            }, 100);
        }
        backing.putting = null;
        backing.next_post_queue();
        return result;
    }
    handle_post_failure(err, url, parameters, options) {
        var backing = this;
        /* clear our state and trigger next */
        if (backing.debug) {
            console.log('POST ERROR ' + url + ' ' + JSON.stringify(err));
        }
        backing.putting = null;
        if (backing.debug) {
            console.log('Delaying next post attempt');
        }
        window.setTimeout(() => backing.next_post_queue(), 5000);
        throw err;
    }
    next_post_queue() {
        /* dispatch the next post operation to the server */
        var backing = this;
        if (backing.putting_queue.length) {
            if (backing.debug) {
                console.log('Next putting queue item');
            }
            var task = backing.putting_queue.shift();
            backing.post(task.parameters, task.url, task.options).then(
                function (result) {
                    if (backing.debug) {
                        console.log('Success on deferred put');
                    }
                    task.callbacks.resolver(result);
                    return result;
                },
                function (err) {
                    if (backing.debug) {
                        console.log('Err on deferred put');
                    }
                    task.callbacks.rejector(err);
                    throw err;
                }
            );
        }
    }
    push_putting_queue(parameters, url, options) {
        /* push the given request onto our push (post) queue */
        var backing = this;
        var promise_functions = {};
        var promise = new Promise(function (resolver, rejector) {
            promise_functions.resolver = resolver;
            promise_functions.rejector = rejector;
        });
        backing.putting_queue.push({
            'parameters': parameters,
            'url': url,
            'promise': promise,
            'callbacks': promise_functions,
            'options': options,
        });
        return promise;
    }
    get(url, context, options, headers = null) {
        /* Fetch the given url, with context as request parameters and options controlling operation

        option dictionary keys:
            text: true -- return {'success':true,'data':response.text()} on successful load
        */
        var backing = this;
        if (!url) {
            console.log('Warning: no url specified');
            return;
        }
        if (url === backing.url && backing.getting) {
            if (backing.debug) {
                console.log(`Duplicate request for backing url ${url}, skipping`);
            }
            return backing.getting;
        }
        if (backing.debug) {
            console.info('Getting: ' + url);
        }
        if (context) {
            var params = new URLSearchParams();
            Object.keys(context).map((key) => {
                params.append(key, context[key]);
            });
            var params_encoded = params.toString();
            if (params_encoded.length) {
                url = url + '?' + params_encoded;
            }
        }
        let cache_settings = {
        };
        if (headers && headers['If-Modified-Since']) {
            cache_settings = {
                'cache': 'default',
            };
        }

        var getting = Promise.resolve(fetch(url, {
            'method': 'GET',
            'mode': 'same-origin',
            'credentials': 'same-origin',
            'headers': {
                'X-CSRFToken': this.csrf_token(),
                ...(headers || {}),
            },
            'redirect': 'error',
            'referrerPolicy': 'no-referrer',
            ...cache_settings,
        })).then(
            (response) => {
                if (response.redirected) {
                    const u = new URL(response.url);
                    if (!window.location.pathname.startsWith(u.pathname)) {
                        console.log(`Redirecting to ${response.url}`);
                        window.location = u.pathname;
                    }
                    throw `Response status code: 302`;
                } else if (response.status == 304) {
                    // Not modified...
                    return {
                        'success': true,
                        'unmodified': true,
                    };
                } else if (response.status == 200) {
                    const headers = {};
                    for (const header of response.headers) {
                        headers[header[0]] = header[1];
                    }
                    // console.log(`Headers for ${url}: ${JSON.stringify(headers)}`);
                    if (options && options.text) {
                        return response.text().then(
                            (body) => ({
                                success: true,
                                data: body,
                                headers: headers,
                            })
                        );
                    } else {
                        return response.json().then(body => {
                            return {
                                ...body,
                                headers: headers,
                            };
                        }).catch(
                            (err) => {
                                response.clone().text().then(content => {
                                    console.error(`Non-json response ${content} on ${url}`);
                                });
                                return {
                                    'error': true, 'message': [
                                        'Non-JSON data returned from query'
                                    ]
                                };
                            }
                        );
                    }
                } else {
                    throw ({
                        status: response.status,
                        error: true,
                        messages: [
                            response.statusText,
                        ]
                    });
                }
            }
        ).then(
            (data) => this.handle_get_success(data, url, context)
        ).catch(
            (err) => this.handle_get_error(err, url, context)
        );
        /* send the start-of-loading signal */
        if (url === backing.url) {
            backing.getting = getting;
        }
        backing.loading.send({ 'getting': true, 'backing': backing, error: null });
        return getting;
    }
    handle_get_success(data, url, context) {
        var backing = this;
        if (backing.debug) {
            console.info(`Response from: ${url}`);
        }
        backing.getting = null;
        backing.error = null;
        if (data === undefined || data === null) {
            /* likely 304 event */
            data = null;
        } else {
            if (data.success) {
                if (backing.debug) {
                    console.debug(`Success on ${url}`);
                }
            } else {
                const messages = data.messages || (data.messsage && [data.message]);
                if (data.error && messages) {
                    backing.error = messages;
                } else if (messages) {
                    console.warning(`Response has messages, but neither success nor error flags ${JSON.stringify(data)}`);
                    backing.error = messages;
                } else if (data.error) {
                    console.warning(`Response has error flag, but no messages ${JSON.stringify(data)}`);
                    backing.error = [`Error flag in response, but no messages`];
                }
                console.log('Error loading data ' + (data.message || data.messages));
            }
        }
        backing.loading.send({
            'getting': false,
            'backing': backing,
            error: backing.error,
            success: !!backing.error
        },
        );
        return data;
    }
    handle_get_error(err, url, context) {
        var backing = this;
        // backing.debug=true;
        if (backing.debug) {
            console.error(`Get error on ${url}: ${JSON.stringify(err)}`);
        }
        backing.getting = null;
        if (err.status === 0 || err.status === undefined) {
            backing.error = [
                'Connection to the server failed'
            ];
        } else {
            if (err.messages) {
                backing.error = err.messages;
            } else {
                backing.error = [
                    'Error: ' + (err.status || err.message || err.statusText || 'Unable to load')
                ];
            }
        }
        backing.loading.send({ 'getting': false, 'backing': backing, error: backing.error });
        return {
            'error': true,
            'messages': [
                backing.error,
            ],
        };
    }
    set(state) {
        /* we don't, by default do an ajax set on every update */
        return state;
    }
}

export default AjaxStorageBacking;
