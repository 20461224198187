import React from 'react';
import { ClassyWidget } from 'reactform/classywidget';
import ComboBox from 'reactform/combobox';
import RenderComboBox from './rendercombobox';
import WidgetRender from './custom_widget_render';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
    chipHolder: {
        width: '100%',
        minHeight: '2em',
        borderBottomColor: theme.palette.primary.shading,
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
    },
    empty: {
        color: theme.roles.disabled,
    }
});

class BaseChipList extends React.Component {
    /* List of chips that does a callback on removal */
    static defaultProps = {
        empty_label: 'Empty, search below',
        remove_callback: null,
        update_callback: null,
        label_callback: null,
        value: [],
    }
    render() {
        const { value, label_callback, remove_callback, classes } = this.props;
        const chips = value.map((selected, i) => {
            // TODO: this is an *extremely* expensive operation
            // we should retain the values in state...
            const label = label_callback(selected);
            return <Chip
                label={label}
                className={`selected-chip-${selected}`}
                classes={{}}
                key={`selection-${i}`}
                onDelete={(e) => remove_callback(selected)}
            />;
        });
        if (!chips.length) {
            chips.push(<span key='empty' className={classes.empty}>{this.props.empty_label}</span>);
        }
        return <div className={('chips', classes.chipHolder)}>{chips}</div>;
    }
}
const ChipList = withStyles(styles)(BaseChipList);

class SelectionFromListWidget extends ClassyWidget {
    /* Select from a (large) set of choices as a chip-set + search-box

    Shows a chip-set with a combo-box under it by default (ChipList)

    Allowed value-types:

        list-of-int, list-of-string, comma-sep string of the same
        json => array-of-int, array-of-string, array-of-objects (with handler callbacks)
    */
    static defaultProps = {
        ...ClassyWidget.defaultProps,
        unique: true, // Require value uniqueness (remove duplicate items when adding to set)
        show_label: true, // Show the overall field label
        unknown_label: null, // Chip label when a value is not in choices, default is the value as a string
        comma_split: true, // If we receive a string, do comma-splitting (django comma-separated widget)
        empty_label: 'Empty, search below', // String to display in chip area when no choices
        get_choices: null, // get_choices(widget) override to get choices from somewhere else...
        get_id: (record, widget) => record[0], // given a record, retrieve the id
        get_label: (record, widget) => record[1], // given a record, get the label
        to_server: null, // get the value to pass to the server from the selected record...
        from_server: null, // resolve the post-value to a choice record (value, choices) => [ record, record, record, ]
        ListWidget: ChipList, // Renderer that shows the set of selected items...
    };
    choices_for_value = (value) => {
        const { get_id } = this.props;
        return this.get_choices().filter(choice => get_id(choice, this) == value);
    }
    label_for_value = (record) => {
        const { get_label } = this.props;
        return get_label(record, this);
    }
    get_choices = () => {
        if (this.props.get_choices) {
            return this.props.get_choices(this);
        }
        var field = this.get_field();
        var base_field = field.field;
        return base_field.choices;
    }
    on_add_value = (record) => {
        const { get_id } = this.props;
        const previous = this.render_value();
        const new_item = this.props.new_item ? this.props.new_item(record) : record;
        let updated = null;
        if (this.props.unique) {
            const filtered = previous.filter(v => get_id(v, this) != get_id(new_item, this));
            updated = [...filtered, new_item];
        } else {
            updated = [...previous, new_item];
        }
        this.handleChange(updated);
        return updated;
    }
    array_value = (content) => {
        /* Convert content to array value */
        let value = content || this.render_value() || [];
        if (typeof value == 'string') {
            if (value && value[0] == '[') {
                return JSON.parse(value);
            } else if (this.props.comma_split) {
                value = value.split(',');
            } else {
                value = [value];
            }
        }
        return value;
    }
    on_remove_value = (value) => {
        /* Remove a single value from the list */
        const { get_id } = this.props;
        const previous = this.render_value();
        const remove_id = get_id(value, this);
        this.handleChange(previous.filter(v => get_id(v, this) !== remove_id));
    }
    render_selected = () => {
        /* Render value as a table/list/chip set */
        const { ListWidget, empty_label } = this.props;
        return <ListWidget
            label_callback={this.label_for_value}
            remove_callback={this.on_remove_value}
            empty_label={empty_label}
            value={this.render_value()}
        />;
    }
    render_choices = () => {
        /* Render the choices as a combobox */
        var field = this.get_field();
        const choices = this.get_choices();

        return <Autocomplete
            options={choices}
            getOptionLabel={choice => (choice[1] || "")}
            onChange={(evt, value) => this.on_add_value(value)}
            clearOnEscape={true}
            // disableOpenOnFocus={true}
            autoSelect={true}
            renderInput={
                (params) => <TextField
                    {...params}
                    label={`Add to ${field.label}`}
                    variant='standard'
                    placeholder='🔍 Search'
                    InputLabelProps={{
                        shrink: true
                    }}
                    style={{
                        width: '100%',
                    }}
                />
            }
        />;
    }
    render() {
        const { show_label, classes } = this.props;
        var field = this.get_field();
        return <WidgetRender
            form_field={field}
            show_label={show_label}
        >
            {this.render_selected()}
            {this.render_choices()}
        </WidgetRender>;
    }
}

export default withStyles(styles)(SelectionFromListWidget);
