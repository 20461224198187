import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
    scrollContainer: {
        maxHeight: '300px',
        border: `1px solid ${theme.roles.divider}`,
        overflow: 'auto',
        marginBottom: theme.spacing(2),
    }
});

const ScrollContainer = ({ classes, children, className = '' }) => (
    <div className={classNames(classes.scrollContainer, className)}>
        {children}
    </div>
);

export default withStyles(styles)(ScrollContainer);
