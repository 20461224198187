import React from 'react';

import TextInputWidget from './TextInputWidget';
import { ClassyWidget } from 'reactform/classywidget';

class EmailInput extends ClassyWidget {
    render() {
        return <TextInputWidget 
            autoFocus={this.props.autoFocus}
            {...this.props}
            type="email" 
        />;
    }
}

export default EmailInput;
