import React from 'react';
import { Redirect } from 'react-router-dom';
import { ResolveTarget } from 'dash/resolvetarget';
import { devices_link_url } from './helpers';
import VSBBView from 'shogun/vsbbview';
import NodeView from 'shogun/nodeview';
import Config from 'config';

const devices_routes = ({ PREFIX }) => ([
    Config.world_is_node ?
        {
            'path': `${PREFIX}nodes/:group(\\d+)?/:node(\\d+)`,
            'node_path': `${PREFIX}nodes/:group(\\d+)/:node(\\d+)`,
            'group_path': `${PREFIX}nodes/:group(\\d+)`,
            'link_url': devices_link_url(PREFIX),
            'header': 'Device',
            'exact': true,
            'title': 'Device',
            'component': NodeView,
            'resolve': [
                { param: 'group', '__type__': 'Group', as_focus: false, 'storage': 'shogunconf_forms', },
                { param: 'node', '__type__': 'Node', as_focus: true, 'storage': 'shogunconf_forms', },
            ],
            'renderer': props => {
                const group = parseInt(props.match.params.group);
                const node = parseInt(props.match.params.node);
                if (!(node === undefined || node === null || Number.isNaN(node))) {
                    // props.focus.set_focus(null);
                    return <ResolveTarget
                        key={`devices-${group}-${node}`}
                        as_focus={true}
                        storage='shogunconf_forms' __type__='Node' __pk__='__default__'
                    >
                        <NodeView
                            key={`node-${node}`}
                            {...props}
                        />
                    </ResolveTarget>;
                } else {
                    return <Redirect to={`${PREFIX}nodes/${group}`} />;
                }
            }
        }

        :
        {
            'path': `${PREFIX}nodes/:group(\\d+)`,
            'node_path': `${PREFIX}nodes/:group(\\d+)/:node(\\d+)`,
            'link_url': devices_link_url(PREFIX),
            'header': 'Devices',
            'exact': true,
            'title': 'Devices',
            'component': VSBBView,
            'resolve': [
                { param: 'group', '__type__': 'Group', as_focus: false, 'storage': 'shogunconf_forms', },
            ],
            'renderer': props => {
                const group = parseInt(props.match.params.group);
                return <ResolveTarget key={`devices-${group}`} as_focus={true} storage='shogunconf_forms' __type__='Group' __pk__={group}>
                    <VSBBView
                        root={group !== 0}
                        withGroups={false}
                        key={`devices-${group}`}
                        storage='shogunconf_forms'
                        {...props}
                    />
                </ResolveTarget>;
            },
            routes: [
                {
                    'path': `${PREFIX}nodes/:group(\\d+)/:node(\\d+)`,
                    'group_path': `${PREFIX}groups/:group(\\d+)`,
                    'link_url': devices_link_url(PREFIX),
                    'hideInNav': true,
                    'header': 'Device',
                    'exact': true,
                    'title': (props) => {
                        const { route, match, location, target } = props;
                        if (target) {
                            return target.title;
                        }
                        return 'Device';
                    },
                    'component': NodeView,
                    'resolve': [
                        { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                        { param: 'node', '__type__': 'Node', as_focus: false, 'storage': 'shogunconf_forms', },
                    ],
                    'renderer': props => {
                        const group = parseInt(props.match.params.group);
                        const node = parseInt(props.match.params.node);
                        if (node) {
                            // props.focus.set_focus(null);
                            return <NodeView
                                key={`node-${node}`}
                                {...props}
                            />;
                        } else {
                            return <Redirect to={`${PREFIX}nodes/${group}`} />;
                        }
                    }
                },
            ]
        },
]);

export default devices_routes;
