/* type-rendering functions for use in display-only contexts */
import RenderBoolean, { GoodBadBool, BadGoodBool, ErrorBoolean } from './renderboolean';
import scaled_integer_component from './renderscaledinteger';
import render_special_values from './renderspecialvalues';
import { register } from 'reactform/typerenderer';
import RenderDownload from './renderdownload';
import render_file from 'widgets/renderfile';
import ShortWithTitle from 'widgets/shortwithtitle';
import HumanBytes from 'widgets/renderhumanbytes';
import HumanDuration, { HumanDurationAgo } from 'widgets/renderhumanduration';

register('CheckboxInput', RenderBoolean);

register('Boolean', RenderBoolean);
register('good_bad_bool', GoodBadBool);
register('bad_good_bool', BadGoodBool);
register('ErrorBoolean', ErrorBoolean);

register('NumberInput', scaled_integer_component(1, null));
register('Download', RenderDownload);
register(
    'ZeroNullInteger',
    render_special_values(
        scaled_integer_component(1, null),
        {
            '0': (props) => null,
            'null': (props) => null,
            'undefined': (props) => null,
        },
    ),
);

register('MHz', scaled_integer_component(1000000, 'MHz'));
register('KHz', scaled_integer_component(1000, 'KHz'));
register('MSym', scaled_integer_component(1000000, 'MSym'));
register('dB', scaled_integer_component(1, 'dB'));

register('Mbps', scaled_integer_component(1000000, 'Mbps'));
register('MB', scaled_integer_component(1000000, 'MB'));

register('MHz_float', scaled_integer_component(1, 'MHz'));
register('BigFileUpload', render_file);

register('ShortFloatSeconds', scaled_integer_component(1, 's', 3));
register('ShortWithTitle', ShortWithTitle);

register('BpM_in_Mbps', scaled_integer_component(1000000 * 60 / 8, 'Mbps'));
register('FloatPercent', scaled_integer_component(.01, '%'));

register('HumanBytes', HumanBytes);
register('HumanDuration', HumanDuration);
register('HumanDurationAgo', HumanDurationAgo);
