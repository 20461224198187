import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import MuiButton from 'dash/MuiButton';

const styles = theme => ({
    button: {
        display: 'flex',
        alignItems: 'center'
    },
    text: {
        marginLeft: theme.spacing(1)
    }
});

class EditIntentRender extends React.Component {
    render() {
        const {
            variant,
            classes,
            className,
            color,
            title,
            handleClick,
            handleKeyUp,
            type_name,
            editing,
            form,
            children,
            icon,
            buttonStyleClass='',
        } = this.props;
        // If type_name is not defined, render an <a> tag with an icon, as it
        // looks bad to render a solo icon inside of a <MuiButton />
        return (
            <div className={className} style={this.props.style}>
                {!type_name ? (
                    <a
                        title={title}
                        onClick={handleClick}
                        onKeyUp={handleKeyUp}
                    >
                        {icon}
                        {children}
                    </a>
                ) : (
                    <MuiButton
                        variant={variant || 'contained'}
                        color={color}
                        title={title}
                        onClick={handleClick}
                        onKeyUp={handleKeyUp}
                        className={buttonStyleClass}
                    >
                        <div className={classes.button}>
                            {icon}
                            <span className={classes.text}>
                                {type_name}
                                {children}
                            </span>
                        </div>
                    </MuiButton>
                )}

                {editing && (
                    <div className="edit-intent-form-popup" onClick={(evt) => evt.stopPropagation()}>{form}</div>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(EditIntentRender);
