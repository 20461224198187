/* Apply an accessor for properties to an object-tree */

const FUNCTION_CACHE = {};

const compile_accessor_function = (body) => {
    let current = FUNCTION_CACHE[body];
    if (!current) {
        current = Function('record', 'apply_accessor', 'base_key', body);
        FUNCTION_CACHE[body] = current;
    }
    return current;
};

function apply_accessor(record, accessor, base_key) {
    /* Given root record lookup components from accessors unless null/undefined reached

    accessor (oneof(string,function,array)) -- components to lookup from record to
        final component via indexing

        'test.example.lookup'
        'moo.0.value'
        (record, apply_accessor, base_key) => some_operation(record)
        {
            "__type__":"function",
            "function":"js code returning value from (record, apply_accessor, base_key)"
        }
        ['test',(record)=>{ return record.something? 'moo': 'voo';}]
        'this.*.moo.*.com' => {'instance.value':[],'other.value':[]}

    */
    if (typeof accessor === 'string' && accessor.includes(',')) {
        return accessor.split(',').map(acc => apply_accessor(record, acc, `${base_key}.${acc}`));
    }
    if (typeof accessor === 'string' && accessor.includes('.')) {
        accessor = accessor.split(".");
    } else if (accessor && accessor.__type__ && accessor.__type__ == 'function') {
        try {
            accessor = compile_accessor_function(accessor.function);
        } catch (e) {
            console.error(`Unable to compile accessor function ${JSON.stringify(accessor)}: ${e}`);
        }
    }
    var current = record;
    if (current === null || current === undefined) {
        return null;
    }
    if (typeof accessor == 'string' || typeof accessor == 'number') {
        current = record[accessor];
        if (current === undefined) {
            current = null;
        }
        return current;
    } else if (Array.isArray(accessor)) {
        accessor.map((v, i) => {
            if (!current) {
                current = null;
                return;
            }
            current = apply_accessor(current, v);
            if (current === null || current === undefined) {
                console.debug(`${accessor.slice(0, i).join('.')}.${v} was null`);
            }
        });
        if (current === undefined) {
            current = null;
        }
        return current;
    } else {
        try {
            return accessor(record, apply_accessor, base_key);
        } catch (e) {
            console.log(`Failure applying ${accessor}(${JSON.stringify(record)})`);
            throw new Error(`Failure applying ${JSON.stringify(accessor)}: ${e}`);
            // return null;
        }
    }
}

function add_key_prefix(result, path = '') {
    /* for each entry in result if there is a path, add it */
    if (!path.length) {
        return result;
    }
    const expanded = {};
    Object.entries(result).map(record => {
        let [key, value] = record;
        if (path && path.length && key && key.length) {
            expanded[`${path}.${key}`] = value;
            // console.log(`${path}.${key} = ${value}`);
        } else if (path && path.length) {
            expanded[path] = value;
            // console.log(`${path} = ${value}`);
        } else {
            expanded[key] = value;
            // console.log(`${key} = ${value}`);
        }
    });
    return expanded;
}

function parallel_sets(root, accessor, path = '') {
    /* Construct a set of parallel sets by applying wildcard accessors to root

    root -- object or array from which to generate the sets

    for each wildcard (*), take the current set of wildcards and append a single
    component matching each attribute, construct from it a path that is
    appended, taking the corresponding value as a root from which to continue
    resolving the remaining accessors...

    returns -- {'wildcardmatch.otherwildcardmatch': value}
    */
    if (typeof accessor === 'string' && accessor.includes('.')) {
        accessor = accessor.split('.');
    }
    // console.log(
    //     `parallel_sets(${JSON.stringify(root)},${JSON.stringify(accessor)},${JSON.stringify(path)})`
    // );
    var current = root;

    if (!Array.isArray(accessor)) {
        /* Functional override */
        return add_key_prefix({
            '': apply_accessor(current, accessor)
        }, path);
    }
    var index = accessor.indexOf('*');
    if (index >= 0) {
        var relative = accessor.slice(0, index);
        var source = current;
        const remainder = accessor.slice(index + 1);
        if (relative.length) {
            source = apply_accessor(current, relative);
            if (source === null) {
                return {};
            }
        }
        // console.log(`Source ${source}`);
        if (remainder.length) {
            const collector = {};
            Object.entries({ ...source }).map(record => {
                let [new_path, new_root] = record;
                Object.entries(parallel_sets(new_root, remainder, new_path)).map(record => {
                    let [child_path, child_result] = record;
                    collector[child_path] = child_result;
                });
            });
            return add_key_prefix(collector, path);
        } else {
            return add_key_prefix({ ...source }, path);
        }
    } else {
        /* Simple case, no wildcard */
        return add_key_prefix({
            '': apply_accessor(current, accessor)
        }, path);
    }
}


export default apply_accessor;
export { parallel_sets, apply_accessor };
