import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import '../css/factoryonly.css';

class PropertyTable extends React.Component {
    render() {
        const { rows,...childProps } = this.props;

        return (
            <Table {...childProps}>
                <TableBody>
                    {rows.map((row, i) => (
                        <TableRow key={i}>
                            <TableCell className={row.factory_only?'factory-only':null}>{row.label || row.key}</TableCell>
                            <TableCell className={row.factory_only?'factory-only':null}>{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }
}


PropertyTable.propTypes = {
    rows: PropTypes.array.isRequired,
};

export default PropertyTable;
export {PropertyTable};