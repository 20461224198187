/* Render an uploaded file for tabular displays */
import React from 'react';
import MuiIcon from 'dash/MuiIcon';
import MuiTypography from 'dash/MuiTypography';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AlignDiv from 'reactform/aligndiv';
import GetApp from '@material-ui/icons/GetApp';

const styles = {
    wrapper: {
    },
    link: {
        textDecoration: 'none',
        display: 'block',
        width: '100%',
        '&:hover': {
            textDecoration: 'underline',
        }
    },
};

const render_file = withStyles(styles)((props) => {
    const {value,record,field,alignment,classes} = props;
    if (value && value.url) {
        return <AlignDiv alignment={alignment} className={classes.wrapper} onClick={(evt)=>evt.stopPropagation()}>
            <a className={classes.link} href={value.url} download><GetApp /> {value.friendly_name}</a>
        </AlignDiv>;
    } else {
        return 'No Upload';
    }
});
export default render_file;
