/* Combine automatically generated definitions with overrides */

function column_key(column) {
    /* Get the column's final key for merging */
    return column.name || column.key || column.id || column.accessor;
}

function find_override(key, overrides, context = null) {
    /* return set of all overrides if overrides */
    var partials = overrides.filter(override => (
        override.key === key ||
        override.key === `${context}.${key}`
    ));
    if (partials.indexOf(false) > -1) {
        return false;
    }
    if (partials.length < 1) {
        return false;
    }
    const result = {};
    partials.map(partial => {
        if (partial) {
            Object.assign(result, partial);
        }
    });
    return result;
}
function merge_column(column, overrides, context = null) {
    const key = column_key(column);
    // console.info(`merge key ${key}`);
    const override = find_override(key, overrides, context);
    if (override === false || override === null || override === undefined) {
        return column;
    }
    var columns = null;
    if (column.columns) {
        columns = merge_columns(column.columns, overrides, key);
    }
    return {
        ...column,
        ...override,
        columns: columns,
    };
}

function merge_columns(columns, overrides, context = null) {
    /*
    columns -- [ {column_def}, ...]
    overrides -- [{ 'key': {override_properties}, `parentkey.key`: {override_properties}, 'key': false }]

    Overrides is a sequence of overrides, where e.g. the product
    level will define a set of renderers, another layer may add
    per-table features (selectability etc).
    */
    return columns.map(
        column => merge_column(column, overrides, context)
    ).filter(x => !!x);
}

export default merge_columns;
export { merge_columns, merge_column };
