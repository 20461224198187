/* Common routes for shogun, individual sub-modules should be imported 

import dashboard_routes from 'commonroutes/dashboard';

const APP_ROUTES = BaseRoutes({
    root_url: PREFIX,
    login_redirect: `${PREFIX}`,
    routes_prepend: [
        ...dashboard_routes({PREFIX}),
    ],
});

Note that most shogun-based routes will require a current page focus in PAGE_FOCUS
and you will have to have the focus provider available in your overall app $PRODUCT.js
*/

import React from 'react';
import { PAGE_FOCUS } from 'storages';
import { ResolveTarget } from 'dash/resolvetarget';
import NodeDashboard from 'shogun/nodedashboard';
import { generatePath } from 'react-router-dom';
import ChooseDashboards from 'shogun/choosedashboards';
// import DashboardEdit from 'dash/dashboardedit';


const dashboard_routes = ({ PREFIX }) => ([
    /* Generate shogun dashboard routes at the given prefix */
    {
        'path': `${PREFIX}`,
        'hideInNav': true,
        'link_url': (page, focus, user, routes) => {
            const group_focus = focus || PAGE_FOCUS.get_focus();
            return generatePath(`${PREFIX}dashboard/:group(\\d+)/:dash([a-zA-Z0-9_.-]+)?/`, {
                group: (group_focus && group_focus.__pk__) || 0,
            });
            // const group_focus = PAGE_FOCUS.get_focus();
            // if (group_focus) {
            //     return `${PREFIX}dashboard/${group_focus.__pk__}/`;
            // }
            // return `${PREFIX}dashboard/0/`;
        },
        'header': 'Dashboard',
        'title': 'Dashboard',
        'exact': true,
        'component': NodeDashboard,
        'renderer': props => {
            return <ResolveTarget key={`dashboard-root`} as_focus={true} storage='shogunconf_forms' __type__='Group' __pk__={0}>
                <NodeDashboard
                    key={`dash-root`}
                />
            </ResolveTarget>;
        },
    },
    {
        'path': `${PREFIX}dashboard/:group(\\d+)/:dash([a-zA-Z0-9_.-]+)?/`,
        'link_url': (page, focus, user, routes, match) => {
            const group_focus = focus || PAGE_FOCUS.get_focus();
            const params = {
                group: (group_focus && group_focus.__pk__) || 0,
            };
            const dashboard_slug = (match && match.params.dash) || null;
            if (dashboard_slug) {
                params.dash = dashboard_slug;
            }
            return generatePath(`${PREFIX}dashboard/:group(\\d+)/:dash([a-zA-Z0-9_.-]+)?/`, params);
        },
        'header': 'Dashboard',
        'title': 'Dashboard',
        'exact': true,
        'component': NodeDashboard,
        'renderer': props => {
            const group = parseInt(props.match.params.group || 0);
            const dashboard_slug = props.match.params.dash || null;
            // PAGE_FOCUS.set_focus(null);
            return <ResolveTarget
                key={`dashboard-${group || 'root'}-${dashboard_slug || 'default'}`}
                as_focus={true}
                storage='shogunconf_forms'
                __type__='Group'
                __pk__={group}
            >
                <NodeDashboard
                    key={`dash-root-${dashboard_slug}`}
                    dashboard_slug={dashboard_slug}
                />
            </ResolveTarget>;
        },
    },
    {
        'path': `${PREFIX}dashboard-select/:group(\\d+)?`,
        'link_url': (page, focus, user, routes, match) => {
            const group_focus = focus || PAGE_FOCUS.get_focus();
            const params = {
                group: (group_focus && group_focus.__pk__) || 0,
            };
            return generatePath(`${PREFIX}dashboard/:group(\\d+)/`, params);
        },
        'header': 'Choose Dashboards',
        'title': 'Choose Dashboards',
        'exact': true,
        'hideInNav': true,
        'component': ChooseDashboards,
        'renderer': props => {
            const group = parseInt(props.match.params.group || 0);
            return <ResolveTarget
                key={`dashboard-edit-${group || 'root'}`}
                as_focus={true}
                storage='shogunconf_forms'
                __type__='Group'
                __pk__={group}
            >
                <ChooseDashboards
                    key={`dash-select-root`}
                />
            </ResolveTarget>;
        },
    },
    // {
    //     'path': `${PREFIX}metric/:group(\\d+)`,
    //     'link_url': `${PREFIX}`,
    //     'hideInNav': true,
    //     'header': 'Dashboard',
    //     'title': 'Dashboard',
    //     'exact': true,
    //     'component': MetricTestView,
    //     'renderer': props => {
    //         const metric_record = null;
    //         return <MetricTestView
    //             key={`metric-test`}
    //             metric_record={metric_record}
    //             __type__='Group'
    //             __pk__='213'
    //             storage='shogunconf_forms'
    //         />;
    //     },
    // },
    // {
    //     'path': `${PREFIX}dashboard/:group(\\d+)/metric_vsbb_status`,
    //     'link_url': `${PREFIX}dashboard/0/metric_vsbb_status`,
    //     'hideInNav': true,
    //     'exact': true,
    //     'header': 'VSBB Dashboard',
    //     'title': 'VSBB Dashboard',
    //     'component': VSBBDashboard,
    //     'renderer': props => {
    //         const group = parseInt(props.match.params.group);
    //         if (!group) {
    //             return <VSBBDashboard
    //                 __type__=''
    //                 __pk__=''
    //             />;
    //         } else {
    //             return <VSBBDashboard
    //                 __type__='Group'
    //                 __pk__={group}
    //             />;
    //         }
    //         // const storage_props = node_storage(null);
    //         // return <NodeEditTable
    //         //     key={`node-table`}
    //         //     storage_props={storage_props} {...props}
    //         // />;
    //     },
    // },
]);

export default dashboard_routes;