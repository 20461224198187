import React from 'react';
import { providing_nearest_form } from 'formprovider';
import { ReactForm } from 'reactform';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import { services_storage, ServicesTable } from './servicetable';
import { EditIntent } from 'editintent';
import { with_resolved_target } from 'dash/resolvetarget';
import ServiceCreator from './servicecreator';

class BaseServiceGroupView extends React.Component {
    render() {
        const { target } = this.props;
        const service_actions = target ? [
            // <EditIntent
            //     key="add-rf-input"
            //     target={{
            //         'type': 'RFInputMapping',
            //         'id': null,
            //         'service_group': target.id,
            //         'service_type_key': 'vsbb',
            //     }}
            //     context={{
            //         'enabled': true,
            //         'service_group': target.id,
            //         'service_type_key': 'q2ip',
            //     }}
            //     action='Add QAM Inputs'
            //     label='QAM Inputs'
            //     help_text='Adds a new set of QAM input definitions (sources) that can be mapped to outputs'
            // />,
            <EditIntent
                key="add-analog"
                target={{
                    'type': 'AnalogMapping',
                    'id': null,
                    'service_group': target.id,
                    'service_type_key': 'vsbb',
                }}
                context={{
                    'enabled': true,
                    'service_group': target.id,
                    'service_type_key': 'q2a',
                }}
                action='Add Analog Output Mapping'
                label='Analog Outputs'
                help_text='Adds a new set of mappings from provided input definitions (sources) to Analog Output Channels'
            />,
            <EditIntent
                key="add-srt-input"
                target={{
                    'type': 'SRTInputMapping',
                    'id': null,
                    'service_group': target.id,
                    'service_type_key': 'srtinput',
                }}
                context={{
                    'enabled': true,
                    'service_group': target.id,
                    'service_type_key': 'srtinput',
                }}
                action='Add SRT Inputs'
                label='SRT Inputs'
                help_text='Adds a new set of SRT input definitions for Visium Gateway Devices'
            />,
            <EditIntent
                key="add-ipoutput"
                target={{
                    'type': 'IPOutputMapping',
                    'id': null,
                    'service_group': target.id,
                    'service_type_key': 'ipout',
                }}
                context={{
                    'enabled': true,
                    'service_group': target.id,
                    'service_type_key': 'ipout',
                }}
                action='Add IP Output Mapping'
                label='IP Outputs'
                help_text='Adds a new set of mappings from provided input definitions (sources) to IP Output Channels'
            />,

            <EditIntent
                key="add-firmware"
                target={{
                    'type': 'FirmwareDeploy',
                    'id': null,
                    'service_group': target.id,
                    'service_type_key': 'firmdep',
                }}
                context={{
                    'enabled': true,
                    'service_group': target.id,
                    'service_type_key': 'firmdep',
                    'owner': target.owner,
                }}
                action='Create'
                label='Firmware Targets'
                help_text='Configures the target firmware for each device type within a group'
            />,
            <EditIntent
                key="add-ipgclient"
                target={{
                    'type': 'IPGClient',
                    'id': null,
                    'service_group': target.id,
                    'service_type_key': 'vsbb',
                    'owner': target.owner,
                }}
                context={{
                    'enabled': true,
                    'service_group': target.id,
                    'service_type_key': 'vsbb',
                    'owner': target.owner,
                }}
                action='Create'
                label='VSBB Config'
                help_text='Provides a configuration for Visium Set Back Box devices'
            />,
            <EditIntent
                key="add-monitor"
                target={{
                    'type': 'Monitor',
                    'id': null,
                    'service_group': target.id,
                    'service_type_key': 'vsbb.monitor',
                    'owner': target.owner,
                }}
                context={{
                    'enabled': true,
                    'service_group': target.id,
                    'service_type_key': 'vsbb.monitor',
                    'owner': target.owner,
                }}
                action='Create'
                label='Monitor'
                help_text='Provides a configuration for Visium Streaming Blade Monitors'
            />,
            <EditIntent
                key="add-bridge"
                target={{
                    'type': 'Bridge',
                    'id': null,
                    'service_group': target.id,
                    'service_type_key': 'bridge',
                }}
                context={{
                    'enabled': true,
                    'service_group': target.id,
                    'service_type_key': 'bridge',
                    'owner': target.owner,
                }}
                action='Create'
                label='Concentrator'
                help_text='Defines the concentrator to which devices will connect'
            />,
        ] : [];
        return <React.Fragment>
            {target && <EditingCard
                title={`${(target && target.title) || 'Config'} Metadata`}
                collapsing={null}
                collapsing='servicegroup-metadata'
            >
                <ReactForm target={target} use_dialog={false} include_delete={true} buttons={['save', 'delete']} />
            </EditingCard>}
            {target && <EditingCard
                title={`${target.title} Services`}
                no_wrapper={true}
                // actions={service_actions}
                collapsing='services-table'
            >
                <ServicesTable storage_props={services_storage(target)} />
            </EditingCard>}
            <ServiceCreator serviceGroup={target} key={`service-creator-${(target && target.id) || 'root'}`} />
        </React.Fragment>;
    }
}
const ServiceGroupView = providing_nearest_form(
    with_resolved_target(
        BaseServiceGroupView,
    ),
    'shogunconf_forms'
);

export default ServiceGroupView;
export { BaseServiceGroupView, ServiceGroupView };
