/* Base Class for implementing select-a-range-of-records */
import React from 'react';
import {ClassyWidget} from "reactform/classywidget";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import WidgetRender from './custom_widget_render';
import MuiTypography from 'dash/MuiTypography'

const trackHeight = 2;
const thumbHeight = 18;
const tickHeight = 16;

const styles = (theme) => ({
    root: {
        padding: tickHeight/2,
        width: '100%',
    },
    slider: {
        position: 'relative',
        width: '100%',
    },
    tick: {
        position: 'absolute',
        top: -tickHeight/2,
        width: tickHeight,
        height: tickHeight,
        borderRadius: tickHeight/2,
        zIndex:3,
        border: 'none',
        backgroundColor: theme.palette.secondary.main,
    },
    rangeStart: {
        left: -(tickHeight/2),
    },
    rangeStop: {
        right: -(tickHeight/2),
    },
    touchBar: {
        marginTop: 16,
        display: 'block',
        position: 'relative',
        height: thumbHeight,
    },

    rail: {
        position: "absolute",
        height: trackHeight,
        top: Math.round((thumbHeight/2)-(trackHeight/2)),
        backgroundColor: theme.palette.grey[200],
        width: '100%',
        zIndex: 1,
        pointerEvents: "none"
    },
    rangeRail: {
        position: "absolute",
        height: trackHeight+2,
        top: Math.round((thumbHeight/2)-(trackHeight/2))-1,
        backgroundColor: theme.palette.secondary.main,
        zIndex: 2,
    },
    trackHotspot: {
        // backgroundColor: "grey", // for debugging
        height: thumbHeight, // Invisible hotspot same size as thumb
        top: thumbHeight * -0.5,
        position: "absolute",
        cursor: "pointer",
    },
    hoverText: {
        fontSize: 'smaller',
        color: theme.palette.grey[500],
    },
});

function ids_to_ranges(records, ids, id_function = r=>r[0]) {
    /* Given the selected ids, create index-ranges for the values

    Note: requires consistent ordering of the records
    */
    const ranges = [];
    if (!ids || !records || !records.length || !ids.length) {
        return ranges;
    }
    const selected = {}; /* mapping for faster lookup */
    ids.map(id => {selected[id] = id;});
    var current_range = null;
    for (var index=0; index < records.length; index++) {
        var record = records[index];
        var record_id = id_function(record);
        if (selected[record_id] !== undefined) {
            /* this is a selected element */
            if (current_range) {
                current_range[1] = index;
            } else {
                current_range = [index,index];
                ranges.push(current_range);
            }
        } else {
            current_range = null;
        }
    }
    return ranges;
}
function current_records(records, ranges) {
    /* Convert a set of ranges to a set of record ids */
    const selected = [];
    ranges.map((range) => {
        records.slice(range[0],range[1]+1).map((record) => selected.push(record));
    });
    return selected;
}
function range_percent(records, index) {
    /* Calculate the position for the given index */
    if (index < 0) {
        return 0;
    } else if (index >= records.length-1) {
        return 100;
    }
    return Math.round((index/((records.length-1) || 1))*100);
}

class BaseRangeSelect extends React.Component {
    static defaultProps = {
        records: null, // [id,label]
        value: null, // [id,id,id,...]
        classes: {}, // from withStyles
    }
    state = {
        value: null,
        ranges: null,
        dragging: null,
        hoverText: null,
        draggingRange: null,
        draggingStart: null,
    }
    constructor(props) {
        super(props);
        this.touchRef = React.createRef();
    }
    onClickStart(evt) {
        /* click on range start or range stop starting a potential drag */

    }
    get_ranges() {
        if (this.state.ranges) {
            return this.state.ranges;
        }
        return ids_to_ranges(this.props.records,this.props.value);
    }
    nearest_item(evt) {
        /* Get the nearest item to the given event location */
    }
    render_range(range, records, index, ranges) {
        /* Render a range as react components */
        const {classes} = this.props;
        return <div className={classNames(classes.rangeRail,'range-select-range')} key={`range-${index}`} style={{
            left: `${range_percent(records, range[0])}%`,
            right: `${100-range_percent(records, range[1])}%`,
        }}>
            <div
                className={classNames(classes.rangeStart,classes.tick,'range-select-start')}
                onMouseDown={
                    (evt) => {
                        this.onRangeStartClick(evt, index, ranges, true);
                    }
                }
            />
            <div
                className={classNames(classes.rangeStop,classes.tick,'range-select-stop')}
                onMouseDown={
                    (evt) => {
                        this.onRangeStartClick(evt, index, ranges, false);
                    }
                }
            />
        </div>;
    }
    onMouseMove(evt) {
        const {records} = this.props;
        const rect = this.touchRef.current.getBoundingClientRect();
        const x = evt.clientX - rect.left;
        const y = evt.clientY - rect.top;
        const w = rect.right - rect.left;
        const h = rect.bottom - rect.top;
        const pct = x/w;
        const index = Math.round( records.length * pct );
        const record = records[index];
        if (record) {
            var state_update = {
                hoverText: record[1],
            };
            if (this.state.draggingRange) {
                this.state.draggingRange[this.state.draggingStart?0:1] = index;
            }
            this.setState(state_update);
        }
    }
    onRangeStartClick(evt, index, ranges, start=true) {
        const range = ranges[index];
        this.setState({
            ranges: ranges,
            draggingRange: range,
            draggingStart: start,
        });
        window.addEventListener('mouseup',this.onRangeStopClick);
    }
    onRangeStopClick = (evt) => {
        const {ranges, draggingRange, draggingStart} = this.state;
        this.setState({
            ranges: ranges,
            draggingRange: null,
            draggingStart: null,
        });
        const values = current_records(this.props.records,ranges);
        this.props.onChange && this.props.onChange(values.map(r => r[0]));
        window.removeEventListener('mouseup',this.onRangeStopClick);
    }

    render() {
        const {classes,records} = this.props;
        const ranges = this.get_ranges();
        return <div
            className={classNames(classes.root,'range-select-control')}
            onMouseMove={(evt) => this.onMouseMove(evt)}
        >
            <div className={classNames(classes.touchBar,'range-select-touch')} ref={this.touchRef}>
                <div className={classNames(classes.rail,'range-select-rail')} />
                {ranges.map((range,index) => {
                    return this.render_range(range,records,index,ranges);
                })}
            </div>
            {ranges.map((range,index) => {
                return <div key={`range-${index}`} className={classNames(classes.hoverText,'range-select-hovertext')} style={{display: 'flex',justifyContent:'space-between'}}>
                    <div className="range-start-label">
                        <MuiTypography>{records[range[0]][1]}</MuiTypography>
                    </div>
                    <div className="range-stop-label">
                        <MuiTypography>{records[range[1]][1]}</MuiTypography>
                    </div>
                </div>;
            })}
        </div>;
    }
}
const StyledRangeSelect = withStyles(styles)(BaseRangeSelect);

class RangeSelectWidget extends ClassyWidget {
    static defaultProps = {
        ...ClassyWidget.defaultProps,
        form_field: null,
        type_name: 'item',
        default_all: true,
        storage: null,
        multiple: false,
        from_server: (value, widget) => {
            if (Array.isArray(value)) {
                return value;
            } else if (typeof value === 'string') {
                return value.split(',');
            } else {
                return value;
            }
        },
        to_server: (value, widget) => {
            if (Array.isArray(value)) {
                return value.map((v) => `${v}`).join(',');
            } else {
                return `${value}`;
            }
        },
    };
    onControlChange = (values) => {
        this.handleChange(values, true);
    }

    render() {
        /* Render a React Compound Slider for a single range of the selection choices */
        const {show_label, default_all, multiple} = this.props;
        const form_field = this.get_field();
        const base_field = form_field.field;
        const records = base_field.choices;

        return <WidgetRender form_field={form_field} widget_class="range-select-widget" show_label={show_label}>
            <StyledRangeSelect
                records={records}
                value={records.map(r=>r[0])}
                onChange={this.onControlChange}
            />
        </WidgetRender>;
    }
}

export default RangeSelectWidget;
export {ids_to_ranges, current_records, range_percent };
