import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListView from 'shogun/listview';

const useStyles = makeStyles({

});

const AlertingView = (props) => {
    /* Provides the root-level alerting view for viewing/configuring notification groups */
    const classes = useStyles();
    const ng_table = <ListView
        key='ngroup-table'
        __type__='NotificationGroup'
        title='Notification Groups'
        form_titles={null}
        creation_action='Create Notification Group'
        exclude={['webhook_url']}
    />;
    return <React.Fragment>
        {ng_table}
    </React.Fragment>;
};

export default AlertingView;