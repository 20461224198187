import React from 'react';
// import {
//     XYPlot,
//     FlexibleWidthXYPlot,
//     YAxis,
//     XAxis,
//     HorizontalGridLines,
//     MarkSeries,
//     LineSeries,
//     Hint,
//     VerticalGridLines,
//     HorizontalBarSeries,
//     VerticalBarSeries,
// } from 'react-vis';
import { get_graph_data, limit_range } from './extractdataset';
import { makeStyles } from '@material-ui/core/styles';
import { apply_accessor } from './accessor';
import Tooltip from '@material-ui/core/Tooltip';
import Legend from './atxlegend';


const useStyles = makeStyles((theme) => ({
    buttonHolder: {
        display: 'flex',
    },
    svgHolder: {
        display: 'flex',
    },
    d3svg: {
        flex: 1,
        // minHeight: '300px',
    },
}));

// class OldATXScatterPlot extends React.Component {
//     static defaultProps = {
//         graph: {},
//         data: [],
//         dataset: {},
//         metric: {},
//         format: {},
//         width: 150,
//         height: 150,
//         line: false,
//         bar: false,
//         time: false,
//     }
//     state = {
//         tooltip: null,
//     }
//     clearTooltip() {
//         this.setState({ 'tooltip': null });
//     }
//     render() {
//         const { series, metric, format, width = 128, height = 128, graph_y_scale = 1, limit = null } = this.props;
//         const { tooltip } = this.state;
//         const data_map = {};
//         const x_scale = this.props.graph_x_scale || (this.props.xType === 'time' ? 1000 : 1);
//         const final_series = series.map((series_data, series_index) => {
//             if (series_data === null || series_data === undefined) {
//                 return null;
//             }
//             const label_map = {};
//             let subset = series_data.map((point, index) => {
//                 const key = `s${series_index}-r${index}`;
//                 data_map[key] = point;
//                 if (point) {
//                     const y = (point.value || 0) * graph_y_scale;
//                     return {
//                         x: (point.coordinate === undefined ? index : point.coordinate) * x_scale,
//                         y: y,
//                         data_key: key,
//                         name: point.label,
//                         label: point.label,
//                         index: index,
//                         size: width < 400 ? 1 : 20,
//                     };
//                 }
//                 return null;
//             }).filter(x => ((x !== undefined) && (x !== null)));
//             if (limit && subset.length > limit) {
//                 subset = subset.slice(subset.length - limit);
//             }
//             const Component = (this.props.bar || metric.bar) ? VerticalBarSeries : (
//                 this.props.line ? LineSeries : MarkSeries
//             );
//             var extra_props = {};
//             if (this.props.line) {
//                 extra_props = {
//                     style: {
//                         fill: 'none'
//                     },
//                     onNearestXY: (event) => {
//                         const original = data_map[event.data_key];
//                         // Unfortunately, we get N callbacks (1 per series) in the same stream
//                         const tooltip = this.state.tooltip || {};
//                         tooltip[original.__key__] = original.label;
//                         this.setState({ 'tooltip': tooltip });
//                     }
//                 };
//             } else {
//                 extra_props = {
//                     onValueMouseOver: (event) => {
//                         const original = data_map[event.data_key];
//                         const tooltip = {};
//                         tooltip[event.label] = original.value;
//                         this.setState({ 'tooltip': tooltip });
//                     }
//                 };
//             }

//             return <Component
//                 key={`series-${series_index}`}
//                 data={subset}
//                 sizeRange={[.2, 1]}
//                 onSeriesMouseOut={this.clearTooltip.bind(this)}
//                 {...extra_props}
//             />;
//         });
//         const y_axis_props = {};
//         if (metric.valueLabel) {
//             y_axis_props.tickFormat = metric.valueLabel;
//         }
//         const x_axis_props = {};
//         if (metric.timeLable) {
//             x_axis_props.tickFormat = metric.timeLable;
//         }
//         // console.log(`Tooltip: ${JSON.stringify(tooltip)}`);
//         if (width < 400) {
//             /* Compressed view of the data for display in a grid */
//             const marginLeft = Number.isNaN(width) ? 5 : (width || 1) / 2;
//             return <XYPlot
//                 width={width}
//                 height={height}
//                 onMouseLeave={this.clearTooltip.bind(this)}
//             // margin={{ left: 240, right: 30, top: 30, bottom: 160 }}
//             >
//                 <HorizontalGridLines style={{ stroke: '#ccc' }} />
//                 <YAxis style={{ fontSize: height / 20 }} {...y_axis_props} />
//                 {final_series}
//                 {tooltip && <Hint style={{
//                     fontSize: '6pt',
//                     marginTop: -(height || 1),
//                     marginLeft: (marginLeft || 0)
//                 }} align={{
//                     horizontal: 'auto',
//                     vertical: 'auto'
//                 }} value={tooltip} />}
//             </XYPlot>;
//         }
//         return <XYPlot
//             onMouseLeave={this.clearTooltip.bind(this)}
//             height={height}
//             xType={this.props.xType || metric.coordinateType}
//             width={width - 100}
//             margin={{ left: 100 }}
//         >
//             <HorizontalGridLines style={{ stroke: '#ccc' }} />
//             <VerticalGridLines style={{ stroke: '#aac' }} />
//             <YAxis style={{ left: '3em', fontSize: '9pt' }} {...y_axis_props} />
//             <XAxis style={{ fontSize: '9pt' }} {...x_axis_props} />
//             {final_series}
//             {tooltip && <Hint style={{
//                 fontSize: '9pt',
//                 marginTop: -(height || 1),
//                 marginLeft: (width || 1) / 2
//             }} align={{
//                 horizontal: 'auto',
//                 vertical: 'auto'
//             }} value={tooltip} />}
//         </XYPlot>;
//     }
// }

const ATXScatterPlot = (props) => {
    const {
        graph,
        series,
        metric,
        format,
        width = 150,
        height = 150,
        line = false,
        bar = false,
        time = false,
    } = props;
    let graph_data = get_graph_data(series);
    if (graph_data === null || graph_data === undefined) {
        return null;
    }
    let legends = [];
    let legend_click = null;
    const svgRef = React.useRef(null);
    const classes = useStyles();
    const [tooltip, setTooltip] = React.useState(null);
    const [highlight, setHighlight] = React.useState(null);

    React.useEffect(() => {
        if (!svgRef.current) {
            console.info(`No svg reference, so cannot render with d3`);
            return;
        }
        const dimensions = {
            'width': svgRef.current.clientWidth,
            'height': svgRef.current.clientHeight,
            'margins': {
                left: limit_range(svgRef.current.clientHeight / 8, 40, 64),
                right: svgRef.current.clientHeight / 12,
                top: 0,
                bottom: limit_range(svgRef.current.clientHeight / 10, 40, 64),
            },
            'axisMargin': 5,
        };
        if ((!dimensions) || dimensions.width < 1 || dimensions.height < 1) {
            console.info(`SVG element has no current size`);
            return;

        }
        console.log(`Should render the scatter-plot here...`);
    }, [graph_data, graph]);

    return <div className={classes.svgHolder} placement="top" >
        <Tooltip title={tooltip || ""} open={tooltip !== null} followCursor>
            <svg style={{ minWidth: width, height: height }} ref={svgRef} className={classes.d3svg} />
        </Tooltip>
        <Legend style={{ height: height }} legends={legends} highlight={highlight} setHighlight={setHighlight} on_click={legend_click} />
    </div>;

};

export default ATXScatterPlot;
