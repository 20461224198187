import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const styles = theme => ({
    formControl: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        // minWidth: '120px'
        '& .MuiInputLabel-formControl': {
            position: 'relative',
        },
    },
    label: {
        whiteSpace: 'nowrap',
        // marginBottom: '.25rem',
    },
    withLabel: {
        // marginTop: 12,
        display: 'flex',
        flexDirection: 'column',

    }
});


function WidgetRender(props) {
    /* Render the form-field wrapper bits for material-ui custom control */
    const { form_field, classes, show_label, children, widget_class } = props;
    const base_field = form_field.field;

    return <FormControl className={classNames(classes.formControl, widget_class)} classes={{}}>
        {show_label && (
            <InputLabel
                className={classes.label}
                classes={{}}
                htmlFor={base_field.label_id}
                shrink={true}
            >
                {base_field.label}
            </InputLabel>
        )}
        <div className={classNames(show_label ? classes.withLabel : classes.noLabel, classes.widgetBody)}>{children}</div>
    </FormControl>;
}
WidgetRender.propTypes = {
    form_field: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    show_label: PropTypes.bool,
    widget_class: PropTypes.string,
};

export default withStyles(styles)(WidgetRender);
