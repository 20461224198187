import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const styles = theme => ({
    value: {
    },
    unit: {
        color: 'lightgrey',
    }
});

class UnitValue extends React.Component {
    static defaultProps = {
        value: null,
        title: null,
        classes: null,
        units: null,
        rounding: null,
    };
    render() {
        const {value,title,classes,units,rounding} = this.props;
        let final_value = value;
        if (rounding) {
            final_value = Math.round(value * (10**rounding))/(10**rounding);
        }
        return <React.Fragment>
            <span className={classNames('unit-value',classes.value)} title={title}>{final_value}</span>
            {
                units && <span className={classes.unit}>{units}</span>
            }
        </React.Fragment>;
    }
}
UnitValue.propTypes = {
    value: PropTypes.number,
    title: PropTypes.string,
    classes: PropTypes.object,
    units: PropTypes.string,
    rounding: PropTypes.number,
};

export default withStyles(styles)(UnitValue);
