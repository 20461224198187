import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import stores from 'storeregistry';

import MuiSnackbar from 'dash/MuiSnackbar';
import { ReactForm, form_action, construct_controls } from 'reactform';
import { using_nearest_form } from 'formprovider';

const styles = theme => ({
    topMargin: {
        marginTop: theme.spacing(2)
    }
});

export class SystemPowerActions extends React.Component {
    state = {
        response: null
    };

    actions = [
        {
            key: 'reboot',
            name: 'Reboot',
            icon: 'replay',
            help_text:'Initiate a warm reboot at scheduled time',
        },
        {
            key: 'shutdown',
            name: 'Shutdown',
            icon: 'poweroff',
            help_text: 'Initiate a shutdown. You will need to manually reset system power. On chassis/blade systems this requires shutting down the entire chassis or re-seating the blade',
        },
        {
            key: 'shutdown_cancel',
            name: 'Cancel Reboot/Shutdown',
            help_text: 'Cancel an initiated shutdown if possible',
            icon: 'close',
        },
        {
            key: 'power_cycle',
            name: 'Power Cycle',
            icon: 'replay',
            help_text: 'Initiate a cold reboot at scheduled time',
        },
    ];

    handleResponse = response => {
        this.setState({ response });
    };

    onClose = () => {
        this.setState({response: null});
    }

    getFormActions = (form) => {
        const if_available = (action) => {
            if (stores.system_status && stores.system_status.config && stores.system_status.config.power) {
                const available = stores.system_status.config.power[action.key];
                return available === undefined || available === true;
            }
            return true;
        };
        const buttons = this.actions.filter(if_available).map(action => {
            return form_action({
                key: action.key,
                name: action.name,
                help_text: action.help_text,
                icon: action.icon,
                color: action.key == 'reboot'? 'primary': 'secondary',
                callback: this.handleResponse
            }).render(form);
        });

        return (
            <div className="form-actions">
                {construct_controls(form, buttons)}
            </div>
        );
    }

    render() {
        const { classes, target } = this.props;
        const { response } = this.state;

        return (
            <div className={classes.topMargin}>
                <ReactForm
                    form_actions={this.getFormActions}
                    target={target}
                    icon="edit"
                    form_key="SystemPowerActions"
                    use_dialog={false}
                />
                {!!response && (
                    <div className={classes.topMargin}>
                        <MuiSnackbar
                            variant={response.success ? 'success' : 'error'}
                            onClose={this.onClose}
                        >
                            {response.message}
                        </MuiSnackbar>
                    </div>
                )}
            </div>
        );
    }
}

SystemPowerActions.propTypes = {
    classes: PropTypes.object.isRequired,
    target: PropTypes.object.isRequired,
};

export default withStyles(styles)(using_nearest_form(SystemPowerActions));
