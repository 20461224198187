/* Chart modelling for dashboard-type displays */
import Graph from './graph';
import COLORS from './colors';
import { human_bandwidth, human_bytes } from 'humanize';
import { apply_accessor } from './graph';


const unix_start_time = record => new Date(record.start * 1000);

const FORMATS = [
    {
        'type': 'pie',
        '__key__': 'enum.pie',
        'format': 'enum',
        'group_by': true,
        'implementation': Graph,
        'aggregate': (values) => {
            if (!values) {
                return values;
            }
            return {
                ...values,
                value: values.value.length
            };
        },
        'title': 'Pie Graph',
        'description': 'Count of data-point for each type of a grouped query as a pie chart',
    },
    {
        'type': 'blinken',
        '__key__': 'enum.blinken',
        'format': 'enum',
        'group_by': false,
        'implementation': Graph,
        'description': 'Show one light for each record value',
    },
    {
        'type': 'redblack',
        '__key__': 'enum.redblack',
        'format': 'bool',
        'group_by': false,
        'implementation': Graph,
        'description': 'Present parallel lines showing boolean on/off over time for each record',
    },
    {
        'type': 'scatter',
        '__key__': 'scatter.measurement',
        'title': 'Scatter Plot',
        'format': 'measurement',
        'group_by': false,
        'implementation': Graph,
        'description': 'Single data-point for N parallel instances as a scatter plot',
    },
    {
        'type': 'line',
        '__key__': 'scatter.measurement.series',
        'title': 'Scatter Plot Series',
        'format': 'measurement',
        'group_by': false,
        'implementation': Graph,
        'description': 'Time series data for N parallel instances as a continuous line graph',
    },
    {
        'type': 'bar',
        '__key__': 'measurement.bar',
        'title': 'Bar Chart',
        'format': 'measurement',
        'group_by': false,
        'implementation': Graph,
        'description': 'Bar chart for measurements of distinct values',
    },
    {
        'type': 'scatter',
        '__key__': 'scatter.counter',
        'title': 'Scatter Counter',
        'format': 'counter',
        'group_by': false,
        'implementation': Graph,
        'description': 'Single data-point for N parallel instances as a scatter plot',
    },
    {
        'type': 'scatter',
        '__key__': 'scatter.counter.series',
        'title': 'Scatter Counter Series',
        'format': 'counter',
        'group_by': false,
        'implementation': Graph,
        'description': 'Time series data for N parallel instances as a scatter plot',
    },
    {
        'type': 'area',
        '__key__': 'area',
        'title': 'Stacked Area Chart',
        'format': 'measurement',
        'group_by': false,
        'implementation': Graph,
        'description': 'Summary counts for periods of time',
    },
    {
        'type': 'area.stacked',
        '__key__': 'area.stacked',
        'title': 'Stacked Area Chart',
        'format': 'measurement',
        'group_by': false,
        'implementation': Graph,
        'description': 'Summary counts for periods of time',
    },
    {
        'type': 'summary',
        '__key__': 'summary',
        'title': 'Summary View (textual)',
        'format': 'custom',
        'group_by': false,
        'implementation': Graph,
        'description': 'Presents summary data via a textual renderer',
    }
];


const SYSTEM_MEASUREMENTS = [
    {
        'accessor': record => (record && ((record.total || 0) / (record.count || 1))),
        'coordinate': unix_start_time,
        'coordinateType': "time",
        'dataset_accessor': 'system_status.memory.historic.0.values',
        'title': 'Mem% 1 Min Avg',
        'type': 'measurement',
        '__key__': 'system_status.system.memory.min',
        'description': 'System memory usage (percent) 1 minute average',
        'label': (record) => (record && `${(record.total || 0) / (record.count || 1)}%`),
        'id': (record, id) => id,
    },
    {
        'accessor': record => (record && ((record.total || 0) / (record.count || 1))),
        'coordinate': unix_start_time,
        'coordinateType': "time",
        'dataset_accessor': 'system_status.memory.historic.1.values',
        'title': 'Mem% 1 Hr Avg',
        'type': 'measurement',
        '__key__': `system_status.system.memory.hour`,
        'description': 'System memory usage (percent) 1 hour average',
        'label': (record) => (record && `${(record.total || 0) / (record.count || 1)}%`),
        'id': (record, id) => id,
    },
    {
        'accessor': record => (record && ((record.total || 0) / (record.count || 1))),
        'coordinate': unix_start_time,
        'coordinateType': "time",
        'dataset_accessor': 'system_status.bandwidth.*.bytes_sent.historic.1.values',
        'title': 'Out Bytes 1 Min',
        'type': 'measurement',
        '__key__': `system_status.system.bandwidth.bytes_sent.hour`,
        'label': (record) => (record && `${human_bytes((record.total || 0) / (record.count || 1))}`),
        'id': (record, id) => id,
    },
    {
        'accessor': record => (record && ((record.total || 0) / (record.count || 1))),
        'coordinate': unix_start_time,
        'coordinateType': "time",
        'dataset_accessor': 'system_status.bandwidth.*.bytes_recv.historic.1.values',
        'title': 'In Bytes 1 Min',
        'type': 'measurement',
        '__key__': `system_status.system.bandwidth.bytes_recv.hour`,
        'label': (record) => (record && `${human_bytes((record.total || 0) / (record.count || 1))}`),
        'id': (record, id) => id,
    },
    {
        'accessor': record => (record && ((record.total || 0) / (record.count || 1))),
        'coordinate': unix_start_time,
        'coordinateType': "time",
        'dataset_accessor': 'system_status.load.historic.0.values',
        'title': 'CPU% 1 Min Avg',
        'type': 'measurement',
        '__key__': 'system_status.system.load.min',
        'description': 'System memory usage (percent) 1 minute average',
        'label': (record) => (record && `${(record.total || 0) / (record.count || 1)}%`),
        'id': (record, id) => id,
    },
    {
        'accessor': record => (record && ((record.total || 0) / (record.count || 1))),
        'coordinate': unix_start_time,
        'coordinateType': "time",
        'dataset_accessor': 'system_status.load.historic.1.values',
        'title': 'CPU% 1 Hr Avg',
        'type': 'measurement',
        '__key__': 'system_status.system.load.hour',
        'description': 'System memory usage (percent) 1 minute average',
        'label': (record) => (record && `${(record.total || 0) / (record.count || 1)}%`),
        'id': (record, id) => id,
    },
];

// const DATA_PROCESSES = [
//     {
//         '__key__': 'count',
//         'implementation': record => { return {...record,value:record.value.length}; }
//     },
// ];



const SYSTEM_GRAPHS = [
    {
        'format': 'scatter.measurement.series',
        'metric': 'system_status.system.memory.min',
    },
    {
        'format': 'scatter.measurement.series',
        'metric': 'system_status.system.memory.hour',
    },
    {
        'format': 'scatter.measurement.series',
        'metric': 'system_status.system.bandwidth.bytes_recv.hour',
    },
    {
        'format': 'scatter.measurement.series',
        'metric': 'system_status.system.bandwidth.bytes_sent.hour',
    },
    {
        'format': 'scatter.measurement.series',
        'metric': 'system_status.system.load.min',
    },
    {
        'format': 'scatter.measurement.series',
        'metric': 'system_status.system.load.hour',
    },

];
const DEFAULT_GRAPHS = SYSTEM_GRAPHS;
const DEFAULT_MEASUREMENTS = SYSTEM_MEASUREMENTS;

function graph_format(graph) {
    /* Lookup the graph format from a __key__ */
    const format_key = graph.format;
    var format = null;
    FORMATS.map((possible) => {
        if (possible.__key__ === format_key || possible.type == format_key) {
            format = possible;
        }
    });
    return format;
}
function graph_metric(graph, metrics = DEFAULT_MEASUREMENTS) {
    /* Lookup the graph metric in metrics from a __key__ value */
    const metric_key = graph.metric.startsWith('metric_') ? graph.metric : `metric_${graph.metric}`;
    const alternate = metric_key.slice(7);
    var metric = null;

    metrics.map((possible) => {
        if (possible.__key__ === metric_key || possible.__key__ == alternate) {
            metric = possible;
        }
    });
    if (!metric) {
        console.error(`Did not find ${metric_key} in ${JSON.stringify(metrics.map(m => m.__key__))}`);
    }
    return metric;
}

function graph_key(graph, metrics = DEFAULT_MEASUREMENTS) {
    /* Get a unique key for use in preferences and the like */
    if (graph.key) {
        return graph.key;
    }
    return `${graph.metric}-${graph.format}`;
}


export {
    FORMATS,
    SYSTEM_MEASUREMENTS,
    SYSTEM_GRAPHS,
    DEFAULT_MEASUREMENTS,
    DEFAULT_GRAPHS,
    graph_format,
    graph_metric,
    graph_key,
    unix_start_time,
};
