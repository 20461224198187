import React from 'react';
import { ReactForm } from 'reactform';
import { FormStorage } from 'storages/basestorage';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

const ServiceCSVUploader = (props) => {
    const { target } = props;
    const [form, setForm] = React.useState(null);
    const on_click = (evt) => {
        if (!form) {
            setForm(
                <ReactForm target={target} form_key='ServiceMappingUpload' forms_key={'shogunconf_forms'}
                    onClose={() => {
                        setForm(null)
                    }}
                    onSave={() => {
                        setForm(null)
                    }}
                />
            );
        }
    };

    return <Badge size="small" overlap="circle" badgeContent={'CSV'}><IconButton
        size={'small'}
        color={'secondary'}
        title={'Upload CSV'}
        onClick={on_click}
    >
        <Icon>cloud_upload</Icon>
        {form}
    </IconButton></Badge>
};
const ServiceCSVDownloader = (props) => {
    const { target } = props;
    const form_storage = FormStorage({ key: 'shogunconf_forms' });
    return <Badge overlap="circle" size="small" badgeContent={'CSV'}><IconButton
        size='small'
        color='secondary'
        title='Download CSV'

        onClick={() => {
            window.location = form_storage.final_url('ServiceMappingDownload', target.id);
        }}
    >
        <Icon>cloud_download</Icon>
    </IconButton></Badge>
};

export { ServiceCSVDownloader, ServiceCSVUploader };