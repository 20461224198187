import React from 'react';
import {LineSeries, XAxis, XYPlot, YAxis,} from 'react-vis';

export default class Counters extends React.Component {
  state = {
    index: null,
  };

  calculate_delta() {
    const {data, bit} = this.props;
    const max = Math.pow(2, bit)
    let values = [];
    for (let i = 1; i < data.length; i++) {
      let delta = data[i] - data[i - 1];
      if (delta < 0) {
        delta = max + delta;
      }
      values.push({x: i, y: delta});
    }

    return values;
  }

  convert_to_coordinates() {
    const {data} = this.props;
    let values = [];
    for (let i = 0; i < data.length; i++) {
      values.push({x: i, y: data[i]});
    }

    return values;
  }

  render() {
    const {data} = this.props;
    let data_to_plot;

    if (this.props.bit !== undefined) {
      // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
      // should use memoize-one as react recommends, to not calculate average
      // for every render call
      data_to_plot = this.calculate_delta();
    }
    else {
      data_to_plot = this.convert_to_coordinates();
    }

    return (
      <XYPlot
        margin = {
          { left: 80, right: 10, top: 10, bottom: 80 }
        }
        xType = 'time'
        width = {600} 
        height = {300} 
      > 
        <XAxis title = 'X' tickLabelAngle = {-90} />
        <YAxis title="Counter" />< LineSeries
          data={data_to_plot}
        />
      </XYPlot>
    );
  }
}
