/* Routes for the clifford support features in vada */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { ResolveTarget } from 'dash/resolvetarget';
import { generic_pagefocus_link_url, generic_pagefocus_link_url_focus_required, group_link_url } from './helpers';
import { ListView } from 'shogun/listview';
import DetailEditor from 'shogun/detaileditor';

const clifford_routes = ({ PREFIX }) => ([
    {
        'path': `${PREFIX}group-services/:group(\\d+)?/streaming/`,
        'exact': true,
        'link_url': group_link_url(PREFIX),
        'hideInNav': true,
        'component': ListView,
        'header': 'Streaming IDs',
        'title': 'Streaming IDs',
        'renderer': (props) => {
            const group = parseInt(props.match.params.group || '0');
            return <ResolveTarget key={`group-config-${group}`} as_focus={true} storage='shogunconf_forms' __type__='Group' __pk__={group}>
                <ListView
                    __type__='CliffordStreaming'
                    __pk__={group} key={`group-config-${group}`}
                    storage='shogunconf_forms'
                />
            </ResolveTarget>;
        },
        'routes': [
            {
                'path': `${PREFIX}group-services/:group(\\d+)?/streaming/:streaming(\\d+)`,
                'exact': true,
                'link_url': group_link_url(PREFIX),
                'hideInNav': true,
                'component': DetailEditor,
                'header': 'Streaming ID',
                'title': 'Streaming ID',
                'renderer': (props) => {
                    const group = parseInt(props.match.params.group || '0');
                    const streaming = parseInt(props.match.params.streaming);
                    return <ResolveTarget key={`streaming-id-${streaming}`} as_focus={true} storage='shogunconf_forms' __type__='Node' __pk__={group}>
                        <DetailEditor
                            __type__='CliffordStreamingTransfer'
                            __pk__={streaming} key={`streaming-id-${streaming}`}
                            storage='shogunconf_forms'
                        />
                    </ResolveTarget>;
                },
            },

        ]
    },
    {
        'path': `${PREFIX}group-services/:group(\\d+)?/streamingcert/`,
        'exact': true,
        'link_url': group_link_url(PREFIX),
        'hideInNav': true,
        'component': ListView,
        'header': 'Streaming Certificates',
        'title': 'Streaming Certificates',
        'resolve': [
            { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
        ],
        'renderer': (props) => {
            const group = parseInt(props.match.params.group || '0');
            return <ResolveTarget key={`group-config-${group}`} as_focus={true} storage='shogunconf_forms' __type__='Group' __pk__={group}>
                <ListView
                    __type__='CliffordStreamingCertificates'
                    __pk__={group} key={`group-config-${group}`}
                    storage='shogunconf_forms'
                    widgets={{
                        'hash': 'ShortWithTitle',
                    }}
                    creation_action={null}
                />
            </ResolveTarget>;
        },
        'routes': [
            {
                'path': `${PREFIX}group-services/:group(\\d+)?/streamingcert/:service(\\d+)`,
                'exact': true,
                'link_url': group_link_url(PREFIX),
                'hideInNav': true,
                'component': DetailEditor,
                'header': 'Streaming Certificates',
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'CliffordStreamingCertificates', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'title': 'Streaming Certificates',
                'renderer': (props) => {
                    const group = parseInt(props.match.params.group || '0');
                    const streamingcert = parseInt(props.match.params.service);
                    return <DetailEditor
                        __type__='CliffordStreamingCertificates'
                        __pk__={streamingcert} key={`streamingcert-${streamingcert}`}
                        storage='shogunconf_forms'
                    />;
                },
            },

        ]
    },
]);

export default clifford_routes;
