/* Helper functions for setting up common route patterns in shogun */
import React from 'react';

import { Redirect } from 'react-router-dom';
import { PAGE_FOCUS } from 'storages';

import { generatePath as rrd_generatePath } from 'react-router-dom';

const generatePath = (template, params) => {
    try {
        return rrd_generatePath(template, params);
    } catch (e) {
        console.error(`Unable to generate path ${template} with ${JSON.stringify(params)}`);
        return null;
    }
};


const render_type_and_title = (target, params) => {
    return `${target.type_name}: ${target.title}`;
};


const basic_focus_title = (param_render, focus_render = render_type_and_title) => {
    return (props) => {
        let { match, target, focus } = props;
        if (!target) {
            if (focus) {
                const current = focus.get_focus();
                if (current && current.focus) {
                    target = current.focus;
                }
            }
        }
        if (target) {
            return focus_render(target, match.params);
        }
        return param_render(match.params);
        // return `Config Package #${match.params.service_group} Q2A Services`;
    };
};
const devices_link_url = (PREFIX) => (
    (page, focus, user, routes, match) => {
        const group_focus = focus || PAGE_FOCUS.get_focus();
        let params = {
            group: (group_focus && group_focus.__pk__) || 0,
        };
        if (group_focus && group_focus.__type__ == 'Node') {
            params = {
                group: (focus && focus.tree && focus.tree.parent_id) || 0,
                node: group_focus.__pk__ || 0,
            };
            return generatePath(`${PREFIX}nodes/:group(\\d+)/:node(\\d+)`, params);
        }
        return generatePath(`${PREFIX}nodes/:group(\\d+)`, params);
    }
);
const generic_pagefocus_link_url = (page, focus, user, routes, match) => {
    const group_focus = focus || PAGE_FOCUS.get_focus();
    const params = (match && { ...match.params }) || {};
    let path = page.path;
    if (focus && focus.__type__ == 'Group' && page.group_path) {
        if (focus.tree && focus.tree.parent_id !== undefined) {
            params.node = params.group;
            params.group = focus.tree && focus.tree.parent_id;
            path = page.group_path
        }
    } else if (focus && focus.__type__ == 'Node' && page.node_path) {
        params.node = params.group;
        params.group = (focus.tree && focus.tree.parent_id) || 0;
        path = page.node_path;
    }
    params.group = (group_focus && group_focus.__pk__) || 0;
    return generatePath(path, params);
};
const generic_pagefocus_link_url_focus_required = (page, focus, user, routes, match) => {
    const group_focus = focus || PAGE_FOCUS.get_focus();
    const group_id = (group_focus && group_focus.__pk__) || 0;
    if (!group_id) {
        return null;
    }
    return generic_pagefocus_link_url(page, focus, user, routes, match);
};
const group_link_url = (PREFIX) => (
    /* Create a function to redirect to group based on prefix */
    (page, focus, user, routes, match) => {
        let group_focus = focus || PAGE_FOCUS.get_focus();
        const parent_id = group_focus && group_focus.tree && group_focus.tree.parent_id;
        let params = {
            group: (group_focus && group_focus.__pk__) || 0,
        };
        // if (group_focus.__type__ == 'Node') {
        //     if (group_focus.ancestors && group_focus.ancestors.length) {
        //         const ancestor_id = (group_focus.ancestors[0].__pk__);
        //         if (ancestor_id) {
        //             const params = (match && { ...match.params }) || {};
        //             params.group = ancestor_id;
        //             return generatePath(page.path, params);
        //         }
        //     }
        // }

        if (group_focus && group_focus.__type__ == 'Node') {
            // If this is a "calculate the group link for the current page"
            if (parent_id) {
                params = {
                    group: parent_id,
                    node: group_focus.__pk__,
                };
                console.log(`Focus is node ${group_focus.title} `);
                return generatePath(page.path, params);
            } else {
                // We don't have a good place to send you, so send you to the top-level group...
                params.group = 0;
                params.node = group_focus.__pk__;
                return generatePath(page.path, params);
            }
        }
        params = (match && { ...match.params }) || {};
        params.group = (group_focus && group_focus.__pk__) || 0;
        return generatePath(page.path, params);
    }
);

const service_redirect_for_group = route => {
    const base_render = route.renderer;
    route.renderer = (props) => {
        const { match } = props;
        const group = match.params.group;
        const service = match.params.service;
        console.log(`Service lookup ${group} ${service}`);

        if (group == undefined || group == '' || group == null) {
            const focus = PAGE_FOCUS.get_focus();
            const group_focus = (focus && focus.__pk__) || 0;
            console.log(`Should redirect to target ${group_focus}`);
            return <Redirect to={generatePath(route.path, {
                group: group_focus,
                service: service,
            })} />;
        } else {
            console.log(`Got non-null groups ${JSON.stringify(service)}`);
        }
        if (base_render) {
            return base_render(props);
        } else {
            const Component = route.component;
            return <Component {...props} />;
        }
    };
    return route;
};

export {
    render_type_and_title,
    basic_focus_title,
    devices_link_url,
    generic_pagefocus_link_url,
    generic_pagefocus_link_url_focus_required,
    group_link_url,
    service_redirect_for_group,
};
