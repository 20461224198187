import React from 'react';
import $ from 'fakequery';
import { withStyles } from '@material-ui/core/styles';

import { ClassyWidget } from 'reactform/classywidget';
import MuiTypography from 'dash/MuiTypography';
import WidgetRender from './custom_widget_render';
import classNames from 'classnames';

const styles = theme => ({
    formControl: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerLabel: {
        marginTop: '1em',
    },
    containerNoLabel: {

    },
    nullValue: {
        color: 'lightgray',
    },
});

class DisplayWidget extends ClassyWidget {
    /* A ClassyWidget that *just* displays the current value (no editing) */
    render_component(value) {
        const { classes } = this.props;
        const field = this.get_field();
        const base_field = field.field;
        if (base_field && base_field.choices && base_field.choices.length) {
            /* Can we find this value in the choices? */
            let test_value = value;
            if (value && value.__pk__) {
                test_value = value.__pk__;
            }
            const display = base_field.choices.filter(rec => `${rec[0]}` === `${test_value}`);
            if (display && display.length) {
                return <MuiTypography>{display[0][1]}</MuiTypography>;
            }
        }
        if (Array.isArray(value)) {
            var children = $.map(value, (record, index) => {
                return (
                    <li key={'item-' + index}>
                        {this.render_component(record)}
                    </li>
                );
            });
            return <ul className="array">{children}</ul>;
        } else if (typeof value === 'string' || typeof value === 'number') {
            return <MuiTypography>{value}</MuiTypography>;
        } else if (value === null || value === undefined) {
            const className = classNames('null', 'disabled', classes.nullValue);
            return <MuiTypography><span className={className}>unset</span></MuiTypography>;
        } else if (!value.getOwnPropertyNames) {
            return <MuiTypography>{'' + value}</MuiTypography>;
        } else {
            var attributes = $.map(value.getOwnPropertyNames(), function (name) {
                return [
                    <dt key={'key-' + name}>{name}</dt>,
                    <dd key={'value-' + name}>
                        {this.render_component(value[name])}
                    </dd>
                ];
            });
            return <dl key={'object-' + value}>{attributes}</dl>;
        }
    }

    render() {
        const { classes, show_label } = this.props;
        const field = this.get_field();
        const value = this.current_value();
        return <WidgetRender form_field={field} widget_class="display-widget" show_label={show_label}>
            {this.render_component(value)}
        </WidgetRender>;
    }
}

export default withStyles(styles)(DisplayWidget);
