import React from 'react';

var grid_rows = function( rows, widths, medium_widths, large_widths, grid_size ) {
    /* create pure grid-rows for each row with the specified widths 
    
    rows -- [ [ cell, cell, cell ], [cell, cell, cell ] ]
    widths -- [ 24, 24, 24 ] cell proportions on the grid_size grid (default 24)
    medium_widths -- [8,8,8] cell proportions for windows over 768px (if provided) (pure-u-md-*)
    large_widths -- [8,16,8] cell proportions for windows over 1024px (if provided) (pure-u-lg-*)
    grid_size -- if provided, override the default proportion denominator (24)
    
    grid_rows allows you to create responsive layouts that use common breakpoints
    to change layout for smaller/larger windows. Create an array-of-arrays holding
    your children (React.DOM nodes) and then pass them to grid_rows to get React.DOM
    div nodes that wrap the cells in the specified layout.
    */
    grid_size = grid_size || 24;
    var suffix = '-'+grid_size;
    return rows.map(function( row, row_i ) {
        var rendered = row.map( function( cell, cell_i ) {
            if (widths[cell_i] === undefined) {
                throw "Undefined width for cell "+cell_i;
            }
            var className = 'pure-u-'+widths[cell_i]+suffix;
            if (medium_widths && medium_widths[cell_i]) {
                className += ' pure-u-md-'+medium_widths[cell_i]+suffix;
            }
            if (large_widths && large_widths[cell_i]) {
                className += ' pure-u-lg-'+large_widths[cell_i]+suffix;
            }
            return <div key={'cell-'+row_i+'-'+cell_i} className={className}>
                {cell}
            </div>;
        });
        return <div key={'row-'+row_i} className='grid-rows pure-g row'>{rendered}</div>;
    });
};
export {grid_rows};
