import $ from 'fakequery';
import {BaseStorage} from 'storages/basestorage';
import { stores } from 'storeregistry';
import {requiring_store } from 'storages/requiringstore';

function SystemLicensesStorage(props) {
    var storage = BaseStorage( {
        'key':'system_licenses',
        'config': null,
        'refresh_on_post': true,
    });
    $.extend( storage, props || {});
    return storage;
}

function default_cb(props) {
    if (!stores.system_licenses) {
        const store = SystemLicensesStorage(props);
        store.get();
    }
}

function requiring_system_licenses_store(Component) {
    return requiring_store(
        Component,
        {
            storage_key: 'system_licenses',
            create_function: default_cb,
            forms_key: 'system_forms',
            prop_function: (props, storage) => {
                let {config} = storage;
                return {
                    ...props,
                    config,
                    storage
                };
            }
        }
    );
}

export {SystemLicensesStorage, requiring_system_licenses_store};
