/* Metric specific storage for shogun */
import React from 'react';
import { WithTabularStorage, TabularStorage } from 'storages/tabularstorage';
import { ReactFormTable } from 'reactform/reactformtable';
import { tabular_storage_props } from 'storages/tabularstorage';
// import useQuery from 'storages/usequery';
// import { useLocation } from 'react-router-dom';
import { TimeSeriesTracker } from 'metrics/timeseriestracker';
import qs from 'query-string';
import useMetricFilters from 'storages/usemetricfilters';

const stable_query_key = (default_context = {}) => {
    /* Calculate a stable query key for sharing metric storages 
    
    Filters out fields that are constantly changing then encodes
    fields as a string key...
    */
    let { start, debug, ts, ...stable_query } = default_context;
    return qs.stringify(stable_query, { sort: true, skipNull: true, skipEmptyString: true });
};

const ServiceSummaryStorage = TabularStorage(tabular_storage_props({
    key: 'ServiceSummary',
    form_key: 'ServiceSummary',
    forms_key: 'shogunconf_forms',
    period: 120,
    refresh_on_post: false,
    convert: (newState, storage) => {
        const service_map = {};
        if (newState.ServiceSummary) {
            newState.ServiceSummary.map((ss) => {
                service_map[ss.__pk__] = ss;
            });
        }
        return {
            ...newState,
            service_map,
        };

    }
}));
const getService = (pk) => {
    /* Lookup cached service details from ServiceSummaryStorage */
    return ServiceSummaryStorage.service_map && ServiceSummaryStorage.service_map[pk];
};
const getStream = (service_id, pk) => {
    /* Lookup cached service details from ServiceSummaryStorage */
    const service = getService(service_id);
    if (service && service.streams) {
        return service.streams[pk];
    }
};



const merge_fields = (Metrics, fields, resource_map, create_missing_rows = false, missing_only = false) => {
    /* Convert raw DB fields to js style objects */
    const rows = [];
    const seen_rows = {};
    (Metrics || []).map((row, index) => {
        const result = {};
        fields.map((field) => {
            if (field.db_row_index !== undefined) {
                result[field.name] = row[field.db_row_index];
            }
        });
        const raw_resource = resource_map && resource_map[result.resource_id];
        let resource = { id: result.resource_id, title: result.resource_id };
        if (raw_resource !== undefined && raw_resource != 'undefined') {
            fields.map(field => {
                if (field.db_row_index === undefined) {
                    result[field.name] = raw_resource[field.name];
                }
            });
            result.resource_id = raw_resource;
            result.__url__ = `/m/dashboard/${raw_resource.node_id}/`;
            result.title = raw_resource.title;
        } else if (result.resource_id) {
            console.log(`Unknown resource id ${result.resource_id}`);
        }
        result.stream_id = getStream(result.service_id, result.stream_id) || (result.service_id && {
            id: result.stream_id,
            service_id: result.service_id,
            'title': 'Stream',
            __url__: `/m/services/by-service/${result.service_id}`,
        }) || null;
        result.service_id = getService(result.service_id) || (result.service_id && {
            id: result.service_id,
            title: 'Service',
            __url__: `/m/services/by-service/${result.service_id}`,
        }) || null;
        seen_rows[resource.id] = result;
        rows.push(result);
    });
    if (create_missing_rows) {
        if (missing_only) {
            rows.length = 0;
        }
        const current_time = (new Date()).getTime() / 1000;
        Object.keys(resource_map).map((resource_id) => {
            if (seen_rows[resource_id]) {
                return;
            }
            const resource = resource_map[resource_id];
            const fake_row = {
                time: current_time,
                resource_id: {
                    ...resource,
                },
                __url__: `/m/dashboard/${resource.node_id}/`,
                title: resource.title,
                service_id: null,
                error: true,
                state: 'not-reporting',
                messages: [
                    `No metrics reported`
                ],
            };
            fields.map(field => {
                if (field.db_row_index === undefined) {
                    fake_row[field.name] = resource[field.name];
                }
            });
            rows.push(fake_row);
        });
    }
    return {
        data: rows,
        form: {
            'fields': fields,
        },
    };
};

const metric_convert = (newstate, storage) => {
    /* Produce a client-side newstate update from the server-side version */

    if ((!newstate.form) || (!newstate.form.fields)) {
        console.error(`No form fields in ${JSON.stringify(newstate)}`);
    }
    const { data, form } = merge_fields(
        newstate[storage.form_key],
        newstate.form.fields,
        storage.resource_map || newstate.resource_map,
        storage.create_missing_rows,
        storage.missing_only,
    );
    let { ts } = storage;
    ts = ts || 0;
    newstate[storage.form_key] = data;
    data.map((r) => {
        ts = Math.max(ts, r.time);
    });
    newstate.ts = ts;
    newstate.form_details = {};
    newstate.form_details[storage.form_key] = form;
    if (storage.debug) {
        console.log(`New ts: ${newstate.ts} ${new Date(newstate.ts * 1000)}`);
    }
    return newstate;
};
const apply_limit = (records, limit) => {
    return records.slice(0, limit);
};

const merge_types = {
    'last_status': null, /* last status summaries, always replace */
    'category': null, /* Categorical summaries, always replace */
    'enum_summary': null, /* Counting summaries, always replace */
    'stats': (newstate, storage) => {
        if (newstate.partial && newstate.start) {
            /* Append new records to the set, relies on ts ordering and exclusion */
            const old_records = storage[storage.form_key];
            newstate[storage.form_key] = apply_limit([
                ...newstate[storage.form_key],
                ...old_records,
            ], storage.default_context.limit || 200);
            console.log(`Extending: ${old_records.length} to ${newstate[storage.form_key].length}`);
        } else {
            console.log(`Replacing: ${newstate[storage.form_key].length}`);
        }
        // storage.Metrics = storage.data;
        const result = storage.base_merge(newstate, storage);
        if (storage[storage.form_key].length && Array.isArray(storage[storage.form_key][0])) {
            console.error(`Post-merge data is in raw array form`);
        }
        return result;
    }
};

const TableFedStorage = (props) => {
    /* A WithTabularStorage wrapped around a ReactFormTable to provide the actual dataset */
    console.log(`TableFedStorage storage props: ${JSON.stringify(props.storage_props)}`);
    return <WithTabularStorage
        {...props}
        Component={ReactFormTable}
    />;
};

const group_filter = (props) => {
    const { target } = props;
    const group_query = target ? {
        'node__tree_id': target.tree.tree_id,
        'node__lft__gte': target.tree.lft,
        'node__rght__lte': target.tree.rght,
    } : {};
    return { instance: target, group_query };
};
const data_filter = () => {
    /* Populate filters from the page query strings */
    const instance_query = {};
    const query = useQuery();
    query.sort();
    for (var pair of query.entries()) {
        const [query_key, value] = pair;
        console.log(`Query parameter ${query_key}: ${JSON.stringify(value)}`);
        instance_query[query_key] = value;
    }
    return instance_query;
};
const default_ensure_keys = (storage) => storage;

const metric_storage = (props) => {
    /* Storage properties for a given metric record */
    const { metric = 'node_status', query_type = 'last_status', merge = null, ensure_keys = default_ensure_keys } = props;
    let { default_context = {} } = props;
    let data_filters = props.data_filters || { create_missing_rows: false };

    const { instance, group_query } = group_filter(props);
    /* TODO: hooks *can't* run outside of a react render cycle, so this is *not* okay */

    /* Filters which are implemented client-side */
    let missing_only = false;
    data_filters = { ...data_filters };
    if (data_filters && data_filters['.missing_only']) {
        missing_only = true;
    }
    let create_missing_rows = data_filters.create_missing_rows;

    // delete data_filters['.missing_only'];
    // delete data_filters.create_missing_rows;


    if (!ServiceSummaryStorage.periodic) {
        ServiceSummaryStorage.poll();
    }

    // console.log(`Setting up storage with key ${key}`);
    default_context = {
        ...default_context,
        query_type: query_type,
        // query_type: 'stats',
        limit: props.limit || 5000,
        ...group_query,
        ...data_filters
    };
    if (query_type === 'last_status') {
        if (!props.start) {
            default_context.start = ((new Date()).getTime() / 1000) - (60 * 60);
        } else {
            default_context.start = props.start;
        }
    } else if (query_type == 'enum_summary') {
        const now_date = (new Date()).getTime() / 1000;
        default_context = {
            ...default_context,
            group_fields: props.group_fields,
            bin: props.bin || 1200,
            start: now_date - (3600 * 12),
            debug: true,
        };
    } else if(query_type == 'threshold') {
        default_context.start = ((new Date()).getTime() / 1000) - (3600 * 12);
    }

    let form_key = metric;
    if (!form_key.startsWith('metric_')) {
        form_key = `metric_${form_key}`;
    }
    form_key = form_key.split('.')[0];
    const key = `metric/${metric}/${query_type}/${(instance && instance.id) || 0}/${stable_query_key(default_context)}`;
    console.log(`Using key ${key} for the metric storage`);
    return {
        key: key,
        default_context: default_context,
        forms_key: 'shogunconf_forms',
        form_key: form_key,
        convert: metric_convert,
        ensure_keys: ensure_keys,
        period: 15,
        // debug: true,
        quick_settings: {
        },
        merge: merge || merge_types[default_context.query_type],

        create_missing_rows: create_missing_rows,
        missing_only: missing_only,
    };
};

const using_timeline_filler = (props) => {
    /* Keep the storage full of content based on the ranges specified by signal */
    const now = (new Date()).getTime();
    const start = now - (1000 * 3600 * 2);
    const [tracker, setTracker] = React.useState(TimeSeriesTracker({ range: [start, now] }));
    tracker.signal.listen();
};

export {
    metric_storage,
    TableFedStorage,
    merge_types,
    merge_fields,
    metric_convert,
    stable_query_key,

    group_filter,
    data_filter,
    ServiceSummaryStorage,
};
