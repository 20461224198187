import React from 'react';

import {human_bytes} from 'humanize';
import {icon_column} from 'faicon';
import {fa_icon} from 'faicon';
import {BaseUploader} from 'baseuploader';
import {Progress} from './progress';

class Uploader extends React.Component {
    /* Upload for a single file */
    static defaultProps = {
        url: '/uploads/upload_chunk/',
        purpose: 'media',
        file: null,
        onFinished: null,
        onCancel: null,
    }
    componentWillMount() {
        const {url,file,purpose,onFinished} = this.props;
        var upload = new BaseUploader({
            onProgress: this.handleProgress.bind(this),
            file: file,
            purpose: purpose,
            onFinished: onFinished,
            url: url,
        });
        this.setState({
            upload: upload
        });
    }
    componentDidMount() {
        /* component is rendering, start upload */
        this.start();
    }
    componentWillUnmount() {
        this.cancel();
    }
    start() {
        /* kick off our upload */
        this.state.upload.next_chunk();
    }
    cancel() {
        /* abort the upload */
        this.state.upload.cancel();
        this.props.onCancel && this.props.onCancel(self, this.state.upload);
    }
    handleProgress = (self, data) => {
        /* handle upload progress report */
        this.setState({});
        if (this.props.onProgress) {
            this.props.onProgress(self, data);
        }
    }
    render() {
        var size = this.props.file.size;
        var written = this.state.upload.state.written;
        var progress = '';
        var fraction = 0;
        if (size && written) {
            progress = <span className="written">{human_bytes(
                this.state.upload.state.written
            )+ '/'}</span>;
            fraction = written/size;
        }
        var file_details = <div className="file-details">
            <span className="file-name">{ this.props.file.name } </span>
            {progress}
            <span className="file-size">{human_bytes(size)}</span>
        </div>;
        var cancel_button = <a className="cancel flat-button iconic" title="Cancel/delete upload" onClick={this.cancel.bind(this)}>{fa_icon('close')}</a>;
        return <div className="file-upload-progress">
            <Progress fraction={fraction}>
                { icon_column(
                    file_details,
                    null,
                    cancel_button
                )}
            </Progress>
        </div>;
    }
}

export default Uploader;
