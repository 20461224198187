import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import MuiCard from 'dash/MuiCard';
import MuiTypography from 'dash/MuiTypography';

const styles = theme => ({
    card: {
        border: 'thin solid #eee',
        backgroundColor: 'white',
        borderRadius: '5px',
        width: '100%',
        height: '100%',
    },
    chart: {
        // backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(.25)
    },
    title: {
        textAlign: 'center',
        width: '100%',
        fontSize: '75%',
        color: '#777',
    }
});

const ChartCard = (props) => {
    const { classes, chart, title, description = null } = props;

    return (
        <div className={classes.card}>
            <div className={classes.title} title={description}>{title}</div>
            <div className={classes.chart}>{chart}</div>
        </div>
    );
};

ChartCard.propTypes = {
    title: PropTypes.string.isRequired,
    chart: PropTypes.element.isRequired,
    description: PropTypes.string,
};

export default withStyles(styles)(ChartCard);
