/* Trivial stub with just enough of jquery for most of our code */

function filter_nulls(arr) {
    return arr.filter(
        (v) => (v !== null && v !== undefined)
    );
}

class FakeQuery {
    map(arr, functor) {

        if (arr !== null && arr !== undefined) {
            if (arr.map) {
                return filter_nulls(arr.map(functor));
            } else {
                const result = [];
                for (var i=0;i<arr.length;i++) {
                    result[i] = functor(arr[i],i);
                }
                return filter_nulls(result);
            }
        } else {
            return [];
        }
    }
    extend(base, extension) {
        Object.assign(base, extension);
        return base;
    }
    grep(arr,functor) {
        return arr.filter(functor);
    }
    elements_by_class(name) {
        return Array.from(document.getElementsByClassName(name));
    }
    document_ready(callback) {
        if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"){
            callback();
        } else {
            document.addEventListener('DOMContentLoaded', callback);
        }        
    }
}
const $ = new FakeQuery();

export default $;
