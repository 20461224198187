/* Humanisation of bandwidth/bytes/durations */
import $ from "fakequery";

const DAY = 60 * 60 * 24, HOUR = 60 * 60, MINUTE = 60;
const DAY_SIZES = [
    ['days', DAY],
    ['hours', HOUR],
    ['minutes', MINUTE],
    ['seconds', 0],
];
const SHORT_LABELS = {
    'days': 'days',
    'hours': 'hrs',
    'minutes': 'min',
    'seconds': 'sec',
};

const K = 1000;
const M = K * 1000;
const G = M * 1000;
const T = G * 1000;

const DURATION_DAYS = 2, DURATION_HOURS = 4, DURATION_MINUTES = 5, DURATION_SECONDS = 7;
const ALT_DURATION_VALUE = 1, ALT_DURATION_UNIT = 2;

function human_bandwidth(count, in_bits) {
    /* convert count bytes to bit-rate
   
    count -- number of bytes
    in_bits -- if provided/true, then the original value is in *bits* already
   
    returns string formatted with Gbps, Mbps, kbps or bps with 1 decimal point of rounding
    */
    if (!in_bits) {
        count *= 8
    }
    var description = '' + Math.round(count) + 'bps';
    [['Kbps', K], ['Mbps', M], ['Gbps', G], ['Tbps', T]].map(function (record) {
        var suffix = record[0], level = record[1];
        if (count >= level) {
            description = '' + (Math.round((count / level) * 10) / 10) + suffix;
        }
    });
    return description;
}

function human_bytes(count) {
    var description = '' + Math.round(count) + 'B';
    [['KB', K], ['MB', M], ['GB', G], ['TB', T]].map((record) => {
        var suffix = record[0], level = record[1];
        if (count >= level) {
            description = '' + (Math.round((count / level) * 10) / 10) + suffix;
        }
    });
    return description;
}
function human_duration(seconds) {
    /* convert duration in seconds to a human description of duration */
    var result = {
        'days': 0, 'hours': 0, 'minutes': 0, 'seconds': 0,
        'getHours': function () { return result.hours; },
        'getMinutes': function () { return result.minutes; },
        'getDays': function () { return result.days; },
        'getSeconds': function () { return result.seconds; },
        'set': function (other) {
            $.map(DAY_SIZES, function (rec) {
                var key = rec[0].slice(0, rec[0].length - 1);
                var value = other[key];
                if (value !== undefined) {
                    result[rec[0]] = value;
                }
            });
            return result;
        },
        'clone': function () {
            return human_duration(computer_duration(result));
        },
        'toString': function () {
            return human_duration_text(result);
        },
        'toFloat': function () {
            return computer_duration(result);
        }
    };
    $.map(DAY_SIZES, function (rec) {
        var prop = rec[0], size = rec[1];
        if (seconds >= size) {
            result[prop] = Math.floor(seconds / (size || 1));
            seconds -= (result[prop] * size);
        }
    });
    return result;
}
function human_duration_text(duration, show_seconds = true) {
    if (typeof duration === 'number') {
        duration = human_duration(duration);
    } else if (duration === undefined || duration === null) {
        return "Undefined Duration";
    }
    var result = "";
    $.map(DAY_SIZES, function (record) {
        var value = duration[record[0]];
        var description = "";
        if (record[0] === 'seconds' && !show_seconds) {
            return;
        }
        if (value) {
            if (value === 1) {
                description = "1 " + record[0].slice(0, record[0].length - 1);
            } else {
                description = "" + value + ' ' + record[0];
            }
            if (result) {
                result += ' ';
            }
            result += description;
        }
    });
    return result;
}
function human_duration_text_short(duration, show_seconds = true, time_ago = true) {
    /* Compact duration display */
    if (typeof duration === 'number') {
        duration = human_duration(duration);
    } else if (duration === undefined || duration === null) {
        return "Undefined Duration";
    }
    var result = [];
    var units = null;
    $.map(DAY_SIZES, function (record) {
        const [label, size] = record;
        var value = duration[label];
        if (record[0] === 'seconds' && !show_seconds) {
            return;
        }
        if (value) {
            var description = "";
            if (!units) {
                units = label;
                description = `${value}`;
            } else {
                description = `${value}`.padStart(2, '0');
            }
            result.push(description);
        }
    })
    if (!units) {
        /* all-0 case */
        if (time_ago) {
            return 'now';
        } else {
            return '0 sec'
        }

    } else {
        return `${result.join(":")} ${SHORT_LABELS[units]}`;
    }
}
function padded(v) {
    if (v < 10) {
        return '0' + v;
    } else {
        return '' + v;
    }
}
function short_duration_text(duration, show_seconds) {
    if (typeof (duration) === 'number') {
        duration = human_duration(duration);
    }
    var base = '' + duration.hours + ':' + padded(duration.minutes);
    if (show_seconds) {
        base = base + ':' + padded(duration.seconds);
    }
    if (duration.days) {
        return '' + duration.days + 'd ' + base;
    } else {
        return base;
    }
}
function parse_duration(value) {
    /* parse duration text from human input 
    
    ### d ##:##
    ### d ##:##:##
    ##:##
    ##:##:##
    */

    /* Note: in js, the regex object is mutated by calls to exec! */
    var duration_finder = new RegExp("^\\W*(([0-9.]+)\\W*[d])?\\W*((\\d{1,2})[:](\\d{2})([:]([0-9.]+))?)?\\W*$", "gi");
    var alt_duration_finder = /\W*([0-9.]+)\W*([dhms])/gi;
    var duration = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    };
    var found = false;
    var fragments = duration_finder.exec(value);
    if (fragments) {
        if (fragments[DURATION_DAYS]) {
            duration.days = parseFloat(fragments[DURATION_DAYS], 10);
            found = true;
        }
        if (fragments[DURATION_HOURS]) {
            duration.hours = parseFloat(fragments[DURATION_HOURS], 10);
            found = true;
        }
        if (fragments[DURATION_MINUTES]) {
            duration.minutes = parseFloat(fragments[DURATION_MINUTES], 10);
            found = true;
        }
        if (fragments[DURATION_SECONDS]) {
            duration.seconds = parseFloat(fragments[DURATION_SECONDS], 10);
            found = true;
        }
        if (found) {
            return duration;
        } else {
            return null;
        }
    }
    fragments = alt_duration_finder.exec(value);
    if (fragments) {
        while (fragments) {
            var partial = parseFloat(fragments[ALT_DURATION_VALUE]);
            var unit = { 'd': 'days', 'h': 'hours', 'm': 'minutes', 's': 'seconds' }[fragments[ALT_DURATION_UNIT]];
            duration[unit] += partial;
            fragments = alt_duration_finder.exec(value);
        }
        return duration;
    } else {
        return null;
    }
}
function computer_duration(struct) {
    var result = 0;
    $.map(DAY_SIZES, function (rec) {
        var prop = rec[0], size = rec[1];
        if (struct[prop]) {
            result += struct[prop] * (size || 1);
        }
    });
    return result;
}


export {
    human_bandwidth,
    human_bytes,
    human_duration,
    human_duration_text,
    human_duration_text_short,
    short_duration_text,
    computer_duration,
    parse_duration,
};
