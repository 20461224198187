/* Declare chart colors */

const COLORS = { // TODO: load from scss or mui and allow for color-blind mapping
    GREY: '#777',
    WHITE: '#FFF',
    BLACK: '#000',

    STARTING: '#FC7',
    INACTIVE: '#333',
    ERROR: '#F33',
    OK: '#3f3',
    DISABLED: '#ccd',
};

export default COLORS;