import React from 'react';
import { providing_nearest_form } from 'formprovider';
import { ReactForm } from 'reactform';
import { with_resolve_target, with_target } from 'dash/resolvetarget';
// import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import { EditIntent } from 'editintent';
import { IPOutputTable, mapping_specific_storage } from './ipoutputtable';
// import FocusLink from 'dash/focuslink';
// import {url_for_key} from 'storages';
import { WithToolbar } from 'shogun/shogunpromotebar';
import { ServiceCSVDownloader, ServiceCSVUploader } from 'shogun/servicecsv';
import ListView from 'shogun/listview';

class BaseIPOutputMappingView extends React.Component {
    render() {
        const { target } = this.props;
        const storage_props = mapping_specific_storage(target);
        storage_props.debug = true;
        const table_actions = [
            (target && target.id) && <ServiceCSVUploader
                target={target}
                key='uploader'
            />,
            (target && target.id) && <ServiceCSVDownloader
                target={target}
                key='downloader'
            />,
        ].filter(x => !!x);
        return <React.Fragment>
            <ListView
                title={'IP Outputs'}
                __type__='IPOutput'
                storage={'shogunconf'}
                context={{
                    'mapping': target.id,
                }}
                creation_context={{
                    'mapping': target.id,
                }}
                actions={table_actions}
                editable={true}
                link_to_edit={false}
            // editing_forms={{ 'IPOutput': 'IPOutputEdit' }}
            />
            <EditingCard title={`${target.title} Metadata`} collapsing='ipoutputmapping-metadata'>
                <ReactForm target={target} use_dialog={false} />
            </EditingCard>
        </React.Fragment>;
    }
}
const IPOutputMappingView = WithToolbar(providing_nearest_form(
    with_resolve_target(
        with_target(
            BaseIPOutputMappingView,
        )
    ),
    'shogunconf_forms'
));

export default IPOutputMappingView;
export { BaseIPOutputMappingView, IPOutputMappingView };
