import React from 'react';
import { Redirect } from 'react-router-dom';
import { ResolveTarget } from 'dash/resolvetarget';
import { generic_pagefocus_link_url, generic_pagefocus_link_url_focus_required, group_link_url } from './helpers';
import GroupView from 'shogun/groupview';
import GroupsView from 'shogun/groupsview';
import DeployView from 'shogun/deployview';
import { ListView } from 'shogun/listview';
import DetailEditor from 'shogun/detaileditor';

const groups_routes = ({ PREFIX }) => ([
    {
        'path': `${PREFIX}groups/:group(\\d+)`,
        'node_path': `${PREFIX}nodes/:group(\\d+)/:node(\\d+)`,
        'exact': true,
        'link_url': group_link_url(PREFIX),
        'hideInNav': false,
        'component': GroupView,
        'header': 'Group',
        'title': 'Group',
        'renderer': (props) => {
            const group = parseInt(props.match.params.group);
            return <ResolveTarget key={`group-config-${group}`} as_focus={true} storage='shogunconf_forms' __type__='Group' __pk__={group}>
                <GroupView
                    __type__='Group' __pk__={group} key={`group-config-${group}`}
                    storage='shogunconf_forms'
                />
            </ResolveTarget>;
        },
        'routes': [
            {
                'path': `${PREFIX}groups/:group(\\d+)/tree`,
                'link_url': group_link_url(PREFIX),
                'hideInNav': true,
                'header': 'Group Tree',
                'title': 'Group Tree',
                'component': GroupsView,
                'renderer': (props) => {
                    const group = parseInt(props.match.params.group);
                    return <ResolveTarget key={`groups-${group}`} as_focus={true} storage='shogunconf_forms' __type__='Group' __pk__={group}>
                        <GroupsView
                            root={group === 0}
                            withGroups={true}
                            {...props}
                        />
                    </ResolveTarget>;
                }
            },
            {
                'path': `${PREFIX}deploy/:group(\\d+)`,
                'link_url': generic_pagefocus_link_url_focus_required,
                'header': 'Apply',
                'hideInNav': true,
                'title': 'Apply',
                'component': DeployView,
                'renderer': props => {
                    let group = parseInt(props.match.params.group);
                    return <ResolveTarget key={`groups-${group}`} as_focus={true} storage='shogunconf_forms' __type__='Group' __pk__={group}>
                        <DeployView root={!group} />
                    </ResolveTarget>;
                },
            },
            {
                'path': `${PREFIX}enrollments/:group(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                'exact': true,
                'hideInNav': true,
                'permission': 'shogunconf.view_enrollments',
                'header': 'Enrollments',
                'title': 'Enrollments',
                'component': ListView,
                'renderer': (props) => {
                    const { match } = props;
                    const group = parseInt(match.params.group);
                    const context = group ? { target_group: group } : {};
                    return <ResolveTarget key={`enrollments-list-${group || 0}`} __type__='Group' __pk__={group} storage='shogunconf_forms' as_focus={true}>
                        <ListView __type__='Enrollment' storage='shogunconf_forms' context={context} />
                    </ResolveTarget >;
                },
                routes: [
                    {
                        'path': `${PREFIX}enrollments/:group(\\d+)/:enrollment(\\d+)`,
                        'link_url': generic_pagefocus_link_url,
                        'exact': true,
                        'hideInNav': true,
                        'permission': 'shogunconf.view_enrollments',
                        'header': 'Enrollment',
                        'title': 'Device Enrollment',
                        'component': ListView,
                        'renderer': (props) => {
                            const { match } = props;
                            const group = parseInt(match.params.group);
                            const enrollment = parseInt(match.params.enrollment);
                            const context = group ? { target_group: group } : {};
                            return <ResolveTarget key={`enrollments-${group || 0}-${enrollment}`} __type__='Group' __pk__={group} storage='shogunconf_forms' as_focus={true}>
                                <DetailEditor
                                    __type__={'Enrollment'}
                                    __pk__={enrollment}
                                    storage='shogunconf_forms'
                                />
                            </ResolveTarget>;
                        }
                    },
                ],
            },
        ]
    },
]);

export default groups_routes;
