import React from 'react';
import TextField from '@material-ui/core/TextField';

import { ClassyWidget } from 'reactform/classywidget';

class TextInputWidget extends ClassyWidget {
    render() {
        const { show_label, type = "text" } = this.props;
        const field = this.get_field();
        const base_field = field.field;

        return (
            <TextField
                type={type}
                label={show_label && field.label}
                value={this.render_value() || ''}
                id={base_field.label_id}
                name={field.name}
                onChange={event => this.handleChange(event.target.value)}
                onBlur={event => {
                    const result = this.handleChange(event.target.value);
                    if (this.props.onBlur) {
                        this.props.onBlur(this);
                    }
                    return result;
                }}
                className={this.props.className || ''}
                classes={{}}
                ref={this.controlRef}
                fullWidth
                autoFocus={this.props.autoFocus}
                InputLabelProps={{
                    shrink: true
                }}
                inputProps={{
                    size: base_field.max_length + 1 || '',
                    maxLength: base_field.max_length || '',
                    onKeyDown: this.props.onKeyDown || null,
                }}
            />
        );
    }
}

export default TextInputWidget;
