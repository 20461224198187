import React from "react";
import WithCurrentUser from 'withcurrentuser';
import { withRouter, Redirect } from "react-router-dom";
import {LoginControl} from 'dash/login';

class ProtectedRoute extends React.Component {
    static defaultProps = {
        'protection_test': (props) => true,
        'protected': null,
        'protection_control': LoginControl,
    }
    onSave = () => {
        console.log("Protection saved refreshing");
        this.setState({});
    }
    render() {
        const {protection_test,protection_url,location,protection_control} = this.props;
        if (protection_test(this.props)) {
            return this.props.protected;
        } else {
            const ProtectionControl = protection_control;
            return <ProtectionControl onSave={this.onSave} />;
        }
    }
}

export default withRouter(WithCurrentUser(ProtectedRoute));