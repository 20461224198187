import React from 'react';
import {
    NodeMetadataCard, NodeServiceAssignmentCard, NodeResourcesCard,
    NodeApplyForm, NodeAppliedConfigsForm, statBlockStyles,
} from 'shogun/nodeview';
import EditIntent from 'editintent';
import ReactFormButton from 'widgets/ReactFormButton';
import { register } from 'shogun/nodeview_registry';
import RestartAlt from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import Cloud from '@material-ui/icons/Cloud';
import { default_save_button } from 'reactform/constructcontrols';
import WithCurrentUser from 'withcurrentuser';
import useMetricData from 'metrics/usemetricdata';
import { BASE_COLOR_MAP } from 'metrics/metricgraph';
import { ErrorBoolean, GoodBadBool } from 'widgets/renderboolean';
import { withStyles } from '@material-ui/styles';
import SecondsAgo from 'dash/secondsago';
import { useHistory } from 'react-router-dom';
import { LiveTv } from '@material-ui/icons';
import classNames from 'classnames';

const StreamingIdStat = withStyles(statBlockStyles)((props) => {
    const { clifford_streaming, classes } = props;
    if (clifford_streaming) {
        const { streaming_id, reported_streaming_id, client_reported } = clifford_streaming;
        const update_needed = streaming_id != reported_streaming_id;
        let client_reported_formatted = null;
        if (client_reported) {
            const value_date = new Date(client_reported * 1000);
            client_reported_formatted = `${value_date.toDateString()} ${value_date.toTimeString()}`;
        } else {
            client_reported_formatted = 'Unknown time';
        }
        return <div className={classes.statProperty} key='clifford-streaming-id'>
            <span className={classes.statPropertyName}>Streaming ID:</span>
            <div
                className={classNames(classes.statPropertyValue, update_needed ? classes.error : null)}
            >
                <div title="Configured Streaming ID">{streaming_id}</div>
                {update_needed ? <div
                    className={classes.error}
                    title={`First reported by client at ${client_reported_formatted}, log into the interface of the Smartbox and click to "Download the SMARTBOX Streaming Identifier"`}
                >({reported_streaming_id || <i>Not Applied</i>})</div> : null}
            </div>
        </div>;
    }
    return null;
});


const CliffordStatBlock = withStyles(statBlockStyles)((props) => {
    /* Render quick-stats from node connection metrics */
    const { target, classes } = props;
    const { reported_structure } = target;
    const history = useHistory();
    const storage = useMetricData({
        query_type: 'last_status',
        metric: 'node_status',
        target: target,
        create_missing_rows: false,
        start: (new Date()).getTime() / 1000 - (3600 * 366),
    });
    const data = storage.get_last_value();
    const connection_record = (data) ? data[0] : null;
    return <div className={classes.statBlock}>
        <div className={classes.statProperties}>
            <div className={classes.statProperty}>
                <span className={classes.statPropertyName}>ATX S/N:</span>
                <span className={classes.statPropertyValue}>{reported_structure && reported_structure.serial_number}</span>
            </div>
            <div className={classes.statProperty}>
                <span className={classes.statPropertyName}>Build Date:</span>
                <span className={classes.statPropertyValue}>{reported_structure && reported_structure.agent_timestamp}</span>
            </div>
            <StreamingIdStat clifford_streaming={target.clifford_streaming} />
            {connection_record &&
                <div className={classes.statProperty}>
                    <GoodBadBool value={connection_record && connection_record.connected} />
                    <span>{connection_record && (connection_record.connected ? 'Connected' : 'Not Connected')}</span>
                </div>}
        </div>
        {connection_record && <div className={classes.statMeta}>
            <div>Reported</div>
            <div><SecondsAgo time={connection_record.time} /></div>
        </div>}
    </div>;
    // if (data && data.length > 0) {
    //     const record = data[0];
    //     return <LoadingDisplay signal={storage.loading}><div className={classes.statBlock} key='loading'>
    //         <div className={classes.statProperties}>
    //             <div className={classes.statProperty}>
    //                 <ErrorBoolean value={record.error} />
    //                 <span>{record.error ? 'Error' : 'No Error'}</span>
    //             </div>
    //             <div className={classes.statProperty}>
    //                 <GoodBadBool value={record.connected} />
    //                 <span>{record.connected ? 'Connected' : 'Not Connected'}</span>
    //             </div>
    //             <div className={classes.statProperty}>
    //                 <span style={{ color: `${BASE_COLOR_MAP[record.state] || 'black'}` }}>State: {record.state}</span>
    //             </div>
    //             {target.reported_structure && target.reported_structure.room_number && <div className={classes.statProperty} title="Room number entered by installer, reported only on reboot/reconnect">
    //                 Room: {target.reported_structure.room_number}
    //             </div>}
    //         </div>
    //         <div className={classes.statMeta}>
    //             <SecondsAgo time={record.time} />
    //         </div>
    //     </div></LoadingDisplay>;
    // } else {
    //     return <div className={classes.statBlock}>Not reporting</div>;
    // }

});
CliffordStatBlock.displayName = 'CliffordStatBlock';

const NodeView_Clifford = (props) => {
    /* Custom view for VSBB Devices (nodes) */
    const { target, user } = props;
    const buttons = [
        'save',
        'delete',

        // user.has_permission('shogunconf.reboot') ? <EditIntent
        //     key={`reboot-${target.__pk__}`}
        //     target={target}
        //     form_key='NodeReboot'
        //     storage='shogunconf_forms'
        //     label='Reboot'
        //     action='Reboot'
        //     icon={<RestartAlt />}
        //     title="Reboot the MD Chassis (Immediately)"
        //     variant='outlined'
        //     color='secondary'
        //     buttons={[(self) => {
        //         return default_save_button(self, {
        //             key: 'reboot',
        //             title: 'Send the request to reboot',
        //             busy_message: 'Sending Request',
        //             action_message: 'Request Reboot',
        //         });
        //     }, 'cancel']}
        // /> : null,

        user.has_permission('shogunconf.map_ssh') ? <EditIntent
            key={`map_ssh-${target.__pk__}`}
            target={target}
            form_key='NodeMapSSH'
            storage='shogunconf_forms'
            label='Map SSH'
            icon={<Cloud />}
            title="Map SSH Access"
            variant='outlined'
            color='secondary'
            buttons={[(self) => {
                return default_save_button(self, {
                    key: 'map_ssh',
                    title: 'Send the request to map ssh',
                    busy_message: 'Sending Request',
                    action_message: 'Request SSH',
                });
            }, 'cancel']}
        /> : null,
        user.has_permission('shogunconf.proxy_http') ? <EditIntent
            key={`proxy_http-${target.__pk__}`}
            target={target}
            form_key='NodeProxyHttp'
            storage='shogunconf_forms'
            label='Proxy HTTP'
            icon={<Cloud />}
            title="Proxy HTTP Interface"
            variant='outlined'
            color='secondary'
            buttons={[(self) => {
                return default_save_button(self, {
                    key: 'proxy_http',
                    title: 'Send the request to proxy the http port',
                    busy_message: 'Sending Request',
                    action_message: 'Request HTTP Proxy',
                });
            }, 'cancel']}
            onSave={(button, result, form) => {
                console.log(`Result: ${JSON.stringify(result)}`);
                if (result.redirect) {
                    window.open(result.redirect, '_blank');
                }
                return true;
            }}
        /> : null,
        (user.has_permission('shogun_cliff.view_streaming_id') && target.clifford_streaming) ? <EditIntent
            key={`streaming-transfer-${target.__pk__}`}
            target={target.clifford_streaming}
            form_key='CliffordStreamingTransfer'
            storage='shogunconf_forms'
            label='Replace Streaming Identifier'
            action='Replace'
            icon={<LiveTv />}
            title="Move Streaming Identifier to this Device"
            variant='outlined'
            color='secondary'
            onSave={(button, result, form) => {
                console.log(`Result: ${JSON.stringify(result)}`);
                if (result.redirect) {
                    window.open(result.redirect, '_blank');
                }
                return true;
            }}

        /> : null,
    ].filter(x => !!x);
    return <React.Fragment>
        <CliffordStatBlock target={target} key={`node-statblock-${target.__pk__}`} />
        <NodeMetadataCard {...props} key={`metadata`} buttons={buttons} />
        <NodeResourcesCard {...props} key={`resources`} />
        {/* <NodeApplyForm {...props} key={`apply`} /> */}
        {/* <NodeAppliedConfigsForm {...props} key={`applied`} /> */}
    </React.Fragment>;
};
const enable_clifford_view = () => {
    register('clifford', WithCurrentUser(NodeView_Clifford));
    register('clifford.v1', WithCurrentUser(NodeView_Clifford));
};

export default enable_clifford_view;