/* Sample summary "graph" type for dashboard */
import React from 'react';
import { register_graph } from 'dash/charts/graph';
import { extract_data, split_streams_by, get_graph_data } from 'dash/charts/extractdataset';
import { graph_format, graph_metric, graph_key } from 'dash/charts/chartmodel';
import { query_color_map } from 'dash/charts/colormap';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { human_duration_text_short, short_duration_text } from 'humanize';
import { timestamp_render } from 'reactform/typerenderer';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    nodeSummary: {
        margin: '.5rem',
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'flex-start',
        alignItems: 'stretch',
        overflowY: 'scroll',
        overflowX: 'hidden',
    },
    logRecords: {
        display: 'grid',
        flex: 1,
        gridTemplateColumns: '3rem 6rem 1fr',
    },
    timeStamp: {
        lineHeight: '1rem',
        fontSize: '.5rem',
    },
    nodeName: {
        lineHeight: '1rem',
        fontSize: '.5rem',
        overflow: 'hidden',
    },
    dateHeader: {
        gridColumn: 'span 3',
        color: '#777',
        fontSize: '.75rem',
        lineHeight: '1rem',
        backgroundColor: '#f0f0f0',
    },
    message: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '.75rem',
        lineHeight: '1rem',
        minHeight: '1rem',
    },
    info: {
        color: '#777',
    },
    warning: {
        color: '#cc9900',
    },
    error: {
        color: '#700',
    },

}));

const format_time = (time, now) => {
    const zero_pad = (x, digits) => {
        let base = `${x}`;
        while (base.length < digits) {
            base = `0${base}`;
        }
        return base;
    };
    const as_date = new Date(time * 1000);
    const date = `${as_date.getFullYear()}-${zero_pad(as_date.getMonth() + 1, 2)}-${zero_pad(as_date.getDate(), 2)}`;
    const timestring = `${zero_pad(as_date.getHours(), 2)}:${zero_pad(as_date.getMinutes(), 2)}:${zero_pad(as_date.getSeconds(), 2)}`;
    return [date, timestring];
};

const RecentLogs = (props) => {
    const [tooltip, setTooltip] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const popperRef = React.useRef(null);
    const { graph, metric = null, format = null, color_map = null, link_callback = null, width = null, height = null } = props;

    const classes = useStyles();
    const dataset = get_graph_data(props.series);
    if (!dataset) {
        return null;
    }
    const level_map = {
        20: classes.info,
        30: classes.warning,
        40: classes.error,
    };
    const level_names = {
        10: 'DEBUG',
        20: 'INFO',
        30: 'WARNING',
        40: 'ERROR',
        50: 'CRITICAL',
    };
    const now = (new Date()).getTime() / 1000;
    let last_date_header = null;
    let log_formatted = [];
    dataset.map((lr, index) => {
        const level_class = level_map[lr.level] || level_map[30];
        const [date, time] = format_time(lr.time, now);
        if (date != last_date_header) {
            last_date_header = date;
            log_formatted.push(<div className={classes.dateHeader}>
                {date}
            </div>);
        }
        const mouseOver = (evt) => {
            setAnchorEl(evt.target);
            setTooltip(`${date} ${time}: ${lr.resource_id.title}: ${level_names[lr.level]}: ${lr.message}`);
        };
        log_formatted.push(<div className={classNames(classes.timeStamp, level_class)} key={`r-${index}-patch`} onMouseOver={mouseOver}>{time}</div>);
        log_formatted.push(<div className={classNames(classes.nodeName, level_class)} key={`r-${index}-resource`} onMouseOver={mouseOver}>{lr.resource_id.title}</div>)
        log_formatted.push(<div className={classNames(classes.message, level_class)} key={`r-${index}-message`} onMouseOver={mouseOver}>{lr.message}</div>);
    });
    let tooltip_render = null;
    if (tooltip) {
        tooltip_render = tooltip;
    }

    return <div className={classes.nodeSummary} style={{ width: width, height: height }}>
        <Tooltip title={tooltip_render} followCursor={true} PopperProps={{
            popperRef,
            anchorEl,
        }}>
            <div className={classes.logRecords}>
                {log_formatted}
            </div>
        </Tooltip>
    </div>;
};
const enable_recent_logs = () => {
    register_graph('log.recent', RecentLogs);
};

export default RecentLogs;
export { enable_recent_logs };