import React from 'react';
import BaseApp from 'dash/BaseApp';
import { APP_ROUTES, PREFIX } from './appconfig';
import { GroupStorageProvider } from 'withgrouphierarchy';
import { FocusProvider } from 'dash/focusprovider';

const ShogunRouteWrapper = (props) => {
    return <div
        {...props}
    >
        <FocusProvider>
            <GroupStorageProvider>
                {props.children}
            </GroupStorageProvider>
        </FocusProvider>
    </div>;
};

const App = BaseApp({
    product_name: 'UCrypt Monitor',
    routes: APP_ROUTES,
    base_url: PREFIX,
    login_url: '/accounts/login',
    login_redirect: `${PREFIX}groups/`,
}, ShogunRouteWrapper);
App.install('app');
