import React from 'react';

import { grid_rows } from 'puregrid';
import { ErrorListFactory } from 'errorlist';
import classNames from 'classnames';

class ReactFormFieldRender extends React.Component {
    render() {
        const { widget_inst, field } = this.props;
        const hasErrors = field.errors && field.errors.length;
        var field_body = (
            <div className={ classNames('field-body', {'error': hasErrors})}>
                {widget_inst}
                {hasErrors
                    ? ErrorListFactory(field)
                    : null}
            </div>
        );

        if (this.props.show_label) {
            if (widget_inst && widget_inst.props && widget_inst.props.wide) {
                field_body = (
                    <div className="wide-field">
                        <div>
                            <label htmlFor={field.label_id}>
                                {field.label}
                            </label>
                        </div>

                        <div className="wide-field-body-holder">
                            {field_body}
                        </div>
                    </div>
                );
            } else {
                var grid = [field_body];

                field_body = grid_rows([grid], [24, 24], [24, 24]);
            }
        }

        return field_body;
    }
}

export default ReactFormFieldRender;
