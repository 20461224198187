import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import MuiLoader from 'dash/MuiLoader';
import PropertyTable from 'dash/PropertyTable';
import WithCurrentUser from 'withcurrentuser';

import { requiring_system_store } from 'systemstorage';
const styles = theme => ({});

export class FirmwareTable extends React.Component {
    get rows() {
        const { system, user } = this.props;

        const rows = [
            {
                key: 'SKU',
                value: system.sku.sku_name
            },
            {
                key: 'Current Firmware',
                value: system.sku.release
            }
        ];
        if (!user || !user.has_permission('config.scary_debugging')) {
            return rows;
        }
        return [
            ...rows,
            {
                key: 'Commit',
                value: system.firmware.commit
            },
            {
                key: 'Branch',
                value: system.firmware.branch
            }
        ];
    }

    render() {
        const { system } = this.props;
        if (!system) {
            return <MuiLoader />;
        }
        return <PropertyTable rows={this.rows} />;
    }
}

export default requiring_system_store(WithCurrentUser(withStyles(styles)(FirmwareTable)));
