import React, { useEffect } from 'react';
import apply_accessor from './accessor';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    legend: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '8rem',
        padding: '.5rem',
        backgroundColor: 'white',
        color: 'black',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    legendItem: {
        display: 'flex',
        flexDirection: 'row',
        height: `1.25rem`,
        fontSize: '.75rem',
    },
    legendBox: {
        width: '1rem',
        height: '1rem',
        border: 'thin solid #6a6a6a',
    },
    legendTitle: {
        paddingLeft: '.25rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#6a6a6a',
    },
    highlighted: {
        '& .text': {
            color: 'black',
        },
        '& .color-box': {
            border: 'thin solid black',
        }
    },

}));


const Legend = (props) => {
    /* Render an HTML legend */
    const classes = useStyles();
    const { legends, highlight, setHighlight, style, on_click = null } = props;
    const highlighted = (key) => (highlight != null) ? (highlight == key ? true : false) : true;

    return <div className={classes.legend} style={style} key={`legend`} >
        {legends.map((record, i) => {
            const key = record.__key__ || i;
            return <div
                key={`legend-${i}`}
                className={classNames(classes.legendItem, (highlight != null && highlighted(key)) ? classes.highlighted : null)}
                onMouseOver={() => setHighlight(key)}
                onMouseOut={() => setHighlight(null)}
                onClick={(evt) => {
                    if (on_click) {
                        on_click(record);
                    }
                }}

            >
                <div className={classNames(classes.legendBox, 'color-box')} style={{ backgroundColor: record.color }}></div>
                <div className={classNames(classes.legendTitle, 'text')}>{record.title || record.label}</div>
            </div>;
        })}
    </div>;
};

export default Legend;