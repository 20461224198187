import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    padding: 5,
    opacity: 1
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    marginBottom: '10px'
  },
  popper: {
    marginTop: '5px',
    background: '#fff'
  },
  Button: {
    marginBottom: '10px'
  }
}));

export const customTimeRangeFilter = (rows, columnIds, filterValue) => {
  const [startTime, endTime] = filterValue

  return rows.filter((row, index) => {
    const rowTime = row.original[0].time;
    return rowTime >= startTime && rowTime <= endTime;
  });
};

export function DateAndTimePickers({ setFilter, handleClosePopper, isFilterApplied, setFilterApplied }) {

  const today = new Date();
  const lastWeekDate = getRequiredDate(7);
  const previousDayDate = getRequiredDate(1);
  const previousHour = getRequiredDate(0);
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [previousDayChecked, setPreviousDayChecked] = useState(false);
  const [lastHourChecked, setLastHourChecked] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState({
    FromDate: null,
    ToDate: null

  })

  function getCurrentDateTimeString(now) {
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  function getRequiredDate(days) {
    const now = new Date();

    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - days,
      days === 0 && now.getHours() - 1,
      days === 0 && now.getMinutes(),
    );
  }

  const formLabel = [
    {
      id: 1,
      name: 'FromDate',
      label: 'From Date'
    },
    {
      id: 2,
      name: 'ToDate',
      label: 'To Date'
    }
  ]

  const formCheckBoxes = [
    {
      id: 1,
      label: "Last 7 days",
    },
    {
      id: 2,
      label: "Previous Day"
    },
    {
      id: 3,
      label: "Last 1 hour"
    }
  ]

  const handleLastWeekFilter = (event) => {
    setChecked(event.target.checked);
    if (checked) {
      setDatePickerValue({ ...datePickerValue, FromDate: null, ToDate: null })
    } else {
      setDatePickerValue({
        ...datePickerValue,
        FromDate: getCurrentDateTimeString(lastWeekDate),
        ToDate: getCurrentDateTimeString(today)
      })
    }
  };

  const handlePreviousDayFilter = (event) => {
    setPreviousDayChecked(event.target.checked);
    if (previousDayChecked) {
      setDatePickerValue({ ...datePickerValue, FromDate: null, ToDate: null })
    } else {
      setDatePickerValue({
        ...datePickerValue,
        FromDate: getCurrentDateTimeString(previousDayDate),
        ToDate: getCurrentDateTimeString(today)
      })

    }
  }

  const handlePreviousHourFilter = (event) => {
    setLastHourChecked(event.target.checked);
    if (lastHourChecked) {
      setDatePickerValue({ ...datePickerValue, FromDate: null, ToDate: null })
    } else {
      setDatePickerValue({
        ...datePickerValue,
        FromDate: getCurrentDateTimeString(previousHour),
        ToDate: getCurrentDateTimeString(today) 
      })
    }
  }

  const handleDateSelection = (e) => {
    const { name, value } = e.target;
    setDatePickerValue({ ...datePickerValue, [name]: value });
  }

  //for preventing user to perform keybord action in text field
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const handleAppyFilter = () => {
    setFilter([Date.parse(datePickerValue.FromDate) / 1000, Date.parse(datePickerValue.ToDate) / 1000])
    setFilterApplied(true);
    handleClosePopper();
  }

  const handleReset = () => {
    setFilter();
    setFilterApplied(false);
  }

  return (
    <>
      <form className={classes.container} noValidate>
        {formLabel.map((field) => (
          <TextField
            key={field.id}
            id="datetime-local"
            name={field.name}
            label={field.label}
            type="datetime-local"
            defaultValue={null}
            onChange={handleDateSelection}
            className={classes.textField}
            disabled = {
              isFilterApplied ||
              lastHourChecked ||
              previousDayChecked ||
              checked ||
              field.label === 'To Date' && 
              datePickerValue.FromDate === null
            }
            onKeyDown={onKeyDown}
            inputProps={{
              min: !checked &&
                !previousDayChecked &&
                !lastHourChecked &&
                field.label === 'To Date' ? datePickerValue.FromDate : null,
              max: getCurrentDateTimeString(today)
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ))}

        {formCheckBoxes.map((checkbox) => (
          <FormControlLabel
            key={checkbox.id}
            control={
              <Checkbox
                checked={
                  checkbox.label === "Last 7 days" && checked || 
                  checkbox.label === "Previous Day" && previousDayChecked ||
                  checkbox.label === "Last 1 hour" && lastHourChecked
                }
                onChange={
                  checkbox.label === "Last 7 days" && handleLastWeekFilter ||
                  checkbox.label === "Previous Day" && handlePreviousDayFilter ||
                  checkbox.label === "Last 1 hour" && handlePreviousHourFilter
                }
                inputProps={{ 'aria-label': 'primary checkbox' }}
                disabled={
                  isFilterApplied ||
                   (
                    checkbox.label === "Last 7 days" && (previousDayChecked || lastHourChecked) ||
                    (checkbox.label === "Previous Day" && (checked || lastHourChecked)) ||
                     (checkbox.label === "Last 1 hour" && (checked || previousDayChecked))
                    )
                  }
              />
            }
            label={checkbox.label}
          />
        ))}

        <Button 
          className={classes.Button}
          size="small"
          variant="contained" 
          color="primary" 
          disabled={datePickerValue.FromDate === null || datePickerValue.ToDate === null}
          onClick={() => handleAppyFilter()}
        >
          Apply
        </Button>

        <Button
          className={classes.Button}
          size="small"
          variant="contained" 
          color="primary" 
          onClick={() => handleReset()} 
          disabled={!isFilterApplied}
        >
          Reset
        </Button>

      </form>
    </>
  );
}
