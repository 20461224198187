import React from 'react';
import { stores } from 'storeregistry';
import { BaseStorage } from 'storages';
import { url_for_key } from 'storages/basestorage';
import MuiLoader from 'dash/MuiLoader';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Badge from '@material-ui/core/Badge';
import theme from 'dash/theme';
import ATXTable from 'atxtable';
import {
    XYPlot,
    HorizontalGridLines,
    VerticalGridLines,
    LineSeries,
    XAxis,
    YAxis,
    MarkSeries
} from 'react-vis';
import RedBlackChart from 'dash/charts/redblack';
import Guages from './charts/guages';
import Counters from './charts/counters';
import Grid from '@material-ui/core/Grid';
import EditingCard from 'dash/editingcard';


const CableCardPairingStorage = (props) => {
    const store = BaseStorage({
        key: null, // not shared
        url: `${url_for_key('shogunconf_forms')}/CableCardPairing/`,
        period: 10,
        CableCardPairing: null,
        debug: true,
        ...props,
    });
    store.poll();
    return store;
};


const CableCardChartsStorage = (props) => {
    const store = BaseStorage({
        key: null, // not shared
        url: `${url_for_key('shogunconf_forms')}/CableCardCharts/`,
        period: 10,
        CableCardMap: {},
        CableCardCharts: [],
        debug: true,
        merge: (new_response, storage) => {
            //storage.CableCardCharts = [...storage.CableCardCharts || [], new_response.CableCardCharts];
            // Grab the lastest 10 elements
            storage.CableCardCharts = storage.CableCardCharts.concat(new_response.CableCardCharts).slice(-300);
            storage.CableCardMap = new_response.CableCardMap;
            storage.ts = new_response.ts;
            //console.log("MERGE", storage.CableCardCharts);
            //console.log("MERGE", storage.CableCardMap);
        },
        ...props,
    });
    store.poll();
    return store;
};

class CableCardCharts extends React.Component {
    componentWillMount() {
        /* There's no point requesting if we have no storage property */
        const storage = CableCardChartsStorage({ default_context: this.props.storage_context });
        this.setState({ 'storage': storage });
        storage.change.listen(this.on_state_update);
        storage.poll();
    }
    componentWillUnmount() {
        this.state.storage.periodic && this.state.storage.periodic.stop();
    }
    on_state_update = (storage) => {
        const { CableCardCharts } = storage;
        this.setState({
            'CableCardCharts': CableCardCharts,
        });
    };
    render() {
        const data = this.state.storage;

        if (!data.CableCardCharts || !data.CableCardCharts.length)
            return null;

        const rid = 1, lock = 7, symbol_rate = 5, snr = 8, fec_error_count = 10, pkt_count = 11;
        let packetData = [], fecData = [];
        let pcrLock = [], signalLevel = [], snrData = [], symbolRateData = [];
        if (data.CableCardCharts) {
            let m = data.CableCardMap;
            data.CableCardCharts.forEach(r => {
                let id = r[rid];
                let ts = Math.round(r[0] * 1000);

                packetData.push(r[pkt_count]);
                fecData.push(r[fec_error_count]);

                if (!pcrLock[id])
                    pcrLock[id] = { label: id, data: [] };
                pcrLock[id].data.push({ x: 1, y: m[id].slot, color: r[lock] ? "black" : "red" });

                if (!signalLevel[id])
                    signalLevel[id] = { color: '#ccc', label: [m[id].name, m[id].slot,].join(' '), data: [] };
                signalLevel[id].data.push({ x: ts, y: r[pkt_count] });

                if (!snrData[id])
                    snrData[id] = { color: '#ccc', label: [m[id].name, m[id].slot,].join(' '), data: [] };
                snrData[id].data.push({ x: ts, y: r[snr] });

                if (!symbolRateData[id])
                    symbolRateData[id] = { color: '#ccc', label: [m[id].name, m[id].slot,].join(' '), data: [] };
                symbolRateData[id].data.push({ x: ts, y: r[symbol_rate] });
            });
        }

        return <EditingCard title="Charts" collapsing='group-charts'>
            <Grid container spacing={3}>
                <Grid item xs>
                    <p>PCR Lock (on/off)</p>
                    <RedBlackChart data={pcrLock} />
                </Grid>
                <Grid item xs>
                    <p>Signal / Noise Ratio (guage)</p>
                    <Guages
                        data={snrData}
                        withZoomableModal="true"
                        title="Signal / Noise Ratio"
                        chartProps={{
                            yPadding: 80,
                            yDomain: [-60, 60]
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <p>Symbol Rate (guage)</p>
                    <Guages data={symbolRateData} withZoomableModal="true" title="Symbol Rate" chartProps={{ yPadding: 70 }} />
                </Grid>
                <Grid item xs>
                    <p>Signal Level (guage)</p>
                    <Guages data={signalLevel} withZoomableModal="true" title="Signal Level" chartProps={{ yPadding: 90 }} />
                </Grid>
                <Grid item xs>
                    <p>FEC Count (counter)</p>
                    <Counters data={fecData} />
                </Grid>
                <Grid item xs>
                    <p>Packet Count (counter)</p>
                    <Counters data={packetData} />
                </Grid>
            </Grid>
        </EditingCard>;
        return <MuiLoader />;
    }
}

class CableCardPairingTable extends React.Component {
    valid = true;
    componentWillMount() {
        const storage = CableCardPairingStorage({ default_context: this.props.storage_context });
        this.setState({ 'storage': storage });
        storage.change.listen(this.on_state_update);
        storage.poll();
    }
    componentWillUnmount() {
        this.valid = false;
        this.state.storage.periodic && this.state.storage.periodic.stop();
    }
    on_state_update = (storage) => {
        const { CableCardPairing } = storage;
        if (this.valid) {
            this.setState({
                'CableCardPairing': CableCardPairing,
            });
        }
    };
    render() {
        const { CableCardPairing } = this.state;
        if (CableCardPairing) {
            return <ATXTable
                note="ABC123"
                data={CableCardPairing}
                columns={[
                    {
                        'Header': 'Device',
                        'accessor': 'node__name',
                    },
                    {
                        'Header': 'CC Name',
                        'accessor': 'name',
                    },
                    {
                        'Header': 'Slot',
                        'accessor': 'slot_name',
                    },
                    {
                        'Header': 'CableCARD ID',
                        'accessor': 'CableCARD ID',
                    },
                    {
                        'Header': 'MAC Address',
                        'id': 'MAC Address',
                        'accessor': (record) => record['MAC Address'],
                    },
                    {
                        'Header': 'Hub ID',
                        'accessor': 'HUB ID',
                    },
                    {
                        'Header': 'Serial Number',
                        'accessor': 'Serial Number',
                    },
                    {
                        'Header': 'Host ID',
                        'accessor': 'Host ID',
                    },
                    {
                        'Header': 'Firmware Version',
                        'accessor': 'Firmware Version',
                    },
                    {
                        'Header': 'OOB Lock',
                        'accessor': 'OOB',
                    },
                    {
                        'Header': 'Authorization',
                        'accessor': 'Authorization',
                    },
                ]}
            />;
        } else {
            return <MuiLoader />;
        }
    }
}

export default CableCardPairingTable;
export { CableCardPairingTable, CableCardCharts, CableCardPairingStorage };
