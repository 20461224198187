import React from 'react';
import { ResolveTarget } from 'dash/resolvetarget';
import { basic_focus_title, generic_pagefocus_link_url, service_redirect_for_group } from './helpers';
import DetailEditor from 'shogun/detaileditor';
import TriggerEditor from 'shogun/triggereditor';
import AlertingView from 'shogun/alertingview';
import { StandaloneMetricTable } from 'metrics/metrictable';
import BaseDashboard from 'shogun/basedashboard';

const alerting_routes = ({ PREFIX }) => ([
    {
        'path': `${PREFIX}alerting/:group(\\d+)`,
        'link_url': generic_pagefocus_link_url,
        'header': 'Notifications',
        'title': 'Notifications',
        'hideInNav': false,
        'exact': true,
        'component': AlertingView,
        'renderer': (props) => {
            const { match } = props;
            const group = parseInt(match.params.group);
            return <ResolveTarget __type__='Group' __pk__={group} storage='shogunconf_forms' as_focus={true} key={`alerting-${group}`}>
                <AlertingView />
            </ResolveTarget>;
        },
    },
    {
        'path': `${PREFIX}events/:group(\\d+)`,
        'link_url': generic_pagefocus_link_url,
        'header': 'Alerts',
        'title': 'Alerts',
        'component': BaseDashboard,
        'renderer': (props) => {
            const { match } = props;
            const group = parseInt(match.params.group);
            const definition = {
                "label": "Events",
                "slug": "events",
                "blocks": [
                    {
                        "__type__": "table",
                        "key": "metric_event.table",
                        "metric": "metric_event",
                        "card": true,
                        "card_title": "Alerts",
                        "query_type": "stats",
                        "resource_specific": true,
                        "stream_specific": false,
                        "default_view_settings": {
                            "hidden_fields": [],
                            "expanded": true
                        },
                        "widgets": {}
                    }
                ]
            };
            return <ResolveTarget __type__='Group' __pk__={group} storage='shogunconf_forms' as_focus={true} key={`logs-${group}`}>
                <BaseDashboard
                    __type__='Group' __pk__={group} storage='shogunconf_forms'
                    label='Events'
                    role='events'
                    dashboard={{ definition: definition }}
                />
            </ResolveTarget>;
        }
    },
    {
        'path': `${PREFIX}alerting/:group(\\d+)/notificationgroup/:notification(\\d+)`,
        'link_url': generic_pagefocus_link_url,
        'header': 'Notification Group',
        'title': 'Notification Group',
        'hideInNav': true,
        'exact': true,
        'component': DetailEditor,
        'renderer': (props) => {
            const { match } = props;
            const { group } = parseInt(match.params.group);
            const pk = parseInt(match.params.notification);
            return <ResolveTarget __type__='Group' __pk__={group} storage='shogunconf_forms' as_focus={true} key={`alerting-notification-${group}`}>
                <DetailEditor
                    key={`detail-NotificationGroup`}
                    __type__={'NotificationGroup'}
                    __pk__={pk}
                    storage='shogunconf_forms'
                />
            </ResolveTarget>;
        }
    },
    {
        'path': `${PREFIX}alerting/:group(\\d+)/trigger/:trigger(\\d+)`,
        'link_url': generic_pagefocus_link_url,
        'permission': 'shogunconf.edit_triggers',
        'header': 'Trigger',
        'title': 'Trigger Script',
        'hideInNav': true,
        'exact': true,
        'component': DetailEditor,
        'renderer': (props) => {
            const { match } = props;
            const { group } = parseInt(match.params.group);
            const pk = parseInt(match.params.trigger);
            return <ResolveTarget __type__='Group' __pk__={group} storage='shogunconf_forms' as_focus={true} key={`alerting-trigger-${group}-${pk}`}>
                <TriggerEditor
                    key={`edit-Trigger-${pk}`}
                    __pk__={pk}
                />
            </ResolveTarget>;
        }
    },
    {
        'path': `${PREFIX}alerting/:group(\\d+)/metric/:metric([a-zA-Z0-9._-]+)`,
        'link_url': generic_pagefocus_link_url,
        'header': 'Metric Table',
        'title': 'Table View',
        'hideInNav': true,
        'exact': true,
        'component': StandaloneMetricTable,
        'renderer': (props) => {
            const { match } = props;
            const { group } = parseInt(match.params.group);
            const name = match.params.metric;
            return <ResolveTarget __type__='Group' __pk__={group} storage='shogunconf_forms' as_focus={true} key={`alerting-metric-${name}`}>
                <StandaloneMetricTable
                    key={`metric-table`}
                    table={{
                        'metric': name,
                    }}
                />
            </ResolveTarget>;
        },
    },
]);

export default alerting_routes;
