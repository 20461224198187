import React from 'react';
import { Redirect } from 'react-router-dom';
import { ResolveTarget } from 'dash/resolvetarget';
import { basic_focus_title, generic_pagefocus_link_url, service_redirect_for_group } from './helpers';
import ServiceGroupView from 'shogun/servicegroupview';
import ServiceGroupsView from 'shogun/servicegroupsview';
import { GroupServiceGroupsView } from 'shogun/servicegrouptable';
import DetailEditor from 'shogun/detaileditor';
import FirmwareDeployEditor from 'shogun/firmwaredeployview';
import { AnalogMappingView } from 'shogun/analogmappingview';
import RFInputMappingView from 'shogun/rfinputview';
import ServiceLookup from 'shogun/servicelookup';
import EPGGenMappingView from 'shogun/epggenmappingview';
import IPOutputMappingView from 'shogun/ipoutputmappingview';
import URIMappingView from 'shogun/urimappingview';
import SRTMappingView from 'shogun/srtmappingview';
import { ListView } from 'shogun/listview';
import CacheServiceView from 'shogun/cacheserviceview';
import IPGClientView from 'shogun/ipgclientview';
import ServicesTable from 'shogun/servicetable';
import EditingCard from 'dash/editingcard';

const services_routes = ({ PREFIX }) => ([
    {
        'path': `${PREFIX}servicegroup/:servicegroup(\\d+)`,
        'header': 'Redirect',
        'hideInNav': true,
        'exact': true,
        'component': Redirect,
        'renderer': (props) => {
            const { match } = props;
            const focus = PAGE_FOCUS.get_focus();
            const group_focus = (focus && focus.__pk__) || 0;
            return <Redirect to={`${PREFIX}group-services/${group_focus}/servicegroup/${match.params.servicegroup}`} />;
        },
    },
    {
        'path': `${PREFIX}group-services/:group(\\d+)`,
        'link_url': generic_pagefocus_link_url,
        'exact': true,
        'component': ServiceGroupsView,
        'header': 'Configs',
        'title': 'Configs',
        'renderer': props => {
            let group = parseInt(props.match.params.group);
            if (group) {
                return <ResolveTarget key={`group-services-${group}`} as_focus={true} storage='shogunconf_forms' __type__='Group' __pk__={group}>
                    <GroupServiceGroupsView
                        key={`group-services-${group}`}
                        collapsing='group-services'
                    />
                </ResolveTarget>;
            } else {
                return <ResolveTarget key={`group-services-root`} as_focus={true} storage='shogunconf_forms' __type__='Group' __pk__={0}>
                    <ServiceGroupsView key={`group-services-root`} />
                </ResolveTarget>;
            }
        },
        'routes': [
            service_redirect_for_group({
                'path': `${PREFIX}group-services/:group(\\d+)?/servicegroup/:service(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                hideInNav: true,
                'header': 'Config Package',
                'title': 'Config Package',
                'component': ServiceGroupView,
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'ServiceGroup', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'renderer': (props) => {
                    const { match } = props;
                    const group = parseInt(match.params.group);
                    const service = parseInt(match.params.service);
                    return <ServiceGroupView __type__='ServiceGroup' __pk__={service} storage='shogunconf_forms' />;
                }
            }),
            service_redirect_for_group({
                'path': `${PREFIX}group-services/:group(\\d+)?/bridge/:service(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                hideInNav: true,
                'header': 'Concentrator',
                'title': 'Concentrator',
                'component': DetailEditor,
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'Bridge', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'renderer': (props) => {
                    const { match } = props;
                    const group = parseInt(match.params.group);
                    const service = parseInt(match.params.service);
                    return <DetailEditor __type__='Bridge' __pk__={service} />;
                }
            }),
            service_redirect_for_group({
                'path': `${PREFIX}group-services/:group(\\d+)?/ipg/:service(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                hideInNav: true,
                'header': 'VSBB Config',
                'title': 'VSBB Config',
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'IPGClient', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'component': IPGClientView,
                // 'renderer': (props) => {

                //     const { match } = props;
                //     const group = parseInt(match.params.group);
                //     const service = parseInt(match.params.service);
                //     return <DetailEditor __type__='IPGClient' __pk__={service} />;
                // }
            }),
            service_redirect_for_group({
                'path': `${PREFIX}group-services/:group(\\d+)?/ipgtheme/:service(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                hideInNav: true,
                'header': 'VSBB Theme',
                'title': 'VSBB Theme',
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'IPGTheme', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'component': DetailEditor,
                // 'renderer': (props) => {

                //     const { match } = props;
                //     const group = parseInt(match.params.group);
                //     const service = parseInt(match.params.service);
                //     return <DetailEditor __type__='IPGClient' __pk__={service} />;
                // }
            }),
            service_redirect_for_group({
                'path': `${PREFIX}group-services/:group(\\d+)?/monitor/:service(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                hideInNav: true,
                'header': 'Monitor',
                'title': 'Monitor',
                'component': DetailEditor,
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'Monitor', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'renderer': (props) => {
                    const { match } = props;
                    const group = parseInt(match.params.group);
                    const service = parseInt(match.params.service);
                    return <DetailEditor storage='shogunconf_forms' __type__='Monitor' __pk__={service} />;

                }
            }),
            service_redirect_for_group({
                'path': `${PREFIX}group-services/:group(\\d+)?/firmwaredeploy/:service(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                'hideInNav': true,
                'header': 'Firmware Requirements',
                'title': 'Firmware Requirements',
                'component': FirmwareDeployEditor,
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'FirmwareDeploy', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'renderer': (props) => {
                    const { match } = props;
                    const group = parseInt(match.params.group);
                    const service = parseInt(match.params.service);
                    return <FirmwareDeployEditor __type__='FirmwareDeploy' __pk__={service} />;
                }
            }),
            service_redirect_for_group({
                'path': `${PREFIX}group-services/:group(\\d+)?/analog/:service(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                'header': 'Analog Output',
                'title': 'Analog Output',
                'hideInNav': true,
                'component': AnalogMappingView,
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'AnalogMapping', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'renderer': (props) => {
                    const { match } = props;
                    const group = parseInt(match.params.group);
                    const service = parseInt(match.params.service);
                    return <AnalogMappingView __type__='AnalogMapping' __pk__={service} />;
                }
            }),
            service_redirect_for_group({
                'path': `${PREFIX}group-services/:group(\\d+)?/qamsource/:service(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                'header': 'RF Sources',
                'title': 'RF Sources',
                'hideInNav': true,
                'component': RFInputMappingView,
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'RFInputMapping', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'renderer': (props) => {
                    const { match } = props;
                    const group = parseInt(match.params.group);
                    const service = parseInt(match.params.service);
                    return <RFInputMappingView __type__='RFInputMapping' __pk__={service} required={true} />;
                }
            }),
            service_redirect_for_group({
                'path': `${PREFIX}group-services/:group(\\d+)?/uri2ip/:service(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                'hideInNav': true,
                'header': 'Web Inputs',
                'title': 'Web Inputs',
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'URIMapping', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'component': URIMappingView,
                'renderer': (props) => {
                    const { match } = props;
                    const group = parseInt(match.params.group);
                    const service = parseInt(match.params.service);
                    return <URIMappingView __type__='URIMapping' __pk__={service} storage='shogunconf_forms' />;
                }
            }),
            service_redirect_for_group({
                'path': `${PREFIX}group-services/:group(\\d+)?/srt2ip/:service(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                'hideInNav': true,
                'header': 'SRT Inputs',
                'title': 'SRT Inputs',
                'component': SRTMappingView,
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'SRTInputMapping', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'renderer': (props) => {
                    const { match } = props;
                    const group = parseInt(match.params.group);
                    const service = parseInt(match.params.service || '0');
                    return <SRTMappingView __type__='SRTInputMapping' __pk__={service} storage='shogunconf_forms' />;
                }
            }),
            service_redirect_for_group({
                'path': `${PREFIX}group-services/:group(\\d+)?/cacheblade/:service(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                'hideInNav': true,
                'header': 'Streaming Cache',
                'title': 'Streaming Cache',
                'component': CacheServiceView,
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'CacheService', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'renderer': (props) => {
                    const { match } = props;
                    const group = parseInt(match.params.group);
                    const service = parseInt(match.params.service);
                    return <CacheServiceView __type__='CacheService' __pk__={service} storage='shogunconf_forms' />;
                }
            }),
            service_redirect_for_group({
                'path': `${PREFIX}group-services/:group(\\d+)?/epggen/:service(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                'header': 'Generators',
                hideInNav: true,
                'exact': true,
                'title': 'Electronic Program Guide Generators',
                'component': EPGGenMappingView,
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'EPGGenMapping', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'renderer': (props) => {
                    const { match } = props;
                    const group = parseInt(match.params.group);
                    const service = parseInt(match.params.service);
                    return <EPGGenMappingView __type__='EPGGenMapping' __pk__={service} storage='shogunconf_forms' />;
                },
            }),
            service_redirect_for_group({
                'path': `${PREFIX}group-services/:group(\\d+)?/ipoutput/:service(\\d+)`,
                'link_url': generic_pagefocus_link_url,
                'header': 'IP Outputs',
                hideInNav: true,
                'title': 'IP Outputs',
                'component': IPOutputMappingView,
                'resolve': [
                    { param: 'group', '__type__': 'Group', as_focus: true, 'storage': 'shogunconf_forms', },
                    { param: 'service', '__type__': 'IPOutputMapping', as_focus: false, 'storage': 'shogunconf_forms', },
                ],
                'renderer': props => {
                    const { match } = props;
                    const group = parseInt(match.params.group);
                    const service = parseInt(match.params.service || '0');
                    return <IPOutputMappingView __type__='IPOutputMapping' __pk__={service} storage='shogunconf_forms' />;
                },
            }),
        ]
    },
    {
        'path': `${PREFIX}services/by-service/:service(\\d+)/`,
        'link_url': `${PREFIX}services`,
        hideInNav: true,
        'header': 'Service Lookup',
        'title': basic_focus_title(
            (params) => `Service #${params.service}`,
        ),
        'component': ServiceLookup,
        'renderer': (props) => {
            const { match } = props;
            return <ServiceLookup lookup='by-service' id={match.params.service} storage='shogunconf_forms' />;
        },
    },
    {
        'path': `${PREFIX}services/by-type/:service_type?`,
        'link_url': `${PREFIX}services/by-type/`,
        hideInNav: true,
        'header': 'Services',
        'title': 'Services',
        'component': ServicesTable,
        'renderer': (props) => {
            const { match } = props;
            const typ = props.match.params.service_type;
            const context = typ ? { 'service_type_key': typ } : {};
            return <EditingCard key={`service-list-${typ || 'all'}`} title="Defined Services" no_wrapper={true} ><ServicesTable
                storage_props={{
                    default_context: context,
                    forms_key: 'shogunconf_forms',
                    form_key: 'ShogunServices',
                    form_keys: ['ServiceDisplay'],
                    quick_settings: {
                    },
                }} exclude={['notes']}
                defaultSorting={[
                    { 'id': 'service_type_key', 'desc': false },
                    { 'id': 'name', 'desc': false },
                ]}
            /></EditingCard>;
        },

    },
]);

export default services_routes;
