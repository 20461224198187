/* Dashboard definition to create a particular dashboard */
import React from 'react';
import { WithMetrics, WithLoadedMetrics } from 'metrics/withmetrics';
import { withStyles } from '@material-ui/core/styles';
import MetricGraph from 'metrics/metricgraph';
import { StandaloneMetricTable } from 'metrics/metrictable';
import classNames from 'classnames';
import EditingCard from 'dash/editingcard';
import StatBlock from 'metrics/statblock';
import { useMetricFilters, filterHash, FilterContext } from 'storages/usemetricfilters';
import FilterSet from 'shogun/queryparameditor';

// import defaultDashboardProps from './defaultdashboard';

const styles = (theme) => ({
    dashWrapper: {
        backgroundColor: theme.palette.grey[100],
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    tableWrapper: {
        padding: theme.spacing(.25),
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column',
    },
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    columnWrapper: {
        display: 'flex',
        flexDirection: 'row',
        '&> *': {
            flex: '1',
        }
    },
    leftColumn: {
        flex: 1,
        backgroundColor: 'white',
    },
    rightColumn: {
        flex: 1,
        backgroundColor: 'white',
    },
    grid: {
        display: 'grid',
        columnGap: '2px',

    }
});
const render_table = (props, index) => {
    const { classes, ...restProps } = props;
    const { table } = props;
    const { card = false, card_title = null } = table;
    const base = <StandaloneMetricTable {...restProps} preference_key={`dash-table-${table.key}`} />;
    if (card) {
        return <EditingCard title={card_title} key={`table-${index}`} style={table.style} no_wrapper={true} collapsing={`dash-table-card-${table.key || index}`}>
            {base}
        </EditingCard>;
    } else {
        return <div className={classes.tableWrapper} key={`table-${index}`} style={table.style}>
            {base}
        </div>;
    }
};
render_table.displayName = 'render_table';
const render_statblock = (props, index) => {
    return <StatBlock {...props} key={`statblock-${index}`} />;
};
render_statblock.displayName = 'statblock';

const render_hbox = (props, index) => {
    const { block_renderer, classes, hbox } = props;
    const { children } = hbox;
    return <div className={classes.hbox} key={`hbox-${index}`} style={hbox.style}>{children && children.map(block_renderer)}</div>;
};
render_hbox.displayName = 'render_hbox';
const render_vbox = (props, index) => {
    const { block_renderer, classes, vbox } = props;
    const { children } = vbox;
    return <div className={classes.vbox} key={`vbox-${index}`} style={vbox.style}>{children && children.map(block_renderer)}</div>;
};
render_vbox.displayName = 'render_vbox';
const render_columns = (props, index) => {
    const { block_renderer, classes, columns } = props;
    const { left, right } = columns;
    return <div className={classes.columnWrapper} key={`col-${index}`} style={columns.style}>
        <div className={classes.leftColumn} key='left'>{left && left.map(block_renderer)}</div>
        <div className={classes.rightColumn} key='right'>{right && right.map(block_renderer)}</div>
    </div>;
};
render_columns.displayName = 'render_columns';
const render_graph = (props, index) => {
    const { classes, ...restProps } = props;
    return <MetricGraph {...restProps} />;
};
render_graph.displayName = 'render_graph';

const render_grid = (props, index) => {
    /* Render a grid with each children rendered into a div with size spec'd */
    const { block_renderer, classes, grid } = props;
    const { children } = grid;
    const children_renders = children.map((child, index) => {
        return <div key={`grid-child-${index}`} style={{ gridColumn: `span ${child.colSpan || 1}`, gridRow: `span ${child.rowSpan || 1}` }}>
            {block_renderer(child)}
        </div>;
    });

    return <div key={`grid-${index}`} style={{ display: 'grid', gridTemplateColumns: `repeat(${grid.columns || 4}, 1fr)` }}>
        {children_renders}
    </div>;
};
render_grid.displayName = 'render_grid';

const defaultImplementations = {
    hbox: render_hbox,
    vbox: render_vbox,
    grid: render_grid,
    columns: render_columns,
    
    graph: render_graph,
    table: render_table,
    statblock: render_statblock,
};

const block_renderer = (implementations, classes) => {
    /* Create a function that looks up implementations */
    let block_implementation;
    block_implementation = (block, index) => {
        const Implementation = implementations[block.__type__];
        if (Implementation) {
            const extraProps = {};
            extraProps[block.__type__] = block; /* chart: chart, table: table, hbox: hbox */
            return <Implementation
                {...extraProps}
                block_renderer={block_implementation}
                classes={classes}
                key={`${block.__type__}-${index}`}
            />;
        } else {
            return null;
        }
    };
    return block_implementation;
};

const RenderBlocks = (blocks, render_block, classes) => {
    /* Render set of blocks and return */
    return <React.Fragment>{blocks.map(render_block)}</React.Fragment>;
};
RenderBlocks.displayName = 'RenderBlocks';


const BaseDashboard = (props) => {
    /* Render a basic dashboard into a window */
    const { label, role, dashboard, classes, implementations = defaultImplementations } = props;
    const filter_key = dashboard.definition.filter_context || dashboard.slug;
    /* TODO: Should memoize the render_block function */    
    const render_block = block_renderer(implementations, classes);
    return <FilterContext filter_key={`${filter_key}`} defaults={dashboard.definition.filter_defaults || {}}>
        <FilterSet />
        <div className={classNames(classes.dashWrapper, `dashboard-role-${role}`, `dashboard-${dashboard.id}`)}>
            {RenderBlocks(dashboard.definition.blocks, render_block, classes)}
        </div>
    </FilterContext>;
};
BaseDashboard.displayName = 'BaseDashboard';
export default withStyles(styles)(BaseDashboard);
