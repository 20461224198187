import React from 'react';
import {providing_nearest_form, using_nearest_form} from 'formprovider';
import with_resolve_focus from 'dash/resolvefocus';
import {ReactForm} from 'reactform';
import { with_focus } from 'dash/focusprovider';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import {EditIntent} from 'editintent';
import {RFSourceMapTable,mapping_specific_storage} from './rfsourcetable';
import FocusLink from 'dash/focuslink';
import classNames from 'classnames';
import {SelectingATXTable} from 'atxtable';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MuiTypography from 'dash/MuiTypography';
import { IconButton } from '@material-ui/core';

class SourceTable extends React.Component {
    static defaultProps = {
        'instance': null,
        'selected': [],
        'focus': null,
    }
    componentWillMount() {
        const columns = [
            {
                'Header': 'MSO Name',
                'accessor': 'name',
            },
            {
                'Header': 'Source ID',
                'accessor': 'source_id',
            },
            {
                'Header': 'Source Name',
                'accessor': 'source_name',
            },
            {
                'Header': 'Source Channel',
                'accessor': 'source_channel_number',
            },
            {
                'Header': 'Frequency',
                'accessor': 'source_frequency',
            },
            {
                'Header': 'Program Number',
                'accessor': 'source_program_number',
            },
        ];
        this.setState({
            'columns': columns,
        });
    }
    render() {
        const {sources} = this.props;
        return <SelectingATXTable 
            columns={this.state.columns}
            data={sources}
            on_change={this.props.on_change}
            getTrProps={(state, rowInfo, column, instance) => {
                const { classes, editable } = this.props;
                if (editable) {
                    return {
                        className: classNames(classes.clickableRow, 'clickable'),
                    };
                } else {
                    return {};
                }
            }}
        />;
    }
}

class BaseSourceImportView extends React.Component {
    state = {
        'node': null,
        'node_mapping': {}, // node id to sources available...
        'source_selections': {},
        'rfsources': [],
        'post_response': null,
    }
    active=true;
    load_sources = (node) => {
        /* Pull up a given sources-available set from our storage */
        this.props.storage.get_form('NodeSources', node).then(
            result => {
                if (!this.active) {
                    return;
                }
                const {success,sources,messages} = result;
                if (success) {
                    const new_mapping = {
                        ...this.state.node_mapping,
                    };
                    new_mapping[node] = sources;
                    this.setState({
                        'node_mapping': new_mapping,
                    });
                } else {
                    throw(result);
                }
            }
        ).catch(e => {
            console.log(`Failure loading sources`);
            window.setTimeout(()=>this.load_sources(node), 5000);
        });
    }
    componentWillUnmount() {
        this.active = false;
    }
    on_node_changed = (form, field, new_value) => {
        if (new_value != this.state.node) {
            this.setState({
                'node': new_value,
            });
            if (! this.state.node_mapping[new_value]) {
                this.load_sources(new_value);
            }
        }
    }
    create_node_form = () => {
        const instance = this.props.focus.get_focus().focus;
        if (this.state.node && !this.state.post_response) {
            return null;
        }
        if (this.state.post_response) {
            return <ReactForm
                target={instance} form_key='RFSourceChoice'
                form_details={this.state.post_response.form}
                form_actions={() => null}
                use_dialog={false}
                onChange={this.on_node_changed}
            />;
        }

        return <ReactForm
            target={instance} form_key='RFSourceChoice'
            form_actions={() => null}
            use_dialog={false}
            onChange={this.on_node_changed}
        />;
    }
    on_undo_node = () => {
        this.setState({
            'node': null,
            'source_selections': {},
        });
    }
    on_submit = () => {
        const mapping = this.mapping();
        const form_data = {
            'source_selections': JSON.stringify(Object.values(this.state.source_selections)),
        };
        this.props.storage.save_form('RFSourceChoice',mapping.id, form_data).then(
            response => {
                if (response && response.success) {
                    const mapping = this.mapping();
                    this.props.focus.set_focus(mapping,mapping.__url__);
                    // this.props.focus.navigate();
                } else {
                    this.setState({post_response: response});
                }
            }
        );
    }
    create_node_selector_toolbar = () => {
        const selection_summary = this.create_selection_summary();
        const count = this.count_selections() || null;
        return <Toolbar>
            <Button onClick={this.on_undo_node}><Icon>undo</Icon> Back</Button>
            {count && <Button onClick={this.on_submit} color="primary" variant="contained">Map Sources</Button>}
            <MuiTypography> {selection_summary} </MuiTypography>
        </Toolbar>;
    }
    get_current_sources = () => {
        const {node,node_mapping} = this.state;
        if (node && node_mapping[node]) {
            return node_mapping[node];
        } else {
            return null;
        }
    }
    create_node_selector = () => {
        const {node,node_mapping} = this.state;
        const sources = this.get_current_sources();
        if (sources) {
            return <SourceTable sources={sources} on_change={this.on_sources_selected} />;
        } else {
            return null;
        }
    }
    on_sources_selected = (selections) => {
        this.setState({
            'source_selections': selections,
        });
    }
    count_selections = () => {
        return Object.values(this.state.source_selections).length;
    }
    create_selection_summary = () => {
        if (this.state.source_selections) {
            const count = this.count_selections();
            return `${count} Source${count===1?'':'s'} Selected`;
        } else {
            return null;
        }
    }
    mapping = () => {
        return this.props.focus.get_focus().focus;
    }
    render() {
        const instance = this.mapping();
        const storage_props = mapping_specific_storage( instance );
        storage_props.debug = true;
        const node_form = this.create_node_form();
        const node_selector = this.create_node_selector();
        return <React.Fragment>
            {node_form && <EditingCard title={'Select Source Node'} >
                {node_form}
            </EditingCard>}
            {node_selector && <EditingCard title='Select Sources to Map' no_wrapper={true}>
                {this.create_node_selector_toolbar()}
                {node_selector}
            </EditingCard>}
        </React.Fragment>;
    }
}
const SourceImportView = providing_nearest_form(
    with_resolve_focus(
        with_focus(
            using_nearest_form(
                BaseSourceImportView
            )
        )
    ),
    'shogunconf_forms'
);

export default SourceImportView;
export {BaseSourceImportView, SourceImportView};