import React from 'react';

import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

import { ClassyWidget } from 'reactform/classywidget';
import construct_choices from 'reactform/construct_choices';
import dash_construct_choices from 'dash/construct_choices';

import WidgetRender from './custom_widget_render';
import { IconButton } from '@material-ui/core';
import { EditIntent } from 'editintent';
import getIcon from 'getIcon';
import { green } from '@material-ui/core/colors';

const styles = theme => ({
    formControl: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        // minWidth: '120px'
    },
    selectWidget: {
        width: 'inherit',
        height: 'inherit'
    },

    label: {
        whiteSpace: 'nowrap'
    },
    rowHolder: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        flex: 1,
    },
    creation: {
        maxWidth: 48,
        color: '#00ff77',
    },
    editing: {
        maxWidth: 48,
        color: '#007777',
    }
});

const apply_field_filter = (value, filter_by_field, choices) => {
    const { field, mapping } = filter_by_field;
    if ((!field) || (!mapping)) {
        return choices;
    }
    const to_show = mapping[value];
    if (!to_show) {
        return [];
    } else {
        let filt = {};
        to_show.map(k => { filt[k] = true });
        return choices.filter(rec => filt[rec[0]]);
    }
};

class SelectWidget extends ClassyWidget {
    filter_choices = (choices) => {
        if (!choices) {
            return [];
        }
        const filter_by_field = this.widget_data('filter_by_field');
        if (!filter_by_field) {
            return choices;
        }
        filter_by_field.map(fbf => {
            let value = this.props.form_current_value(fbf.field);
            choices = apply_field_filter(value, fbf, choices);
        })
        return choices;
    }
    render() {
        const { classes, show_label, native, autoFocus } = this.props;
        const field = this.get_field();
        const base_field = field.field;
        const creation = this.widget_data('creation');
        const editing = this.widget_data('editing');

        const value = this.render_value();
        var rvalue = (value === undefined || value === null) ? '' : `${value}`;
        let create_widget = null;
        let edit_widget = null;
        if (creation) {
            let { storage, ...creation_target } = creation;
            let extra_args = {};
            if (storage) {
                extra_args.storage = storage;
            }
            create_widget = <EditIntent
                target={creation_target}
                key='create'
                action='Create'
                title='Click to add a new record to the selections available'
                icon={getIcon('add')}
                className={'inline-create'}
                onSave={(result) => {
                    console.log("Saved, should refresh");
                }}
                {...extra_args}
            />;
            if (editing && value) {
                const editing_target = { ...creation_target, __pk__: value, id: value };
                const { buttons } = editing
                if (buttons) {
                    extra_args.buttons = buttons;
                }

                edit_widget = <EditIntent
                    target={{
                        ...editing,
                        title: 'Click to edit the currently-configured value',
                        __type__: creation.__type__,
                        __pk__: value,
                        id: value,
                    }}
                    key='edit'
                    action='Edit'
                    icon={getIcon('edit')}
                    className={'inline-edit'}
                    onSave={(result) => {
                        console.log("Changed, should refresh");
                    }}
                    {...extra_args}
                />;
            }
        }
        const make_choices = native ? construct_choices : dash_construct_choices;

        const choices = make_choices(this.filter_choices(base_field.choices || []));

        return <WidgetRender form_field={field} widget_class="select-widget" show_label={show_label}>
            <div className={classes.rowHolder}>
                <Select
                    classes={{ select: classes.selectWidget }}
                    displayEmpty={true}
                    value={rvalue}
                    name={field.name}
                    ref={this.controlRef}
                    fullWidth
                    inputProps={{
                        id: base_field.label_id
                    }}
                    onChange={event => this.handleChange(event.target.value)}
                    autoFocus={autoFocus}
                >
                    {choices}
                </Select>
                {edit_widget && <div className={classes.editing}>{edit_widget}</div>}
                {create_widget && <div className={classes.creation}>{create_widget}</div>}
            </div>
        </WidgetRender>;
    }
}

export default withStyles(styles)(SelectWidget);
