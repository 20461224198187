import React from 'react';
import PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';

import MuiCodeBlock from 'dash/MuiCodeBlock';
import MuiLoader from 'dash/MuiLoader';
import {requiring_system_logs_store} from 'storages/systemlogs';

export class ProcessesLog extends React.Component {
    state = {
        config: null
    }

    componentDidMount() {
        const { storage } = this.props;

        storage.get(`${storage.url}/processes`)
            .then(res => this.setState({config: res.config}));
    }


    render() {
        const { config } = this.state;

        if (!config) {
            return <MuiLoader />;
        }

        return <MuiCodeBlock>{config}</MuiCodeBlock>;
    }
}

ProcessesLog.propTypes = {
    storage: PropTypes.object,
};

export default requiring_system_logs_store(withRouter(ProcessesLog), 'processes');
