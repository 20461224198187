import React from 'react';
import { human_duration_text_short } from 'humanize';

const SecondsAgo = (props) => {
    const { time, no_time_message = 'Not reporting' } = props;
    const [ts, setTs] = React.useState((new Date()).getTime() / 1000);

    const updateTs = () => {
        const new_ts = (new Date()).getTime() / 1000;
        setTs(new_ts);
    };

    React.useEffect(() => {
        const handle = window.setInterval(updateTs, 1000);
        return () => {
            window.clearInterval(handle);
        };
    }, []);
    if (!time) {
        return no_time_message;
    }
    const time_delta = ts - time;
    return <React.Fragment>{human_duration_text_short(time_delta)} ago</React.Fragment>;
};

export default SecondsAgo;