import React from 'react';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    type: {}
});

class MuiTypography extends React.Component {
    render() {
        const { classes, children, ...otherProps } = this.props;

        return (
            <Typography className={classes.type} classes={{}} {...otherProps}>
                {children}
            </Typography>
        );
    }
}

export default withStyles(styles)(MuiTypography);
