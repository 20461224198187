/* Provides a registry of widgets for use in react forms 

You would normally use this by importing, for instance,
the `reactforms.dashwidgets` module and then using
reactforms.
*/
import React from 'react';
const WIDGET_REGISTRY = {};

function widget( name, cls ) {
    /* register a React component as a widget at name

    if cls:
        * wraps the cls in a React Factory
        * registers the factory in the WIDGET_REGISTRY under name
    else:
        returns the registered factory for cls
    */
    if (cls !== undefined) {
        cls = React.createFactory( cls );
        WIDGET_REGISTRY[name] = cls;
    } else {
        cls = WIDGET_REGISTRY[name];
    }
    return cls;
}

export default widget;
export {widget, WIDGET_REGISTRY};
