import React from 'react';

import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import MuiTypography from 'dash/MuiTypography';
import MuiSnackbar from 'dash/MuiSnackbar';
import FileUploadTrigger from 'reactform/FileUploadTrigger';

import { requiring_system_store } from 'systemstorage';

const styles = theme => ({
    link: {
        color: theme.palette.common.white
    }
});

export class FirmwareUpgradeForm extends React.Component {
    state = {
        firmwareUpgradeInitiated: false
    };

    onFinished = upload => {
        const { storage } = this.props;

        this.setState({ firmwareUpgradeInitiated: true });
        storage.post({ upload_id: upload.state.upload_id }, '/api/form_bridge/FirmwareForm/');
    };

    render() {
        const { classes } = this.props;
        const { firmwareUpgradeInitiated } = this.state;

        return (
            <React.Fragment>
                <MuiTypography>Upload New Firmware</MuiTypography>
                <FormControl variant="outlined" classes={{}}>
                    <FileUploadTrigger onFinished={this.onFinished} purpose='firmware' />
                </FormControl>

                {firmwareUpgradeInitiated && (
                    <MuiSnackbar variant="success">
                        Firmware upgrade started.{' '}
                        <Link to="/sys/debugging/upgrade-progress/" className={classes.link}>
                            Visit upgrade log to see progress
                        </Link>.
                    </MuiSnackbar>
                )}
            </React.Fragment>
        );
    }
}

export default requiring_system_store(withStyles(styles)(FirmwareUpgradeForm));
