import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';

import MuiIcon from 'dash/MuiIcon';

const styles = theme => ({
    success: {
        backgroundColor: theme.palette.success.main,
    },
    error: {
        backgroundColor: theme.roles.errorLevel.error
    },
    info: {
        backgroundColor: theme.roles.errorLevel.info,
    },
    warning: {
        backgroundColor: theme.roles.errorLevel.warning,
    },
    icon: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    closeIcon: {
        fontSize: 20
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    },
    stretch: {
        marginLeft: theme.sidebarWidth,
        width: `calc(100% - ${theme.sidebarWidth}px)`,
        maxWidth: 'none',
        fontWeight: theme.typography.fontWeightBold
    }
});

class MuiSnackbar extends React.Component {
    static defaultProps = {
        stretch: false
    }

    getActions() {
        const { classes, onClose } = this.props;

        if (onClose) {
            return [
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={onClose}
                >
                    <MuiIcon name="close" className={classes.closeIcon} />
                </IconButton>
            ];
        }

        return [];
    }

    render() {
        const { classes, children, variant, stretch } = this.props;
        const actions = this.getActions();

        return (
            <SnackbarContent
                className={classNames(classes[variant], {[classes.stretch]: stretch}, this.props.className)}
                message={
                    <div className={classes.message}>
                        <MuiIcon name={variant} className={classes.icon} />
                        <div>{children}</div>
                    </div>
                }
                action={actions}
            />
        );
    }
}

MuiSnackbar.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    stretch: PropTypes.bool,
};

export default withStyles(styles)(MuiSnackbar);
