import React from 'react';
import { ClassyWidget } from 'reactform/classywidget';
import { widget } from 'reactform/widget';
import ATXTable from 'atxtable';
import { type_renderer } from 'reactform/typerenderer';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const BoolRenderer = type_renderer('CheckboxInput');

const styles = (theme) => ({
    'error': {
        color: theme.palette.red,
    },
});

const ChangeTable = withStyles(styles)((props) => {
    const { changes, classes } = props;
    const remapped = [];
    /* remap the dataset to be flat, rather than group-indexed */
    changes.map(group_record => {
        const { group, changes } = group_record;
        changes.map(change => {
            if (change.service_slots) {
                change.service_slots.map((sslot, i) => {
                    const slot = change.slots && change.slots[i];
                    const sslot_display = change.service_slots_display && change.service_slots_display[i];
                    remapped.push({
                        ...change,
                        service_slot: sslot,
                        service_slot_display: sslot_display,
                        slot: slot,
                    });
                });
            } else {
                remapped.push({
                    ...change,
                    group: group,
                });
            }
        });
    });
    return <ATXTable
        data={remapped}
        defaultSorting={[
            { 'id': 'error', 'desc': true, },
            { 'id': 'group', 'desc': false, },
        ]}
        columns={[
            {
                'Header': 'Error?',
                'accessor': 'error',
                'Cell': (props) => <BoolRenderer value={props.cell.value} />
            },
            {
                'Header': 'Action',
                'accessor': 'code_display',
                'Cell': (props) => {
                    const value = props.cell.value;
                    const { code } = props.cell.row.original;
                    if (code < 100) {
                        return <div className={classNames(classes.error, "error")}>{value}</div>;
                    } else {
                        return <div className="ok">{value}</div>;
                    }
                }
            },
            {
                'Header': 'Device (Group)',
                'accessor': 'group.title',
            },
            {
                'Header': 'Resource Type',
                'accessor': 'resource_type',
            },
            {
                'Header': 'Source/Resource',
                'id': 'source',
                'accessor': (record) => {
                    if (record.resource) {
                        return record.resource.title;
                    } else if (record.source) {
                        return record.source.title || record.source.name;
                    } else {
                        return null;
                    }
                }
            },
            {
                'Header': 'Slot',
                'id': 'slot',
                'accessor': (record) => {
                    if (record.slots) {
                        return record.slots;
                    } else if (record.source_slot) {
                        return record.source_slot;
                    } else if (record.source) {
                        return record.source.slot;
                    } else {
                        return null;
                    }
                },
            },
            {
                'Header': 'Sink',
                'id': 'service.title',
                'accessor': (record) => {
                    if (record.sink) {
                        return record.sink.title;
                    } else {
                        return record.service.title;
                    }
                }
            },
            {
                'Header': 'Sink Slot',
                'id': 'service.slot',
                'accessor': (record) => {
                    if (record.service_slots) {
                        return record.service_slots;
                    } else if (record.sink_slot) {
                        return record.sink_slot;
                    } else if (record.source_slot) {
                        return record.source_slot;
                    } else {
                        return record.service_slot;
                    }
                }
            },
        ]}
    />;
});

class DeployPlanWidget extends ClassyWidget {
    static defaultProps = {
        ...ClassyWidget.defaultProps,
        wide: true,
    }
    render() {
        const widget_data = this.widget_data('deployment');
        if (widget_data && widget_data.changes) {
            return <ChangeTable changes={widget_data['changes']} />;
        } else {
            return null;
        }
        //return `${JSON.stringify(widget_data)}`;
    }
}

widget('DeployPlan', DeployPlanWidget);
export default DeployPlanWidget;
