/* Spacer that pushes content up the size of an app-bar */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    spacer: theme.mixins.toolbar,
})

const AppBarSpacer = ({classes}) => <div key='appbar-spacer' className={classes.spacer}>
    {' '}
</div>;

export default withStyles(styles)(AppBarSpacer);
