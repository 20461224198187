/* Utility to track segments of a time-series that are loaded */
import { Signal } from 'signals';
import { sort_by_accessors } from 'dash/charts/extractdataset';

class TimeSeriesTracker {
    /* Tracks a range of values for which we need to load records

    set_range(start,stop) -- set the overall window of data we want to load
    segment_loaded(start,stop) -- mark a range loaded
    segments -- get an array of [start,stop] values which should be
                loaded, when a block is loaded, call segment_loaded
                to update the ranges internally
    
    signal.changed.listen -- get updates when segments updated
    signal.changed.ignore -- stop getting segment updates...
    */
    static defaultProps = {
        range: [0, 0],
    };
    constructor(props) {
        Object.assign(this, TimeSeriesTracker.defaultProps, props);
        this.segments_known = [];
        this.signal = new Signal();
    }
    set_range = (start, stop) => {
        /* Set the overall range we would like to load */
        [start, stop] = [Math.min(start, stop), Math.max(start, stop)];
        const segments = this.segments();
        this.signal.send_if_changed(segments);
        return segments;
    }
    segment_loaded = (start, stop) => {
        this._segment_loaded(start, stop);
        this.signal.send_if_changed(this.segments());
    }
    _segment_loaded = (start, stop) => {
        /* Record that the given segment is loaded */
        if (Number.isNaN(start)) {
            throw new Error(`NaN start in loaded segment: ${start}`);
        }
        if (Number.isNaN(stop)) {
            throw new Error(`NaN stop in loaded segment: ${stop}`);
        }
        [start, stop] = [Math.min(start, stop), Math.max(start, stop)];
        for (let index = 0; index < this.segments_known.length; index++) {
            let [seg_start, seg_stop] = this.segments_known[index];
            if (start >= seg_start && stop <= seg_stop) {
                /* withing previously marked */
                return this.segments_known;
            } else if (stop < seg_start) {
                /* before the current segment */
                this.segments_known.splice(index, 0, [seg_start, seg_stop]);
                return this.segments_known;
            } else if (start <= seg_start && stop >= seg_start) {
                /* overlaps current segment (maybe more than one) */
                let to_replace = this.segments_known.slice(index).filter(r => (r[0] <= stop));
                console.log(`To replace: ${stop} => ${JSON.stringify(to_replace)}`);
                seg_stop = to_replace[to_replace.length - 1][1];
                this.segments_known.splice(index, to_replace.length, [start, Math.max(stop, seg_stop)])
                return this.segments_known;
            } else if (start >= seg_start && start <= seg_stop) {
                let to_replace = this.segments_known.slice(index).filter(r => (r[0] <= stop));
                console.log(`To replace: ${stop} => ${JSON.stringify(to_replace)}`);
                seg_stop = to_replace[to_replace.length - 1][1];
                this.segments_known.splice(index, to_replace.length, [seg_start, Math.max(stop, seg_stop)])
                return this.segments_known;
            }
        }
        this.segments_known.push([start, stop]);
        return this.segments_known;
    }
    segments = () => {
        /* Return the set of segments remaining to load */
        const [start, stop] = this.range;
        let current = start;
        const result = [];
        for (let index = 0; index < this.segments_known.length; index++) {
            const [seg_start, seg_stop] = this.segments_known[index];
            if (seg_start > current) {
                result.push([current, seg_start]);
                current = Math.min(stop, seg_stop);
            }
            current = seg_stop;
            if (current > stop) {
                break;
            }
        }
        if (current < stop) {
            result.push([current, stop]);
        }
        return result;
    }
}

const merge_sorted = (old_data, new_data, accessors) => {
    /* Merge new data into the dataset 
    
    old_data -- flat array of records 
    new_data -- flat array of records
    key -- key into records by which to sort
    
    returns new array with ordered records from old and new data
    */
    const result = [
        ...old_data,
        ...new_data,
    ];
    const sort_func = sort_by_accessors(accessors);
    result.sort(sort_func)
    return result;
};


// class TimeSeries {
//     constructor(props) {
//         Object.assign(this, props);
//         this.data = [];
//     }
// }

export default TimeSeriesTracker;
export { merge_sorted, TimeSeriesTracker };