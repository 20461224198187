/* Build a color map for a graph/chart and query for a given data-label */

const build_color_map = (graph, metric, base = null) => {
    /* Build up a color map from the configurations in graph and metric 
    
    graph -- an overall graph metric which may or may not have a color map
    metric -- a metrics-module metric, again, with optional color map
    base -- base map either from global configuration or from a widget-specific mapping
    
    returns {color_key:color_string} mapping;
    */
    return {
        ...(base || {}),
        ...(metric.color_map || {}),
        ...(graph.color_map || {}),
    };
}

const query_color_map = (value, color_map) => {
    /* Lookup state colors in a dotted-format color map table

    Returns match for:
    * Longest dotted prefix
    * Last dotted segment
    
    null if no match
    */
    value = `${value}`;
    const split_value = value.split('.');
    for (let index = split_value.length; index > 0; index--) {
        const key = split_value.slice(0, index).join('.');
        const color = color_map[key];
        if (color) {
            return color;
        }
    }
    for (let index = split_value.length - 1; index >= 0; index--) {
        const key = split_value[index];
        const color = color_map[key];
        if (color) {
            return color;
        }
    }
    return null;
}

export { build_color_map, query_color_map };