import React from 'react';
import PropTypes from 'prop-types';

import { requiring_system_store } from 'systemstorage';
import EditingCard from 'dash/editingcard';
import MuiLoader from 'dash/MuiLoader';
import Grid from '@material-ui/core/Grid';
import WithCurrentUser from 'withcurrentuser';

export class SystemConfiguration extends React.Component {
    render() {
        const { system, forms, location, user } = this.props;
    
        if (!system) {
            return <MuiLoader />;
        }
    
        const locationHash = location.hash ? location.hash.replace('#', '') : null;

        return (
            <React.Fragment>
                {forms.map(form => {
                    const summary = form.get_summary(system);
                    if (form.permission && !(user && user.has_permission(form.permission))) {
                        console.info(`Not rendering card for ${form.title} (${form.key}) needed ${form.permission}`);
                        return null;
                    }

                    return (
                        <EditingCard
                            key={form.key || form.title}
                            defaultExpanded={locationHash === form.title.toLowerCase()}
                            title={form.title}
                            key={form.key || form.title}
                            summary={summary}
                            children={form.details}
                            collapsing={form.key || form.title}
                            section={form}
                            no_wrapper={form.no_wrapper || false}
                        >
                            {
                                form.details ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={8}>{form.render_component(this.props)}</Grid>
                                        <Grid item xs={4}>{form.details}</Grid>
                                    </Grid> :
                                    form.render_component(this.props)
                            }
                        </EditingCard>
                    );
                })}
            </React.Fragment>
        );        
    }
}

SystemConfiguration.propTypes = {
    forms:  PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        get_summary: PropTypes.func.isRequired,
        render_component: PropTypes.func.isRequired,
        details: PropTypes.element,
    })).isRequired,
    location: PropTypes.object.isRequired,
    system: PropTypes.object,
    user: PropTypes.shape({
        has_permission: PropTypes.func,
    }),
};

export default WithCurrentUser(requiring_system_store(SystemConfiguration));
