import { BaseStorage } from "storages/basestorage";
import $ from 'fakequery';
import { stores } from 'storeregistry';
import { requiring_store } from 'storages/requiringstore';

var SystemStorage = function (state) {
    /* piggy-backing storage, updated from other storages

    listen for otherstore.change( ) and pass {'system':otherstore.system}
    to this storage...
    */
    var storage = BaseStorage({
        'key': 'system_status',
        'url': '',
        'system': null,
        'config': null,
    });
    $.extend(storage, state || {});
    $.extend(storage, {
        ensure_keys: function () {
            // if (! storage.last_success) {
            //     console.log(`Got system config ${JSON.stringify(storage.config)}`);
            // }
        },
        merge_accumulator: function (original, update) {
            //console.log("Merge:\n"+JSON.stringify(original)+'\n'+JSON.stringify(update))
            if (original && original.historic !== undefined && update.historic !== undefined) {
                $.map(update.historic, function (period, i) {
                    var previous_period = original.historic[i];
                    if (period.values.length) {
                        var last = previous_period.values[previous_period.values.length - 1];
                        var incoming = period.values[period.values.length - 1];
                        if ((last && last.start) == (incoming && incoming.start)) {
                            previous_period.values[previous_period.values.length - 1] = incoming;
                        } else {
                            if (update.historic.length > 1) {
                                // two items, so update the original with it, in case it changed...
                                previous_period.values[previous_period.values.length - 1] = period.values[period.values.length - 2];
                            }
                            previous_period.values[previous_period.values.length] = incoming;
                        }
                    }
                });
            } else {
                // otherwise, recurse into children updating...
                $.map(Object.getOwnPropertyNames(update), function (name) {
                    var record = update[name];
                    var existing = original && original[name];
                    if (existing === undefined || existing == null) {
                        original[name] = record;
                    } else {
                        storage.merge_accumulator(existing, record);
                    }
                });
            }
        },
        merge: function (update) {
            if (update.system && storage.system) {
                storage.merge_accumulator(storage.system, update.system);
            } else if (update.system) {
                storage.system = update.system;
            }
            if (update.config) {
                storage.config = update.config;
            }
        },
        piggy_back: function (other_storage) {
            if (other_storage.system) {
                storage.set({ 'system': other_storage.system });
            }
            return other_storage;
        }
    });
    return storage;
};

function default_system_storage() {
    /* Create default configured system storage */
    let current = stores.system_storage;
    if (!current) {
        current = SystemStorage({
            debug: false,
            period: 30,
            key: 'system_status'
        })
        current.poll();
    }
    return current
}

function requiring_system_store(Component, forms_key = 'system_forms') {
    return requiring_store(
        Component,
        {
            storage_key: 'system_status',
            create_function: default_system_storage,
            forms_key,
            prop_function: (props, storage) => {
                return {
                    ...props,
                    storage,
                    system: storage.config,
                    os: storage.os,
                };
            }
        }
    );
}
function requiring_system_status(Component, forms_key = 'system_forms') {
    return requiring_store(
        Component,
        {
            storage_key: 'system_status',
            create_function: default_system_storage,
            forms_key,
            prop_function: (props, storage) => {
                // console.info(storage.system);
                return { ...props, system_status: storage.system };
            }
        }
    );

}

function system_os_create(props) {
    if (!stores.system_os_status) {
        BaseStorage({
            key: 'system_os_status',
            period: 20,
        }).get();
    }
}

function requiring_system_os(Component) {
    return requiring_store(
        Component,
        {
            storage_key: 'system_os_status',
            create_function: system_os_create,
            prop_function: (props, storage) => ({
                ...props,
                os: storage.config,
            })
        }
    );
}

export {
    SystemStorage, default_system_storage,
    requiring_system_store, requiring_system_status,
    requiring_system_os,
};
