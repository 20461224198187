import React from 'react';
import { providing_nearest_form } from 'formprovider';
import { with_resolved_target } from 'dash/resolvetarget';
import { ReactForm, ReactFormTable } from 'reactform';
import { with_focus } from 'dash/focusprovider';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import { EditIntent } from 'editintent';
import { Link } from 'react-router-dom';
import FocusLink from 'dash/focuslink';
import { url_for_key } from 'storages';
import { WithToolbar } from 'shogun/shogunpromotebar';
import ListView from 'shogun/listview';
import { WithTabularStorage } from 'storages/tabularstorage';
import { ServiceCSVDownloader, ServiceCSVUploader } from 'shogun/servicecsv';


const SrtSourceEditor = (props) => {
    const { target, editable, storage, excludes = ['notes'], ...restProps } = props;

    const actions = [
        (target && target.id) && <ServiceCSVUploader
            target={target}
        />,
        (target && target.id) && <ServiceCSVDownloader
            target={target}
        />,
    ].filter(x => !!x);
    return <ListView
        __type__={'SRTSource'}
        storage={'shogunconf'}
        actions={actions}
        inPlaceEditing={editable}
        overall_target={target}
        column_widths={{
            'number': 'small',
            'enabled': 'small',
            'uri': 'wide',
            'program_number': 'small',
            'minimum_latency': 'small',
            'connection_mode': 'small',
            'target_interface': 'small',
        }}
        defaultSorting={[
            { id: 'number', desc: false },
        ]}
        preference_key='gui.srt-source-edit-table'
        preference_default={{
            hiddenColumns: [
                'mapping',
                'link',
                'ts_id',
                'virtual_channel_name',
                'virtual_channel_major',
                'virtual_channel_minor',
                'pid_map',
            ]
        }}
        {...restProps}
        editable={editable}
        context={{
            'mapping': target.id,
        }}
        creation_context={{
            'mapping': target.id,
        }}
    />;

    return <EditingCard title={`SRT Source Mapping`} collapsing={`srt-mapping-source-editor`} no_wrapper={true} actions={actions}>
        <ReactFormTable
            form_keys={["SRTSource", "IPOutput"]}
            form_titles={["Source", "Output"]}
            wrap_data={(record, table) => {
                return [record, record.sinks && record.sinks.length && record.sinks[0]];
            }}
            inPlaceEditing={editable}
            overall_target={target}
            widgets={{
                // tuner_slot: 'DisplayWidget',
                // input_port: 'DisplayWidget',
                // modulation_type: null_as_default,
                // symbol_rate: null_as_default,
                // spectrum_type: null_as_default,
            }}
            exclude={excludes}
            column_widths={{
                'number': 'small',
                'enabled': 'small',
                'uri': 'wide',
                'program_number': 'small',
                'minimum_latency': 'small',
                'connection_mode': 'small',
                'target_interface': 'small',
            }}
            defaultSorting={[
                { id: 'number', desc: false },
            ]}
            preference_key='gui.srt-source-edit-table'
            preference_default={{
                hiddenColumns: [
                    'mapping',
                    'link',
                    'ts_id',
                    'virtual_channel_name',
                    'virtual_channel_major',
                    'virtual_channel_minor',
                    'pid_map',
                ]
            }}
            {...restProps}
            editable={editable}
        />
    </EditingCard>;
};


class BaseSRTMappingView extends React.Component {
    render() {
        const instance = this.props.target;
        if (!instance) {
            return null;
        }
        const children_context = {
            mapping: instance.__pk__ || instance.id,
        };
        const children_creation_context = {
            ...children_context,
            enabled: true,
        };
        return <React.Fragment>
            <WithTabularStorage
                Component={SrtSourceEditor}
                storage_props={{
                    default_context: children_context,
                    forms_key: 'shogunconf_forms',
                    form_key: 'SRTSources',
                    debug: true,
                }}
                key={`streams`}
                target={instance}
                editable={true}
            />
            {/* <ListView
                title={`Sources`}
                __type__='SRTSource'
                storage='shogunconf_forms'
                context={children_context}
                creation_context={children_creation_context}
                creation_action='Add Source'
                exclude={['parent', 'notes']}
                key='sources'
                dialog_edit={true}
            /> */}
            <EditingCard title={`${instance.title} Metadata`} key={`metadata-card-${instance.id}`}>
                <ReactForm target={instance} use_dialog={false} key='srt-mapping-metadata' />
            </EditingCard>
        </React.Fragment>;
    }
}
const SRTMappingView = WithToolbar(providing_nearest_form(
    with_resolved_target(
        BaseSRTMappingView
    ),
    'shogunconf_forms'
));

export default SRTMappingView;
export { BaseSRTMappingView, SRTMappingView };
