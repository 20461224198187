import React from 'react';
import $ from 'fakequery';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Typography } from '@material-ui/core';

var construct_choices = function (choices, optionClassName = '') {
    /* Construct set of choices from Django choice-set */
    const options = [];
    choices.map((choice, index) => {
        var value = choice[0];
        var label = choice[1];
        if (Array.isArray(label)) {
            return [
                <ListSubheader disableSticky key={`header-${index}`}>{value}</ListSubheader>,
                ...construct_choices(label),
            ];
        } else {
            return [
                <MenuItem key={`item-${index}-${value}`} value={value} label={label} className={optionClassName}>
                    <Typography>{label}</Typography>
                </MenuItem>,
            ];
        }

    }).map(set => set.map(opt => options.push(opt)));
    return options;
};

export {
    construct_choices
};
export default construct_choices;