/* Models for server-hosted metrics */
import { graph_format, graph_metric, graph_key } from 'dash/charts/chartmodel';
import { metric_storage } from './metricstorage';

const NON_NODE_METRICS = ['service.log', 'service.event'];
const METRIC_DESCRIPTIONS = {
    'node_status': {
        // path: settings
    }
};


const create_metrics = (metrics, graphs, fields, metric_key) => {
    /* Convert server side available_metrics to client side metric records */
    if (metrics && graphs) {
        return { metrics, graphs };
    }
    metrics = [];
    graphs = [];
    (fields || []).map(field => {
        const metric = field_to_metric(field, metric_key);
        if (metric) {
            metrics.push(metric);
            if (metric.type == 'bool' || metric.type == 'enum') {
                graphs.push({
                    'format': 'enum.pie',
                    'metric': field.label_id,
                    'title': field.label,
                    'description': `Pie Graph: ${field.help}`,
                });
                // graphs.push({
                //     'format': 'enum.blinken',
                //     'metric': field.label_id,
                //     'title': field.label,
                //     'description': `Blinken Lights: ${field.help}`,
                // });
            } else if (metric.type == 'counter') {
                graphs.push({
                    'format': 'scatter.counter',
                    'metric': field.label_id,
                    'title': field.label,
                    'description': `Scatter Plot: ${field.help}`,
                });
            }
        }
    });
    return { metrics, graphs };
};
const field_to_metric = (field, metric_key) => {
    /* create default configuration for the given field as a metric */
    let metric = null;
    if (!metric_key) {
        throw (`Null metric key`);
    }
    const node_link = (record) => {
        if (record && record.resource_id && record.resource_id.node_id) {
            return `/m/dashboard/${record.resource_id.node_id}/`;
        } else {
            return null;
        }
    };
    const record_label = (record) => {
        if (record && record.resource_id && record.resource_id.title) {
            return `${record.resource_id.title}: ${record[field.label_id]}`;
        } else {
            return `${record[field.label_id]}`;
        }
    };
    if (field.field.display_widget == 'Boolean') {
        metric = {
            'type': 'bool',
            'accessor': field.label_id,
            'dataset_accessor': metric_key,
            'title': field.label,
            '__key__': `${metric_key}.${field.label_id}`,
            'id': (record, id) => id,
            'label': record_label,
            'link': node_link,
        };
    } else if (field.field.type == 'STRING' && field.params && field.params.role && field.params.role == 'string_enum') {
        metric = {
            'type': 'enum',
            'accessor': field.label_id,
            'dataset_accessor': metric_key,
            'title': field.label,
            '__key__': `${metric_key}.${field.label_id}`,
            'id': (record, id) => id,
            'label': record_label,
            'link': node_link,
        };
    } else if (field.field.type == 'STRING') {
        metric = {
            'type': 'enum',
            'accessor': field.label_id,
            'dataset_accessor': metric_key,
            'title': field.label,
            '__key__': `${metric_key}.${field.label_id}`,
            'id': (record, id) => id,
            'label': record_label,
            'link': node_link,
        };
    } else if (field.field.type == 'UINT64' || field.field.type == 'INT64') {
        metric = {
            'type': 'counter',
            'accessor': field.label_id,
            'dataset_accessor': metric_key,
            'title': field.label,
            '__key__': `${metric_key}.${field.label_id}`,
            'id': (record, id) => id,
            'label': record_label,
            'link': node_link,
        };
    } else if (field.field.type == 'INTEGER') {
        metric = {
            'type': 'measure',
            'accessor': field.label_id,
            'dataset_accessor': metric_key,
            'title': field.label,
            '__key__': `${metric_key}.${field.label_id}`,
            'id': (record, id) => id,
            'label': record_label,
            'link': node_link,
        };
    } else if (field.field.type == 'DOUBLE' || field.field.type == 'float') {
        metric = {
            'type': 'measure',
            'accessor': field.label_id,
            'dataset_accessor': metric_key,
            'title': field.label,
            '__key__': `${metric_key}.${field.label_id}`,
            'id': (record, id) => id,
            'label': record_label,
            'link': node_link,
        };
    }
    // } else {
    //     console.log(`No mapping for ${field.label_id}: ${field.field.type}`);

    // }
    //  else {
    //     console.log(`Data-type ${field.field.type} isn't mapped to a metric type in ${JSON.stringify(field)}`);
    // }
    if (metric && field && field.params) {
        // Allow backend metadata about the field to be forwarded...
        metric = {
            ...metric,
            ...field.params,
        };
        metric.form_field = field;
        // console.log(`Metric with key ${metric.__key__}`);
    }
    return metric;
};

const metric_field_description = (metric_table, form_field) => {
    /* Construct a metric field description from table and field record */
    const base = field_to_metric(form_field, metric_table.key);
    if (!base) {
        return base;
    }
    return {
        dataset_accessor: metric_table.key,
        __key__: `${metric_table.key}.${form_field.label_id}`,
        metric_record: metric_table,
        form_field: form_field,
        ...base,
    };
};

const product_metrics_to_graph_metrics = (props) => {
    /* Convert product:metric-set metrics to graph-compatible metrics */
    const { available_metrics } = props;
    if (!available_metrics) {
        return [];
    }

    // console.log(`Available metrics: ${JSON.stringify(available_metrics, null, 2)}`);

    const chart_metrics = [];

    const metrics = Object.values(available_metrics);
    const seen_metrics = {};
    metrics.map((product_metrics) => {
        if (!Array.isArray(product_metrics)) {
            return;
        }
        product_metrics.map((metric_table, index) => {
            // console.log(`Individual metric: ${JSON.stringify(metric_table)}`);
            if (metric_table.key == 'metric_node_network') {
                console.log(`Registering the metric node table`);
            }
            if (seen_metrics[metric_table.key]) {
                return;
            }
            metric_table.form.fields.map((form_field) => {
                const description = metric_field_description(metric_table, form_field);
                if (description) {
                    chart_metrics.push(description);
                }
            });
        });
    });
    return chart_metrics;
};

export {
    create_metrics,
    field_to_metric,
    product_metrics_to_graph_metrics,
};
