/* Graph which loads/registers for metrics (shared)

Should be used in dashboards to render a particular
graph which is pulling data from the metric database
in order to provide summary information.
*/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { graph_format, graph_metric, graph_key } from 'dash/charts/chartmodel';
import { extract_data } from 'dash/charts/extractdataset';
import { with_resolved_target } from 'dash/resolvetarget';
import { providing_nearest_form } from 'formprovider';
import { WithTabularStorage } from 'storages/tabularstorage';
import { merge_types, metric_storage } from './metricstorage';
import { WithMetrics, WithLoadedMetrics } from './withmetrics';
import PropTypes from 'prop-types';
import ZoomableDialog from 'dash/zoomabledialog';
import { Graph_Type } from './metrictypes';
import with_error_boundary from 'errorboundary';

import useQuery from 'storages/usequery';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { TabularStorage } from 'storages/tabularstorage';
import useMetricFilters from 'storages/usemetricfilters';
import useStorage from 'storages/usestorage';

const styles = (theme) => ({

});

const BASE_COLOR_MAP = {
    'online': 'green',
    'offline': '#777',
    "playing": "palegreen",
    'not-reporting': '#777',
    "error": "red",
    'installing': '#00ff33',
    'missing': '#ff000060',
    'downloading': '#00ffff60',
    'download-failure': '#ff000030',
    "waiting": "#99777760",
    "configuring": "#77777760",
    "loading": "#33337760",
    "timeout": "pink",
};

const RenderGraph = (props) => {
    /* Creates a single <Graph/> with parameters pulled from chart, metric and (metric) storage 
    
    Component rendered comes from `graph_format(graph).implementation`
    which is normally a `graph.js:Graph` which then uses the constant 
    `format.type` to lookup the implementation in `Graph.props.option_map`
    
    */
    const { graph, chart_metrics, storage } = props;
    const { zoomable = false, ...childProps } = props;
    const key = graph_key(graph);
    ['graph_label_y'].map(function_key => {
        try {
            if (graph[function_key]) {
                const fun = Function(...graph[function_key]);
                graph[`${function_key}_function`] = fun;
            }
        } catch (e) {
            console.error(`Unable to compile ${key}.${function_key}: ${e.stack} `);
            return;
        }
    });
    const format = graph_format(graph);
    const datasets = storage;
    const [redirect, setRedirect] = useState(null);
    const location = useLocation();
    // const query = useQuery(location.search);
    const [filterSettings, setFilter] = useMetricFilters(graph.metric);
    if (redirect) {
        return <Redirect to={redirect} />;
    }
    if (!datasets) {
        console.log(`No datasets in RenderGraph`);
        return null;
    }
    if (!format) {
        console.error(`No format ${graph.format} is available`);
        return null;
    }
    let metric = graph_metric(graph, chart_metrics);
    if (!metric) {
        console.error(`No metric ${graph.metric} is available`);
        return null;
    }
    if (!graph.title) {
        graph.title = metric.title;
    }
    if (!graph.description) {
        graph.description = metric.description;
    }
    childProps.color_map = {
        ...BASE_COLOR_MAP,
        ...(metric.color_map || {}),
        ...(graph.color_map || {}),
    };
    childProps.labels = {
        ...(metric.labels || {}),
        ...(graph.labels || {}),
    };
    metric = {
        ...metric,
        ...graph,
    };
    let link_callback = null;
    if (graph.format == "enum.pie" || graph.format == "area.stacked") {
        /* Clicking on a value should redirect to filtered view */
        link_callback = (datapoint, key, series) => {
            if (filterSettings[key] == datapoint.value || filterSettings[key] == datapoint.label) {
                setFilter(key, undefined);
            } else {
                if (datapoint.label == 'Not Reporting' || datapoint.label == 'not-reporting') { // ICK!!
                    setFilter('.missing_only', true);
                } else {
                    setFilter(key, datapoint.grouped_by || datapoint.value, datapoint.label);
                }
            }
        };
    }
    /* We now have each graph do the manipulations to get the data to the correct format */
    let series = datasets;
    const Component = format.implementation;
    if (!Component) {
        return <div className="warning">No implementation on format {JSON.stringify(format)}</div>;
    }
    if (graph.zoomable || zoomable) {
        const ZoomGraph = React.forwardRef((props, ref) => {
            return <Component
                {...childProps}
                {...graph}
                ref={ref}
                series={series}
                graph={graph}
                format={format}
                metric={metric}
                key={key}
                width={1024}
                height={768}
            />;
        });
        return <ZoomableDialog key={`zd-${key}`} small={<Component
            {...graph}
            {...childProps}
            series={series}
            graph={graph}
            format={format}
            metric={metric}
            key={key}
        />} large={ZoomGraph} />;
    } else {
        return <Component
            {...graph}
            {...childProps}
            series={series}
            graph={graph}
            format={format}
            metric={metric}
            key={key}
            link_callback={link_callback}
        />;
    }

};
RenderGraph.propTypes = {
    graph: Graph_Type.isRequired,
    zoomable: PropTypes.bool,
};

const replace_query_type = (key, query_type) => {
    /* Replace the query type in the key for metric lookup */
    const temp_key = key.split('/');
    temp_key.splice(2, 1, query_type);
    return temp_key.join('/');
};

const BaseMetricGraph = (props) => {
    const { storage_props, available_metrics, chart_metrics, graph = {
        'format': 'enum.pie',
        'title': null,
        'description': null,
        'key': `metric_node_status.state.enum.pie`,
        'metric': 'node_status.state',
    } } = props;
    const now_date = (new Date()).getTime() / 1000;
    let storage = null;

    const [filters, set_filter] = useMetricFilters();

    const updateContext = (new_context) => {
        storage.default_context = {
            ...storage.default_context,
            ...new_context,
        };
        // console.log(`New context: ${JSON.stringify(storage.default_context)}`);
        storage.ts = null;
        storage.poll();
    };

    props = {
        ...props,
        graph,
    };

    const final_storage_props = metric_storage({ ...props, ...graph, data_filters: filters });

    if (graph.query_type) {
        final_storage_props.default_context.query_type = graph.query_type;
        if (graph.query_type == 'enum_summary') {
            final_storage_props.default_context = {
                ...final_storage_props.default_context,
                group_fields: graph.group_fields,
                bin: graph.bin || 1200,
                start: now_date - (3600 * 12),
                debug: true,
            };
            if (graph.count_field) {
                final_storage_props.default_context.count_field = graph.count_field;
            }
            final_storage_props.duration = 12;
        }
    } else if (graph.resource_specific) {
        final_storage_props.default_context.query_type = 'last_status';
    } else {
        final_storage_props.default_context.query_type = 'stats';
    }
    if (graph.resource_as_node) {
        final_storage_props.default_context.resource_as_node = true;
    }
    if (graph.max_age) {
        final_storage_props.default_context.start = now_date - graph.max_age;
    }
    final_storage_props.create_missing_rows = final_storage_props.default_context.create_missing_rows;
    final_storage_props.missing_only = final_storage_props.default_context['.missing_only'];

    final_storage_props.key = replace_query_type(
        final_storage_props.key,
        final_storage_props.default_context.query_type
    );

    final_storage_props.merge = merge_types[
        final_storage_props.default_context.query_type
    ];

    // const location = useLocation();

    const [someState, setSomeState] = React.useState();

    const onTabularChange = React.useCallback((storage) => {
        setSomeState(storage[storage.type_key]);
    }, [final_storage_props.key]);

    storage = TabularStorage(final_storage_props);
    storage.default_context = final_storage_props.default_context;
    useStorage(storage, onTabularChange);

    return <RenderGraph
        {...props}
        storage={storage}
        updateContext={updateContext}
    />;
};
BaseMetricGraph.displayName = 'BaseMetricGraph';

const MetricGraph = with_error_boundary(with_resolved_target(
    WithLoadedMetrics(
        BaseMetricGraph
    )
));

const BaseMetricTestView = (props) => {
    const query = useQuery();
    const base = {
        'format': 'enum.pie',
        'title': null,
        'description': null,
        'key': `metric_node_status.state.enum.pie`,
        'metric': 'metric_node_status.state',
    };
    const final = {};
    ['format', 'title', 'description', 'key', 'metric'].map(k => {
        const v = query.get(k);
        if (v !== null && v !== '') {
            final[k] = v;
        } else {
            final[k] = base[k];
        }
    });
    return <MetricGraph
        {...props}
        graph={final}
    />;
};

const MetricTestView = providing_nearest_form(
    withStyles(styles)(
        with_resolved_target(
            WithLoadedMetrics(
                BaseMetricTestView,
            )
        )
    ),
    'shogunconf_forms'
);

export default MetricGraph;
export { MetricTestView, BASE_COLOR_MAP };
