import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { requiring_system_os } from 'systemstorage';
import MuiTypography from 'dash/MuiTypography';

const styles = theme => ({});

class BasePowerSummary extends React.Component {
    render() {
        const {system} = this.props;
        const status = this.props.os;
        if (system) {
            var uptime = '';
            if (status) {
                uptime = `Up: ${status.uptime}`;
            }
            var periodic_reboot = '';
            if (system && system.system && system.system.periodic_reboot) {
                periodic_reboot = system.system.periodic_reboot_display;
            }
            return `${uptime} ${periodic_reboot}`;
        } else {
            return '';
        }
    }
}

export default withStyles(styles)(requiring_system_os(BasePowerSummary));
