import React from 'react';
import ChartCard from './chartcard';
import COLORS from './colors';
import { apply_accessor, parallel_sets } from './accessor';
// import 'react-vis/dist/style.css';
import ATXPieChart from './atxpiechart';
import ATXBarChart from './atxbarchart';
import ATXScatterPlot from './atxscatterplot';
import ATXBlinkenLights from './atxblinkenlights';
import RedBlack from './redblack';
import ATXStackedArea from './atxstackedarea';

class Graph extends React.Component {
    static defaultProps = {
        data: [],
        series: [], // sequence of sequence of {'coordinate':x,'value':y, ...}
        graph: {},
        format: {},
        metric: {},
        color_map: {
            true: 'black',
            false: 'blue',
            0: 'blue',
            1: 'black',
            null: '#707070',
            undefined: '#f07070',
            Normal: 'dark-green',
            Auto: 'black',
            auto: 'black',
            error: 'red',
            ok: 'dark-green',
        },
    };
    static option_map = {
        'pie': (props) => {
            return <ATXPieChart {...props} />;
        },
        'scatter': (props, tooltip) => {
            return <ATXScatterPlot {...props} />;
        },
        'blinken': (props, tooltip) => {
            return <ATXBlinkenLights {...props} />;
        },
        'line': (props, tooltip) => {
            return <ATXScatterPlot {...props} line={true} />;
        },
        'bar': (props, tooltip) => {
            return <ATXBarChart {...props} bar={true} />;
        },
        'redblack': (props, tooltip) => {
            return <RedBlack {...props} />;
        },
        'area.stacked': (props, tooltip) => {
            return <ATXStackedArea stackBy='y' {...props} />;
        },
        'area': (props, tooltip) => {
            return <ATXStackedArea stackBy='y' {...props} />;
        }
    };
    render() {
        const { series, graph, format, metric } = this.props;
        const GraphRender = Graph.option_map[graph.renderer || format.type];
        let final_graph = null;
        if (GraphRender) {
            final_graph = GraphRender(
                {
                    ...this.props,
                }
            );
        } else {
            final_graph = `No renderer for graph type ${graph.renderer || format.type}`;
        }
        return (
            <ChartCard
                chart={final_graph}
                title={graph.title || metric.title}
                description={graph.description || metric.description}
            />
        );
    }
}
const register_graph = (key, Component) => {
    Graph.option_map[key] = (props, tooltip) => {
        return <Component tooltip={tooltip} {...props} />;
    };
};

export default Graph;
export { COLORS, apply_accessor, Graph, register_graph };
