import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import redirect from 'redirect';
import classNames from 'classnames';

const useStyles = makeStyles({
    shortened: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    link:{
        whiteSpace: 'nowrap',
        textDecoration: 'none'
    }
});


const EllipsisText = (props) => {
    const { value, maxWidth = 15, link = null } = props;
    const classes = useStyles();
    const history = useHistory();

    const clickHandler = (evt) => {
        evt.stopPropagation();
        const target_url = link;
        console.log(`Link clicked ${target_url}`);
        if (target_url.startsWith("https://") || target_url.startsWith("http://")) {
            window.location = target_url;
        } else {
            redirect(target_url, history);
        }
        return true;
    }

    if (link != null) {
        return <Link className={classNames(classes.shortened, classes.link)} style={{ maxWidth: `${maxWidth}rem` }} title={`Click to edit the linked record ${value}`} to={link} onClick={clickHandler}>{value}</Link>
    }
    return <div className={classes.shortened} style={{ maxWidth: `${maxWidth}rem` }} title={value}>{value}</div>
};

export default EllipsisText;