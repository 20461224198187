import $ from 'fakequery';
import React from 'react';

import {stores} from 'storeregistry';
import {log_render_errors} from 'debugfail';
import {grid_rows} from 'puregrid';
import {error_report} from 'debugfail';
import {human_bandwidth} from 'humanize';

class AreaGraph extends React.Component {
    static displayName = 'AreaGraph';

    static defaultProps = {
        historic: null,
        className: null,
        title: null,
        maximum: null,
        width: 100,
        height: 32
    };

    render() {
        var height = this.props.height;
        var width = this.props.width;
        var historic = this.props.historic || [];
        var points = [];
        if (historic.length) {
            var maximum = this.props.maximum;
            var now = historic[historic.length-1].ts;
            var duration = now - historic[0].ts || 1;
        
            if (maximum === undefined || maximum===null) {
                maximum = 0;
                $.map( historic, function( datapoint ) {
                    maximum = Math.max( maximum, datapoint.value );
                });
            }
            if (maximum === 0) {
                maximum = 100;
            }
            var add_point = function (datapoint) {
                var value = datapoint.value;
                var x_coord = width - (((now-datapoint.ts || 1)/duration)*width);
                var y_coord = (height - ((value/maximum)*height));
                if (y_coord === undefined) {
                    console.log(datapoint);
                }
                points.push( ''+x_coord+' '+y_coord );
            };
            if (historic.length > 1) {
                add_point( {ts:historic[0].ts,value:0} );
                $.map( historic, add_point );
                add_point( {ts:now,value:0} );
            }
        }
        var positions = points.join(',');
        var className = this.props.className || '';
        className += ' status-graph';
        return (
            <svg
                title={this.props.title||''}
                className={className}
                height={height}
                width={width}>
                <polygon points={positions} className="status-area" />
            </svg>
        );
    }
}

var AreaGraphFactory = React.createFactory(AreaGraph);

class SimpleStatus extends React.Component {
    static displayName = 'SimpleStatus';

    /* Component used to display system load status */
    static defaultProps = {
        'get_historic': null, // function() to retrieve historic from storage
        'get_textual': null, // function( value ) to get textual description of value,
        'className': '', // className added to the wrapper div
        'period': 10,
        'height': 32,
        'width': 100,
    };
    componentDidMount() {
        this.mounted = true;
        stores.system_status.change.listen(this.on_system_change);
    }
    componentWillUnmount() {
        this.mounted = false;
        stores.system_status.change.ignore(this.on_system_change);
    }
    on_system_change = (storage) => {
        if (this.mounted) {
            this.setState({
                'system': storage.system,
                'load': storage.load,
                'memory': storage.memory,
            });
        }
    }

    render() {
        var self = this;
        var height = self.props.height || 32;
        var width = self.props.width || 100;
        var textual = '';
        var datapoints = [];
        var status = self.props.get_historic();
        if (status) {
            $.map( status.historic, function( period ) {
                // TODO: we want separate graphs for the period-lengths...
                if (period.period === self.props.period) {
                    $.map( period.values, function( block ) {
                        var value;
                        if (block) {
                            if (status.rate) {
                                value = block.total / period.period;
                            } else {
                                value = block.count? block.total/block.count : block.total;
                            }
                            if (status.rate && block.fractional_period) {
                                //value = value / block.fractional_period;
                                return;
                            }
                            datapoints.push( {'ts': block.start, 'value': value });
                            if (! block.fractional_period ) {
                                textual = self.props.get_textual( value );
                            }
                        }
                    });
                }
            });
        }
        var area_graph = AreaGraphFactory({
            height:height,
            width:width,
            historic:datapoints,
            maximum:null
        });
        if (textual) {
            textual = <span className="textual">
                {textual}
            </span>;
        }
        return (
            <div className={self.props.className}>
                {area_graph}
                {textual}
            </div>
        );
    }
}

var SimpleStatusFactory = React.createFactory(SimpleStatus);

class LoadStatus extends React.Component {
    static displayName = 'LoadStatus';

    /* Component used to display system load status */
    static defaultProps = {
        'height': 32,
        'width': 100
    };

    get_historic = () => {
        if (stores.system_status.system && stores.system_status.system.load) {
            return stores.system_status.system.load;
        } else {
            return null;
        }
    };

    get_textual = (value) => {
        return ''+Math.round(value)+'%';
    };

    render() {
        return SimpleStatusFactory({
            height:this.props.height,
            width:this.props.width,
            get_textual:this.get_textual,
            get_historic:this.get_historic
        });
    }
}

var LoadStatusFactory = React.createFactory( LoadStatus );

class MemoryStatus extends React.Component {
    static displayName = 'MemoryStatus';

    static defaultProps = {
        'height': 32,
        'width': 100
    };

    get_historic = () => {
        if (stores.system_status.system && stores.system_status.system.memory) {
            return stores.system_status.system.memory;
        } else {
            return null;
        }
    };

    get_textual = (value) => {
        return ''+Math.round(value)+'%';
    };

    render() {
        return SimpleStatusFactory({
            className:'memory',
            height:this.props.height,
            width:this.props.width,
            get_textual:this.get_textual,
            get_historic:this.get_historic
        });
    }
}

class NetStatus extends React.Component {
    static displayName = 'NetStatus';

    static defaultProps = {
        interface_name: '',
        property_name: '',
        label: '',
        show_label: true,
        accumulator: null,
        width: 100,
        height: 32,
    };

    get_historic = () => {
        return this.props.accumulator;
    };

    get_textual = (value) => {
        if (!this.props.show_label) {
            return null;
        }
        return (
            <div className="inline-block">
                <div>
                    {human_bandwidth(value)}
                </div>
                {this.props.label?
                    <div>
                        {this.props.label}
                    </div>: null}
            </div>
        );
    };

    render() {
        var className = 'netstatus interface-'+this.props.interface_name + ' prop-'+this.props.property_name;
        return SimpleStatusFactory({
            height:this.props.height,
            width:this.props.width,
            className: className,
            get_textual:this.get_textual,
            get_historic:this.get_historic
        });
    }
}

class NetStatusGroup extends React.Component {
    static displayName = 'NetStatusGroup';

    static defaultProps = {
        interface_name: '',
        property_name: '',
        accumulator: null,
        child_width: 100,
        child_height: 32,
    };
    mounted = true


    render() {
        const {system} = this.state;
        const current = system;
        try {
            var self = this;
            var children = [['Interface','Incoming','Outgoing']];
            if (current) {
                var historic = current.bandwidth;
                var names = Object.getOwnPropertyNames(current.bandwidth);
                names.sort();
                $.map(names, function(name) {
                    var int_record = historic[name];
                    var row = [ 
                        <div className="interface-name">
                            {name}
                        </div>
                    ];
                    $.map( ['bytes_recv','bytes_sent'], function( prop ) {
                        if (name === 'lo' && prop === 'bytes_recv') {
                            /* no point double-reporting it */
                            return;
                        }
                        var key = name + '-' + prop;
                        var acc = int_record[prop];
                        row.push(
                            <NetStatus
                                key={key}
                                interface_name={name}
                                property_name={prop}
                                height={self.props.child_height}
                                width={self.props.child_width}
                                interface_Name={name}
                                accumulator={acc}
                                current={current}
                            />
                        );
                    });
                    children.push(row);
                });
            } else {
                children = [[ "Collecting bandwidth stats","","" ]];
            }
            var rows = grid_rows(children,[8,8,8]);
            return (
                <div className="netstatus-group">
                    {rows}
                </div>
            );
        } catch(e) {
            error_report(e,''+current);
            throw e;
        }
    }
}

export {
    NetStatusGroup,
    NetStatus,
    MemoryStatus,
    LoadStatus,
    SimpleStatus,
    AreaGraph,
};
