import React from 'react';

function render_special_values(base_render,value_map) {
    /* For (only) values in the given value_map override the base renderer's representation 

    base_render -- React.Component for normal rendering
    value_map -- { `value`: ({value,field,record}) => React.Component
    */
    class RenderSpecial extends React.Component {
        static defaultProps = {
            value_map: value_map,
            base_render: base_render,
        }
        render() {
            const {value_map,base_render,...childProps} = this.props;
            const {value,field,record,classes} = childProps;
            const key = `${value}`;
            var Component = base_render;
            if (this.props.value_map.hasOwnProperty(key)) {
                Component = this.props.value_map[key];
            }
            return <Component {...childProps} />;
        }
    }
    return RenderSpecial;
}

export default render_special_values;
