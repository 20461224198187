import React from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
// import { with_bare_focus } from 'dash/focusprovider';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import MuiButton from 'dash/MuiButton';
import { with_bare_focus } from 'dash/focusprovider';
import redirect from 'redirect';

const styles = (theme) => ({
    link: {
        padding: theme.spacing(1),
        textDecoration: 'none',
        '&:hover': {
            boxShadow: theme.shadows[4],
        },
        // display: 'flex',
        // flexDirection: 'row',
        width: '100%',
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

const BaseFocusLink = (props) => {
    const {
        focus_on = null,
        as_button = false,
        to = "",
        onClick = null,
        classes,
        focus,
        staticContext = null,
        className,
        ...childProps
    } = props;
    const history = useHistory();
    const final_url = () => {
        return to || focus_on.__url__;
    };
    const is_absolute = (target_url) => {
        if (target_url.startsWith("https://") || target_url.startsWith("http://")) {
            return true;
        } else {
            return false;
        }
    };
    const clickHandler = (evt) => {
        evt.stopPropagation();
        const target_url = final_url();
        console.log(`Link clicked ${target_url}`);
        if (onClick) {
            console.log(`On clicked callback ${target_url}`);
            onClick(evt);
        }
        if (target_url.startsWith("https://") || target_url.startsWith("http://")) {
            window.location = target_url;
        } else {
            redirect(target_url, history);
        }
        return true;
    }
    if (as_button) {
        return <MuiButton
            onClick={clickHandler}
            {...childProps}
        >{props.children}</MuiButton>;
    } else {
        if (is_absolute(final_url())) {
            return <a className={classNames(classes.link, className)} onClick={clickHandler} {...childProps} href={final_url()} />
        }
        return <Link
            className={classNames(classes.link, className)}
            onClick={clickHandler}
            {...childProps}
            to={final_url()}
        />;
    }
};

BaseFocusLink.propTypes = {
    // focus: PropTypes.object.isRequired, // From with_focus...
    focus_on: PropTypes.oneOfType([PropTypes.shape({
        '__type__': PropTypes.string,
        'context': PropTypes.object,
    }), PropTypes.number]),
    to: PropTypes.string,
    onClick: PropTypes.func,
};

export default withStyles(styles)(with_bare_focus(BaseFocusLink));