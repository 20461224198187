/* Component to render a short summary of text with tooltip showing long-form */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ClassyWidget } from 'reactform/classywidget';
import WidgetRender from './custom_widget_render';
import classNames from 'classnames';

const styles = (theme) => ({
    'shortText': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    'noFillStyle': {
        maxWidth: '8em',
    }
});


const ShortWithTitle = withStyles(styles)((props) => {
    const { classes, value, fill = false } = props;
    return <div className={classNames(classes.shortText, fill ? null : classes.noFillStyle)} title={value}>{value}</div>;
});
class ShortWithTitleWidget extends ClassyWidget {
    render() {
        return <WidgetRender
            form_field={this.get_field()}
            show_label={this.props.show_label}
        ><ShortWithTitle value={this.current_value()} fill={true} /></WidgetRender>;
    }
}

export default ShortWithTitle;
export { ShortWithTitle, ShortWithTitleWidget };