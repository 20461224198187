import React from 'react';
import ReactFormButton from 'widgets/ReactFormButton';
import $ from 'fakequery';
import { stores } from "storeregistry";
import getIcon from 'getIcon';
import { url_for_key } from 'storages/basestorage';
import theme from 'dash/theme';

/** Construct bottom-of-dialog controls for react forms

@param {array} buttons -- list of constructor for the buttons

'cancel' -- cancel button, closes form (and dialog)
'save' -- save button, saves form, closes on success
function -- result of function( form ) added to results (can be null)
string -- span with the text added
React DOM element -- added unchanged
object -- <ReactFormButton
    key={button.key}
    type="button"
    className={button.className}
    title={button.help}
    onClick={function( evt ) {
        if (button.onClick) {
            button.onClick( self );
        }
    }}>
    {button.icon || null}
    {(button.icon && button.label)?' ':null}
    {button.label || null}
/>

*/
function default_save_button(self, props) {
    /* Create the default save button for the given reactform

    To override values:

    <ReactForm buttons=[
        (self) => default_save_button(self,{
            'busy_message': 'Saving Result',
            'action_message': 'Do Save',
        }),
    ]
    */
    if (!self.props.editable) {
        return null;
    }
    const busy_message = props.busy_message || 'Saving';
    const action_message = props.action_message || 'Save';
    return <ReactFormButton
        key={props.key || "save"}
        type="button"
        className="primary save"
        color="primary"
        title={props.title || "Save the form values"}
        onClick={(evt) => self.handleSave(evt, props)}
        icon={self.state.saving ? 'spinner spinner' : (props.icon || 'check')}
        {...(props || {})}
    >
        {self.state.saving ? busy_message : action_message}
    </ReactFormButton>;
}
function default_cancel_button(self, props) {
    const action_message = props.action_message || 'Cancel';
    return <ReactFormButton
        key="cancel"
        type="button"
        className="cancel secondary"
        onClick={self.handleClose.bind(self)}
        title="Cancel editing of the record"
        icon='close'
        {...(props || {})}
    >
        {action_message}
    </ReactFormButton>;
}
function danger_confirm_button(self, props) {
    if (!self.props.editable) {
        return null;
    }
    const { className, busy_message = 'Confirming', action_message = 'Confirm' } = props;
    return <ReactFormButton
        key={props.key || "confirm"}
        type="button"
        className={className}
        variant="outlined"
        title={props.title || "Confirm the operation"}
        onClick={(evt) => self.handleSave(evt, props)}
        icon={self.state.saving ? 'spinner spinner' : (props.icon || 'error')}
        {...(props || {})}
    >
        {self.state.saving ? busy_message : action_message}
    </ReactFormButton>;
}
const DeleteButtonWithConfirm = (props) => {
    const { parent } = props;
    const [clicked, setClicked] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(false);
    const action_message = props.action_message || 'Delete';
    return <React.Fragment>
        {(!clicked) && <ReactFormButton
            key="delete"
            type="button"
            className="delete secondary"
            onClick={(evt) => setClicked(true)}
            title="Delete the record"
            icon='close'
            iconColor='red'
            {...(props || {})}
        >
            {action_message}
        </ReactFormButton>}
        {clicked && <ReactFormButton
            key="delete-cancel"
            type="button"
            className="delete secondary"
            onClick={(evt) => setClicked(false)}
            title="Cancel deletion"
            icon='close'
            iconColor='darkgrey'
            {...(props || {})}
        >
            Cancel {action_message}
        </ReactFormButton>}
        {clicked && <ReactFormButton
            key="delete-confirm"
            type="button"
            className="delete primary"
            onClick={parent.handleDelete.bind(self)}
            title="Confirm deletion of the record"
            icon='close'
            iconColor='red'
            {...(props || {})}
        >
            Confirm {action_message}
        </ReactFormButton>}
    </React.Fragment>;
};
function default_delete_button(self, props) {
    if (!self.props.editable) {
        return null;
    }
    return <DeleteButtonWithConfirm {...props} parent={self} />;
}

var construct_controls = function (self, buttons) {
    return $.map(buttons, function (button, index) {
        if (button === 'save') {
            return default_save_button(self, { key: `save-${index}` });
        } else if (button === 'cancel') {
            return default_cancel_button(self, { key: `cancel-${index}` });
        } else if (button === 'delete') {
            return default_delete_button(self, { key: `delete-${index}` });
        } else if (typeof button === 'function') {
            return button(self);
        } else if (typeof button === 'string') {
            return (
                <span key={`str-${button}`}>
                    {button}
                </span>
            );
        } else if (React.isValidElement(button)) {
            return button;
        } else if (button.onClick) {
            return (
                <ReactFormButton
                    key={button.key || `button-${index}`}
                    type="button"
                    className={button.className}
                    title={button.help}
                    onClick={function (evt) {
                        if (button.onClick) {
                            button.onClick(self);
                        }
                    }}>
                    {button.icon || null}
                    {(button.icon && button.label) ? ' ' : null}
                    {button.label || null}
                </ReactFormButton>
            );
        } else {
            // TODO: use accessors to allow for e.g. editing linked records or creating
            // new linkable records...
            if (button && button.__pk_required__ && !(self.props.target && self.props.target.__pk__)) {
                return null;
            }
            const onClick = (evt) => {
                evt.stopPropagation();
                const { action, __pk_required__ = true } = button;
                const __pk__ = self.props.target.id || self.props.target.__pk__;
                if (action.method == 'download') {
                    let url = `${url_for_key(action.forms_key)}/${action.form_key}/`
                    if (!__pk_required__ && action.params_from_fields) {
                        var params = new URLSearchParams();
                        Object.keys(action.params_from_fields).map((key) => {
                            params.append(key, self.currentFormValues()[action.params_from_fields[key]]);
                        });
                        var params_encoded = params.toString();
                        if (params_encoded.length) {
                            url = url + '?' + params_encoded;
                        }
                    }
                    else {
                        url = url + `${__pk__}`;
                    }

                    console.log(`Redirecting user to ${url}`);
                    window.location = url;
                }
            };
            return (
                <ReactFormButton
                    key={button.key || `button-${index}`}
                    type="button"
                    className={button.className}
                    title={button.help}
                    onClick={onClick}
                    icon={button.icon}
                    iconColor={button.iconColor}
                >
                    {button.label || null}
                </ReactFormButton>
            );

        }
    });

};

/** Add an `action` to a particular form

Creates a new form action/button which is
a bottom-of-dialog button in most cases.

~~~
Usage example:
var detect = form_action({
    key: 'detection',
    name: 'Detect',
    icon: 'eye',
    help_text: 'Detect current programs/PIDs',
    callback: function( response, form, action ) {
        var self = form;
        if (response.details) {
            self.setState({'detect': response.details});
        } else if (response.form) {
            self.setState({'form_details':response.form});
        }
        return self;
    },
    errback: function( err, form, action ) {
    }
});
var multicast_editor = function( properties ) {
    properties.form_actions = properties.form_actions || function( self ) {
        var buttons = ['save','cancel',detect.render(self)];
        if (multicast && multicast.type == 'failover' ){
            buttons.push(failover_break.render(self));
            buttons.push(failover_swap.render(self));
        }
        return <div className='form-actions'>{
            construct_controls( self, buttons )
        } />;
    };
    return <MulticastEditor {...properties} />;
};

EditIntent({
    form: multicast_editor
});
~~~

*/

var form_action = function (state) {
    var action = {
        key: 'action'
        // icon: 'fa-icon-class'
        // help_text: 'title for button',
        // name: 'Friendly Name'
        // callback: function(response, form, action)
        // errback: function( err, form, action )
    };
    $.extend(action, state);
    $.extend(action, {
        render: action.render || function (form) {
            var spinning = (form.state && form.state[action.key]);
            return (
                <ReactFormButton
                    variant={state.variant || 'contained'}
                    color={state.color}
                    title={action.help_text}
                    onClick={function (evt) {
                        window.setTimeout(() => {
                            return action.handleAction(evt, form);
                        }, 500);
                    }}
                    key={action.key}>
                    {action.icon && getIcon(spinning ? 'spinner spinner' : action.icon)}
                    {' ' + action.name}
                </ReactFormButton>
            );
        },
        handleAction: function (evt, form) {
            console.log('Action Triggered: ' + (action.name || action.key));

            var self = form;
            var key = action.key;
            var target = self.props.target;
            var base = self.currentFormValues();
            base.action = action.key;
            var state = {};
            state[key] = true;
            self.setState(state);
            var storage = (self.props.storage || stores.forms);
            return storage.save_form(
                self.getFormKey(),
                target.id,
                base
            ).then(function (response) {
                console.log('Action Response: ' + (action.name || action.key));
                state[key] = false;
                self.setState(state);
                if (action.callback) {
                    console.log('Action Callback: ' + (action.name || action.key));
                    action.callback(response, self, action);
                }
                return self;
            }, function (err) {
                console.log('Action Error: ' + (action.name || action.key));
                if (action.errback) {
                    console.log('Action Errback: ' + (action.name || action.key));
                    action.errback(err);
                } else {
                    console.warn(`Failure doing ${action.name || action.key} on storage ${storage.url}`);
                    if (self.handleError) {
                        self.handleError(err, `Failure ${action.busy_message || action.key} on storage ${storage.url}`);
                    }
                }
            });
        }
    });
    return action;
};


export { construct_controls, default_cancel_button, default_save_button, danger_confirm_button, form_action, default_delete_button };
