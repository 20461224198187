import React from 'react';
class Progress extends React.Component {
    /* Provides Yahoo-style thin progress bar at the top of an element
   
    Children are rendered in a sub-div and the colour of the accent can be 
    controlled with the accentColor prop
    */
    static defaultProps = {
        accentColor: '#7BFEA7',
        className: '',
        fraction: 0,
        percent: 0,
        label: null
    }
    render() {
        var percent = this.props.percent || this.props.fraction * 100;
        return <div className={"progress-holder inline-block "+this.props.className} style={{position:'relative'}}>
            <div className="progress-indicator" style={{right: (100-percent)+'%',backgroundColor:this.props.accentColor}} />
            <div className="progress-content">
                { this.props.children }
            </div>
        </div>;
    }
}
var ProgressFactory = React.createFactory( Progress );
export {
    Progress,
    ProgressFactory
};
export default Progress;
