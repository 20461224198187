import React from 'react';
import WithDomainZoom from 'metrics/domainzoom';
import { XYPlot, Crosshair } from 'react-vis';

const Timeline = (props) => {
    /* Wraps an XYPlot to produce a zoomable/pannable timeline control */
    const { zoom, doZoom, doPan, children, margin, ...restProps } = props;

    const plot_ref = React.createRef();
    const timestamp = (new Date()).getTime();

    return <XYPlot
        ref={plot_ref}
        {...restProps}
        margin={margin}
        draggable={false}
        xDomain={[zoom.start, zoom.stop]}
        xType="time"
        // yType="ordinal" // TODO: this is specifc to redblack...
        onMouseMove={(event) => {
            /* Bit-field saying we have major is the only one we want */
            if (event.buttons != 1) {
                return;
            }
            let physical_delta = event.movementX;
            const graph_width = event.target.clientWidth - (margin.left + margin.right);
            if (graph_width) {
                doPan(physical_delta / graph_width);
            }
            event.preventDefault();
            event.stopPropagation();
        }}
        onWheel={(event) => {
            /* Mouse wheel event */
            const is_up = event.deltaY > 0;
            const rect = event.target.getBoundingClientRect();
            const graph_width = rect.width - (margin.left + margin.right);
            const scroll_pos = event.pageX - (rect.x + margin.left);
            // console.log(`Wheel ${scroll_pos}/${graph_width}`);
            if (graph_width) {
                doZoom(Math.max(0, Math.min(1, scroll_pos / graph_width)), is_up ? 1.25 : .8);
            }
            event.preventDefault();
            event.stopPropagation();
        }}
    >
        <Crosshair values={[{ x: timestamp }]} titleFormat={() => null} itemsFormat={() => []}><div /></Crosshair>
        {children}
    </XYPlot>;

};

const ZoomableTimeline = WithDomainZoom(Timeline);

export default ZoomableTimeline;
export { ZoomableTimeline, Timeline };