/* Common code to issue a redirect 

Needs to use useHistory() from react-router-dom to provide
the history...
*/

const redirect = (url, history) => {
    window.setTimeout(() => {
        if (url != window.location.pathname) {
            history.push(url);
        }
    }, 10);
};

export default redirect;