import React from 'react';
import { Redirect } from 'react-router-dom';
import '../css/pure-release-0.6.0/pure-min.css';
import '../css/pure-release-0.6.0/grids-responsive-min.css';

import factory_forms from 'dash/factoryforms';
import LicensePage from 'dash/licenses/LicensesPage';
import system_forms from 'dash/systemforms';
import SystemOsStatus from 'dash/SystemOsStatus';
import SystemConfiguration from 'dash/SystemConfiguration';
import { InterfaceView } from 'netconfig/interfacetable';
import PromoteView from 'netconfig/dash/netconfigpromoteview';
import { UsersPage } from 'userspage';
import EventLogTable from 'dash/debugging/EventLogTable';
import DebuggingPage from 'dash/debugging/DebuggingPage';
import LogView from 'dash/debugging/LogView';
import UpgradeLog from 'dash/debugging/UpgradeLog';
import ProcessesLog from 'dash/debugging/ProcessesLog';
import LicenseDetail from 'dash/licenses/LicenseDetail';
import LoginView from 'dash/login';
import Config from 'config';

import PropTypes from 'prop-types';
import { with_bare_focus } from 'dash/focusprovider';

const DEFAULT_PROPS = {
    'product_name': Config.company_short_name + ' Device',
    'login_redirect': '/accounts/login',
    'system_status_callback': null,
    'root_url': '/',
    'tacacs': true,
    'system_routes': [],
    'routes_prepend': [],
    'routes_append': [],

    'system_prepend': [],
    'system_append': [],
    'factory_prepend': [],
    'factory_append': [],
};

const SystemGroup = (props) => (
    {
        'header': 'System',
        'path': `${props.root_url}sys/`,
        'component': Redirect,
        'props': {
            'to': `${props.root_url}sys/configuration/`,
        },
        'routes': [
            {
                'header': 'Setup',
                'path': `${props.root_url}sys/configuration/`,
                'component': SystemConfiguration,
                'permission': 'config.view_system',
                'props': {
                    'forms': system_forms(props),
                },
                'title': 'OS Configuration',
            },
            {
                'header': 'Status',
                'key': 'os-status',
                'path': `${props.root_url}sys/os-status/`,
                'component': SystemOsStatus,
                'title': 'System OS Status',
                'permission': 'config.view_system',
                'props': {
                    system_status_callback: props.system_status_callback,
                },
            },
            {
                'header': 'Network',
                'key': 'network',
                'path': `${props.root_url}sys/network/`,
                'link_url': `${props.root_url}sys/network/`,
                'component': InterfaceView,
                'exact': true,
                'title': 'Network Configuration',
                'permission': 'config.view_system',
                'routes': [
                    {
                        'header': 'Promote',
                        'path': `${props.root_url}sys/network/promote/`,
                        'component': PromoteView,
                        'hideInNav': true,
                        'exact': true,
                        'title': 'Network Promote',
                        'permission': 'config.view_system',
                    },
                ],
            },
            {
                'header': 'Users',
                'key': 'users',
                'path': `${props.root_url}sys/users/`,
                'title': 'User Accounts',
                'props': {
                    'tacacs': props.tacacs === undefined ? true : props.tacacs,
                },
                'component': UsersPage
            },
            ...(props.system_routes || []),
            {
                'header': 'EULA',
                'path': '/license/',
                'title': 'End User License Agreement',
                'exact': true,
                'hideInNav': true,
                'component': Redirect,
                'props': {
                    'to': `${props.root_url}sys/licenses/`,
                },
            },
            {
                'header': 'Licenses',
                'path': `${props.root_url}sys/licenses/`,
                'title': 'Licenses',
                'component': LicensePage,
                'routes': [
                    {
                        'header': 'License Detail',
                        'path': `${props.root_url}sys/licenses/:package/`,
                        'component': LicenseDetail,
                        'exact': true,
                    },
                    {
                        'header': 'License Detail 2',
                        'path': `${props.root_url}sys/licenses/:package/:license/`,
                        'component': LicenseDetail,
                    },
                ]
            },
            {
                'header': 'Debugging',
                'key': 'debugging',
                'path': `${props.root_url}sys/debugging/`,
                'link_url': `${props.root_url}sys/debugging/`,
                'title': 'Debugging',
                'permission': 'config.debugging',
                'component': DebuggingPage,
                'routes': [
                    {
                        'header': 'Event Log',
                        'path': `${props.root_url}sys/debugging/events/`,
                        'permission': 'config.debugging',
                        'component': EventLogTable,
                    },
                    {
                        'header': 'Processes',
                        'path': `${props.root_url}sys/debugging/processes/`,
                        'component': ProcessesLog,
                        'permission': 'config.scary_debugging',
                    },
                    {
                        'header': 'Upgrade Log',
                        'path': `${props.root_url}sys/debugging/upgrade-progress/`,
                        'component': UpgradeLog,
                        'permission': 'config.upgrade',
                    },
                ],
            },
            {
                'header': 'Log View',
                'key': 'logview',
                'hideInNav': true,
                'path': `${props.root_url}sys/log/:log_name`,
                'component': LogView,
                'renderer': (props) => {
                    const log_name = props.match.params.log_name;
                    return <LogView log_name={log_name} />;
                },
            },
            {
                'header': 'Login',
                'path': `/accounts/login/`, // note absolute path rather than relative...
                'component': LoginView,
                'no_sidebar': true,
                'hideInNav': true,
                'public': true,
                'title': `${props.product_name} Login`,
                'props': {
                    'redirect_target': props.login_redirect,
                }
            },
        ].filter(route => ((props.system_route_skip || []).indexOf(route.key) == -1)),
    }
);
const FactoryGroup = (props) => {
    return {
        'header': 'Factory',
        'path': `${props.root_url}factory/`,
        'permission': 'factory.edit',
        'component': SystemConfiguration,
        'props': {
            'forms': factory_forms(props),
        },
    };
};

const BaseRoutes = (props) => {
    const final_props = {
        ...DEFAULT_PROPS,
        ...props,
    };
    if (!final_props.root_url.match(/[/]$/)) {
        console.error(`Root URL must have trailing slash (/) character, got ${final_props.root_url}`);
    }
    return [
        /* Constructs overall GUI routes based on passed props */
        ...(final_props.routes_prepend || []),
        SystemGroup(final_props),
        FactoryGroup(final_props),
        ...(final_props.routes_append || []),
    ];
};
BaseRoutes.propTypes = {
    'product_name': PropTypes.string.isRequired, // required product name
    'root_url': PropTypes.string, // Override default base url of '/'
    'tacacs': PropTypes.bool, // Enable tacacs support (default true)
    'routes_prepend': PropTypes.array, // Route-groups to add before System and Factory
    'routes_append': PropTypes.array, // Route-groups to add after System and Factory

    'system_routes': PropTypes.array, // System top-level routes added before licensing...
    'system_prepend': PropTypes.array, // extra ExpansionSection.sections structs to add to start of system page
    'system_append': PropTypes.array, // extra ExpansionSection.sections structs to add to the end of system page
    'factory_prepend': PropTypes.array, // extra ExpansionSection.sections for start of factory page
    'factory_append': PropTypes.array, // extra ExpansionSection.sections for endof factory page
};
FactoryGroup.propTypes = {
    'product_name': PropTypes.string.isRequired, // required product name
    'factory_prepend': PropTypes.array, // extra expansion sections for start of factory page
    'factory_append': PropTypes.array, // extra expansion sections for endof factory page
};

export { BaseRoutes, SystemGroup, FactoryGroup };
