import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { fa_icon } from 'faicon';
import '../fonts/materialicons.css';
import theme from 'dash/theme'; // YUCK

const styles = theme => ({
    icon: {
        display: 'inline-block',
        fontSize: '16px !important'
    }
});

class MuiIcon extends React.Component {
    render() {
        const { classes, name, color = null, title, className = '', ...restProps } = this.props;
        const icon_mapping = theme.icons;

        if (icon_mapping[name] !== undefined) {
            // TODO: title/tooltip is needed still...
            var final_name = icon_mapping[name];
            let style = null;
            if (color) {
                style = { color: color };
            }
            return <Icon style={style} {...restProps} className={classNames(classes.icon, className)}>{final_name}</Icon>;
        } else {
            return fa_icon(name, title);
        }
    }
}

MuiIcon.propTypes = {
    name: PropTypes.string.isRequired
};

export default withStyles(styles)(MuiIcon);
