/* Modern/Dash version of Netconfig Promote Button */
import React from 'react';
import EditIntent from 'editintent';
import { providing_nearest_form } from 'formprovider';
import { WithCurrentUser } from 'withcurrentuser';
import { Redirect } from 'react-router-dom';

const NetconfigPromoteButton = (props) => {
    const { user } = props;
    const [state, setState] = React.useState(null);
    const on_promote = (evt) => {
        setState({
            'redirect': true,
        });
    };
    if (state && state.redirect) {
        // This is for dev servers which run on http
        const host = window.protocol === 'https' ? window.location.hostname : window.location.host;
        const target_url = `http://${host}/media/.recovery-app/network-promote-app.html?to=${window.location.pathname}`;
        console.log(`Redirecting to ${target_url}`);
        window.location.href = target_url;
        return <div>Redirecting</div>;
        // return <Redirect to={target_url} />;
    }
    let disabled = !user.has_permission('netconfig.edit');
    return <EditIntent
        target={{
            '__type__': 'Interface',
        }}
        key='promote-trigger'
        form_key='PromoteInterfaces'
        initial_post={{
            'promote': true,
        }}
        disabled={disabled}
        onSave={on_promote}
        label='Apply Configuration'
        color='primary'
        help_text='Apply the configuration to the network, the machine may reboot to attempt to recover from any failures'
        action='Apply'
    />;
};
export default providing_nearest_form(
    WithCurrentUser(NetconfigPromoteButton),
    'net_forms'
);
