/* Provides the ping control for network interfaces */
import React from "react";
import {EditIntent} from 'editintent';
import {stores} from 'storeregistry';
import {form_action,construct_controls,ReactForm} from 'reactform';
import { using_nearest_form,providing_nearest_form } from "formprovider";

/** Provides a Ping dialog when bound to an interface 

@param {object} target -- target Interface (structure) to ping
@param {object} storage -- storage on which to run (default `stores.forms`)

*/

const describe_ping = ( iface ) => {
    return 'on '+iface['interface'];
};

class BasePingForm extends React.Component {
    static defaultProps = {
        target: null,
        storage: null
    }
    handlePing( evt ) {
        /* event handler that requests a ping from the server on the interface */
        var self = this;
        var target = self.props.target;
        var base = self.currentFormValues();
        if (self.isMounted()) {
            self.setState({'saving':true});
        }
        var storage = (self.props.storage || stores.forms);
        storage.save_form(
            self.getFormKey(),
            target.id,
            base
        ).then( self.handlePingResponse , function(err) {
            self.handleError( err, 'Pinging host' );
            return err;
        });
        return true;
    }
    handlePingResponse( response, form, action ) {
        console.log( 'Response'+response);
        var className = 'instructions ping-result';
        if (! response.success) {
            className += ' error';
        }
        var current = form.state.form_details;
        if (!current) {
            current = form.props.form_details;
        }
        current.instructions = {
            'tag': 'div',
            'className':className,
            children : [
                {'tag':'h2','children':'Ping Results for '+response.address},
                {'tag':'pre','children': response.messages }
            ]
        };
        form.setState({'form_details':current});
    }
    
    render() {
        var self = this;
        var ping = form_action({
            key: 'ping',
            name: 'Ping',
            icon: 'signal',
            help_text: 'Ping the specified host',
            callback: self.handlePingResponse,
            errback: function( err, form, action ) {
                console.log("Failed");
            }
        });
        const form_actions = (form) => {
            var buttons = [ ping.render( form ), 'cancel']; 
            return construct_controls( form, buttons );
        };
        return <ReactForm 
            form_key='PingForm'
            {...this.props} 
            describe={describe_ping} 
            form_actions={form_actions}
        />;
    }
}
class PingControl extends React.Component {
    handleSave( evt ) {
        console.log( 'Save completed' );
        return false;
    }
    render() {
        return <EditIntent 
            label="Ping" 
            action="Ping"
            form={PingForm} 
            describe={describe_ping}
            onSave={this.handleSave} 
            icon="signal" 
            target={this.props.target} 
            form_key="PingForm"
        />;
    }
}
var PingControlFactory = React.createFactory( PingControl );
const PingForm = providing_nearest_form(using_nearest_form(BasePingForm), 'net_forms');

export {PingForm, PingControl, PingControlFactory};
