import $ from 'fakequery';
import React from 'react';

import {UPLOAD_STORAGE} from 'baseuploader';
import {ClassyWidget} from 'reactform/classywidget';

class FileUploadSelect extends ClassyWidget {
    /* select control to choose already-uploaded media files */
    static defaultProps = {
        storage: UPLOAD_STORAGE,
    }
    constructor(props) {
        super(props);
        this.handler = function(storage) {
            /* instance-specific so that we can *unlisten* later */
            this.handleStorageChange(storage); 
        }.bind(this);
        this.state = {
            uploads: [],
            storage: props.storage || UPLOAD_STORAGE
        };
    }
    componentWillMount() {
        this.state.storage.signal.listen( this.handler );
        this.state.storage.start();
    }
    componentWillUnmount() {
        this.state.storage.signal.ignore( this.handler );
    }
    handleStorageChange( storage ) {
        this.setState({
            uploads:storage.uploads.slice(0,storage.uploads.length)
        });
    }
    render() {
        var current = this.current_value();
        var inputs = $.map( this.state.uploads, function( upload ) {
            var handler = function() {
                this.handleChoice( upload, true );
            };
            return <tr className="upload" key={upload.upload_id}>
                <th>
                    <input type="radio" checked={current===upload.upload_id?'CHECKED':null} onChange={handler} />
                </th>
                <td>{upload.filename}</td>
            </tr>;
        });
        if (inputs.length == 0) {
            inputs.push(<tr className="empty" key="empty"><td>No uploads yet</td></tr>);
        }
        return <table className="compact-list no-bullets upload-select">
            <tbody>
                {inputs}
            </tbody>
        </table>;
    }
}

export default FileUploadSelect;