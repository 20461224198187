import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import MuiIcon from 'dash/MuiIcon';

const styles = theme => ({
    centered: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginLeft: `${theme.spacing(0.5)}px`
    }
});

class MuiButton extends React.Component {
    render() {
        const { children, classes, icon, iconColor = null, className = '', ...buttonProps } = this.props;
        return (
            <Button className={classNames(classes.centered, className)} {...buttonProps}>
                {icon && <MuiIcon name={icon} color={iconColor} />}
                <div className={classNames(classes.centered, { [classes.icon]: icon })}>{children}</div>
            </Button>
        );
    }
}

export default withStyles(styles)(MuiButton);
