import React from 'react';
import Grid from '@material-ui/core/Grid';
import ExpansionSection from 'dash/ExpansionSection';
import { UserSummary, UserTable } from 'usertable';
import { TacacsSummary, TacacsConfig } from 'tacacsconfig';
import EditingCard from 'dash/editingcard';
import { EditIntent } from 'editintent';
import { providing_nearest_form } from 'formprovider';
import WithCurrentUser from 'withcurrentuser';
import Config from 'config';

const BaseUsersPage = (props) => {
    const { user } = props;
    const locationHash = location.hash ? location.hash.replace('#', '') : null;

    return <div className='user-configuration'>
        {[
            {
                'key': 'local-users',
                'collapsing': 'local-users',
                'title': 'Local Users',
                'no_wrapper': true,
                'summary': <UserSummary />,
                'children': <UserTable />,
                'actions': [
                    user.has_permission('auth.create_user') &&
                    <EditIntent target={{ 'type': 'User' }} label='Add User' key='new-user' />,
                    user.has_permission('auth.create_group') &&
                    <EditIntent target={{ 'type': 'Group' }} label='Add Group' key='new-group' />
                ],
            },
            props && props.tacacs && {
                'key': 'tacacs-users',
                'title': 'Tacacs+',
                'summary': <TacacsSummary />,
                'details': <React.Fragment>
                    <div>Users using TACACS+ authentication may log into
                        the machine using their TACACS+ credentials when enabled. You
                        must provide the host and shared secret in order to perform
                        the TACACS+ authentication with the server.
                    </div>
                    <div>You may add users to a set of user groups by defining the
                        TACACS+ property&nbsp;<code>{`${Config.company_name.replace(' ', '')}UserGroups`}</code>
                        &nbsp;with a comma-separated list of group names such as <code>administrator,customer-west-ops</code>.
                    </div>
                </React.Fragment>,
                'permission': 'tacacsauth.edit_config',
                'children': <TacacsConfig />
            }
        ].map(form => {
            if (form.permission && !user.has_permission(form.permission)) {
                return null;
            }
            return (
                <EditingCard
                    key={form.key || form.title}
                    defaultExpanded={locationHash === form.title.toLowerCase()}
                    title={form.title}
                    summary={form.summary}
                    children={form.details}
                    collapsing={form.key || form.title}
                    no_wrapper={form.no_wrapper}
                    actions={form.actions}
                >
                    {
                        form.details ?
                            <Grid container spacing={3}>
                                <Grid item xs={8}>{form.children}</Grid>
                                <Grid item xs={4}>{form.details}</Grid>
                            </Grid> :
                            form.children
                    }
                </EditingCard>
            );
        })}
    </div>;
};
const UsersPage = providing_nearest_form(WithCurrentUser(BaseUsersPage), 'system_forms');

export default UsersPage;
export { UsersPage };