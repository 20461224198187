import React from 'react';
import { ClassyWidget } from 'reactform/classywidget';
import WidgetRender from 'widgets/custom_widget_render';
import { withStyles } from '@material-ui/core/styles';
import TuningTableEditor from './tuningtableeditor';

class TuningTableWidget extends ClassyWidget {
    static defaultProps = {
        ...ClassyWidget.defaultProps,
    }
    render() {
        const { show_label } = this.props;
        var field = this.get_field();
        const stations = this.widget_data('stations') || [];
        const no_stations = (!(stations && stations.length)) ? <div className="warning">Click save with an EPG Feed selected to get the TMSID search-box</div> : null;

        return <WidgetRender
            form_field={field}
            show_label={show_label}
        >
            {no_stations}
            <TuningTableEditor
                value={this.render_value()}
                stations={stations}
                onChange={value => this.handleChange(value)}
            />
        </WidgetRender>;
    }
}


export default TuningTableWidget;
