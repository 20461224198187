import React from 'react';
import ReactDOM from 'react-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { CurrentUserProvider } from 'withcurrentuser';
import { BrowserRouter as Router } from 'react-router-dom';
// import {withRouter} from 'react-router-dom';

import AppRoutes from 'dash/AppRouter';
import theme from 'dash/theme';
import 'scss/atx-dash.scss';
import '../fonts/materialicons.css';
import { default_system_storage } from 'systemstorage';
import { DBPrefProvider } from 'dash/userprefs';

const DEFAULT_CONFIG = {
    theme: theme,
};
default_system_storage();

// const Test = withRouter((props) => {
//     return <div>Sample text with router</div>;
// });

const validate_routes = (routes) => {
    /* Do some sanity checks on routes before loading them */
    routes.map(route => {
        // if (route.component) {
        //     if (typeof route.component !== "function") {
        //         console.warn(`route ${route.path} declares a non-function 'component', type ${typeof route.component}`);
        //     }
        // }
        if (!route.path) {
            console.warn(`Route has no path ${JSON.stringify(route)}`);
        }
        // We fallback to path
        // if (!route.hideInNav) {
        //     if (!route.link_url) {
        //         console.warn(`You may want to define a link_url (string or function) for route ${route.path} (otherwise just links to path)`);
        //     }
        // }
        if (route.routes) {
            validate_routes(route.routes);
        }
    });
};
const RouteWrapper = (props) => {
    return <div
        {...props}
    >{props.children}</div>
};

const BaseApp = (config, Component = RouteWrapper) => {
    /* Create Shogun Dashboard Based Applications
    
    Component -- provide custom context for children, defaults to <div>
    
    */
    const theme = theme || DEFAULT_CONFIG.theme;
    const { routes, base_url } = config;
    validate_routes(routes);

    class App extends React.Component {

        render() {
            if (!Component) {
                Component = 'div';
            }
            return (
                <CurrentUserProvider>
                    <ThemeProvider theme={theme}>
                        <DBPrefProvider>
                            <Router>
                                {/* <Test /> */}
                                <CssBaseline />
                                <Component
                                    style={{
                                        display: 'flex',
                                        fontFamily: theme.typography.fontFamily,
                                        paddingTop: theme.toolbar.height,
                                    }}
                                    className='route-wrapper'

                                >
                                    <AppRoutes routes={routes} base_url={base_url || '/'} app_config={config} />
                                </Component>
                            </Router>
                        </DBPrefProvider>
                    </ThemeProvider>
                </CurrentUserProvider>
            );
        }
        static install(element_id) {
            ReactDOM.render(<App />, document.getElementById(element_id));
        }
    }
    return App;
};
export default BaseApp;
