/* Network configuration view */
import React from 'react';
import {EditIntent,delete_intent} from 'editintent';
import {LightFactory} from 'light';
import {providing_nearest_form} from 'formprovider';

/** Button to Edit Current or Create New ACME (Let's Encrypt) SSL */
class ACMESSL extends React.Component {
    render() {
        const { ssl } = this.props;

        if (ssl && ssl.key) {
            var icon = LightFactory({
                icon: 'pencil',
                ok: ssl.ok,
                warning: ssl.warning
            });
            return <EditIntent
                key={ssl.key}
                target={ssl}
                icon={icon}
                action='Edit'
                label={ssl.domains ? 'ACME SSL '+ ssl.domains: 'ACME SSL Certificate'}
            />;
        } else {
            return <EditIntent
                key='create-ACMESSL'
                form_key='ACMESSL'
                target={{id:null,type:'ACMESSL'}}
                action='Create'
                label='ACME SSL Certificate'
                help_text="Create a public SSL certificate using Let's Encrypt for machines exposed to the internet"
            />;
        }
    }
}

/** Button to Edit Current or Create New Uploaded SSL */
class UploadedSSL extends React.Component {
    render() {
        const { ssl } = this.props;

        if (ssl && ssl.key) {
            return <EditIntent
                key={ssl.key}
                target={ssl}
                action="Edit"
                label="Uploaded SSL"
            />;
        } else {
            return <EditIntent
                key='create-UploadedSSL'
                form_key="UploadedSSL"
                target={{id:null,type:'UploadedSSL'}}
                action='Upload'
                label='SSL Certificate'
                help_text="Configure a public SSL certificate by uploading an SSL key and certificate"
            />;
        }        
    }
}

/** Editor section to configure SSL setup (ACME or Uploaded) */
class SslConfig extends React.Component {
    render() {
        const { ssl } = this.props;

        return (
            <React.Fragment>
                {
                    (!ssl || ssl.type === 'ACMESSL') &&
                    <EditIntent 
                        key={ssl?ssl.key:'Create-ACMESSL'}
                        target={ssl||{type:'ACMESSL'}}
                        label="ACME SSL"
                        form_key='ACMESSL'
                        help_text="Configure a public SSL certificate using Let's Encrypt"
                    />
                }
                {
                    (!ssl || ssl.type === 'UploadedSSL') && 
                    <EditIntent
                        key={ssl?ssl.key:'Create-UploadedSSL'}
                        target={ssl||{type:'UploadedSSL'}}
                        form_key='UploadedSSL'
                        label="Uploaded SSL"
                        help_text="Configure a public SSL certificate by uploading the certificate and key"
                    />
                }
                {
                    (ssl) &&
                    delete_intent(ssl)
                }
            </React.Fragment>
        );        
    }
}

export default providing_nearest_form(SslConfig,'net_forms');
