import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

import ScrollContainer from 'dash/ScrollContainer';
import SectionTitle from 'dash/SectionTitle';

class PackageLicenses extends React.Component {
    render() {
        const { config, match } = this.props;
        const { licenses } = config;

        return (
            <div>
                <SectionTitle
                    title={`${licenses.os} Package Licenses`}
                    description="This product is built on an Open Source operating system, with the following packages installed. Should you wish to use the packages themselves or exercise other rights granted in your licenses, please see the package information on the linked pages, which includes links to the source code for the packages."
                />
                <ScrollContainer>
                    <List dense={true}>
                        {licenses.packages.map((pkg, i) => (
                            <ListItem
                                button
                                key={i}
                                component={Link}
                                to={`${match.path}${pkg[0]}/`}
                            >
                                <ListItemText>{pkg[0]}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </ScrollContainer>
            </div>
        );
    }
}

PackageLicenses.propTypes = {
    match: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
};

export default PackageLicenses;
