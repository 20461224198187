import React from 'react';
import { ReactForm } from 'reactform';

const forms = (props) => {
    const base = [
        ...(props.factory_prepend || []),
        {
            'title': 'Factory Serial',
            'permission': 'factory',
            'key': 'factory-serial',
            'get_summary': system => {
                const { serial } = system.system;

                return serial ? `Serial #${serial}` : null;
            },
            'render_component': props => (
                <ReactForm
                    target={props.system}
                    form_key={'SerialForm'}
                    use_dialog={false}
                />
            )
        },
        {
            'title': 'Product Theme',
            'permission': 'factory',
            'key': 'factory-theme',
            'get_summary': system => {
                return 'Theme Installer';
            },
            'render_component': props => (
                <ReactForm
                    target={props.system}
                    form_key={'ProductTheme'}
                    use_dialog={false}
                />
            )
        },
        ...(props.factory_append || []),
    ];
    return base.filter(
        (item) => (
            (!props.factory_skip) ||
            (props.factory_skip && props.factory_skip.indexOf(item.key) == -1)
        )
    );
}

export default forms;
export { forms };
