import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import MuiIcon from 'dash/MuiIcon';
import { NavLink } from 'react-router-dom';
import { ActiveRouteContext, get_page_url, current_user_can_view } from 'dash/navigationUtils';
import { FocusContext } from 'dash/focusprovider';
import { useCurrentUser } from 'withcurrentuser';
import Sysinfo from 'dash/sidebarinfo';
import PAGE_FOCUS from 'storages/pagefocus';
import redirect from '../redirect';

const styles = theme => ({
    drawerPaper: {
        marginTop: theme.toolbar.height,
        height: `calc(100vh - ${theme.toolbar.height})`,
        background: theme.palette.white,
        whiteSpace: 'nowrap',
        width: theme.sidebarWidth
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        color: 'white',
        width: theme.spacing(0.5)
    },
    navigationList: {
        flex: 1
    },
    nestedList: {
        opacity: 0.75
    },
    productLogo: {
        width: `${theme.sidebarWidth - 20}px !important`,
        height: 40,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundRepeat: 'no-repeat',
        backgroundSize: '80%',
        backgroundPosition: 'center',
        backgroundImage: "url('/ptheme/img/logo.svg')",
    },
    disabledLink: {
        color: theme.palette.grey[200],
    },
});



let RenderListItem = (props) => {
    /* Render a sidebar list item */
    let { page, nested = false, focus, user, routes, activeRoutes, classes } = props;
    if (!can_render(page, user)) {
        return null;
    }
    let link_url = get_page_url(page, focus, user, routes);
    if (!link_url) {
        console.log(`No link url for ${page.path} on ${focus && focus.__type__}.${focus && focus.__pk__}`)
    }
    const selected = !!(activeRoutes.filter(route => page.path === route.path).length);
    return (
        <ListItem
            key={`${page.path}`}
            selected={selected}
            component={link_url ? NavLink : 'span'}
            to={link_url}
            dense={!!nested}
            disabled={!link_url}
        // className={(!link_url) ? classes.disabled : null}
        >
            <ListItemText primary={page.header} />
        </ListItem>
    );
}
let can_render = (page, user) => {
    /* Does the given page have a render operation? */
    if (page.renderer || page.component) {
        if (!page.hideInNav) {
            if (current_user_can_view(page, user)) {
                return true;
            }
        }
    }
    return false;
}

let RenderNestedList = (props) => {
    const { header, activeRoutes, classes, user, history, routes, focus } = props;
    const isExpanded = !!(activeRoutes.filter(route => header.path === route.path).length);
    if (!current_user_can_view(header, user)) {
        return null;
    }
    let link_url = get_page_url(header, focus, user, routes);
    const on_click_header = (evt) => {
        /* Handle user clicking on the header */
        let page = header;
        if (!can_render(page)) {
            if (header.routes && header.routes.length) {
                const visible_children = header.routes.filter(route => can_render(route, user));
                if (visible_children.length) {
                    page = visible_children[0];
                } else {
                    console.error(
                        `Header ${header.path} has no renderable Component or render method cannot find a renderable child`
                    );
                    return;
                }
            } else {
                console.error(
                    `Header ${header.path} has no renderable Component or render method`
                );
                return;
            }
        }
        redirect(link_url, history);
        evt.preventDefault();
    }
    const visible_routes = header.routes
        .filter(page => !page.hideInNav)
        .filter(page => current_user_can_view(page, user));
    const has_children = visible_routes.length > 0 ? true : null;
    if (!has_children) {
        /* Nested only logically, not graphically */
        return <ListItem
            key={`${header.path}`}
            selected={isExpanded}
            component={link_url ? NavLink : 'span'}
            to={link_url}
            disabled={!link_url}
        // className={(!link_url) ? classes.disabled : null}
        >
            <ListItemText primary={header.header} />
        </ListItem>

    }

    return (
        <div key={`${header.path}`}>
            <ListItem onClick={evt => on_click_header(evt)} selected={isExpanded}>
                <ListItemText primary={header.header} />
                {
                    has_children
                    && (isExpanded ? (
                        <MuiIcon name="expand_more" color='disabled' style={{ color: '#ccc' }} />
                    ) : (
                        <MuiIcon name="expand_more" />
                    ))
                }
            </ListItem>
            {has_children && <Collapse in={isExpanded}>
                <List className={classes.nestedList}>
                    {
                        visible_routes.map((page, index) => <RenderListItem
                            page={page}
                            nested={true}
                            user={user}
                            focus={focus}
                            routes={routes}
                            key={`route-${index}`}
                            activeRoutes={activeRoutes}
                            classes={classes}
                        />)}
                </List>
            </Collapse>}
        </div>
    );
};



const AppSidebar = (props) => {
    const { classes, location, routes, base_url, target, match, history } = props;
    const { activeRoute, activeRoutes } = React.useContext(ActiveRouteContext);
    const focus = React.useContext(FocusContext);
    const user = useCurrentUser();
    // console.log(`Rendering AppSidebar`);

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: classNames(classes.drawerPaper)
            }}
        >
            <List className={classes.navigationList} key={`menu-${(focus && focus.__pk__) || 'root'}-${activeRoute.path}`}>
                {routes
                    .filter(x => !x.hideInNav)
                    .filter(x => current_user_can_view(x, user))
                    .map(
                        (item, index) =>
                            item.routes && item.routes.length
                                ? <RenderNestedList {...{ header: item, focus, activeRoutes, classes, user, history, routes }} key={`route-${index}`} />
                                : <RenderListItem {...{ page: item, focus, activeRoutes, classes, user, history, routes }} key={`route-${index}`} />
                    )}
            </List>
            <div className="overall-header">
                <Sysinfo />
                <div
                    className={classNames(
                        'product-logo',
                        classes.productLogo
                    )}
                    style={{
                        backgroundUrl: routes.product_logo,
                    }}
                />
            </div>
        </Drawer>
    );

};

AppSidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    routes: PropTypes.array.isRequired
};

const BaseAppTabBar = (props) => {
    /* Horizontal variant of the AppSideBar */
    const { classes, location, routes, base_url, target, match, history } = props;
    const { activeRoute, activeRoutes } = React.useContext(ActiveRouteContext);
    const focus = React.useContext(FocusContext);
    const user = useCurrentUser();
    return <Tabs>

    </Tabs>;

}
const AppTabBar = withRouter(withStyles(styles)(BaseAppTabBar));

export default withRouter(withStyles(styles)(AppSidebar));
export {
    AppTabBar,
};