/* Sample summary "graph" type for dashboard */
import React from 'react';
import { register_graph } from 'dash/charts/graph';
import { extract_data, split_streams_by } from 'dash/charts/extractdataset';
import { graph_format, graph_metric, graph_key } from 'dash/charts/chartmodel';
import { query_color_map } from 'dash/charts/colormap';
import { makeStyles } from '@material-ui/core/styles';
import MuiButton from 'dash/MuiButton';

const useStyles = makeStyles((theme) => ({
    nodeSummary: {
        padding: '.15em',
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'flex-start',
        alignItems: 'stretch',
    },

}));

const NodeSummary = (props) => {
    const { graph, metric = null, format = null, color_map = null, link_callback = null, width = null, height = null } = props;

    const classes = useStyles();

    let extracted_series = null;
    if (graph.need_extraction === undefined || graph.need_extraction) {
        extracted_series = extract_data(graph, props.series, { ...(metric || {}), ...graph }, format || graph_format(graph));
    } else {
        extracted_series = props.series;
    }
    /* We only render the first data-set */
    extracted_series = extracted_series[0];

    /* Summary is just for those items with parseInt(grouped_by) > 20 */
    let total = 0;
    extracted_series.map((series) => { total += series.value; });
    extracted_series = extracted_series.filter((series) => {
        return true;
        return parseInt(series.grouped_by) > 20;
    }).sort((a, b) => parseInt(a.grouped_by) < parseInt(b.grouped_by));
    const levels = extracted_series.map(series => {
        const color = query_color_map(series.__key__, color_map);
        const label = series.label == 'null' ? 'Not Reporting' : series.label;
        return <MuiButton key={`level-${series.__key__}`} onClick={(evt) => {
            link_callback({
                value: series.grouped_by,
                label: label,
            }, metric.accessor, series);
        }} variant='contained' ><div style={{ display: 'inline-block', textOverflow: 'ellipsis', color: color }}>{series.value} in {label}</div></MuiButton>;
    });
    return <div className={classes.nodeSummary} style={{ width: width, height: height }}>
        {/* <div className={classes.subsetSummary} key='total'>{total} devices</div> */}
        {levels}
    </div>;
};
const enable_node_summary = () => {
    register_graph('node_status.level.summary', NodeSummary);
};

export default NodeSummary;
export { enable_node_summary };