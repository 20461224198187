import React from 'react';
import LogTable from './LogTable';

const styles = theme => ({
    tableCell: {
        maxWidth: '400px',
        wordWrap: 'break-word',
    }
});

const lineparser = /^[[]?(DEBUG|INFO|WARN|WARNING|ERROR|CRITICAL)[\]]?[ \t]+([^ ]+)[ \t]+([^ ]+)[ \t]+([^ ]+)[ \t](.*)/;
const subline_parser = /^\W*([a-zA-Z0-9-]+)[:]\W*[b]?["'](.*)['"]\W*$/;
const subrecord_parser = /^\W*(DEBUG|INFO|WARN|WARNING|ERROR|CRITICAL)\W+([a-zA-Z][a-zA-Z0-9-.]*)\W+[:]\W+(\d+)\W+(\d{4}[-]\d{2}[-]\d{2})\W+(\d{2}[:]\d{2}:\d{2})\W+(.*)$/;
const old_parser = /^\W*(DEBUG|INFO|WARN|WARNING|ERROR|CRITICAL)[:]([a-zA-Z][a-zA-Z0-9-.]*)[:](.*)\W*$/;
const LEVEL_NUMBERS = { 'DEBUG': 10, 'INFO': 20, 'WARNING': 30, 'WARN': 30, 'ERROR': 40, 'CRITICAL': 50 };

const parseLogLine = (line) => {
    try {
        const groups = lineparser.exec(line);
        if (!groups || !groups.length) {
            const old_record = old_parser.exec(line);
            if (old_record) {
                return {
                    levelname: old_record[1],
                    levelno: LEVEL_NUMBERS[old_record[1]],
                    name: old_record[2],
                    msg: old_record[3],
                    asctime: null,
                    style: 'old',
                };
            }
            console.log(`Base line parser and old style did not match ${line}`);
            return null;
        }
        const base = {
            levelname: groups[1],
            levelno: LEVEL_NUMBERS[groups[1]],
            name: groups[2],
            asctime: `${groups[3]} ${groups[4]}`,
            msg: `${groups[5]}`,
            style: 'base',
        };
        const subgroups = subline_parser.exec(base.msg);
        if (subgroups && subgroups.length > 2) {
            base.name = subgroups[1];
            base.msg = subgroups[2];
            base.style = 'subline';
            const subrecord = subrecord_parser.exec(base.msg);
            if (subrecord) {
                base.levelname = subrecord[1];
                base.levelno = LEVEL_NUMBERS[subrecord[1]],
                    base.name = subrecord[2];
                base.line = subrecord[3];
                base.msg = subrecord[6];
                base.style = 'subrecord';
            }
        }
        return base;
    } catch (e) {
        console.error(`Unable to parse line: ${line} ${e}`);
        return null;
    }
};
const parse_full_log = (log_data) => {
    return log_data.split('\n').map((line) => {
        try {
            return parseLogLine(line);
        } catch (e) {
            console.debug(`Failed to parse ${line}`);
        }
    }).filter(x => x);
};

const UpgradeLog = (props) => {
    return <LogTable
        parse_log={parse_full_log}
        form_key='upgrade-log'
        url='/media/upgrade-progress.log'
        log_name='upgrade-log.json'
        scary_level={20}
    />;
};

export default UpgradeLog; // withStyles(styles)(withRouter(WithCurrentUser(UpgradeLog)));
export { parseLogLine, UpgradeLog, LogTable, parse_full_log };
