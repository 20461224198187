import React from 'react';
import MuiIcon from 'dash/MuiIcon';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AlignDiv from 'reactform/aligndiv';
import theme from 'dash/theme';

const styles = theme => ({
    boolean: {
        textAlign: 'center',
        '&:focus-within': {
            borderColor: 'white',
        }
    },
    warning: {
        color: theme.palette.warning,
    },

});

class RenderBoolean extends React.Component {
    /* A ClassyWidget that *just* displays a boolean toggle-value */
    static defaultProps = {
        value: null,
        record: null,
        field: null,

        color: 'primary',

        true_class: null,
        false_class: null,
        null_class: null,

        true_title: 'Value is True',
        false_title: 'Value is False',
        null_title: 'Value is undefined/unset',

        onClick: null,
        onKeyUp: null,
    }
    state = {
        icon_ref: React.createRef()
    }
    focus = () => {
        if (this.state.icon_ref && this.state.icon_ref.current) {
            this.state.icon_ref.current.focus();
        }
    }
    render_value() {
        const {
            value,
            field,
            record,
            classes,
            color,
            null_class,
            null_title,
            true_class,
            true_title,
            false_class,
            false_title,
            ...childProps
        } = this.props;
        var name = null_class || theme.icons.bool_unknown;
        var title = null_title;
        if (value !== null && value !== undefined) {
            name = value ? true_class || theme.icons.bool_true : false_class || theme.icons.bool_false;
            title = value ? true_title : false_title;
        }
        return <AlignDiv
            alignment='center'
            className={classNames('boolean-display', classes.boolean)}
            {...childProps}
        >
            <Icon
                ref={this.state.icon_ref}
                onClick={this.props.onClick}
                onKeyUp={this.props.onKeyUp}
                title={title}
                color={color}
                fontSize='small'
            >{name}</Icon>
        </AlignDiv>;
    }
    render() {
        return this.render_value();
    }
}

const GoodBadBool = withStyles(styles)((props) => {
    /* Boolean where True is Good and False is Bad */
    const { classes } = props;
    let color = 'primary';
    if (!props.value) {
        if (props.value === null || props.value === undefined) {
            color = 'error';
        } else {
            color = 'error';
        }
    }
    return <RenderBoolean {...props} color={color} true_class={'check'} false_class={'error'} null_class={'check_box_outline_blank'} />;
});
const BadGoodBool = withStyles(styles)((props) => {
    /* Boolean where False is Good and True is Bad */
    return <RenderBoolean {...props} color={props.value ? 'error' : 'primary'} />;
});

const ErrorBoolean = withStyles(styles)((props) => {
    /* Boolean where False is Good and True is Error (Bad) */
    return <RenderBoolean {...props} color={props.value ? 'error' : 'primary'} false_class={'check'} true_class={'error'} />;
});

export default withStyles(styles)(RenderBoolean);
export { GoodBadBool, BadGoodBool, ErrorBoolean }