import $ from 'fakequery';
import {BaseStorage} from 'storages/basestorage';
import { stores } from 'storeregistry';

function TacacsStorage(props) {
    var storage = BaseStorage( {
        'key':'tacacs_config',
        'config': null,
        'refresh_on_post': true,
        'period': 10,
    });
    $.extend( storage, props || {});
    return storage;
}

function default_tacacs_cb(props) {
    if (stores.tacacs_config === undefined) {
        const store = TacacsStorage(props);
        store.poll();
        
    }
}

export {default_tacacs_cb, TacacsStorage};