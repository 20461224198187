/* Use metric storage using hooks vs. wrapped tables */
import React from 'react';
import { metric_storage } from "metrics/metricstorage";
import TabularStorage from 'storages/tabularstorage';
import useStorage from 'storages/usestorage';

const useMetricData = (props) => {
    /* Use (single) (last_status) metric data for given context 
    
    context -- limit query
    limit -- default 1, limit number of records
    query_type -- default 'last_status' type of metric query

    returns { data: [records], resources: {id:resource_record}, form_details: {fields:[...]}}
    for rendering the current result of the metric query
    */
    const { query_type = 'last_status', context = {}, limit = 1 } = props;

    const { final_storage_props = metric_storage({
        debug: true,
        ...props,
        query_type,
        default_context: context,
        data_filters: {}, // explicitly *not* filtered
        limit: limit,
    }) } = props;
    const tabularStorage = TabularStorage(final_storage_props);
    // tabularStorage.period = 5;
    // tabularStorage.debug = true;
    const [someState, setSomeState] = React.useState(tabularStorage);

    const onTabularChange = React.useCallback((storage) => {
        setSomeState({});
    }, [final_storage_props.key]);
    useStorage(tabularStorage, onTabularChange);
    return tabularStorage;
};

export default useMetricData;