import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DetailEditor from 'shogun/detaileditor';
import EditingCard from 'dash/editingcard';
import ReactFormTable from 'reactform/reactformtable';
import { WithTabularStorage } from 'storages/tabularstorage';

const useStyles = makeStyles({

});

const QueueList = (props) => {

};


const QueueView = (props) => {
    /* Describe a particular queue's structure and contents */

    const [queue, setQueue] = React.useState(props.initialQueue);
    if (queue) {
        return <QueueDetail />;
    } else {
        return <WithTabularStorage
            storage_props={{
                'forms_key': 'shogunconf_forms',
                'form_key': 'QueueList',
            }}
            Component={ReactFormTable}
            form_keys={['QueueList']}
            storage='shogunconf_forms'
            editable={false}
        />;
    }
};

const TriggerEditor = (props) => {

    const { __pk__ } = props;
    const classes = useStyles();
    const on_save = (form, update) => {
        console.log(`Response: ${JSON.stringify(update)}`);
        form.setState({ 'saving': false });
    };

    return <div className='trigger-editor'>
        <DetailEditor
            key={`detail-Trigger`}
            __type__={'Trigger'}
            __pk__={__pk__}
            storage='shogunconf_forms'
            onSave={on_save}
        />
        <EditingCard key='queues' title='Streams' collapsing='trigger-editor-queues'>
            <QueueView />
        </EditingCard>
    </div>;
};

export default TriggerEditor;