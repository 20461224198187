import React from 'react';
import { WithTabularStorage } from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import { ReactFormTable } from 'reactform';
import { with_focus } from 'dash/focusprovider';
import { delete_target_action } from 'reactform/actionmenu';
import { register, DefaultRender } from 'reactform/typerenderer';

const form_keys = ['ServiceDisplay'];

const service_type_link = (props) => {
    /* Default value renderer, null (empty), otherwise display value or otherwise stringify */
    const { value, record, field, alignment, classes } = props;
    record.service_type_key_url = `/m/services/by-type/${value}`;
    return <DefaultRender {...props} />;
};
register('service_type_link', service_type_link);

class BaseServicesTable extends React.Component {
    render() {
        const { form_details, service_group } = this.props;
        if (!(form_details)) {
            return <MuiLoader title={`No form details yet`} />;
        }
        return <ReactFormTable
            wrap_data={(record, table) => {
                return [record,];
            }}
            link_to_edit={true}
            // link_function={this.on_view_group.bind(this)}
            form_details={form_details}
            overall_target={service_group}
            field_actions={{
                'name': (target, context, menu) => {
                    return [
                        delete_target_action(target, context, menu),
                    ];
                }
            }}
            exclude={this.props.exclude}
            extra_fields={{
                // 'ServiceDisplay': [
                // ]
            }}
            widgets={{
                service_type_key: 'service_type_link',
                ...(this.props.widgets || {}),

            }}
            {...this.props}
        />;
    }
}

const services_storage = (service_group) => {
    /* Get a group-specific storage for given group *or* nodes/non-nodes */
    const context = (service_group !== undefined && service_group !== null) ? {
        service_group: service_group.id || service_group.__pk__ || service_group,
    } : {
    };
    return {
        default_context: context,
        forms_key: 'shogunconf_forms',
        form_key: 'ShogunServices',
        editing_forms: form_keys,
        quick_settings: {
        },
    };
};


const ServicesTable = (props) => {
    return <WithTabularStorage
        key={`service-groups-for-group-${props.storage_props.default_context.group || 'all'}`}
        storage_props={props.storage_props}
        Component={with_focus(BaseServicesTable)}
        form_keys={form_keys}
        service_group={props.storage_props.default_context.service_group}
        service_type={props.storage_props.default_context.service_type}
        exclude={props.exclude || ['notes', 'service_group']}
        preference_default={{
            hiddenColumns: [
                'service_role',
                ...((props.preference_default && props.preference_default.hiddenColumns) || []),
            ]
        }}
        preference_key={props.preference_key || `services-table`}
        {...props}
    />;
};
export default ServicesTable;
export { services_storage, BaseServicesTable, ServicesTable };
