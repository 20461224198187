import $ from 'fakequery';
import {BaseStorage} from 'storages/basestorage';
import { stores } from 'storeregistry';
import {requiring_store } from 'storages/requiringstore';

function SystemLogsStorage(props) {
    var storage = BaseStorage( {
        'key':'system_logs',
        'config': null,
        'refresh_on_post': true,
        url: '/api/system_logs_json'
    });
    $.extend( storage, props || {});
    return storage;
}

function default_cb(props) {
    if (!stores.system_logs) {
        SystemLogsStorage(props);
    }
}

function requiring_system_logs_store(Component) {
    return requiring_store(
        Component,
        {
            storage_key: 'system_logs',
            create_function: default_cb,
            forms_key: 'system_logs_form',
            prop_function: (props, storage) => {
                let {config} = storage;
                return {
                    ...props,
                    config,
                    storage
                };
            }
        }
    );
}

export {SystemLogsStorage, requiring_system_logs_store};
