import $ from "fakequery";
import { Signal } from "signals";
// import {POST_SIGNAL} from "storages/ajaxobservables";

var Periodic = function (state) {
    var periodic = {
        'period': 20,
        'debug': false,
        'signal': Signal(),
        'refresh_on_post': false,
        'backoff_multiplier': 1.25, // fractional multiplier on backoff
        'backoff_current': 1.0, // initial multiple of period
        'backoff_maximum': 5.0, // maximum multiple of period
        'timer': null
    };
    $.extend(periodic, state);
    $.extend(periodic, {
        backoff: function (multiplier) {
            if (multiplier === 0) {
                periodic.backoff_current = 1.0;
                return;
            }
            if (periodic.debug) {
                console.log('Doing backoff on periodic operation');
            }
            periodic.backoff_current = Math.min(
                (multiplier || periodic.backoff_multiplier) * periodic.backoff_current,
                periodic.backoff_maximum
            );
        },
        stop: function () {
            if (periodic.debug) {
                console.log("Stop: " + periodic.period);
            }
            if (periodic.timer) {
                window.clearTimeout(periodic.timer);
                periodic.timer = null;
            }
        },
        ensure: function () {
            if (!periodic.timer) {
                if (periodic.debug) {
                    console.log("Trigger on ensure: " + periodic.period);
                }
                periodic.trigger();
                return true; // has run...
            } else {
                if (periodic.debug) {
                    console.log("Already running on ensure: " + periodic.period);
                }
            }
            return false;
        },
        start: function () {
            periodic.stop();
            if (periodic.debug) {
                console.log("Start: " + periodic.period);
            }
            var duration = periodic.period * periodic.backoff_current * 1000;
            if (periodic.debug) {
                console.log("Next iteration: " + duration);
            }
            periodic.timer = window.setTimeout(function () {
                periodic.signal.send(periodic);
            }, duration);
        },
        trigger: function () {
            /* trigger an immediate restart, which is the same as any other start */
            periodic.signal.send(periodic);
            periodic.start();
        }
    });
    return periodic;
};

export default Periodic;
