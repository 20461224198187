import React from 'react';
import { providing_nearest_form } from 'formprovider';
import { ReactForm } from 'reactform';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import { store_by_key } from 'formprovider';
import { PAGE_FOCUS } from 'storages';
import { Button, List, ListItem } from '@material-ui/core';
import MuiTypography from 'dash/MuiTypography';
import { default_cancel_button, default_save_button } from '../../shared_modules/reactform/constructcontrols';
import ServiceBindings from 'widgets/servicebindings';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel'

const styles = theme => ({
    serviceList: {
        display: 'flex',
    },
    button: {
        width: '100%',
        flex: 1,
    },
});

const RenderServiceTypes = withStyles(styles)((props) => {
    /* Render resource/service types as a nested list */
    const { serviceMap, classes, setChoice } = props;
    return <List key="resource-type-list">
        {
            serviceMap.map(record => {
                const { resource_type, services } = record;
                return <ListItem key={resource_type.key}>
                    {resource_type.title}:
                    <List key={'services'} classes={{ root: classes.serviceList }}>
                        {
                            Object.keys(services).sort((x, y) => x < y ? -1 : (x == y ? 0 : 1)).map(k => {
                                const service = services[k];
                                const onChoice = (evt) => {
                                    setChoice({
                                        'resource_type': resource_type,
                                        'service_type': service,
                                    });
                                };
                                return <ListItem key={`service-role-${k}`}>
                                    <a classes={{ root: classes.button }} onClick={onChoice} key={`${k}-${service.key}`} color="secondary">{service.title}</a>
                                    {service.description}
                                </ListItem>;
                            })
                        }
                    </List>
                </ListItem>;
            })
        }
    </List>;
});

const RenderEditor = (props) => {
    /* Render editor for the service to create */
    const { selectedService, target, serviceGroup, setSelectedService, setServiceMap } = props;
    const { resource_type, service_type, role } = selectedService;
    const path = service_type.model.split('.');
    const model = path[path.length - 1];
    const form = service_type.create_form || model;
    return <React.Fragment>
        <MuiTypography key={`selected-key-${(resource_type && resource_type.key) || null}-${service_type.key}`}>Adding: {service_type.name}</MuiTypography>
        <ReactForm key={`creation-form-${(target && target.id) || 'root'}-${model}`} target={{
            type: model,
            id: null,
        }}
            form_key={form}
            onSave={evt => {
                setSelectedService(null);
                setServiceMap(null);
            }}
            context={{
                ...(service_type.context || {}),
                groups: (target && target.id),
                service_group: serviceGroup.id,
                owner: serviceGroup.owner,
                service_role: role,
                service_type_key: service_type.key,
            }}
            use_dialog={false}
            buttons={[
                'save',
                (form) => {
                    return default_cancel_button(form, {
                        key: 'clear-selected-service',
                        onClick: () => setSelectedService(null),
                    })
                }
            ]}
        />
    </React.Fragment>;
};

const GenericCreator = (props) => {
    /* Allows creation of any service-type that the customer wants, regardless of whether it's matches resources */
    const { serviceGroup, setSelectedService } = props;
    const [serviceSet, setServiceSet] = React.useState(null);
    const storage = store_by_key('shogunconf_forms');
    React.useEffect(() => {
        if (!serviceSet) {
            storage.get_form('ServiceCreateChoices').then(response => {
                if (response.success) {
                    // TODO: guard against unmounted since request...
                    response.ServiceCreateChoices.sort((a, b) => {
                        if (a.label.toLowerCase() < b.label.toLowerCase()) {
                            return -1;
                        } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    setServiceSet(response.ServiceCreateChoices);
                } else {
                    console.error(`Unable to load service creation choices: ${response.messages}`);
                }
            });
        }
    });

    let items = [
    ];
    if (serviceSet) {
        items = serviceSet.map(service => {
            return <MenuItem key={service.key} value={service.key}>{service.label}</MenuItem>;
        });
    }
    const onSelect = (evt) => {
        const key = evt.target.value;
        serviceSet.map(service => {
            if (service.key == key) {
                setSelectedService(
                    { 'service_type': service, 'resource_type': null, 'role': '', 'name': service.label }
                );
            }
        });
    };
    return <div title="Allow for creating services not explicitly required by the resources attached to the Config">
        <FormLabel>Create</FormLabel>
        {' '}
        <Select onChange={onSelect} value={0} style={{ minWidth: 200 }} >
            <MenuItem value={0} key='empty'>Select a service type</MenuItem>
            {items}
        </Select>
    </div >;

};

const ServiceCreator = (props) => {
    const { serviceGroup } = props;
    const target = PAGE_FOCUS.get_focus();
    const [serviceMap, setServiceMap] = React.useState(null);
    const [selectedService, setSelectedService] = React.useState(null);
    const storage = store_by_key('shogunconf_forms');
    React.useEffect(() => {
        if (!serviceMap) {
            storage.get_form('ServiceChoices', (target && (target.id || target.__pk__)) || 0).then(response => {
                if (response.success) {
                    // TODO: guard against unmounted since request...
                    setServiceMap(response.ServiceChoices);
                } else {
                    console.error(`Unable to load service map: ${response.messages}`);
                }
            });
        }
    });

    const chooseType = ((!selectedService) && <React.Fragment>
        {serviceMap && <ServiceBindings
            value={serviceMap}
            onCreate={setSelectedService}
        />}
        <GenericCreator
            serviceGroup={serviceGroup}
            setSelectedService={setSelectedService}
        />
    </React.Fragment>) || null;
    const createType = (selectedService && <RenderEditor
        target={target}
        selectedService={selectedService}
        setSelectedService={setSelectedService}
        serviceGroup={serviceGroup}
        setServiceMap={setServiceMap}
    />) || null;

    return <EditingCard title={`Add Service`} collapsing={`service-creator-card`}>
        {
            chooseType || createType || 'Loading services mapping'
        }
    </EditingCard>;

};

export default providing_nearest_form(ServiceCreator, 'shogunconf_forms');