import React from 'react';
import $ from 'fakequery';
import { withStyles } from '@material-ui/core/styles';

import { ClassyWidget } from 'reactform/classywidget';
import MuiTypography from 'dash/MuiTypography';
import WidgetRender from './custom_widget_render';
import Chip from '@material-ui/core/Chip';

const styles = (theme) => ({
    chipHolder: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

class ArrayDisplayWidget extends ClassyWidget {
    /* A ClassyWidget that displays an array as flex-list */
    render_component(value) {
        const { classes, show_label } = this.props;
        const field = this.get_field();
        const base_field = field.field;
        if (base_field && base_field.choices && base_field.choices.length) {
            /* Can we find this value in the choices? */
            const display = base_field.choices.filter(rec => `${rec[0]}` === `${value}`);
            if (display && display.length) {
                return <MuiTypography>{display[0][1]}</MuiTypography>;
            }
        }
        if (Array.isArray(value)) {
            return <div className={classes.chipHolder}>
                {value.map((item, index) => <Chip
                    variant="outlined"
                    size="small"
                    label={item}
                    key={`item-${index}`}
                />)}
            </div>;
        } else {
            return <div className={classes.chipHolder}></div>;
        }
    }
    render() {
        const { classes, show_label } = this.props;
        const field = this.get_field();
        const value = this.current_value();
        return <WidgetRender form_field={field} widget_class="array-display-widget" show_label={show_label}>
            {this.render_component(value)}
        </WidgetRender>;
    }
}

export default withStyles(styles)(ArrayDisplayWidget);