import React from 'react';
import {
    LineMarkSeries,
    Hint,
    XAxis,
    XYPlot,
    YAxis,
    FlexibleXYPlot,
    Borders, Highlight, DiscreteColorLegend
} from 'react-vis';
import Modal from "@material-ui/core/Modal";
import { Button } from '@material-ui/core';

const MSEC_DAILY = 86400000;
const timestamp = new Date("March 29 2019").getTime();
const SampleData1 = [
    {
        color: "#ccc",
        data: [
            { x: timestamp + MSEC_DAILY, y: 3 },
            { x: timestamp + MSEC_DAILY * 2, y: 5 },
            { x: timestamp + MSEC_DAILY * 3, y: 15 }
        ]
    },
    {
        color: "#ccc",
        data: [
            { x: timestamp + MSEC_DAILY, y: 5 },
            { x: timestamp + MSEC_DAILY * 2, y: 9 },
            { x: timestamp + MSEC_DAILY * 3, y: 2 }
        ]
    },
    {
        color: "#ccc",
        data: [
            { x: timestamp + MSEC_DAILY, y: 11 },
            { x: timestamp + MSEC_DAILY * 2, y: 6 },
            { x: timestamp + MSEC_DAILY * 3, y: 3 }
        ]
    }
];

const calculate_average = (dataset) => {
    const average = []
    let i = 0;
    dataset.forEach((data, idx) => {
        if (!data.data) {
            return;
        }
        i += 1;
        data.data.forEach((cor, j) => {
            if (average[j] === undefined) {
                average[j] = Object.assign({}, cor)
            } else {
                average[j]["y"] = average[j]["y"] + (cor["y"] - average[j]["y"]) / i
            }
        });
    });
    return { color: "#0F52BA", data: average, label: "Average" }; // different color for average line
}

export default class Guages extends React.Component {
    static defaultProps = {
        data: [],
        withZoomableModal: true,
        title: 'Metric View',
    }
    state = {
        index: null,
        hovered: null,
        open: false,
        lastDrawLocation: null,
        /*
        https://github.com/uber/react-vis/issues/1067
        to disable mouse listener of lines when brushing
        */
        brushing: false
    }

    handleOpen = () => {
        this.setState({ open: true, lastDrawLocation: null });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        let { data, withZoomableModal, title } = this.props;
        const { hovered, lastDrawLocation, brushing, open } = this.state;
        const renderZoomableChart = withZoomableModal !== undefined;

        let clickEvent, styles;
        if (renderZoomableChart) {
            clickEvent = this.handleOpen;
            styles = { cursor: "pointer" };
        }

        data = data ? data : SampleData1;

        // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
        // should use memoize-one as react recommends, to not calculate average for every render call
        const average = calculate_average(data);
        data = data.slice(0);
        // data.push(average);

        // legends
        let legend_items = [];
        data.forEach((element, i) => {
            if (!element) {
                return;
            }
            legend_items[i] = {
                color: element.color,
                title: element.label,
                disabled: this.state.index === i
            };
        });

        const render_series = data.map((item, idx) => {
            if (!item)
                return null;
            const color = this.state.index === idx ? (this.state.index === data.length - 1 ? "#f00" : "#777") : item.color;
            const fill = this.state.index === idx ? color : "#fff0";
            const size = this.state.index === idx ? 5 : 0;
            return {
                ...item,
                color: color,
                fill: fill,
                size: size
            };
        });

        return <React.Fragment>
            <div>
                {open ?
                    (<div style={{ width: '600px', height: '300px' }} />) // placeholder when displaying large graph
                    : (
                        <XYPlot
                            margin={{ left: 80, right: 10, top: 10, bottom: 80 }}
                            xType="time"
                            width={600}
                            height={300}
                            onMouseLeave={e => {
                                this.setState({ index: null }); // reset hover color
                            }}
                            onClick={clickEvent} style={styles}
                            {...this.props.chartProps}
                        >
                            {/* <XAxis title="Time" tickLabelAngle={-90} />
                            <YAxis title="Y Axis" /> */}
                            {render_series.map((data, idx) => {
                                if (!data)
                                    return null;
                                return (
                                    <LineMarkSeries
                                        key={idx}
                                        color={data.color}
                                        data={data.data}
                                        size={data.size}
                                        onSeriesMouseOver={evt => {
                                            this.setState({ index: idx });
                                        }}
                                        onValueMouseOver={d => this.setState({ hovered: d })}
                                        onValueMouseOut={d => this.setState({ hovered: false })}
                                    />
                                );
                            })}
                            {hovered && <Hint value={hovered} marginLeft={0} marginTop={0} />}
                        </XYPlot>
                    )
                }
            </div>
            <div key="zoomable-chart-example0">
                {renderZoomableChart &&
                    <Modal
                        open={this.state.open}
                        onClose={this.handleClose}>
                        <div className="chart-modal">
                            <div className="chart-modal-header">
                                <button type="button" className="close-btn" onClick={this.handleClose}>&times;</button>
                                <h4 className="modal-title">{title ? title : "Untitled"}</h4>
                            </div>
                            <div className={"chart-modal-content" + (brushing ? " brushing" : "")}>
                                <div className="legend">
                                    <DiscreteColorLegend orientation="vertical" width={200} items={legend_items}
                                        onItemMouseEnter={(item, i) => this.setState({ index: i })}
                                        onItemMouseLeave={() => this.setState({ index: null })} />
                                </div>
                                <div className="chart">
                                    <FlexibleXYPlot
                                        xDomain={
                                            lastDrawLocation && [
                                                lastDrawLocation.left,
                                                lastDrawLocation.right
                                            ]
                                        }
                                        margin={{ left: 80, right: 10, top: 10, bottom: 80 }}
                                        xType="time"
                                        onMouseLeave={e => {
                                            this.setState({ index: null }); // reset hover color
                                        }}
                                        {...this.props.chartProps}
                                    >
                                        <Highlight
                                            onBrushStart={() => this.setState({ brushing: true, index: null })}
                                            onBrushEnd={area => this.setState({ lastDrawLocation: area, brushing: false })}
                                            onDrag={area => {
                                                this.setState({
                                                    lastDrawLocation: {
                                                        bottom: lastDrawLocation.bottom + (area.top - area.bottom),
                                                        left: lastDrawLocation.left - (area.right - area.left),
                                                        right: lastDrawLocation.right - (area.right - area.left),
                                                        top: lastDrawLocation.top + (area.top - area.bottom)
                                                    }
                                                });
                                            }}
                                        />
                                        {render_series.map((data, idx) => {
                                            if (!data)
                                                return null;
                                            return (
                                                <LineMarkSeries
                                                    key={idx}
                                                    color={data.color}
                                                    data={data.data}
                                                    size={data.size}
                                                    onSeriesMouseOver={evt => {
                                                        this.setState({ index: idx });
                                                    }}
                                                    onValueMouseOver={d => this.setState({ hovered: d })}
                                                    onValueMouseOut={d => this.setState({ hovered: false })}
                                                />
                                            );
                                        })}
                                        <Borders style={{
                                            all: { fill: '#fff' }
                                        }} />
                                        {/* <XAxis title="Time" tickLabelAngle={-90} />
                                        <YAxis title="Y Axis" /> */}
                                        {hovered && <Hint value={hovered} marginLeft={0} marginTop={0} />}
                                    </FlexibleXYPlot>
                                </div>

                            </div>
                            {
                                <div style={{ height: "60px", textAlign: "center" }}>
                                    <Button variant="outlined" size="medium" color="primary" onClick={() => this.setState({ lastDrawLocation: null })}>Reset</Button>
                                </div>
                            }
                        </div>
                    </Modal>
                }
            </div>
        </React.Fragment>;
    }
}

