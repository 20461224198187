import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import LogTable from './LogTable';
import PropTypes from 'prop-types';
import { current_user } from 'storeregistry';

const styles = (theme) => ({
    logView: {
        backgroundColor: '#f0f0f0',
        padding: theme.spacing(1),
        '&:hover': {
            backgroundColor: '#c0c0c0'
        }
    }
});

class BaseLogView extends React.Component {
    static defaultProps = {
        LogTable: LogTable,
        log_name: null,
    };
    render() {
        const {log_name} = this.props;
        const {LogTable,...childProps} = this.props;
        const user = current_user();
        console.info(`Rendering log table ${LogTable.displayName} for ${log_name}`);
        return <React.Fragment>
            <EditingCard title={`Log View for ${log_name}`} key={`log-${log_name}`} no_wrapper={true}>
                <LogTable {...childProps} />
            </EditingCard>
        </React.Fragment>;
    }
}
const LogView = withStyles(styles)(BaseLogView);
LogView.propTypes = {
    'log_name': PropTypes.string.isRequired,
    'LogTable': PropTypes.element,
};

export default LogView;
export { BaseLogView, LogView};
