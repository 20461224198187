/* Render an mptt tree item with tree-data values */
import React from 'react';
import {withStyles} from '@material-ui/core/styles';

const TREE_INDENT = '1.5rem';
const TREE_ICON_FONT_SIZE = '.85rem';
const INDENT_ICON = '\u2BA9';
const styles = (theme) => ({
    tree_name: {
        display: 'flex',
    },
    tree_indent: {
        width: TREE_INDENT,
        fontSize: TREE_ICON_FONT_SIZE,
        textAlign: 'right',
        color: theme.palette.primary.very,
    },
    expanding_child: {
        'flex': 1,
    }
});

const TreeNameRenderer = (props) => {
    const { record, classes, Component, tree_id, icon } = props;
    const tree_data = record[tree_id];
    if (tree_data) {
        const levels = Array(tree_data.level).fill(1).map((l, i) => (
            <div key={`lvl-${i}`} className={classes.tree_indent}>{(i == tree_data.level - 1) && (icon || INDENT_ICON)}</div>
        ));
        return <div className={classes.tree_name}>
            {levels}
            <div className={classes.expanding_child}>
                <Component {...props} />
            </div>
        </div>;
    } else {
        return <Component {...props} />;
    }
};

export default withStyles(styles)(TreeNameRenderer);