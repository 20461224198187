import React from 'react';
import { basic_focus_title } from './helpers';
import { ListView } from 'shogun/listview';
import DetailEditor from 'shogun/detaileditor';


const crud_routes = ({ PREFIX }) => ([
    {
        'path': `${PREFIX}list/:type([a-zA-Z0-9]+)/`,
        'link_url': `${PREFIX}list/`,
        'exact': true,
        hideInNav: true,
        'header': 'List View',
        'title': 'List View',
        'component': ListView,
        'renderer': (props) => {
            const { match } = props;
            return <ListView key={`list-${match.params.type}`} __type__={match.params.type} storage='shogunconf_forms' />;
        }
    },
    {
        'path': `${PREFIX}detail/:type([a-zA-Z0-9]+)/`,
        'link_url': `${PREFIX}detail/`,
        'exact': true,
        hideInNav: true,
        'header': 'Record Creator',
        'title': 'Create New',
        'component': DetailEditor,
        'renderer': (props) => {
            const { match } = props;
            return <DetailEditor
                key={`detail-{match.params.type}.create`}
                __type__={match.params.type}
                __pk__=''
                storage='shogunconf_forms'
            />;
        }
    },
    {
        'path': `${PREFIX}detail/:type([a-zA-Z0-9]+)/:pk(\\d+)/`,
        'link_url': `${PREFIX}detail/`,
        hideInNav: true,
        'header': 'Record Editor',
        'title': basic_focus_title(
            (params) => `Edit ${params.type} #${params.pk}`,
            (focus) => `Edit: ${render_type_and_title(focus)}`,
        ),
        'component': DetailEditor,
        'renderer': (props) => {
            const { match } = props;
            return <DetailEditor
                key={`detail-${match.params.type}.${match.params.pk}`}
                __type__={match.params.type}
                __pk__={match.params.pk}
                storage='shogunconf_forms'
            />;
        }
    },
]);

export default crud_routes;