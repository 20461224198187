import moment from "moment";
import { build_color_map, query_color_map } from 'dash/charts/colormap';

const asDateTime = (value) => {
    return moment.unix(value).format('YYYY-MM-DD HH:mm');
};

const extract_redblack = (groups, chart, metric, domain = null) => {
    /* groups -- single dataset with group_by_accessor() called on it 
    
    
    */
    const series = [];

    let color_map = build_color_map(
        chart, metric,
        {
            'online': 'green',
            'playing': 'green',
            'timeout': '#ffff77',
            'buffering': '#ffff33',
            'error': 'red',
            'downloading': 'black',
        }
    );

    groups.map((group, grp_index) => {
        let all_points = group.value.map((point, pointIndex) => (
            {
                x: point['time'] * 1000,
                y: grp_index,
                label: `${group.label} ${asDateTime(point['time'])}: ${point.state}`,
                fill: color_map[point.state] || 'purple',
                original: point,
            }
        ));
        if (domain) {
            all_points = all_points.filter(record => record.x >= domain[0] && record.x <= domain[1]);
        }
        series.push({
            __type__: 'LineMarkSeries',
            key: `series-${grp_index}`,
            barWidth: 0.5,
            colorType: 'literal',
            fillType: 'literal',
            stroke: 0,
            label: group.label,
            data: all_points,
        });
    });
    return series;
};
export default extract_redblack;