import React from 'react';
import { register, with_align_div } from 'reactform/typerenderer';

const suppress_bubble = (evt) => {
    evt.stopPropagation();
    return false;
};


const CVECProxyLink = (props) => {
    const { value, label, field, record } = props;
    const stream_name = value;
    const stream_url = encodeURIComponent(stream_name);

    const title = 'View video streams from the streaming blade';
    const id = record.resource_id && record.resource_id.node_id;
    const display = <div
        className='suppress-link' onClick={suppress_bubble}
    ><a target="_blank" href={`/proxy/access/${id}/#name=${stream_url}`}>{stream_name}</a></div>;
    return with_align_div({
        ...props,
        title: title || label || (field && field.label) || null,
    }, display);
};

register('CVECProxyLink', CVECProxyLink);

export { CVECProxyLink };
export default CVECProxyLink;
