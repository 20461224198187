/* Observables for watching for updates */
import {Signal} from "signals";

const POST_SIGNAL = Signal({
    name: 'post-success',
    description: 'Signal triggered when AJAX back-end successfully posts anything'
});
const PERMISSION_FAILURE = Signal({
    name: 'permission-failure',
    description: 'Signal indicating that a request failed permission checking',
});
const USER_RESOLVED = Signal({
    name: 'current-user',
    description: 'Sent when current user resolves',
});


export {POST_SIGNAL, PERMISSION_FAILURE, USER_RESOLVED};