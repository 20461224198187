/* Gets moment.js timezones selection set for SelectionFromListWidget */
import React from 'react';
import moment from 'moment-timezone';
import SelectionFromListWidget from './SelectionFromListWidget';

const _get_timezone_choices = () => {
    /* Format moment timezone choices for user values */
    const tz = moment.tz;
    return tz.names().map( name => {
        const zone = moment.tz(name)._z; // Ick, get the internal object, why is this necessary?
        const abbrs = {};
        zone.abbrs.map(abbr => {
            abbrs[abbr] = true;
        });
        const abbr_set = Object.keys(abbrs);
        abbr_set.sort();
        return [name,`${zone.name} (${abbr_set.join(', ')})`];
    });
};
const timezone_choices = _get_timezone_choices();
const timezone_map = {};
timezone_choices.map((choice) => {
    timezone_map[choice[0]] = choice[1];
});

const MomentTimezoneWidget = (props) => {
    /* A SelectionFromListWidget that gets timezones from moment.js */
    props.field.field.choices = timezone_choices;
    return <SelectionFromListWidget 
        from_server={
            (value,widget) => {
                if (typeof value == 'string') {
                    if (value.length) {
                        if (value[0] == '[') {
                            value = JSON.parse(value);
                        } else {
                            value = value.split(',');
                        }
                    } else {
                        value = [];
                    }
                } else if (value === null || value === undefined) {
                    value = [];
                }
                return value.map((key) => [key,timezone_map[key] || key]);
            }
        }
        to_server={(records) => records.map(k => k[0]).join(',')}
        {...props} 
    />;
};
export default MomentTimezoneWidget;