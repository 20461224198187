import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import MuiSnackbar from 'dash/MuiSnackbar';

const styles = theme => ({
    snackbar: {
        marginTop: theme.spacing(2)
    }
});

class ReactFormSuccessOrError extends React.Component {
    static defaultProps = {
        'on_close': () => null,
        'details': {
            'errors': false,
            'message': null,
        },
        'default_messages': {
            'errors': 'Could not save. See messages above.',
            'success': 'Record has been updated',
        },
    }
    render() {
        const { classes, details, on_close, default_messages } = this.props;
        const message = details.message || (
            details.errors? default_messages.errors: default_messages.success
        );

        return (
            <div className={classes.snackbar}>
                <MuiSnackbar variant={details.errors ? 'error' : 'success'} onClose={on_close}>
                    <div className="overall-message">{message}</div>
                </MuiSnackbar>
            </div>
        );
    }
}

ReactFormSuccessOrError.propTypes = {
    classes: PropTypes.object.isRequired,
    on_close: PropTypes.func.isRequired,
    details: PropTypes.object.isRequired,
    default_messages: PropTypes.shape({
        'errors': PropTypes.string.isRequired,
        'success': PropTypes.string.isRequired,
    }),
};

export default withStyles(styles)(ReactFormSuccessOrError);
