/* React useEffect to subscribe to and refresh when a storage updates */
import React from 'react';

const useStorage = (storage, onChange) => {
    /* Given reference to the storage, arrange to subscription and unsubscription and polling 
    
    storage -- the BaseStorage instance you want to use with polling
    onChange(storage) -- callback to be registered/deregistered for storage changes,
                         you *MUST* ensure that onChange is memo-ised otherwise it will
                         cause a re-render on every cycle and DOS the system.

    */
    const changeCallback = React.useCallback(() => {
        console.log(`Deregistering with storage: ${storage.key}`);
        storage.change.ignore(onChange);
    }, [storage, onChange]);
    return React.useEffect(() => {
        storage.change.listen(onChange);
        console.log(`Registering with storage: ${storage.key}`);
        window.setTimeout(storage.poll, 5);
        return changeCallback;
    }, [storage.key, onChange]);
};
export default useStorage;
export { useStorage };