class DelayTimer {
    constructor( options ) {
        if (!options) {
            options = {
                'duration': 1.0
            };
        }
        this.duration = options.duration || 1.0;
        this.period_handle = null;
        this.after_handle = null;
    }
    periodic( duration, callback ) {
        /* call callback every duration, cancelling any current periodic check */
        if (this.period_handle || (! callback)) {
            window.clearInterval( this.after_handle );
        }
        if (callback) {
            this.period_handle = window.setInterval( callback, duration * 1000 );
        }
    }
    after( duration, callback ) {
        /* call callback after duration, cancelling any current after
       
        duration -- duration in seconds 
        callback -- callback to call...
        */
        if (this.after_handle || (! callback)) {
            window.clearTimeout( this.after_handle );
        }
        if (callback) {
            this.after_handle = window.setTimeout( callback, duration * 1000 );
        }
    }
}
export {DelayTimer};
