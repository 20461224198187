/* HOC that loads Form Definitions from the server for each form display */
import React from 'react';
import { using_nearest_form } from 'formprovider';
import { ErrorList } from 'errorlist';
import PropTypes from 'prop-types';

function MultiTemplated(WrappedComponent) {
    /* A component that loads all templates in props.form_keys into props.form_details for the WrappedComponent

    props:
        form_keys: [ 'key','key' ],
        form_details: {
            key: preloaded_form_template,
        }

    resolves and passes:
        form_keys: ...
        form_details: {
            key: details,
        }

    state:
        form_details: {

        }
        loaded: bool (finished loading)

    */
    class MultiTemplated extends React.Component {
        static defaultProps = {
            'form_keys': [],
            'form_details': {},
            'handleError': null,
            'context': null,
        }
        state = {
            'loaded': false,
            'form_details': {
                ...(this.props.form_details || {}),
            },
        }
        formTemplate = (key) => {
            return this.state.form_details[key] || this.props.form_details[key];
        }
        componentDidMount() {
            this.wanted = true;
            this.loadFormDetails();
        }
        componentWillUnmount() {
            this.wanted = false;
        }
        loadFormDetails = () => {
            this.props.form_keys.map(this.load_form);
            if (this.wanted) {
                this.setState({ loaded: this.all_loaded() });
            }
        }
        load_form = (key) => {
            var template = this.formTemplate(key);
            if (template) {

                return Promise.resolve(template);
            }
            const { storage } = this.props;
            return storage.get_form_template(
                key,
                this.props.context,
            ).then(
                (result) => this.on_form_load(result, key)
            ).catch(
                (err) => this.on_form_load_failure(err, key)
            );
        }
        on_form_load = (result, key) => {
            const { storage } = this.props;
            if (result.success && result.form) {
                console.log(`Loaded form ${key} from ${storage.url}`);
                this.state.form_details[key] = result.form;
                if (this.wanted) {
                    this.setState({ form_details: this.state.form_details, loaded: this.all_loaded() });
                }
                return result.form;
            }
            throw (`Failed to load form template retrying`);
        }
        on_form_load_failure = (err, key) => {
            const { storage } = this.props;
            console.warn(`Failure loading form ${key} from storage ${storage.url}`);
            if (this.props.handleError) {
                this.handleError(err, 'Loading form description');
            }
            window.setTimeout(() => {
                this.loadFormDetails();
            }, 5000);
        }

        loaded_keys = () => {
            return this.props.form_keys.filter(this.formTemplate);
        }
        all_loaded() {
            return this.props.form_keys.every(this.formTemplate);
        }
        render() {
            const loaded = this.loaded_keys();
            if (!(loaded.length == this.props.form_keys.length)) {
                const description = `${loaded.length}/${this.props.form_keys.length}`;
                return <div className="spinner form-loading">Loading... {description}</div>;
            }
            let { form_details, ...childProps } = this.props;
            // console.log(`Final form details ${JSON.stringify(form_details)}`);
            form_details = { ...this.props.form_details, ...this.state.form_details };
            return <WrappedComponent form_details={form_details} {...childProps} />;
        }
    }
    MultiTemplated.propTypes = {
        'storage': PropTypes.shape({
            get_form_template: PropTypes.func.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired,
        'form_keys': PropTypes.arrayOf(PropTypes.string).isRequired,
        'form_details': PropTypes.object,
        'handleError': PropTypes.func,
    };
    return using_nearest_form(MultiTemplated);
}


export default MultiTemplated;
