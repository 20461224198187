import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import MuiTypography from 'dash/MuiTypography';
import ScrollContainer from 'dash/ScrollContainer';
import SectionTitle from 'dash/SectionTitle';
import { requiring_system_licenses_store } from 'storages/systemlicense';

const styles = theme => ({
    section: {
        marginBottom: theme.spacing(4)
    },
    copyright: {
        minHeight: '20em',
        padding: theme.spacing(2),
        whiteSpace: 'pre-wrap', // Modern browsers
        wordWrap: 'break-word', // IE
    },
});

export class LicenseDetail extends React.Component {
    state = {
        license_details: {}
    };

    componentDidMount() {
        const { storage, match } = this.props;
        const { params } = match;

        const url = params.license
            ? `${storage.url}${params.package}/${params.license}/`
            : `${storage.url}${params.package}/`;

        storage.get(url).then(result => {
            this.setState({ license_details: result });
        });
    }

    get description() {
        const { match } = this.props;

        return (
            <span>
                This page displays the license for the package{' '}
                {match.params.package}, which is one component of this
                appliance. The license for the component may grant you rights
                (such as access to the source of the component) which are not
                applicable to the product as a whole. Any GPL or similarly
                licensed components are used through inter-process communication
                mechanisms. Please see the{' '}
                <Link to="/sys/licenses/">overall license page</Link> for details.
            </span>
        );
    }

    get title() {
        const { match } = this.props;
        const { params } = match;

        return params.license
            ? `${params.package} ${params.license}`
            : params.package;
    }

    renderList(license_details) {
        const { match } = this.props;
        const url = match.url.substr(-1) === '/' ? match.url : match.url + '/';

        const items = Object.entries(license_details.licenses).map(entry => (
            <ListItem
                button
                dense={true}
                key={entry[0]}
                component={Link}
                to={`${url}${entry[0]}`}
            >
                <ListItemText>{entry[0]}</ListItemText>
            </ListItem>
        ));

        return (
            <ScrollContainer>
                <List>{items}</List>
            </ScrollContainer>
        );
    }

    render() {
        const { license_details } = this.state;
        const { classes } = this.props;
        const source_link = license_details && license_details.source_link && <a color="secondary" href={license_details.source_link}>Package Source</a>;
        return (
            <div>
                <SectionTitle
                    title={this.title}
                    description={this.description}
                />

                {
                    license_details.licenses && this.renderList(license_details)
                }
                {
                    source_link && <div>{source_link}</div>
                }
                {license_details.copyright && (
                    <ScrollContainer className={classes.copyright}>
                        <MuiTypography>
                            {license_details.copyright}
                        </MuiTypography>
                    </ScrollContainer>
                )}
            </div>
        );
    }
}

LicenseDetail.propTypes = {
    classes: PropTypes.object.isRequired,
    storage: PropTypes.object.isRequired,
};

export default requiring_system_licenses_store(withStyles(styles)(LicenseDetail));
