/* Load defined dashboards for use lower in the stack */
import React from 'react';
import { WithTabularStorage } from 'storages/tabularstorage';
import getDisplayName from 'react-display-name';
import wraps from '../wraps';

const DashboardContext = React.createContext();

const convert_storage = (state, storage) => {
    const slugs = {};
    state.Dashboards.map(d => {
        if (!d.slug) {
            let slug = d.label.toLowerCase().replace(
                /[^a-zA-Z0-9_-]/g,
                ''
            );
            let base_slug = slug;
            let count = 0;
            while (slugs[slug]) {
                count += 1;
                slug = base_slug + count;
            }
            d.slug = slug;
        }
        slugs[d.slug] = d.slug;
    });
    return state;
};

const WithLoadedDashboards = (Component) => {
    /* Only rendering children once we've resolved the set of available dashboards

    Component will receive the prop 'dashboards' on resolution
    and will not be rendered until those dashboards are made available.

    *also* MetricContext.Provider will have a value that can be used
    by any MetricContext.Consumer
    */
    const WithDashboards = (props) => {
        const { storage, passthrough_storage, ...restProps } = props;
        return <DashboardContext.Provider value={storage.Dashboards}>
            <Component {...restProps} dashboards={storage.Dashboards} storage_props={passthrough_storage} />
        </DashboardContext.Provider>;
    };
    WithDashboards.displayName = `WithDashboards(${getDisplayName(Component)})`;
    const WithDashboardLoading = (props) => {
        const storage_props = {
            'forms_key': 'system_forms',
            'form_key': 'Dashboards',
            'period': 60,
            'key': 'Dashboards',
            'convert': convert_storage,
        };
        return <WithTabularStorage
            Component={WithDashboards}
            storage_props={storage_props}
            {...props}
            passthrough_storage={props.storage_props}
        />;
    };
    WithDashboardLoading.displayName = `WithLoadedDashboards(${getDisplayName(Component)})`;
    return WithDashboardLoading;
};

const WithDashboards = (Component) => {
    return wraps((props) => {
        return <DashboardContext.Consumer>
            {(value) => <Component {...props} dashboards={value} />}
        </DashboardContext.Consumer>;
    }, Component, 'WithDashboards');
};

export default WithLoadedDashboards;
export { WithDashboards, WithLoadedDashboards };
