/* DB Backed Log View with *just* log viewing */
import React from 'react';
import ResolveTarget, { with_target } from 'dash/resolvetarget';
import { product_metrics_to_graph_metrics } from './metricmodel';
import getDisplayName from 'react-display-name';
import wraps from 'wraps';

const MetricContext = React.createContext();

const WithLoadedMetrics = (Component) => {
    /* Only rendering children once we've resolved the set of available metrics

    Component will recieve the prop 'available_metrics' on resolution
    and will not be rendered until those metrics are made available.

    *also* MetricContext.Provider will have a value that can be used
    by any MetricContext.Consumer
    */
    let WithMetrics = (props) => {
        return <MetricContext.Provider value={props.available_metrics}>
            <Component {...props} chart_metrics={
                product_metrics_to_graph_metrics({ available_metrics: props.available_metrics })
            } />
        </MetricContext.Provider>;
    };
    WithMetrics.displayName = `WithMetrics(${getDisplayName(Component)})`;
    WithMetrics = with_target(WithMetrics, MetricContext, 'available_metrics');
    const WithMetricLoading = (props) => {
        return <ResolveTarget __type__='MetricRegistry' __pk__='1' storage='shogunconf_forms' ContextClass={MetricContext}>
            <WithMetrics {...props} />
        </ResolveTarget>;
    };
    WithMetricLoading.displayName = `WithLoadedMetrics(${getDisplayName(Component)})`;
    return WithMetricLoading;
};

const WithMetrics = (Component) => {
    const WithMetrics = wraps((props) => {
        console.log(`WithMetrics render`);
        return <MetricContext.Consumer>
            {(value) => <Component {...props} available_metrics={value} metrics={
                product_metrics_to_graph_metrics({ available_metrics: value })
            } />}
        </MetricContext.Consumer>;
    }, Component, 'WithMetrics');
    return WithMetrics;
};

export default WithLoadedMetrics;
export { WithMetrics, WithLoadedMetrics };
