import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import GroupDashboard from 'dash/charts/groupdashboard';
import { LoadingDisplay } from 'reactloading';
import { ErrorList } from 'errorlist';
import { NODE_GRAPHS } from 'shogun/nodecharts';
import { WithTabularStorage } from 'storages/tabularstorage';
import { with_focus_target } from 'dash/resolvefocus';
import { providing_nearest_form } from 'formprovider';
import Tab from '@material-ui/core/Tab';
import { TabContext, TabPanel, TabList } from '@material-ui/lab';
import { useLocation } from 'react-router-dom';
import BaseDashboard from './basedashboard';
import { Link } from 'react-router-dom';

import { WithMetrics, WithLoadedMetrics } from 'metrics/withmetrics';
import { WithLoadedDashboards } from 'metrics/withdashboards';
import { create_metrics } from 'metrics/metricmodel';
import MetricTable from 'metrics/metrictable';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { ReactForm } from 'reactform';
import { DBPrefContext } from 'dash/userprefs';

import SettingsLink from 'settingslink';
import { useMetricFilters, filterHash, FilterContext } from 'storages/usemetricfilters';

const styles = theme => ({

    dashboard: {
        display: 'flex',
        flex: 1
    },
    tabListRoot: {
        minHeight: 'inherit',
        marginBottom: 2,
    },
    tabPanelRoot: {
        padding: 0,
    },
    tightTab: {
        fontSize: '.7rem',
        minWidth: '10vw',
        height: '2rem',
        minHeight: '2rem',
    },
    tightTabSelected: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '4px',
    },
    tightTabIndicator: {
        display: 'none',
    },
});

const BaseNodeDashboard = (props) => {
    let { metric_record } = props;
    const { storage, metric_key, dashboard } = props;

    const { form, resource_map, form_key } = storage;
    const Metrics = storage[form_key];
    const { fields } = form;

    const { metrics, graphs } = create_metrics(Metrics, graphs, fields, metric_key);

    let children = null;

    if (!Metrics) {
        if (storage && storage.last_error) {
            children = <ErrorList errors={storage.last_error.messages} />;
        } else {

            children = <div key='no-status'>No status present</div>;
        }
    } else {
        const table = <MetricTable key='main-table' {...props} />;
        children = <React.Fragment>
            <GroupDashboard
                datasets={{
                    'Metrics': Metrics,
                }}
                key={metric_key}
                graphs={graphs}
                metrics={metrics}
                title={storage.title || 'Metrics'}
                tables={[table]}
            />    {/* <MetricsDebug /> */}
        </React.Fragment>;
    }
    const filter_key = dashboard.definition.filter_context || dashboard.slug;

    return <FilterContext filter_key={`${filter_key}`} defaults={dashboard.definition.filter_defaults || {}}>
        <LoadingDisplay signal={storage.loading}>
            {/* <FilterSet key={`display-filters-${dashboard.slug}`} /> */}
            {children}
        </LoadingDisplay>
    </FilterContext>;


};
// class BaseNodeDashboard extends React.Component {
//     static defaultProps = {
//         storage: {}
//     };

//     renderCharts() {

//     }

//     render() {
//         const { storage } = this.props;
//     }
// }

const LOG_VIEW = {
    'key': 'logs',
    'title': 'Logs',
    'tables': [
        {
            'key': 'log-stats',
            'metric': 'metric_log', // which metric to look up the available_metrics
            'query_type': 'stats', // stats, last_status, category
            'filter_mode': 'server', // client for default
            'by_resource': false,
        },
    ]
};
const VSBB_VIEW = {
    'key': 'vsbb.v1',
    'title': 'VSBBs',
    'graphs': [
        {
            'key': 'states',
            'graph_type': 'radial_donut',
            'query_type': 'category',
            'by_resource': false,
            'metric': 'metric_vsbb_status',
            'datapoints': [
                'firmware_release',
                'stb_state',
                'update_state',
                'video_windowed',
                'video_ready',
                'video_format',
                'video_present',
                'content_blocked',
                'video_showing',
            ],
        },
        {
            'key': 'timeline',
            'graph_type': 'timeline',
            'query_type': 'stats',
            'by_resource': true,
            'metric': 'metric_vsbb_status',
            'datapoints': [
                'video_errors',
                'video_frames',
                'video_pts_errors',
                'audio_errors',
                'audio_frames',
                'audio_pts_errors',
                'qam_snr',
                'qam_ber',
                'qam_fec_corr',
                'qam_fec_uncorr',
            ],
        }
    ]
};

const dashboard_url = (target, dashboard_slug, kwargs = null) => {
    const params = kwargs ? new URLSearchParams(kwargs) : "";
    return `/m/dashboard/${target || 0}/${dashboard_slug}/?${params}`;
};

const DashboardChooser = (props) => {
    let editor = null;
    const [editing, setEditing] = React.useState(null);
    const on_click = (evt) => {
        setEditing(true);
    };
    if (editing) {
        editor = <ReactForm
            target={{
                type: 'GroupDashboards',

            }}
        />;
    }
    return <React.Fragment>
        {editor}
        <IconButton onClick={on_click}><MoreVertIcon /></IconButton>
    </React.Fragment >;
};

const dashboard_sort = (first, second) => {
    if (first.definition.default && !second.definition.default) {
        return -1;
    } else if (second.definition.default && !first.definition.default) {
        return 1;
    }
    if (first.name < second.name) {
        return -1;
    } else if (second.name < first.name) {
        return 1;
    }
    return 0;
};

const BaseDashboardSwitcher = (props) => {
    /* Overall node dashboard with tabbed sub-sets */
    const { target, classes, ...restProps } = props;
    const {
        available_metrics,
        history,
        dashboard_slug = null,
        dashboard_role = 'dashboard',
    } = props;
    const preferences = React.useContext(DBPrefContext);
    const is_enabled = (key) => {
        let enabled = false;
        preferences.preferences.dashboards && preferences.preferences.dashboards.map((dash) => {
            if (key == dash) {
                enabled = true;
            }
            // console.log(`Check ${dash} vs ${key} ${enabled}`);
        });
        // console.log(` Display Slug ${key} => ${enabled}`);
        return enabled;
    };

    let target_id = target && target.__pk__;

    let { dashboards } = props;

    const dashboard_groups = dashboards.filter(
        dash => dash.role == `${dashboard_role}-group`
    );
    dashboard_groups.sort(dashboard_sort);

    // if ((!dashboards) || (!dashboards.length)) {
    //     return 'No dashboards defined';
    // }
    const enabled_groups = {};
    const enabled_dashboards = dashboards.filter(
        dash => dash.role == dashboard_role
    ).filter(dash => is_enabled(dash.slug));
    enabled_dashboards.sort(dashboard_sort);
    enabled_dashboards.map(dash => {
        let prefix = dash.slug.split('.')[0];
        if (!enabled_groups[prefix]) {
            enabled_groups[prefix] = dash.slug;
        }
    });

    // if ((!dashboards) || (!dashboards.length)) {
    //     return 'No dashboards defined';
    // }
    // if (enabled_dashboards && enabled_dashboards.length && !enabled_dashboards[0].default) {
    //     enabled_dashboards[0].default = true;
    // }
    let final_slug = (dashboard_slug || (enabled_dashboards.length && enabled_dashboards[0].slug)) || 'device.overview';
    const group_slug = (final_slug && final_slug.split('.')[0]) || 'device';

    const group_links = dashboard_groups.filter(dash => enabled_groups[dash.slug]).map(dashboard => {
        let to_slug = enabled_groups[dashboard.slug];
        return <Tab
            label={dashboard.label}
            key={`dash-${dashboard.id}-${query}`}
            value={`${dashboard.slug}`}
            component={Link}
            to={dashboard_url(target_id, to_slug, query)}
            classes={{
                root: classes.tightTab,
                selected: classes.tightTabSelected,
            }}
        />;
    });
    const dashboard_links = [];
    const dashboard_pages = [];
    const already_rendered = {};

    const query = useLocation().search;

    enabled_dashboards.map((dashboard, index) => {
        if (already_rendered[dashboard.slug]) {
            return null;
        }
        if (!dashboard.slug.startsWith(`${group_slug}.`)) {
            console.log(`${dashboard.slug} doesn't match ${group_slug}`);
            return null;
        }
        already_rendered[dashboard.slug] = true;
        dashboard_links.push(<Tab
            label={dashboard.label}
            key={`dash-${dashboard.id}-${query}`}
            value={`${dashboard.slug}`}
            component={Link}
            to={dashboard_url(target_id, dashboard.slug, query)}
            classes={{
                root: classes.tightTab,
                selected: classes.tightTabSelected,
            }}
        />);

        dashboard_pages.push(<TabPanel
            value={`${dashboard.slug}`}
            key={`dash-${dashboard.slug}-${query}`}
            index={index}
            classes={{
                root: classes.tabPanelRoot,
            }}
        >
            <BaseDashboard dashboard={dashboard} />
        </TabPanel>);
    });
    if (!dashboard_links.length) {
        dashboard_links.push(<div key="align-div" style={{ flex: 2, fontSize: 'larger' }}>No dashboards currently enabled.  Click the settings button to enable dashboards <span style={{ fontSize: 'larger' }}>→</span></div>);
    } else {
        dashboard_links.push(<div key="align-div" style={{ flex: 2 }}></div>);
    }
    group_links.push(<div key="align-div" style={{ flex: 2 }}></div>);
    group_links.push(
        <SettingsLink url='/m/dashboard-select/' key='settings-div' />
    );


    return <React.Fragment>
        <TabContext value={group_slug} key='groups'>
            <TabList aria-label="Dashboard Groups" variant="scrollable" classes={{
                indicator: classes.tightTabIndicator,
                root: classes.tabListRoot,
                selected: classes.tightTabSelected
            }}>{group_links}</TabList>
        </TabContext>
        <TabContext value={final_slug} key='secondary'>
            <TabList aria-label="Dashboard Views" variant="scrollable" classes={{
                indicator: classes.tightTabIndicator,
                root: classes.tabListRoot,
                color: 'primary',
                selected: classes.tightTabSelected
            }}>
                {dashboard_links}
            </TabList>
            {dashboard_pages}
        </TabContext>
    </React.Fragment>;

};
BaseDashboardSwitcher.displayName = 'BaseDashboardSwitcher';

const DashboardSwitcher = providing_nearest_form(
    withStyles(styles)(
        with_focus_target(
            WithLoadedMetrics(
                WithLoadedDashboards(BaseDashboardSwitcher)
            )
        )
    ),
    'shogunconf_forms',
);



export default DashboardSwitcher;
