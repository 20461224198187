import React from 'react';
import { GroupEditTable, group_specific_storage } from './groupedittable';
import { CableCardCharts } from './cablecardview';
import { providing_nearest_form } from 'formprovider';
import { with_resolve_focus, with_focus_target } from 'dash/resolvefocus';
import { ReactForm } from 'reactform';
// import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import { node_specific_storage, ResourceEditTable } from './resourceedittable';
import { CableCardPairingTable } from './cablecardview';
import { GroupServiceGroupCard } from './servicegrouptable';
import { WithToolbar } from 'shogun/shogunpromotebar';
import { ListView } from 'shogun/listview';

const BaseGroupView = (props) => {
    let { target } = props;
    if (target === undefined || target === null) {
        target = {
            __type__: 'Group',
            __pk__: 0,
            type_name: 'World',
            title: 'Root',
        };
    }
    const resource_storage_props = node_specific_storage(target);
    const cc_charts_actions = [
    ];
    const enrollment_creation_context = (target && target.__pk__) ? {
        target_group: target.__pk__,
        owner: target.owners,
    } : {};
    const enrollment_context = (target && target.__pk__) ? {
        'target_group__tree_id': target.tree.tree_id,
        'target_group__lft__gte': target.tree.lft,
        'target_group__rght__lte': target.tree.rght
    } : {};

    const children_context = {};
    if (target && target.__pk__) {
        children_context.parent = target && target.__pk__;
    } else {
        children_context.parent__isnull = true;
    }
    const children_creation_context = {
        ...children_context,
        ...(target ? {
            owner: target.owner,
            parent: target.__pk__,
        } : {})
    };

    const is_group = ((!target) || (target && target.__type__ == 'Group')) || null;


    return <React.Fragment>
        {/* <CableCardCharts
            key={`cc-pair-table-${(target && target.id) || 'root'}`}
            storage_context={resource_storage_props.default_context}
        /> */}
        {target && (target.__pk__ || null) &&
            <EditingCard collapsing='group-metadata' title={`${target.title} Metadata`} actions={cc_charts_actions} key='metadata'>
                <ReactForm target={target} use_dialog={false} buttons={['save', 'delete']} />
            </EditingCard>
        }
        {target && (target.__pk__ || null) &&
            <EditingCard title='Config Packages' key={`metadata-${target.__pk__}`} collapsing='node-metadata'>
                <ReactForm
                    form_key={'GroupServiceGroups'}
                    target={target}
                    use_dialog={false}
                    buttons={['save']}
                />
            </EditingCard>
        }
        {is_group && <ListView
            title={`Children`}
            __type__='Group'
            storage='shogunconf_forms'
            context={children_context}
            creation_context={children_creation_context}
            creation_action='Add Group'
            exclude={['parent', 'notes']}
            key='children'
        />}
        {is_group && <ListView
            title={`Enrollments`}
            __type__='Enrollment'
            storage='shogunconf_forms'
            creation_action='New Enrollment'
            context={enrollment_context}
            creation_context={enrollment_creation_context}
            key='enrollments'
        />}

    </React.Fragment>;
}

const GroupView = providing_nearest_form(
    with_resolve_focus(
        WithToolbar(
            with_focus_target(BaseGroupView, false)
        ),
        true
    ),
    'shogunconf_forms'
);

export default GroupView;
export { BaseGroupView, GroupView };
