import React from 'react';

import interfaceTableDefaultColumns from 'netconfig/interfaceTableDefaultColumns';
import { ReactFormTable } from 'reactform';
import { WithTabularStorage } from 'storages/tabularstorage';
import { LoadingDisplay } from 'reactloading';
import theme from 'dash/theme';
import { PingForm } from './dash/ping';
import { delete_target_action } from 'reactform/actionmenu';

const interface_storage = () => {
    /* Get a group-specific storage for given group *or* nodes/non-nodes */
    return {
        default_context: {},
        forms_key: 'net_forms',
        form_key: 'Interfaces',
        quick_settings: {
        },
    };
};

class BaseInterfaceTable extends React.Component {

    static defaultProps = {
        form_keys: ['Interface',],
        editing_forms: [
            'Interface',
            'VLANInterface',
            'BondInterface',
        ],
        exclude: [
            'dhcp_hostname', 'netmask', 'gateway', 'dns_server1', 'dns_search', 'ntp_server',
            'gateway_v6', 'prefix_v6', 'force_igmp',
        ],
        quick_settings: {
            snmp_port: 'QuickSettingSnmpPort',
            management_port: 'QuickSettingManagementPort',
            pingable: 'QuickSettingPingable',
            support_port: 'QuickSettingSupportPort',
            dhcp: 'QuickSettingDhcp',
            dhcp_v6: 'QuickSettingDhcpV6',
            slaac_v6: 'QuickSettingSlaacV6',
        },
        extra_fields: {
            'Interface': [
                {
                    'name': 'vlan_id',
                    'label_id': 'id_vlan',
                    'label': 'VLAN',
                    'field': {
                        'help_text': 'VLAN ID for VLAN records',
                        'widget': 'ZeroNullInteger',
                    },
                },
            ],
        },
        field_actions: {
            'interface': (target, context, menu) => {
                return [
                    {
                        label: `Ping on ${target.title}`,
                        icon: theme.icons.ping,
                        permission: 'config.view_system',
                        list_item_props: {
                            color: 'secondary',
                        },
                        form: (props) => <PingForm {...props} />,
                        form_props: {
                            action: 'Ping on',
                            target: target,
                        },
                    },
                    (target.__type__ == 'VLANInterface' || target.__type__ == 'BondInterface') ?
                        delete_target_action(target, context, menu, {
                            'permission': 'netconfig.edit',
                        })
                        : null,
                    (target.__type__ == 'Interface' || target.__type__ == 'BondInterface') ?
                        {
                            label: `Create VLAN`,
                            icon: theme.icons.add,
                            list_item_props: {
                                color: 'primary',
                            },
                            form_props: {
                                action: 'Create VLAN on',
                                context: {
                                    base: target.id,
                                    role: target.role !== 'management' ? target.role : 'output',
                                },
                                target: {
                                    ...target,
                                    __type__: 'VLANInterface',
                                    __pk__: null,
                                    id: null,
                                    base: target.id,
                                    role: target.role !== 'management' ? target.role : 'output',
                                },
                                form_key: 'VLANInterface'
                            },
                            permission: 'netconfig.edit',
                        }
                        : null,
                    target.__type__ == 'Interface' ?
                        {
                            label: `Create Bond`,
                            icon: theme.icons.add,
                            list_item_props: {
                                color: 'primary',
                            },
                            form_props: {
                                action: 'Create Bond on',
                                target: target,
                                form_key: 'BondCreate'
                            },
                            permission: 'netconfig.edit',
                        }
                        : null,
                ];
            }
        }
    };

    columns() {
        return interfaceTableDefaultColumns({ minWidthSm: 50 })
            .filter(column => column.Header !== 'Ports')
            .filter(column => (this.props.use_ipv6 ? true : !column.ipv6_only));
    }
    render() {
        const { storage } = this.props;
        const table = <ReactFormTable
            wrap_data={(record, table) => {
                return [record,];
            }}
            editable={true}
            permission='netconfig.edit'
            {...this.props}
        />;
        if (storage) {
            return <LoadingDisplay signal={storage && storage.loading}>{table}</LoadingDisplay>;
        } else {
            return table;
        }
    }
}

const InterfaceTable = (props) => {
    return <WithTabularStorage
        key='interfaces'
        storage_props={props.storage_props || interface_storage()}
        Component={BaseInterfaceTable}
        {...props}
    />;
};


export default InterfaceTable;
export { BaseInterfaceTable, InterfaceTable };
