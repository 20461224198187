import React from 'react';
import { short_duration_text, human_duration_text_short } from 'humanize';

const HumanDuration = (props) => {
    const { value, short = false, ago = false } = props;
    if (short) {
        return short_duration_text(value);
    } else {
        return human_duration_text_short(value, true, ago);
    }
};
const HumanDurationAgo = (props) => {
    return <HumanDuration {...props} ago={true} />;
}

export { HumanDuration, HumanDurationAgo };
export default HumanDuration;