import React from 'react';
import PropTypes from 'prop-types';
import { BaseStorage } from 'storages/basestorage';
import { current_user } from 'storeregistry';
import classNames from 'classnames';
import ATXTable from 'atxtable';
import { LoadingDisplay } from 'reactloading';
import Config from 'config';

const Rebrander = (config) => {
    /* Create a product/company rebranding mapper */
    if (!config.themed) {
        return (conf) => conf;
    }
    const short_prefix = (
        config.company_short_name || 'atx'
    ).toLowerCase().replace(/[ .]+/, '');
    const to_rewrite = [
        ['atxstyle', `${short_prefix}style`],
        ['atxlicense', `${short_prefix}license`],
        ['atxnode', `${short_prefix}node`],
        ['digistreamepgdata.atxnetworks.com', 'licensing-server'],
        ['atx', `${short_prefix}`],
        ['ATX', `${config.company_short_name}`],
    ];
    const rewrite_message = (message) => {
        if (!message) {
            return message;
        }
        return to_rewrite.reduce(
            (current, rep) => {
                const [bad, good] = rep;
                return current.replace(bad, good);
            },
            message
        );
    };
    const rewriter = (record) => {
        return {
            ...record,
            name: rewrite_message(record.name),
            msg: rewrite_message(record.msg),
        };
    }
    return rewriter;
};

const FULL_PARSER = /^(DEBUG|INFO|WARN|WARNING|ERROR|CRITICAL)[ ]+([^ ]+)[ ]*([:][ ]*(\d+)[ ]+)?((2[0-9]{3}[-][0-9]{2}-[0-9]{2}) (\d{2}[:]\d{2}[:]\d{2}))[ ]+(.*)/;
const FULL_NAMES = [null, 'levelname', 'name', null, 'lineno', 'asctime', 'date', 'time', 'msg'];
const LEVEL_NUMBERS = { 'DEBUG': 10, 'INFO': 20, 'WARNING': 30, 'WARN': 30, 'ERROR': 40, 'CRITICAL': 50 };
const parse_full_log = (log_data) => {
    /* Parse standardlog full formatter log files */
    const records = [];
    log_data.split('\n').map((line) => {
        const match = FULL_PARSER.exec(line);
        if (match) {
            const record = {};
            FULL_NAMES.map((name, index) => {
                if (name !== null) {
                    record[name] = match[index];
                }
            });
            record['levelno'] = LEVEL_NUMBERS[record['levelname']] || 10;
            records.push(record);
        } else {
            if (records.length) {
                const old_record = records[records.length - 1];
                if (old_record.traceback === undefined) {
                    old_record.traceback = [];
                }
                old_record.traceback.push(line);
            }
        }
    });
    return records;
};

class LogTable extends React.Component {
    static defaultProps = {
        parse_log: parse_full_log,
        form_key: 'raw_log',
        log_name: 'node-manager.log',
        show_scary: false,
        scary_level: LEVEL_NUMBERS['WARNING'],
        name_map: null,
    };
    default_columns = [
        {
            'Header': 'Timestamp',
            'id': 'timestamp',
            'accessor': 'asctime',
            'size': 'medium',
        },
        {
            'Header': 'Level',
            'id': 'levelname',
            'accessor': 'levelname',
            'Cell': props => {
                const { value } = props.cell;
                return <span className={classNames(value && value.toLowerCase())}>{value}</span>;
            },
            'size': 'small',
        },
        {
            'Header': 'Module',
            'id': 'name',
            'accessor': 'name',
            'size': 'expanded',
        },
        {
            'Header': 'Message',
            'id': 'msg',
            'accessor': 'msg',
            'Cell': props => {
                const { value } = props.cell;
                return <div style={{ whiteSpace: 'initial' }}>{value}</div>;
            },
            'size': 'wide',
        },
    ];

    state = {
        parsed: [],
        rebrander: null,
        storage: BaseStorage({
            'key': this.props.form_key,
            'config': null,
            'refresh_on_post': false,
            'period': 5,
            'debug': false,
            'url': this.props.url || `/log/raw/${this.props.log_name}`,
            'default_options': {
                'text': true,
            },
        }),
    };

    componentDidMount() {
        this.state.storage.change.listen(this.on_update);
        this.state.storage.poll();
    }
    componentWillUnmount() {
        this.state.storage.periodic && this.state.storage.periodic.stop();
    }
    on_update = () => {
        const { show_scary, scary_level } = this.props;
        const final_scary = show_scary || current_user().has_permission(
            'config.scary_debugging'
        );
        const rebrander = Rebrander(Config);
        let parsed = this.parseLog(this.state.storage.data);
        if (!final_scary) {
            parsed = parsed.filter(row => row.levelno >= scary_level);
            if (rebrander) {
                parsed = parsed.map(row => rebrander(row));
            }
        }
        this.setState({
            'parsed': parsed,
            'rebrander': rebrander,
        });
        console.info(`Update on log ${this.state.storage.url} ${parsed.length} records`);
    }

    parseLog = (data) => {
        try {
            return this.props.parse_log(data);
        } catch (e) {
            return [
                {
                    'levelname': 'ERROR',
                    'name': 'Log Parser',
                    'lineno': null,
                    'asctime': 'Now',
                    'date': 'now',
                    'time': 'now',
                    'message': `Unable to parse the incoming log: ${e}`,
                }
            ];
        }
    }

    render() {
        const { parsed, storage } = this.state;

        return <LoadingDisplay key='loading' signal={storage.loading}>
            <ATXTable
                data={parsed}
                defaultSorting={[{
                    'id': 'timestamp',
                    'desc': true,
                }]}
                columns={this.default_columns}
                showPagination={false}
                pageSize={200}
                filterable={false}
                minRows={0}
                filterable
                collapseOnDataChange={false}
            />
        </LoadingDisplay>;
    }
}

export default LogTable;
export { LogTable, parse_full_log };
