import React from 'react';
import { providing_nearest_form } from 'formprovider';
import { ReactForm } from 'reactform';
import { with_resolved_target } from 'dash/resolvetarget';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import { EditIntent } from 'editintent';
import { RFSourceMapTable, mapping_specific_storage } from './rfsourcetable';
import FocusLink from 'dash/focuslink';

class BaseRFInputMappingView extends React.Component {
    render() {
        const { target } = this.props;
        const storage_props = mapping_specific_storage(target);
        storage_props.debug = true;
        const table_actions = [
            <EditIntent
                key="add-map"
                target={{
                    'type': 'RFSource',
                    'id': null,
                }}
                context={{
                    'enabled': true,
                    'mapping': target.id,
                }}
                action='Add Program'
                label='Add Program'
                help_text='Add new program to this source-set'
            />,
            <FocusLink
                focus_on={target}
                key={`import-vct`}
                to={`../vct/${target.id}`}
                title='Import a selection of channels from a Virtual Channel Table provided by a CableCARD/Tuner'
            >Import From VCT</FocusLink>
        ];
        return <React.Fragment>
            <EditingCard title={`${target.type_name}`} actions={table_actions} no_wrapper={true}>
                <RFSourceMapTable
                    key='rf-mapping-${mapping}'
                    mapping={target}
                    exclude={['name', 'tags', 'notes']}
                    storage_props={storage_props}
                />
            </EditingCard>
            <EditingCard title={`${target.title} Metadata`}>
                <ReactForm target={target} use_dialog={false} />
            </EditingCard>
        </React.Fragment>;
    }
}
const RFInputMappingView = providing_nearest_form(
    with_resolved_target(
        BaseRFInputMappingView
    ),
    'shogunconf_forms'
);

export default RFInputMappingView;
export { BaseRFInputMappingView, RFInputMappingView };
