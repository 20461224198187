import React from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiTypography from 'dash/MuiTypography';
import MuiIcon from 'dash/MuiIcon';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.headers.card,
    },
    iconOffset: {
        marginRight: theme.spacing(4) // visually balance grids for ExpansionPanelSummary and ExpansionPanelDetails
    },
    summary: {
        color: theme.palette.text.secondary
    },
});


class ExpansionSection extends React.Component {
    static defaultProps = {
        'classes': null,
        'section': {
            'key':'section-key',
            'title':'Section',
            'summary':null,
            'details': null
        },
        'defaultExpanded': false,
        'onChange': null,
    }
    render() {
        const { classes, section, defaultExpanded, expanded } = this.props;
        var { key, title, summary, details } = section;
        if (typeof summary === 'string') {
            summary = <MuiTypography>{summary}</MuiTypography>;
        }
        if (typeof details === 'string') {
            details = <MuiTypography>{details}</MuiTypography>;
        }
        return <ExpansionPanel className={"section-"+key} defaultExpanded={!!defaultExpanded} expanded={!!expanded} onChange={this.props.onChange}>
            <ExpansionPanelSummary
                expandIcon={<MuiIcon name="expand_more" />}
                className={classes.root + ' section-expander'}
            >
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <MuiTypography className={classes.leftSide}>{title}</MuiTypography>
                    </Grid>
                    {
                        summary &&
                        <Grid item xs={4}>
                            {summary}
                        </Grid>
                    }
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.iconOffset}>
                { 
                    details ? 
                    <Grid container spacing={3}>
                        <Grid item xs={8}>
                            {this.props.children}
                    </Grid>
                        {
                            details &&
                            <Grid item xs={4}>
                                <MuiTypography className={classes.summary}>{details}</MuiTypography>
                            </Grid>
                        }
                    </Grid> 
                    :
                    this.props.children
                }
            </ExpansionPanelDetails>
        </ExpansionPanel>;
    }
}
ExpansionSection.propTypes = {
    'classes': PropTypes.object,
    'section': PropTypes.shape({
        'key':PropTypes.string.isRequired,
        'title':PropTypes.string.isRequired,
        'summary':PropTypes.oneOfType([PropTypes.string,PropTypes.instanceOf(React.Component),PropTypes.object]),
        'details': PropTypes.object,
    }),
    'defaultExpanded': PropTypes.bool,
};

export default withStyles(styles)(ExpansionSection);
