import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class MuiTable extends React.Component {
    render() {
        const { config, data } = this.props;

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        {config.columns.map((column, i) => (
                            <TableCell key={i}>{column.name}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, i) => (
                        <TableRow key={i}>
                            {config.columns.map((column, i) => (
                                <TableCell key={i}>
                                    <span>
                                        {
                                            column.get_value
                                                ? column.get_value(item)
                                                : item[column.key]
                                        }
                                    </span>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }
}

MuiTable.propTypes = {
    config: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
};

export default MuiTable;
