import React from 'react';
import { GroupEditTable, group_specific_storage } from './groupedittable';
import { providing_nearest_form } from 'formprovider';
import EditingCard from 'dash/editingcard';
import { EditIntent } from 'editintent';
import { WithToolbar } from 'shogun/shogunpromotebar';
import { with_resolve_focus } from 'dash/resolvefocus';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import WithCurrentUser from 'withcurrentuser';

class BaseGroupsCard extends React.Component {
    render() {
        const { target, user } = this.props;
        const storage_props = group_specific_storage(null);
        const { title = 'Groups', ...childProps } = this.props;
        const service_group_actions = [
            user && user.has_permission('shogunconf.edit_content') && <EditIntent
                key="add-group"
                target={{
                    'type': 'Group',
                    'id': null,
                }}
                context={{
                }}
                action='Create a new Group'
                label='Add Group'
                help_text='Adds a new group of nodes'
            />,
            target ? <Link key={`enrollments-${target.__pk__}`} to={`/m/enrollments/${target.__pk__}`} title="Manage the device enrollments for this group">Enrollments</Link> : null,
        ].filter(x => !!x);
        return <React.Fragment>
            <EditingCard
                title={title}
                no_wrapper={true}
                actions={service_group_actions}
                collapsing={`group-table`}
            >
                <GroupEditTable key='group-table' storage_props={storage_props} {...childProps} />
            </EditingCard>
        </React.Fragment>;
    }
}
const GroupsCard = providing_nearest_form(WithCurrentUser(withRouter(BaseGroupsCard)), 'shogunconf_forms');

const GroupsView = providing_nearest_form(
    WithToolbar(
        GroupsCard,
    ),
    'shogunconf_forms'
);


export default GroupsView;
export { GroupsCard, GroupsView };