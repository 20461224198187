import React from 'react';
import WithCurrentUser from 'withcurrentuser';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    container: {
        marginBottom: theme.spacing(2),
    },
    link: {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

class DebuggingPage extends React.Component {
    render() {
        const { classes, match, user } = this.props;

        return (
            <React.Fragment>
                <div className={classes.container}><Link className={classes.link} to={`${match.path}events/`}>Events</Link></div>
                {
                    user.has_permission('config.scary_debugging') &&
                    <React.Fragment>
                        <div className={classes.container}><Link className={classes.link} to={`${match.path}processes/`}>Process List</Link></div>
                    </React.Fragment>
                }
                {
                    user.has_permission('config.upgrade') &&
                    <React.Fragment>
                        <div className={classes.container}><Link className={classes.link}to={`${match.path}upgrade-progress/`}>Upgrade Logs</Link></div>
                        <div className={classes.container}><Link className={classes.link} to={`../log/os-updates/`}>OS Updates</Link></div>
                        <div className={classes.container}><Link className={classes.link} to={`../log/license-client/`}>Licensing Log</Link></div>
                    </React.Fragment>
                }
                {
                    user.has_permission('factory') &&
                    <React.Fragment>
                        <div className={classes.container}><Link className={classes.link} to={`../log/django/`}>Web GUI Log</Link></div>
                        <div className={classes.container}><Link className={classes.link} to={`../log/preconfigure/`}>Preconfiguration</Link></div>
                        <div className={classes.container}><Link className={classes.link} to={`../log/promote-interfaces/`}>Network Promotion</Link></div>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(WithCurrentUser(DebuggingPage));
