import React from 'react';
import {ChannelPlanTable, channel_plan_storage} from './channelplantable';
import {providing_nearest_form} from 'formprovider';
import EditingCard from 'dash/editingcard';
import {EditIntent} from 'editintent';

class BaseChannelPlanView extends React.Component {
    render() {
        const actions = [
            <EditIntent target={{
                type: 'ChannelPlan',
                id: null,
                label: 'Add Channel Plan',
            }} />
        ];
        return <React.Fragment>
            <EditingCard title="Analog Channel Plans" no_wrapper={true} collapsing='analog-channel-plans'>
                <ChannelPlanTable
                    key={`channel-plans-table`}
                    storage_props={channel_plan_storage()}
                />
            </EditingCard>
        </React.Fragment>;
    }
}
const ChannelPlanView = providing_nearest_form(BaseChannelPlanView,'channel_plans_forms');

export default ChannelPlanView;
export {BaseChannelPlanView, ChannelPlanView};