import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

import { ClassyWidget } from 'reactform/classywidget';

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    url: {
        marginTop: theme.spacing(0.5),
        fontSize: theme.typography.fontSize,
        color: theme.palette.primary.main,
        cursor: 'pointer'
    }
});

// Note: this is the same as URLLinkPopup. It would be good to consolidate
// if there will not be much difference between the two
export class DownloadLink extends ClassyWidget {
    render() {
        const { classes } = this.props;
        const url = this.current_value();
        const field = this.get_field();

        if (!url) {
            return null;
        }

        return (
            <div className={classes.container}>
                <InputLabel shrink={true} classes={{}}>{field.label}</InputLabel>
                <a href={url} target="_blank" className={classes.url}>
                    Download
                </a>
            </div>
        );
    }
}

export default withStyles(styles)(DownloadLink);
