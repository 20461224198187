import React from 'react';
import { providing_nearest_form } from 'formprovider';
import { useLocation, useHistory } from 'react-router-dom';
import { null_load } from 'dash/resolvetarget';
import EditingCard from 'dash/editingcard';
import { ReactFormTable } from 'reactform';
import MuiLoader from 'dash/MuiLoader';
// import { with_resolve_focus, with_focus_target } from 'dash/resolvefocus';
import { EditIntent } from 'editintent';
import { WithTabularStorage } from 'storages/tabularstorage';
import useQuery from 'storages/usequery';
import { actions } from 'react-table';

const BaseListView = (props) => {
    /* Generic form-based editor for a resolved target from shogunconf */
    let {
        __type__, __pk__,
        __form_key__, title = null,
        context, creation_context = null,
        dialog_edit = false,
        forms_key = 'shogunconf_forms',
        actions = [],
        form_titles = ['Records'],
        creation_action = 'New Record',
        exclude_fields = ['notes'],
        debug = false } = props;
    const location = useLocation();
    const query = useQuery(location.search);

    if (context == null || context == undefined) {
        context = JSON.parse(query.get('context') || '{}');
    }
    if (query.get('forms_key')) {
        forms_key = query.get('forms_key');
    }
    let linking = !dialog_edit;
    if (query.get('dialog_edit')) {
        linking = false;
    }

    const final_props = {
        storage_props: {
            default_context: {
                ...context,
            },
            forms_key: forms_key,
            form_key: __form_key__ || `${__type__}s`,
            quick_settings: {
            },
            debug: debug,
        },

        ...props
    };
    actions = [
        creation_action && <EditIntent
            target={{
                __type__: __type__,
                __pk__: '',
            }}
            context={creation_context || context}
            action={creation_action}
            label={creation_action}
            color={'primary'}
            key='default-create'
        />,
        ...actions,
    ].filter(x => !!x);

    return <EditingCard title={title || `${__type__} Records`} actions={actions} key={`list-${__type__}`} no_wrapper={true} collapsing={`list-view-${__type__}`}>
        <WithTabularStorage
            key={`list-${__type__}`}
            Component={ReactFormTable}
            // editing_forms={[__type__]}
            form_titles={form_titles}
            exclude={exclude_fields}
            form_keys={[__type__]}
            editable={true}
            preference_key={`listview-${__type__}`}
            link_to_edit={linking}
            link_only={linking}
            {...final_props}
        />
    </EditingCard>;
};
const ListView = providing_nearest_form(
    BaseListView,
    'shogunconf_forms'
);

export default ListView;
export { BaseListView, ListView };
