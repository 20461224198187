/* Get the context for the view if we don't already have it */
import React from 'react';
import MuiLoader from 'dash/MuiLoader';
import { PAGE_FOCUS } from 'storages';
import { ResolveTarget } from './resolvetarget';
import wraps from 'wraps';
import getDisplayName from 'react-display-name';
import { withRouter } from 'react-router';
import { shallow_compare } from 'signals';
import { ResolveFocus, FocusContext } from './focusprovider';

const with_focus_target = (Component, required = false, prop = 'target') => {
    return wraps((props) => {
        const focus = React.useContext(FocusContext);
        props = {
            ...props
        };
        props[prop] = focus;
        if (required && !focus) {
            return <MuiLoader title={`Waiting for target on ${getDisplayName(Component)}`} ></MuiLoader>;
        }
        return <Component {...props} />;
    }, Component, 'with_focus_target');
};
const with_resolve_focus = (Component, required = false) => {
    const RComponent = with_focus_target(Component);
    const with_resolve_focus = wraps((props) => {
        const { __type__, __pk__, storage, context } = props;
        return <ResolveTarget __type__={__type__} __pk__={__pk__} storage={storage} context={context} as_focus={true}>
            <RComponent required={required} {...props} />
        </ResolveTarget>;
    }, Component, 'with_resolve_focus');
    return with_resolve_focus;
};


export default with_resolve_focus;
export { with_resolve_focus, with_focus_target };