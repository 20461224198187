import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';

import AcceptLicenseForm from './AcceptLicenseForm';
import ExternalLicenses from './ExternalLicenses';
import MuiLoader from 'dash/MuiLoader';
import PackageLicenses from './PackageLicenses';
import { requiring_system_licenses_store } from 'storages/systemlicense';

const styles = theme => ({
    section: {
        marginBottom: theme.spacing(4)
    }
});

class LicensesPage extends React.Component {
    render() {
        const { config, classes, ...childProps } = this.props;

        if (!config) {
            return <MuiLoader />;
        }

        return (
            <div>
                <div className={classes.section}>
                    <AcceptLicenseForm config={config} {...childProps} onSave={this.props.onSave} />
                </div>
                <div className={classes.section}>
                    <ExternalLicenses config={config} {...childProps} />
                </div>
                <div className={classes.section}>
                    <PackageLicenses config={config} {...childProps} />
                </div>
            </div>
        );
    }
}

export default requiring_system_licenses_store(withStyles(styles)(withRouter(LicensesPage)));
