/* Top level page showing promotion process */
import React from 'react';
import { current_user, stores } from 'storeregistry';
import { LoadingDisplay } from 'reactloading';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import PropertyTable from 'dash/PropertyTable';
import {fa_icon} from 'faicon';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'whatwg-fetch';

const styles = theme => ({
    buttons: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
    },
    IPv4: {
        color: 'green',
    },
    IPv6: {
        color: 'teal',
    },
    MAC: {
        color: 'light-grey',
    },
    ipLink: {
        paddingLeft: '.5em',
        paddingRight: '.5em',
    },
    note: {
        color: 'light-grey',
    }
});

const ip_to_url = (ip,path,protocol=null) => {
    /* For each IP in ips, check if the ip is responding */
    if (ip.indexOf(':') > -1) {
        ip = `[${ip}]`;
    }
    protocol = protocol || window.location.protocol;
    const to_pull = `${protocol}//${ip}${path}`;
    return to_pull;
};
const poll_url = (u,poll_handles) => {
    const controller = new AbortController();
    const { signal } = controller;
    poll_handles[u] = controller;

    return Promise.resolve(fetch(u,{
        'method':'GET',
        'cache':'no-cache',
        // 'mode': 'no-cors',
        'headers': {
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
        'redirect':'follow',
        'referrer':'no-referrer',
        signal,
    })).catch(e => {
        if (e.name == 'TypeError') {
            console.log(`Connection failure on ${u}`);
            throw( new TypeError(`Failure connecting to ${u}`));
        } else {
            throw( e );
        }
    }).then(
        (result)=>{
            if (result.status !== 200) {
                throw('Not found on server');
            }
            return result.text();
        }
    ).then(
        content => {
            try {
                return JSON.parse(content);
            } catch(e) {
                console.error(
                    `Failure parsing response: ${content}`
                );
                throw(e);
            }
        }
    ).then(
        (result) => {
            return {
                ...result,
                url: u,
            };
        }
    );
};


var PromoteView = class extends React.Component {
    static defaultProps = {
        original_url: '/media/.netconfig-promote-status.json',
    };
    wanted = false;
    state = {
        status: null,
        frequency: 2.0,
    }
    gui_link = (ip,i=0) => {
        /* Get a user-follow link to the target ip address */
        const {classes} = this.props;
        let icon = 'question';
        if (this.state.success_map){
            const response = this.state.success_map[ip];
            if (response === true) {
                icon = 'link';
            } else if (response === 'TypeError') {
                icon = 'globe';
            } else if (response === 'AbortError') {
                icon = 'chain-broken';
            }
        }
        const urlParams = new URLSearchParams(window.location.search);
        let path = window.location.pathname;
        if (urlParams.has('to')) {
            path = urlParams.get('to');
        }
        return <span className={classes.ipLink} key={`ip-${i}`}>{
            <a href={ip_to_url(ip,path,'http:')}>{
                fa_icon(icon)
            }{ip}</a>
        }</span>;

    }

    do_poll = () => {
        if (! this.wanted) {
            return;
        }
        const urls = [
            {
                url: `${window.location.protocol}//${window.location.host}${this.props.original_url}`,
                ip: window.location.hostname,
            }
        ];
        if (this.state.status && this.state.status.ips) {
            this.state.status.ips.map(
                ip => urls.push({
                    url:ip_to_url(ip,this.props.original_url),
                    ip: ip,
                })
            );
        }
        urls.map(({url,ip}) => {
            if (this.poll_handles[url]) {
                this.poll_handles[url].abort();
            }
            Promise.resolve(poll_url(url,this.poll_handles)).then(
                (result) => {
                    this.poll_handles[url] = null;
                    return this.on_status({
                        ...result,
                        url:url,
                        ip:ip,
                    });
                }
            ).catch(
                (err) => {
                    this.poll_handles[url] = null;
                    return this.on_error({
                        err: err,
                        url: url,
                        ip: ip,
                    });
                }
            );
        });
        window.setTimeout(
            this.do_poll,
            this.state.frequency * 1000
        );
    }
    on_status = (content) => {
        if (this.wanted) {
            console.debug(`Success on ${content.ip}`);
            /* TODO: track per-url status instead */
            const current = this.state.status;
            const success_map = this.state.success_map || {};
            success_map[content.ip] = true;
            const update = {
                'success_map': success_map,
            };
            if ((!current) || (current && current.ts < content.ts)) {
                // It is a replacement for current state...
                console.debug(`New state`);
                update.status = content;
                const finished = update.state === 'SUCCESS' || update.state === 'FAILURE';
                update.frequency = finished?15:5;
            }
            this.setState(update);
        }
    }
    on_error = ({err,url,ip}) => {
        console.log(`Error on ${url}: ${err}`);
        if (this.wanted) {
            // console.debug(`No response from ${url}:${err}`);
            const current = this.state.success_map || {};
            current[ip] = err.name;
            this.setState({
                'success_map': current,
            });
        }
    }

    componentDidMount() {
        this.wanted=true;
        this.poll_handles = {};
        this.do_poll();
    }
    componentWillUnmount() {
        this.wanted = false;
    }

    render() {
        var { classes, user, classes } = this.props;
        const {status} = this.state;
        if (!status) {
            return 'Loading...';
        }
        const rows = [];
        if (status) {
            const {
                state='Unknown',
                messages=[],
                ip=null,
                ips=null,
                ts=null,
                url=null
            } = status;
            rows.push({
                key:'state',
                label:'State',
                value: <React.Fragment>
                    {state}
                    {' '}
                    <span className={classes.note}> from {this.gui_link(ip,'responder')}</span>
                </React.Fragment>,
            });
            if (messages) {
                rows.push({
                    key:'messages',
                    label:'Messages',
                    value: <React.Fragment>{
                        messages.map((message,i) => {
                            return <div key={`msg-${i}`}>{message}</div>;
                        })
                    }</React.Fragment>,
                });
            }
            if (ips) {
                ips.sort();
                rows.push({
                    key: 'expected-ips',
                    label: 'Configured IPs',
                    value: <React.Fragment>
                        <div key="note" className={classes.note}>Note that links will show down for SSL failures</div>
                        {ips.map((ip,i) => {
                            return this.gui_link(ip,i);
                        })}
                    </React.Fragment>
                });
            }
            if (ts) {
                const date = new Date( ts * 1000 );
                const date_value = date.toString( );
                rows.push({
                    key:'ts',
                    label: 'Updated',
                    value: <div>
                        {date_value} (your time)
                    </div>
                });
            }
        }
        // {JSON.stringify(this.state.status)}
        return <React.Fragment>
            <EditingCard title='Network Promotion Status'>
                <PropertyTable rows={rows} />
            </EditingCard>
        </React.Fragment>;
    }
};

export default withStyles(styles)(PromoteView);
