import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ReactForm } from 'reactform';
import './hover-parent.css';

import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/MenuList';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Portal from '@material-ui/core/Portal';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import WithCurrentUser from 'withcurrentuser';
import FilterListIcon from '@material-ui/icons/FilterList';

const styles = theme => ({
    actionMenuBase: {
        display: 'flex',
        flexDirection: 'row',
    },
    actionMenuIcon: {
        fontSize: 16,
        width: 24,
        height: 24,
        cursor: 'context-menu',
    },
    actionMenuRest: {
        flex: 1,
    },
    // Hover style requires a parent to define the 
    // relative position against which we will align
    hoverMenuBase: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
    },
    hoverMenuRest: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    hoverMenuIconHolder: {
        position: 'absolute',
        width: '1rem',
        height: '1rem',
        lineHeight: '1rem',
        right: 0,
        top: 0,
    },
    hoverMenuIcon: {
        fontSize: '1rem',
        width: '1rem',
        height: '1rem',
        cursor: 'context-menu',
    },

});


class BaseActionMenu extends React.Component {
    /* A hambuger/context menu with potentially lots of operations 
    
    Displayed as a flex-box to the right of the component
    such that the elipsis shows up in a "column" at the side
    of the normal area for the content.
    */
    static defaultProps = {
        'actions': (target, context) => [
            <DeleteIntent target={target} />,
        ],
        'title': 'Show available actions',
        'context': null, // passed into actions callback
        'target': {},
        'icon': 'more-vert',
        'list_props': {
            'disablePadding': true,
            'dense': true,
        },
        'list_item_props': {
            'dense': true,
        }
    };
    state = {
        anchor: null,
        action: null,
    }
    handleClick = event => {
        this.setState({ anchor: event.currentTarget });
        event.stopPropagation();
        return true;
    }
    handleClose = event => {
        this.setState({ 'action': null, 'anchor': null });
        if (event.stopPropagation) {
            event.stopPropagation();
        }
        return true;
    }
    handleAction(action, target, context) {
        this.setState({
            anchor: null,
            action: () => action.callback(action, target, context, this)
        });
    }

    render_actions() {
        /* Render our set of actions for display onClick */
        const { anchor, action } = this.state;
        const { target, context, actions, list_item_props, list_props, user } = this.props;
        const open = Boolean(anchor);
        if (!(open || action)) {
            return null;
        }
        const action_array = actions(target, context).filter(
            x => (!!x)
        ).filter(
            action => {
                if (action.permission) {
                    if (!(user && user.has_permission(action.permission))) {
                        return false;
                    }
                }
                return true;
            }
        );
        // if (!action_array.length) {
        //     return null;
        // }
        if (action) {
            return action();
        } else {
            const rendered = <MenuList key='actions' open={true} classes={{}} {...list_props}>
                {action_array.map((action, i) => {
                    const resolved = create_action(action);
                    const { icon } = action;
                    const rendered_icon = (
                        icon && icon.isReactComponent && icon.isReactComponent()
                    ) ? icon : <Icon>{icon || 'create'}</Icon>;
                    return <MenuItem
                        key={`action-${i}`}
                        onClick={(evt) => {
                            this.handleAction(resolved, target, context);
                            evt.stopPropagation();
                            return true;
                        }}
                        classes={{}}
                        {...list_item_props}
                    >
                        <ListItemIcon classes={{}}>
                            {rendered_icon}
                        </ListItemIcon>
                        {resolved.label}
                    </MenuItem>;
                }).filter(x => x)}
            </MenuList>;
            return <Portal container={document.body}>
                <div className='click-away-catcher' onClick={evt => {
                    this.handleClose(evt);
                    evt.stopPropagation();
                    return true;
                }}>
                    <ClickAwayListener onClickAway={evt => this.handleClose(evt)}>
                        <Popover
                            anchorEl={anchor}
                            open={open}
                            classes={{}}
                        >
                            {rendered}
                        </Popover>
                    </ClickAwayListener>
                </div>
            </Portal>;
        }

        /* Yes, this is kinda crazy, ClickAwayListener does *not* stop the propagation of the events 
        
        Basically the portal moves the pop-up to the document body, then
        the click-away-listener *should* catch and ignore the events, but
        actually it propages them to the *react parent*, so we have to 
        catch that in the div here and discard it.
        */
    }
    onClickBubble = (evt) => {
        evt.stopPropagation();
        return false;
    }
    render() {
        const { classes, children, title, position = 'inline', Icon = MoreVertIcon } = this.props;
        const actions = this.render_actions();
        const baseClass = position === 'inline' ? classes.actionMenuBase : classes.hoverMenuBase;
        const restClass = position === 'inline' ? classes.actionMenuRest : classes.hoverMenuRest;
        const iconClass = position === 'inline' ? classes.actionMenuIcon : classes.hoverMenuIcon;
        const holderClass = position === 'inline' ? null : classes.hoverMenuIconHolder;
        // if (!actions) {
        //     return null;
        // }
        let icon = <MoreVertIcon />;
        if (React.isValidElement(Icon)) {
            icon = Icon;
        } else if (typeof Icon == 'function') {
            icon = <Icon />;
        }
        return <div className={classNames(baseClass, 'action-menu', 'hover-parent')}>
            <div className={classNames(restClass, 'action-menu-name')}>{children}</div>
            <div onClick={this.onClickBubble} className={classNames('hover-child', holderClass)}>
                <div
                    onClick={(evt) => this.handleClick(evt)}
                    className={classNames(iconClass, 'clickable', 'action-menu-icon')}
                    title={title}
                >{icon}</div>
                {actions}
            </div>
        </div>;
    }
}
const ActionMenu = withStyles(styles)(WithCurrentUser(BaseActionMenu));

const create_action = (structure) => {
    //    if (structure === 'delete') {
    //        structure = 
    //    }
    if (structure.isReactComponent && structure.isReactComponent()) {
        return structure;
    }
    const final_structure = {
        'list_item_props': {},
        'form_props': {},
        'callback': (action, target, context, menu) => {
            const onClose = evt => {
                menu.handleClose(evt);
                if (final_structure.onClose) {
                    final_structure.onClose();
                }
                return true;
            };
            const onSave = evt => {
                menu.handleClose(evt);
                if (final_structure.onSave) {
                    final_structure.onSave();
                }
                return true;
            };
            if (final_structure.form) {
                return final_structure.form({
                    target: final_structure.target || target,
                    ...action.form_props,
                    onClose: onClose,
                    onSave: onSave,
                });
            }
            return <ReactForm
                target={final_structure.target || target}
                {...action.form_props}
                onClose={onClose}
                onSave={onSave}
            />;
        },
        ...structure
    };
    return final_structure;
};

const delete_target_action = (target, context, menu, base = null) => ({
    /* Create a deletion action for the target 
    
    target -- record in the db to delete
    context -- 
    
    */
    label: `Delete ${target.title}`,
    icon: 'close',
    list_item_props: {
        title: `Delete ${target.title}`,
        className: classNames('delete-intent'),
        color: 'secondary',
        disabled: (!target) || (target && target.editable === undefined) || (!target.editable),
    },
    form_props: {
        title: `Delete ${target.title}`,
        context: {
            'delete-instance': true
        },
    },
    ...base,
});


export default ActionMenu;
export { ActionMenu, delete_target_action };
