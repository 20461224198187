import React from 'react';
import { useRef } from 'react';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';


const promise_read = (file, text = true, encoding = 'utf-8') => {
    /* Use FileReader api to provide promise-based loading

    Newer browser have arrayBuffer readers, but we want to
    support more platforms.
    */
    const reader = new FileReader();
    const prom = new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        if (text) {
            reader.readAsText(file, encoding);
        } else {
            reader.readAsBinaryString(file);
        }
    });
    return prom;
};


const UploadTrigger = (props) => {
    /* Icon-button based File Input with onFile callback for per-file updates

    <UploadTrigger
        color='primary'
        title='Click to upload your file'
        onFile={(file,input) => {
            parseFile(file)
        }}
        format='csv'
    />

    Hides a file input and uses it to do the actual triggering
    of file-selection and triggering of the data-load.
    */
    const {
        onFile,
        size = 'small',
        color = 'secondary',
        title = 'Click to upload',
        text = true,
        encoding = 'utf-8',
        format = 'csv'
    } = props;
    const inputRef = useRef(null);
    const on_click = (evt) => {
        inputRef.current.click();
    };
    const on_file = (evt) => {
        evt.persist();
        const files = [];
        for (let i = 0; i < evt.target.files.length; i++) {
            files.push(evt.target.files[i]);
        }
        Promise.all(
            files.map(
                (file) => {
                    return promise_read(file, text, encoding).then(data =>
                        onFile(file, inputRef.current)
                    );
                }
            )
        ).then(() => {
            evt.target.value = '';
        });
    };
    return <Badge badgeContent={format}><IconButton
        size={size}
        color={color}
        title={title}
        onClick={on_click}
    >
        <Icon>cloud_upload</Icon>
        <div className="hidden" >
            <input type="file" ref={inputRef} onChange={on_file} />
        </div>
    </IconButton></Badge>;
};

export default UploadTrigger;
