import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import MuiSnackbar from 'dash/MuiSnackbar';
import { requiring_system_store } from 'systemstorage';

const styles = theme => ({
    link: {
        color: theme.palette.common.white
    }
});

export class RebootRequired extends React.Component {
    get is_reboot_required() {
        const { system = {} } = this.props;

        return (system && system.reboot_required) ? !!system.reboot_required.reboot : false;
    }

    render() {
        const { classes, system } = this.props;
        if (!this.is_reboot_required) {
            return null;
        }

        return (
            <MuiSnackbar variant="success" stretch={true}>
                <Link
                    to={{ pathname: `/sys/configuration/`, hash: 'power' }}
                    className={classes.link}
                >
                    Reboot is required
                </Link>

                {system.reboot_required.reasons && system.reboot_required.reasons.map((reason, i) => <div className="reason" key={i}>{reason}</div>)}
            </MuiSnackbar>
        );
    }
}

RebootRequired.propTypes = {
    classes: PropTypes.object.isRequired,
    system: PropTypes.object,
};

export default withStyles(styles)(requiring_system_store(RebootRequired));
