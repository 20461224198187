const concrete_columns = (columns) => {
    const result = [];
    columns.map((column) => {
        if (column.columns) {
            concrete_columns(column.columns).map(
                (c) => result.push(c)
            );
        } else {
            result.push(column);
        }
    });
    return result;
};
export default concrete_columns;