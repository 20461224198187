/* Allow user to choose the set of dashboards they have enabled */
import React from 'react';
import { FormStorage } from 'storages/basestorage';
import ListView from 'shogun/listview';
import { useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import { DBPrefContext } from 'dash/userprefs';
import SettingsLink from 'settingslink';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    tabHolder: {
        display: 'flex',
    },
    tabSpacer: {
        flex: 1,
        fontSize: 'larger',
    }
});

const DashboardToggle = (props) => {
    const { slug } = props;
    const preferences = React.useContext(DBPrefContext);
    const is_enabled = (key) => {
        let enabled = false;
        preferences.preferences.dashboards && preferences.preferences.dashboards.map((dash) => {
            if (key == dash) {
                enabled = true;
            }
        });
        return enabled;
    };
    const enabled = is_enabled(slug);
    const setSelected = (enabled, slug) => {
        console.debug(`Set dashboard ${slug} to ${enabled ? 'enabled' : 'disabled'}`);
        const dashboards = (preferences.preferences.dashboards || []).filter((key) => (key !== slug && key !== null && key !== undefined));
        if (enabled) {
            dashboards.push(slug);
        }
        console.debug(`Update on dashboards ${JSON.stringify(preferences.preferences.dashboards)} => ${JSON.stringify(dashboards)}`);
        const { save, ...restPrefs } = preferences;
        const run_save = () => save({
            ...restPrefs,
            preferences: {
                ...(preferences.preferences || {}),
                dashboards: dashboards,
            },
        });
        window.setTimeout(run_save, 1);
    };
    return <Switch checked={enabled} onChange={(evt) => {
        setSelected(!enabled, slug);
    }} />;

};

const DashboardChoiceTable = (props) => {
    const classes = useStyles();
    return <React.Fragment>
        <div className={classes.tabHolder}>
            <div key="align-div" className={classes.tabSpacer} >Select dashboards to enable. Click the settings button to return to dashboard <span style={{ fontSize: 'larger' }}>→</span></div>
            <SettingsLink url='/m/dashboard/0/' />
        </div>
        <ListView
            __type__='Dashboard'
            forms_key='system_forms'
            creation_action={null}
            exclude={["definition", "owner", "default"]}
            default_order={['slug']}
            widgets={{
                "label": (cell) => {
                    if (cell.record.role == 'dashboard-group') {
                        return <b>{cell.value}</b>;
                    } else {
                        return <span>{cell.value}</span>;
                    }
                }
            }}
            column_callback={(columns, table) => {
                columns.push({
                    'Header': 'Selected',
                    'accessor': '0.selected',
                    'Cell': (props) => {
                        if (props.row.original[0].role == 'dashboard') {
                            const slug = props.row.original[0].slug;
                            return <DashboardToggle slug={slug} />;
                        } else {
                            return null;
                        }
                    }
                });
                return columns;
            }}
        />
    </React.Fragment>;
};


const ChooseDashboards = (props) => {
    return <DashboardChoiceTable />;
};

export default ChooseDashboards;