import React from 'react';
import TextField from '@material-ui/core/TextField';

const PatientTextField = (props) => {
    /* Text field that does onChange only after a small typing delay */
    const { value = undefined, onChange, size = 'small', delay = 250, title = `Type to filter rows in the table` } = props;
    const [current, setState] = React.useState(value);
    const [waiting, setWaiting] = React.useState(null);

    return <TextField
        value={current || ''}
        title={title}
        onChange={e => {
            let value = e.target.value;
            if (value === current) {
                return;
            }
            if (!value || !value.length) {
                console.log(`Setting undefined`);
                value = undefined;
            } else {
                console.log(`Setting value ${value}`);
            }
            setState(value);
            if (waiting) {
                console.log(`Clearing waiting`);
                window.clearTimeout(waiting);
            }
            setWaiting(window.setTimeout(() => {
                console.log(`Running onChange for patient text ${JSON.stringify(value)}`);
                return onChange(value);
            }, delay));
        }}
        size={size}
        onClick={e => e.stopPropagation()}
    />;
};

export default PatientTextField;