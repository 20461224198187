/* React control that allows you to select-from-list or enter new value 

choices -- set of [key,label] values to display in a select-like drop down
value -- currently selected key

If you type a value in the text input, we'll search for that value, displaying
a list of items. If there's no match, the value is reported as a new value,
i.e. a value reported as [key,null] such that higher-level code can determine
how to handle the record.
*/

import React from 'react';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ClassyWidget } from 'reactform/classywidget';
import WidgetRender from 'widgets/custom_widget_render';
import Input from '@material-ui/core/Input';

import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { List } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    selectOrCreateRoot: {
        display: 'flex',
        flexDirection: 'column',
    },
    inputRoot: {
        minWidth: '5rem',
        width: '100%',
    },
    menuPaper: {
        maxHeight: '25vh',
        overflow: 'auto',

    },
    currentDisplay: {
        fontSize: '.75rem',
    }
}));

const search = (value, choices) => {
    const matches = choices.filter(choice => {
        const test = `${value}`.toLowerCase();
        if (`${choice[0]}`.toLowerCase().indexOf(test) > -1) {
            return true;
        } else {
            return `${choice[1]}`.toLowerCase().indexOf(test) > -1
        }
    });
    return matches
}
const find_test = (value) => {
    let test = `${value}`;
    if (value && value.__pk__) {
        test = value.__pk__;
    } else if (value && value.id) {
        test = value.id;
    }
    return test;
}

const find_selected = (value, choices) => {
    const index = find_index(value, choices);
    if (index > -1) {
        return choices[index];
    } else {
        return null;
    }
}
const find_index = (value, choices) => {
    const test = find_test(value);
    for (let i = 0; i < choices.length; i++) {
        const x = choices[i];
        if (`${x[0]}` == test) {
            return i;
        }
    }
    return -1;
}
const defaultOnChange = (value) => {
    console.log(`Selected ${value[0]} => ${value[1]}`);
}

const SelectOrCreate = (props) => {

    const { value = null, choices = [], onChange = defaultOnChange, validateValue = null } = props;

    const classes = useStyles();
    const [localId, setLocalId] = React.useState(`${Math.floor(Math.random() * 4294967296)}`);
    const [choicesVisible, setChoicesVisible] = React.useState(false);
    const inputRef = React.useRef();
    const menuListRef = React.useRef();
    const selectedRenderRef = React.useRef();

    const initialMatch = find_selected(value, choices);
    const [selected, setSelected] = React.useState(initialMatch);

    const [localEditValue, setLocalEditValue] = React.useState(value);
    const [localChoices, setLocalChoices] = React.useState(choices);
    const [localSelect, setLocalSelect] = React.useState(value);
    const currentMatch = find_selected(localEditValue, choices);

    // const onSelectItem = (item) => {
    //     setLocalEditValue(item[0]);
    //     setSelected(item);
    //     setChoicesVisible(false);
    //     onChange && onChange(item);
    // };
    const onInputTyping = (evt) => {
        const newValue = evt.target.value;
        setLocalEditValue(newValue);
        const matches = search(newValue, choices);
        if (matches.length == 0) {
            setChoicesVisible(false);
            setLocalChoices(choices);
            setLocalSelect(null);
            selectByKey(newValue);
            // Send change-with-null
        } else {
            const exact = find_selected(newValue, matches);
            if (exact) {
                setLocalSelect(exact[0]);
                selectByKey(exact[0]);
            } else if (localSelect != null) {
                /* is previously selected in choices? */
                setLocalSelect(null);
            }
            setLocalChoices(matches);
            setChoicesVisible(true);
        }
    };
    const selectCurrentMenu = (evt) => {
        if (localSelect) {
            selectByKey(localSelect);
        } else {
            setChoicesVisible(false);
            setLocalChoices(choices);
        }
    };
    const selectByKey = (key) => {
        setLocalEditValue(key);
        let choice = find_selected(key, choices);
        if (choice) {
            setLocalSelect(choice[0]);
            setSelected(choice);
            onChange && onChange(choice);
        } else {
            /* Custom value */
            const newValid = validateValue ? validateValue(localEditValue) : true;
            setLocalSelect(null);
            if (newValid) {
                setSelected([key, null]);
                onChange && onChange([key, null]);
            }
        }
        setChoicesVisible(false);
        setLocalChoices(choices);
    }
    const scrollByOffset = (direction) => {
        /* Scroll up/down by an offset (direction) */
        if ((localChoices && localChoices.length)) {
            let tempSelect = localSelect;
            if (!tempSelect) {
                tempSelect = localChoices[0][0];
            }
            let index = find_index(tempSelect, localChoices);
            if (index > -1) {
                let new_index = index + direction;
                if (new_index < 0) {
                    new_index = localChoices.length - 1;
                } else if (new_index > localChoices.length - 1) {
                    new_index = 0;
                }
                setLocalSelect(localChoices[new_index][0]);
            }
        }

    }
    const selectMenuKeyPress = (evt) => {
        if (evt.key == 'Enter') {
            if (localSelect) {
                selectCurrentMenu(evt);
            } else {
                selectByKey(localEditValue);
            }
            evt.preventDefault()
            evt.stopPropagation();
            return false;
        } else if (evt.key == 'Escape') {
            if (selected) {
                selectByKey(selected[0]);
            } else {
                selectByKey(value);
            }
            evt.preventDefault()
            evt.stopPropagation();
            setChoicesVisible(false);
            return false;
        } else if (evt.key == 'ArrowUp') {
            scrollByOffset(-1);
            evt.preventDefault()
            evt.stopPropagation();
            return false;
        } else if (evt.key == 'ArrowDown') {
            scrollByOffset(1);
            evt.preventDefault()
            evt.stopPropagation();
            return false;
        } else if (evt.key == 'Tab') {
            if (localSelect) {
                selectByKey(localSelect);
            } else {
                setChoicesVisible(false);
                setLocalChoices(choices);
                setLocalSelect(null);
            }
            evt.preventDefault();
            evt.stopPropagation();
            window.setTimeout(() => {
                let e = new KeyboardEvent("keyup", {
                    key: evt.key,
                    altKey: evt.altKey,
                    shiftKey: evt.shiftKey,
                    metaKey: evt.metaKey,
                    ctrlKey: evt.ctrlKey,

                    code: evt.code,
                    location: evt.location,
                    repeat: evt.repeat,
                    isComposion: evt.isComposion,
                })
                inputRef.current.dispatchEvent(e);
            }, 100);
        }
    }
    const onSelectFocus = React.useCallback((evt) => {
        setChoicesVisible(true);
    }, []);

    let menu = null;

    if (choicesVisible) {
        const choices_rendered = localChoices.map((choice, index) => {
            const [value, label] = choice;
            const selectedTest = localSelect === null ? localEditValue : localSelect;
            const isSelected = value == selectedTest ? true : false;
            const renderRef = isSelected ? selectedRenderRef : null;
            return <MenuItem ref={renderRef} selected={isSelected} key={`choice-${index}`} value={value} onClick={
                (evt) => selectByKey(choice[0])
            }> {label}</MenuItem >;
        });

        menu = <Popover open={true} anchorEl={inputRef.current} classes={{
        }} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} getContentAnchorEl={false} autoFocus={false} onClose={() => setChoicesVisible(false)}>
            <Input autoFocus={true} onFocus={(evt) => {
                evt.target.select();
            }}
                id={`${localId}-search`}
                value={localEditValue}
                onChange={onInputTyping}
                onKeyUp={selectMenuKeyPress}
                classes={{
                    root: classes.inputRoot,
                }}
            />
            <List ref={menuListRef} classes={{
                root: classes.menuPaper,
            }}>
                {choices_rendered}
            </List>
        </Popover>;

    }
    React.useEffect(() => {
        if (choicesVisible && selectedRenderRef.current && menuListRef.current) {
            selectedRenderRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [localSelect, localEditValue, selected, choicesVisible]);
    const isValid = validateValue ? validateValue(localEditValue) : true;
    const onInputKeypress = (evt) => {
        if (evt.key == 'ArrowDown') {
            setChoicesVisible(true);
            evt.preventDefault();
            evt.stopPropagation();
            return false
        }
    };

    return <div className={classes.selectOrCreateRoot}>
        <Input id={localId} value={localEditValue} error={!isValid} classes={{
            root: classes.inputRoot,
        }} onClick={(evt) => {
            evt.stopPropagation();
            setChoicesVisible(!choicesVisible);
        }} onFocus={onSelectFocus} onChange={onInputTyping} onKeyUp={onInputKeypress} inputRef={inputRef} />
        {menu}
        <div className={classes.currentDisplay}>{currentMatch && currentMatch[1]}</div>
    </div>
};

class SelectOrCreateWidget extends ClassyWidget {
    controlChanged = (value) => {
        const [key, label] = value;
        console.log(`New value from the widget ${key} => ${value}`);
        this.handleChange(key, true);
    }
    render = () => {
        const { classes, show_label = true, autoSelect = false, validateValue = (v) => {
            return /^\d+$/.test(v);
        } } = this.props;
        var field = this.get_field();
        var base_field = field.field;
        const current_value = this.render_value();
        return <WidgetRender form_field={field} widget_class="display-widget" show_label={show_label}>
            <SelectOrCreate
                value={current_value}
                choices={base_field.choices}
                onChange={this.controlChanged}
                validateValue={validateValue}
            />
        </WidgetRender>;
    }
}

export default SelectOrCreate;
export { SelectOrCreate, SelectOrCreateWidget };
