/* Subscription provider for components that need access to current user 

<CurrentUserProvider /> needs to wrap the app

useCurrentUser() => User record 
WithCurrentUser(Component) => HOC providing current user as a property

*/
import React from 'react';
import { USER_RESOLVED } from 'storages/ajaxobservables';
import PropTypes from 'prop-types';
import wraps from 'wraps';
import { requiring_store } from 'storages/requiringstore';
import { default_user_storage_callback } from 'storages/userstorage';

const CurrentUserContext = React.createContext();

const BaseCurrentUserProvider = (props) => {
    /* Provides the top-level user record for customising preferences and what to display */
    const [user, setUser] = React.useState({
        'permissions': [],
        'groups': [],
        'is_superuser': false,
        'is_staff': false,
        'is_anonymous': true,
        'name': '',
        has_permission: () => false,
    });
    React.useEffect(() => {
        USER_RESOLVED.listen(setUser);
        return () => {
            console.error(`Likely app error, the user provider is un-mounting, that shouldn't happen in normal operation`);
            USER_RESOLVED.ignore(setUser);
        };
    }, []);
    return <CurrentUserContext.Provider value={user}>{props.children}</CurrentUserContext.Provider>;
};
const CurrentUserProvider = requiring_store(BaseCurrentUserProvider, {
    storage_key: 'system_accounts',
    create_function: default_user_storage_callback(),
    prop_function: (props, store) => ({ ...props, store })
});


const useCurrentUser = () => {
    return React.useContext(CurrentUserContext);
};

const WithCurrentUser = (Component) => {
    const UsingCurrentUser = wraps((props) => {
        const user = useCurrentUser();
        return <Component {...props} user={user} />;
    }, Component, 'WithCurrentUser');
    return UsingCurrentUser;
}

const UserRecordPropType = PropTypes.shape({
    'permissions': PropTypes.arrayOf(PropTypes.string).isRequired,
    'groups': PropTypes.arrayOf(PropTypes.shape({
        'id': PropTypes.number,
        'name': PropTypes.string,
        'type': PropTypes.string,
    })).isRequired,
    'is_superuser': PropTypes.bool.isRequired,
    'is_anonymous': PropTypes.bool.isRequired,
    'name': PropTypes.string.isRequired,
    'has_permission': PropTypes.func.isRequired,
});
WithCurrentUser.propTypes = {
    user: UserRecordPropType,
};

export default WithCurrentUser;
export { WithCurrentUser, UserRecordPropType, CurrentUserProvider, CurrentUserContext, useCurrentUser };
