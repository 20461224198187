import React from 'react';
import PropTypes from 'prop-types';
import LogTable from './LogTable';
import EditingCard from 'dash/editingcard';
import EditIntent from 'editintent';
import { providing_nearest_form } from 'formprovider';
import getIcon from 'getIcon';
import classNames from 'classnames';

const parse_event_log = (log_data) => {
    /* Parse system event log for presentation in log table */
    try {
        const arr = log_data.split('\n').filter(x => !!x);
        return arr.map(JSON.parse).map(r => {
            r.asctime = r.created_formatted;
            return r;
        });
    } catch (e) {
        return [{
            'error': true,
            'messages': 'Event log cannot be parsed'
        }];
    }
};

const EventLogTable = (props) => {
    return <LogTable
        parse_log={parse_event_log}
        form_key='event_logs'
        log_name='event-log.json'
    />;
};

const EventLogView = (props) => {
    const actions = [
        <EditIntent
            target={{
                type: 'ClearEventLog',
                id: null,
            }}
            key='clear'
            icon={getIcon('close')}
            label='Clear Events'
            color='primary'
        />
    ];

    return <EditingCard title={'Logged Events'} no_wrapper={true} actions={actions}>
        <EventLogTable {...props} />
    </EditingCard>;
};

export default providing_nearest_form(EventLogView, 'system_forms');
export { parse_event_log, EventLogTable, EventLogView };
