/* Stand-alone editor for tuning-table structures */
import React, { useEffect, useState } from 'react';
import ATXTable from 'atxtable/atxtable';
import Papa from 'papaparse';
import UploadTrigger from 'dash/UploadTrigger';
// import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { PureForm } from 'reactform/reactform';
import hz_display from 'hzdisplay'; // side-effect registration :(
import { saveAs } from 'file-saver';
import { withStyles } from '@material-ui/core/styles';
import { default_render } from 'reactform/typerenderer';
import AlignDiv from 'reactform/aligndiv';
import RenderBoolean from 'widgets/renderboolean';
import classNames from 'classnames';
import md5 from 'md5';

const styles = theme => ({
    chipHolder: {
        width: '100%',
        minHeight: '2em',
        borderBottomColor: theme.roles.shading,
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
    },
    empty: {
        color: theme.roles.disabled,
    },
    customHolder: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    customField: {
        flex: 1,
    },
    tableField: {
        overflow: 'hidden',
    },
    unsaved: {
        borderLeft: '2px solid yellow',
        borderRight: '2px solid yellow',
    }
});


const udp_tuning_errors = (tuning) => {
    const messages = [];
    if (!tuning.ip) {
        messages.push('Need a Group for UDP service');
    }
    if (!tuning.port) {
        messages.push('Need a Port for UDP service');
    }
    if (messages.length) {
        return {
            error: true,
            messages: messages,
        }
    }
    return null;
};
const qam_tuning_errors = (tuning) => {
    const messages = [];
    if (!tuning.frequency) {
        messages.push('Need a Frequency for QAM service');
    }
    if (!tuning.program_id) {
        messages.push('Need a Program for QAM service');
    }
    if (!tuning.mode) {
        tuning.mode == 'qam64';
    }
    if (messages.length) {
        return {
            error: true,
            messages: messages,
        };
    }
    return null;
};

const int_cast = (value) => {
    if (value === null || value === undefined) {
        return null;
    }
    if (value instanceof Number) {
        return value;
    }
    return parseInt(value);
};
const float_cast = (value) => {
    if (value === null || value === undefined) {
        return null;
    }
    if (value instanceof Number) {
        return value;
    }
    return parseFloat(value);
};

const tuning_errors = (tuning) => {
    /* Does the tuning make a valid configuration? */
    const typ = tuning.type.toLowerCase();
    if (tuning.encryption) {
        const formatted_enc = {
            'NONE': '',
            'PROIDIOM': 'ProIdiom',
            'PRO:IDIOM': 'ProIdiom',
            'VCAS': 'VCAS',
            'AUTO': 'Auto',
        }[tuning.encryption.toUpperCase()];
        tuning.encryption = formatted_enc || '';
    }
    if (typ == "udp") {
        return udp_tuning_errors(tuning);
    } else if (typ == "qam") {
        return qam_tuning_errors(tuning);
    } else {
        return {
            error: true,
            messages: [
                `Unrecognised tuning type ${tuning.type}`,
            ],
        };
    }
};

const on_csv_upload = (content) => {
    return new Promise((resolve, reject) => {
        Papa.parse(content, {
            header: true,
            complete: resolve,
        });
    }).then(result => {
        return result.data.map((row) => {
            let formatMatcher = /^.*[(](.*)[)]$/;
            let tmsid = row.tmsid;
            let match = formatMatcher.exec(tmsid);
            if (match && match.length > 0) {
                tmsid = match[1];
            }
            return {
                channel: row.channel,
                tmsid: tmsid,
                station: [
                    tmsid,
                    row.station_name || '',
                    row.call_sign || '',
                    row.language || 'eng',
                    row.location || '',
                ],
                url: row.url,
                enabled: (row.enabled === undefined || row.enabled === null || row.enabled.toLowerCase() == 'true' || row.enabled == '1' || row.enabled.toLowerCase() == 'yes') ? true : false,
            };
        });
    }).then(rows => {
        const result = rows.filter(
            row => (
                row.tmsid && row.url
            )
        );
        return result;
    });
};

const format_csv = (tuning) => {
    return Papa.unparse(
        {
            data: tuning.map((record) => {
                return [
                    record.channel,
                    record.station[0],
                    record.station[1],
                    record.station[2],
                    record.station[3],
                    record.station[4],
                    record.url,
                    !!(record.enabled),
                ];
            }),
            fields: [
                'channel',
                'tmsid',
                'station_name',
                'call_sign',
                'language',
                'location',
                'url',
                'enabled',
            ],
        },
        {
            header: true,
            quotes: true,
        }
    );
};



const from_server = (base) => {
    if (typeof base == 'string') {
        if (!base.length) {
            return [];
        } else if (base == 'null') {
            return [];
        } else if (base.length && base[0] == '[') {
            return JSON.parse(base);
        } else {
            console.warn(`Unexpected lineup description: ${base}`);
            return [];
        }
    } else if (base === null || base === undefined) {
        return [];
    } else {
        return base;
    }
};
const merge_errors = (data, errors) => {
    if (errors && errors.length) {
        errors.map(rec => {
            const [index, err] = rec;
            if (index >= 0 && index < data.length) {
                data[index].errors = err;
            }
        });
    }
    return data;
}
const to_server = (base) => {
    if (Array.isArray(base)) {
        return JSON.stringify(base);
    } else {
        return base;
    }
};
const update_value = (updated, tmsid_map) => {
    /* sort and annotate the updated value */
    updated.sort(
        (a, b) => (parseInt(a.channel) < parseInt(b.channel) ? (parseInt(a.channel) == parseInt(b.channel) ? 0 : -1) : 1)
    );
    updated.map(
        (channel) => {
            const station = tmsid_map[channel.tmsid];
            if (station) {
                channel['station'] = station;
            } else if (
                channel['station'] && (
                    channel['station'][0] == channel.tmsid // unchanged
                )
            ) {
                channel['station'] = [channel.tmsid, ...channel.station.slice(1)];
            } else {
                /* custom, new or changed */
                channel['station'] = [
                    channel.tmsid,
                    '',
                    '',
                    '',
                    '',
                    '',
                ];
            }
            channel['tmsid_display'] = `#${channel['tmsid']} ${channel['station'][2]}`;
        }
    );
    return updated;
}
const createTmsidMap = (stations) => {
    const tmsid_map = {};
    stations.map((station) => {
        if (station.selection_description) {
            tmsid_map[station.station[0]] = station.station;

        } else {
            tmsid_map[station[0]] = station;

        }
    });
    return tmsid_map;
}
const is_custom_station = (tmsid, tmsidMap) => {
    if (Array.isArray(tmsid)) {
        tmsid = tmsid[0];
    }
    return !tmsidMap[tmsid];
}

const TuningMapTable = withStyles(styles)((props) => {
    const { data, edit_callback, add_callback, edit_station, tmsidMap, remove_callback, errors, classes, csv_upload_callback } = props;

    const field_cell_fn = (key) => (props) => {
        let err = null;
        if (props.row.original.errors && props.row.original.errors[key]) {
            err = <div class='error'>{props.row.original.errors[key]}</div>
        }
        return <div className={classNames(classes.tableField)}>
            {err}
            {props.value}
        </div>
    };
    const enabled_render = (props) => {
        const value = props.row.original.enabled;
        return <RenderBoolean value={value} true_title={'Enabled'} false_title={'Disabled'} />;
    };

    return <div className={classNames('tuning-table-editor', (errors && errors.length) ? classes.unsaved : null)}><ATXTable
        data={data}
        getTrProps={(state, rowInfo, baseProps) => {
            return {
                onClick: (evt) => {
                    edit_callback && edit_callback(rowInfo.original, rowInfo.index);
                },
            };
        }}
        columns={[
            {
                Header: 'Enabled',
                accessor: 'enabled',
                size: 'small',
                Cell: enabled_render,
            },
            {
                Header: 'Channel',
                accessor: 'channel',
                size: 'small',
                Cell: field_cell_fn('channel'),
            },
            {
                Header: 'TMSID',
                accessor: (r) => r.tmsid,
                size: 'small',
                Cell: field_cell_fn('tmsid'),
            },
            {
                Header: 'Station',
                accessor: 'station',
                size: 'short',
                Cell: (props) => {
                    const station = props.value;
                    if (is_custom_station(station[0], tmsidMap)) {
                        const on_edit_station = (evt) => {
                            edit_station && edit_station({
                                'editing_station_channel': props.row.original,
                                'editing_station': station,
                            });
                            evt.stopPropagation();
                        }
                        return <React.Fragment><IconButton onClick={on_edit_station} size='small'><EditIcon /></IconButton> {station[2] || station[1]}</React.Fragment>
                    } else {
                        return station[2] || station[1];
                    }
                }
            },
            {
                Header: 'URL',
                accessor: (r) => r.url,
                size: 'large',
                Cell: field_cell_fn('url'),
            },
            {
                Header: '',
                'id': 'delete',
                'width': 64,
                Cell: (props) => {
                    const { original } = props;
                    return <IconButton
                        onClick={(evt) => {
                            remove_callback && remove_callback(original, props.row.index);
                            evt.stopPropagation();
                        }}
                        aria-label="Remove Channel"
                        size='small'
                    ><Icon>close</Icon></IconButton>;
                },
            }
        ]}
    />
        <div>
            <IconButton
                size='small'
                onClick={(evt) => {
                    add_callback && add_callback({ 'tmsid': '', 'tuning': { 'type': 'udp' }, 'enabled': true });
                    evt.stopPropagation()
                }}
            ><AddIcon /></IconButton>
            <IconButton
                size='small'
                color='secondary'
                title='Download CSV'
                onClick={
                    (evt) => {
                        let current = data;
                        const seen = {};
                        current.map((record) => {
                            seen[record.tmsid] = true;
                        });
                        const formatted = format_csv(current);
                        const blob = new Blob([formatted], { type: "text/csv;charset=utf-8" });
                        saveAs(blob, `tuning-table.csv`);
                    }
                }
            ><CloudDownloadIcon /></IconButton>
            <UploadTrigger onFile={(content) => {
                csv_upload_callback && csv_upload_callback(content);
            }} />
        </div>
    </div>;
});

const TuningTableEditor = withStyles(styles)((props) => {
    /* Stand-alone tuning table editor providing callback based valueChanged on tuning table structure 
    
    props:

        value -- array of channel records
        onChange -- callback for when the value has updated 
        stations -- array of station records providing tmsid search/autocomplete
    */
    const { value, onChange, stations, errors } = props;


    const [tmsidMap, setTmsidMap] = useState(createTmsidMap(stations));
    const [stationsHash, setStationsHash] = useState("")

    useEffect(() => {
        // creates a hash of stations to be used as the key for TuningChannelForm
        const raw = JSON.stringify(stations);
        const content = md5(raw);

        setStationsHash(content)
    }, [stations]);

    const editingValue = React.useMemo(() => {
        return merge_errors([...from_server(value)], errors);
    }, [value, errors]);

    const [editingRecord, setEditingRecord] = useState(null);
    const [stationEditState, setStationEditState] = useState(null);


    const valueChanged = (value) => {
        let fixed = update_value(value, tmsidMap);
        if (onChange) {
            onChange(to_server(fixed));
        }
        return fixed;
    }

    const remove_callback = (record, index) => {
        const old_records = editingValue;
        const new_records = [
            ...old_records.slice(0, index),
            ...old_records.slice(index + 1),
        ];
        if (old_records.length <= new_records.length) {
            console.warn("Remove callback did not remove any elements");
        }
        // console.log(`New records ${new_records.length}`);
        valueChanged(new_records);
    };
    const add_callback = (channel) => {
        const current = [...editingValue];
        const index = current.length;
        current.push(channel);
        // current.sort(channel_sort);
        valueChanged(current);
        setEditingRecord({
            editing_record: current,
            editing_record_index: index,
        })
    };
    const edit_callback = (current, index) => {
        setEditingRecord({
            editing_record: current,
            editing_record_index: index,
        })
    }
    const stop_editing_callback = () => {
        setEditingRecord(null);
    }
    const edit_station = (station_state) => {
        setStationEditState(station_state);
    }

    const csv_upload_callback = (content) => {
        on_csv_upload(content).then(
            (result) => valueChanged(result)
        );
    };
    const table = <TuningMapTable
        key='tt-table'
        data={editingValue}
        add_callback={add_callback}
        remove_callback={remove_callback}
        edit_callback={edit_callback}
        edit_station={edit_station}
        csv_upload_callback={csv_upload_callback}
        tmsidMap={tmsidMap}
        errors={errors}
    />;
    const editingForm = editingRecord && editingRecord.editing_record && <TuningChannelForm
        key={`tt-edit-form-${stationsHash}`}
        data={editingValue}
        editingRecord={editingRecord}
        update_callback={valueChanged}
        stop_editing_callback={stop_editing_callback}
        stations={stations}
        tmsidMap={tmsidMap}
    />;
    const stationForm = stationEditState && stationEditState.editing_station && <CustomStationForm
        key='tt-station-form'
        data={editingValue}
        stationEditState={stationEditState}
        update_callback={valueChanged}
        stop_editing_callback={() => edit_station(null)}
        stations={stations}
        tmsidMap={tmsidMap}
    />;
    return <React.Fragment>
        {table}
        {editingForm}
        {stationForm}
    </React.Fragment>

});

const TuningChannelForm = (props) => {
    const { data, update_callback, stop_editing_callback, stations, tmsidMap } = props;
    const { editing_record, editing_record_index } = props.editingRecord;

    if (!editing_record.station) {
        editing_record.station = [
            editing_record.tmsid,
            "",
            "",
            "",
            "",
            "",
        ];
    }

    const station_map = {};
    stations.map(k => {
        station_map[k[0]] = k[1];
    });

    const tmsid_is_custom_station = (tmsid) => {
        return is_custom_station(tmsid, tmsidMap);
    }

    return <PureForm
        key={`form-${editing_record_index}`}
        target={{ editing_record }}
        type_name="Tuning Channel"
        speculative_modification={false}
        onClose={(evt) => { stop_editing_callback() }}
        handleSave={(form, buttonParameters) => {
            const parameters = form.currentFormValues()
            if (parameters.enabled === undefined) {
                parameters.enabled = false;
            }
            let updated = {
                ...parameters,
            };
            if (tmsid_is_custom_station(parameters.tmsid)) {
                updated.station = [parameters.tmsid, parameters.name, parameters.short_name, parameters.language, parameters.location];
            }
            update_callback([
                ...data.slice(0, editing_record_index),
                updated,
                ...data.slice(editing_record_index + 1,),
            ]);
            stop_editing_callback();
        }}
        form_details={{
            'field_sets': [
                {
                    'key': 'identity',
                    'fields': ['enabled', 'channel', 'tmsid'],
                    'columns': 3,
                },
                {
                    'key': 'parameters',
                    'fields': ['url'],
                    'columns': 1,
                    'field_props': {
                        'url': {
                            'style': {
                                'gridColumn': 'span 3',
                            }
                        }
                    }
                },
            ],
            'fields': [
                {
                    'name': 'channel',
                    'field': {
                        "widget": "NumberInput",
                        "initial": null,
                        "widget_data": null,
                        "required": true,
                        "label": "Channel",
                        "max_length": null,
                        "help_text": "Channel number the user will type to tune the channel",
                        "show_hidden_initial": false,
                        "min": 0,
                        "max": 999,
                    },
                    "label_id": "id_channel",
                    "widget_data": {},
                    "hidden": false,
                    "value": editing_record.channel || '',
                    "label": "Channel"
                },
                {
                    'name': 'enabled',
                    'field': {
                        "widget": "CheckboxInput",
                        "initial": true,
                        "widget_data": null,
                        "required": false,
                        "label": "Enabled",
                        "max_length": null,
                        "help_text": "If true, the channel will be included in the channel plan for the device",
                        "show_hidden_initial": false,
                    },
                    "label_id": "id_enabled",
                    "widget_data": {},
                    "hidden": false,
                    "value": editing_record.enabled === undefined ? true : editing_record.enabled,
                    "label": "Enabled"
                },
                {
                    'name': 'tmsid',
                    'field': {
                        "widget": "SelectOrCreate",
                        "initial": null,
                        "widget_data": null,
                        "required": true,
                        "label": "Schedule Identifier (TMSID)",
                        "max_length": null,
                        "help_text": "Unique key used to lookup schedules in your EPG Data Source, commonly the Tribune Media Services (TMS) identifier",
                        "show_hidden_initial": false,
                        "choices": stations.map((station) => {
                            if (station && station.selection_description) {
                                /* DishEPG format with a custom description for the station */
                                return [
                                    station.station[0],
                                    station.selection_description,
                                ];
                            }
                            return [
                                station[0],
                                `${station[1]} (${station[0]})`
                            ];
                        }),
                    },
                    "label_id": "id_tmsid",
                    "widget_data": {
                        'create_new_option': ["", "Locally Inserted Channel"],
                    },
                    "hidden": false,
                    "value": editing_record.tmsid,
                    "label": "TMSID"
                },

                {
                    'name': 'name',
                    'field': {
                        "widget": "TextInput",
                        "initial": null,
                        "widget_data": null,
                        "required": true,
                        "label": "Name",
                        "max_length": null,
                        "help_text": "Formal name of the channel (full name), displayed when no schedule information is available",
                        "show_hidden_initial": false,
                    },
                    "label_id": "id_name",
                    "widget_data": {},
                    "hidden": false,
                    "value": editing_record.station[1],
                    "label": "Channel Name",
                    "dependencies": [
                        ["tmsid", tmsid_is_custom_station],
                    ]
                },
                {
                    'name': 'short_name',
                    'field': {
                        "widget": "TextInput",
                        "initial": null,
                        "widget_data": null,
                        "required": true,
                        "label": "Callsign",
                        "max_length": null,
                        "help_text": "Callsign or short-name of the channel, commonly displayed under the channel number",
                        "show_hidden_initial": false,
                    },
                    "label_id": "id_short_name",
                    "widget_data": {},
                    "hidden": false,
                    "value": editing_record.station[2],
                    "label": "Callsign",
                    "dependencies": [
                        ["tmsid", tmsid_is_custom_station],
                    ]
                },
                {
                    'name': 'language',
                    'field': {
                        "widget": "TextInput",
                        "initial": null,
                        "widget_data": null,
                        "required": false,
                        "label": "Language",
                        "max_length": null,
                        "help_text": "Primary language of the channel (ISO6392 3-character form), optional",
                        "show_hidden_initial": false,
                    },
                    "label_id": "id_language",
                    "widget_data": {},
                    "hidden": false,
                    "value": editing_record.station[3],
                    "label": "Language",
                    "dependencies": [
                        ["tmsid", tmsid_is_custom_station],
                    ]
                },
                {
                    'name': 'location',
                    'field': {
                        "widget": "TextInput",
                        "initial": null,
                        "widget_data": null,
                        "required": false,
                        "label": "Location",
                        "max_length": null,
                        "help_text": "Broadcast location for the station, optional",
                        "show_hidden_initial": false,
                    },
                    "label_id": "id_location",
                    "widget_data": {},
                    "hidden": false,
                    "value": editing_record.station[4],
                    "label": "Location",
                    "dependencies": [
                        ["tmsid", tmsid_is_custom_station],
                    ]
                },

                {
                    'name': 'url',
                    'field': {
                        "widget": "URLInput",
                        "initial": null,
                        "widget_data": null,
                        "required": true,
                        "label": "URL",
                        "max_length": 255,
                        "help_text": "URL to use to tune content qam256+proi://57.0MHz#1 udp://224.1.1.2:8000#2 https://example.com/path/content.mpd (protocols: qam64, qam256, udp, http, https  encryptions: proi, auto, mcas)",
                    },
                    "label_id": "id_url",
                    "widget_data": {},
                    "hidden": false,
                    "value": editing_record.url,
                    "label": "Content URL",
                },
            ]
        }}
    />;
}

const CustomStationForm = (props) => {
    const { data, stationEditState, update_callback, stop_editing_callback, stations } = props;
    const { editing_station_channel, editing_station } = stationEditState
    const station = editing_station;
    const channel = editing_station_channel;

    const on_update_channel = (form) => {
        const parameters = form.currentFormValues();
        const new_channel = {
            ...channel,
            station: [channel.tmsid, parameters.name, parameters.short_name, parameters.language, parameters.location],
        };
        const updated = data.map(chan => {
            if (chan.channel == new_channel.channel) {
                return new_channel;
            } else {
                return chan;
            }
        });
        update_callback(updated);
        stop_editing_callback();
    }
    return <PureForm
        key={`form-station-${station[0]}`}
        target={{ name: station[1], short_name: station[2], language: station[3], location: station[4] }}
        type_name="Custom Channel"
        speculative_modification={false}
        onClose={(evt) => { stop_editing_callback && stop_editing_callback(); }}
        handleSave={on_update_channel}
        form_details={{
            'fields': [
                {
                    'name': 'name',
                    'field': {
                        "widget": "TextInput",
                        "initial": null,
                        "widget_data": null,
                        "required": true,
                        "label": "Name",
                        "max_length": null,
                        "help_text": "Formal name of the channel (full name)",
                        "show_hidden_initial": false,
                    },
                    "label_id": "id_name",
                    "widget_data": {},
                    "hidden": false,
                    "value": station[1],
                    "label": "Channel Name"
                },
                {
                    'name': 'short_name',
                    'field': {
                        "widget": "TextInput",
                        "initial": null,
                        "widget_data": null,
                        "required": true,
                        "label": "Callsign",
                        "max_length": null,
                        "help_text": "Callsign or short-name of the channel",
                        "show_hidden_initial": false,
                    },
                    "label_id": "id_short_name",
                    "widget_data": {},
                    "hidden": false,
                    "value": station[2],
                    "label": "Callsign"
                },
                {
                    'name': 'language',
                    'field': {
                        "widget": "TextInput",
                        "initial": null,
                        "widget_data": null,
                        "required": false,
                        "label": "Language",
                        "max_length": null,
                        "help_text": "Primary language of the channel (ISO6392 3-character form)",
                        "show_hidden_initial": false,
                    },
                    "label_id": "id_language",
                    "widget_data": {},
                    "hidden": false,
                    "value": station[3],
                    "label": "Language"
                },
                {
                    'name': 'location',
                    'field': {
                        "widget": "TextInput",
                        "initial": null,
                        "widget_data": null,
                        "required": false,
                        "label": "Location",
                        "max_length": null,
                        "help_text": "Broadcast location for the station",
                        "show_hidden_initial": false,
                    },
                    "label_id": "id_location",
                    "widget_data": {},
                    "hidden": false,
                    "value": station[4],
                    "label": "Location"
                },
            ]
        }}
    />;

};

export default TuningTableEditor;
export { TuningChannelForm, TuningMapTable, TuningTableEditor, tuning_errors }