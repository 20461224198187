/* Network configuration view */
import React from 'react';
import {EditIntentFactory} from 'editintent';
import {grid_rows} from "puregrid";
import {providing_nearest_form} from 'formprovider';

class IPFilter extends React.Component {
    static displayName = 'IPFilters';

    static defaultProps = {
        'ipfilters': null,
        'editable': true,
        'settings': null
    };

    render() {
        var editable = this.props.editable;
        var ipfilters = this.props.ipfilters;
        return (
            <div className="card ipfilters">
                {grid_rows([[
                    (editable && ipfilters)?
                        EditIntentFactory({
                            key: 'footer',
                            target: ipfilters,
                            label: 'Address Filters'
                        }): null,
                    <div className="ipfilter-details">
                        {grid_rows([[
                            <div className="allow">
                                Allow
                                {ipfilters.allow.definition?
                                    <ul className="network-list no-bullets compact-list">
                                        {ipfilters.allow.networks.map(function( network, index ) {
                                            return (
                                                <li key={''+index}>
                                                    {network}
                                                </li>
                                            );
                                        })}
                                    </ul>:
                                    null}
                            </div>,
                            <div className="allow">
                                Deny
                                {ipfilters.allow.definition?
                                    <ul className="network-list no-bullets compact-list">
                                        {ipfilters.deny.networks.map(function( network, index ) {
                                            return (
                                                <li key={''+index}>
                                                    {network}
                                                </li>
                                            );
                                        })}
                                    </ul>:
                                    null}
                            </div>
                        ]],[12,12])}
                    </div>
                ]],[4,20],[6,18])}
            </div>
        );
    }
}

export default providing_nearest_form(IPFilter,'net_forms');
