import React, { useEffect } from 'react';
import * as d3 from "d3";
import apply_accessor from './accessor';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Legend from './atxlegend';
import { graph_format, graph_metric, graph_key } from 'dash/charts/chartmodel';
import { extract_count_data, calculate_domains } from './atxstackedarea';
import { get_graph_data, extract_dataset, dataset_max, dataset_min } from './extractdataset';

const useStyles = makeStyles({
    buttonHolder: {
        display: 'flex',
    },
    svgHolder: {
        display: 'flex',
    },
    d3svg: {
        flex: 1,
        // minHeight: '300px',
    },
});

const nearest_record = (recordset, date) => {
    /* Find nearest record in recordset to given date (x coordinate) */
    const deltas = recordset.map((record, index) => [Math.abs(record.x - date), record]);
    deltas.sort((a, b) => a[0] < b[0] ? -1 : 1);
    const record = deltas[0][1];
    return record;
};



const ATXBarChart = (props) => {
    const svgRef = React.useRef(null);
    const holderRef = React.useRef(null);
    const classes = useStyles();
    const [tooltip, setTooltip] = React.useState(null);
    const [highlight, setHighlight] = React.useState(null);
    const {
        width = 900,
        height = 200,
        opacity = .2,
        series,
        graph,
        metric,
        updateContext = null,
        storage = null,
        need_extraction = true,
        link_callback = null,
        do_x_axis = null,
        do_y_axis = null,
        axis_font_size = '.75rem',
    } = props;

    const x_type = graph.xType || graph.x_type || 'ordinal';
    const y_type = graph.yType || graph.y_type || 'measurement';

    const graph_data = get_graph_data(series);
    const series_data = extract_dataset(graph_data, graph.extraction, graph.stacking, graph.binning, graph.sorting);
    let dimensions = null;

    useEffect(() => {
        if (!svgRef.current) {
            console.info(`No svg reference, so cannot render with d3`);
            return;
        }
        let doing_x_axis = do_x_axis;
        let doing_y_axis = do_y_axis;

        let [x_domain, y_domain] = [null, null];
        if (y_type == 'percent') {
            y_domain = [0, dataset_max(series_data, 'y') * 1.05];
        } else {
            y_domain = [dataset_min(series_data, 'y'), dataset_max(series_data, 'y')];
        }
        let do_vertical_lines = true;
        if (x_type == 'ordinal') {
            x_domain = series_data.map(record => record.x_label);
            do_vertical_lines = false;
            if (x_domain.length && (svgRef.current.clientWidth / x_domain.length < 30)) {
                doing_x_axis = false;
            }
        } else {
            x_domain = [dataset_min(series_data, 'x'), dataset_max(series_data, 'x')];
        }

        if (doing_x_axis === null) {
            doing_x_axis = svgRef.current.clientHeight > 150;
        }

        if (doing_y_axis === null) {
            doing_y_axis = svgRef.current.clientWidth > 150;
        }
        dimensions = {
            'width': svgRef.current.clientWidth,
            'height': svgRef.current.clientHeight,
            'margins': {
                left: doing_y_axis ? Math.max(svgRef.current.clientHeight / 6, 30) : svgRef.current.clientHeight / 12,
                right: svgRef.current.clientHeight / 12,
                top: 0,
                bottom: doing_x_axis ? svgRef.current.clientHeight / 10 : svgRef.current.clientHeight / 12,
            },
            'axisMargin': 5,
        };
        if ((!dimensions) || dimensions.width < 1 || dimensions.height < 1) {
            console.info(`SVG element has no current size`);
            return;
        }


        const svg = d3
            .select(svgRef.current)
            .classed("bar-chart", true)
            .attr('view-box', `0 0 ${dimensions.width} ${dimensions.height}`)
            // .attr("width", dimensions.width)
            // .attr("height", dimensions.height)
            ;
        svg.selectAll('*').remove();
        const container_height = dimensions.height - (dimensions.margins.top + dimensions.margins.bottom);
        const container_width = dimensions.width - (dimensions.margins.left + dimensions.margins.right);
        const container = svg
            .append("g")
            .attr("width", container_width)
            .attr("height", container_height)
            .classed("container", true)
            .attr("transform", `translate(${dimensions.margins.left}, ${dimensions.margins.top})`);
        const c_rad = container_height / 2;

        let x_scale = null;
        if (x_type == 'ordinal') {
            x_scale = d3.scaleBand().domain(x_domain).padding(.2).range([0, container_width]);
        } else if (x_type == 'time') {
            x_scale = d3.scaleTime().domain(x_domain.map(x => x * 1000)).range([0, container_width]).nice();
        } else {
            x_scale = d3.scaleLinear().domain(x_domain);
        }
        const x_axis = d3.axisBottom(x_scale);

        let y_scale = d3.scaleLinear().domain(y_domain).range([container_height, 0]).nice();
        const y_axis = d3.axisLeft(y_scale);

        if (doing_x_axis && do_vertical_lines) {
            container.append('g')
                .call(d3.axisTop(x_scale).tickSize(-container_height).tickFormat('')).selectAll(
                    '.tick line'
                ).style('stroke', '#c0c0c0');
        }
        if (doing_y_axis) {
            container.append('g')
                .call(d3.axisLeft(y_scale).tickSize(-container_width).tickFormat('')).selectAll(
                    '.tick line'
                ).style('stroke', '#c0c0c0');
        }
        container.selectAll('.domain').remove();
        container.append('g').classed('primary-graph');
        if (doing_x_axis) {
            svg.append("g")
                .attr("transform", `translate(${dimensions.margins.left},${container_height + dimensions.margins.top + dimensions.axisMargin})`)
                .call(x_axis)
                .selectAll(".tick text")
                .attr("font-size", axis_font_size)
                ;
        }
        if (doing_y_axis) {
            svg.append("g")
                .attr("transform", `translate(${dimensions.margins.left - dimensions.axisMargin},${dimensions.margins.top})`)
                .call(y_axis)
                .selectAll(".tick text")
                .attr("font-size", axis_font_size);
        }

        if (series_data) {
            // console.log(series_data);

            const path = container
                .selectAll('rect.bar')
                .data(series_data)
                .enter()
                .append('rect')
                .attr('class', 'bar')
                .attr('y', record => y_scale(record.y))
                .attr('height', record => y_scale(y_domain[0]) - y_scale(record.y))
                .attr('x', record => x_scale(record.x))
                .attr('width', x_scale.bandwidth())
                .attr('fill', record => record.color)
                // .attr('opacity', (highlight !== null) ? (highlight == set_index ? 1 : .5) : 1)
                .on('mousemove', function (d, i) {
                    // if (set_index != highlight) {
                    //     setHighlight(set_index);
                    // }
                    const date = x_scale.invert(d.offsetX).getTime() / 1000;
                    const record = nearest_record(recordset, date);
                    const record_date = new Date((record.x - (record.x % 60)) * 1000);
                    const new_tooltip = `${date_format(record_date)} => ${record.label} = ${record.y}`;
                    if (new_tooltip != tooltip) {
                        setTooltip(new_tooltip);
                    }

                })
                .on('mouseout', function (d, i) {
                    setHighlight(null);
                    setTooltip(null);
                })
                .on('click', function (d, i) {
                    if (link_callback != null) {
                        const date = x_scale.invert(d.offsetX).getTime() / 1000;
                        const record = nearest_record(recordset, date);
                        // Need datapoint, key, series
                        link_callback({
                            ...record,
                            key: record.click_key,
                            value: record.click_value,
                        }, record.click_key, recordset);
                    }
                })
                ;
        }
    }, [series_data, graph, svgRef.current]
    );

    const legend_click = link_callback ? (index) => {
        const recordset = series_data[index];
        for (let i = 0; i < recordset.length; i++) {
            const record = recordset[index];
            link_callback({
                ...record,
                key: record.click_key,
                value: record.click_value,
            }, record.click_key, recordset);
        }
    } : null;

    return <div className={classes.svgHolder} placement="top" >
        <Tooltip title={tooltip || ""} open={tooltip !== null} followCursor>
            <svg style={{ width: width, height: height }} ref={svgRef} className={classes.d3svg} />
        </Tooltip>
        {/* <Legend style={{ height: height }} legends={legends} highlight={highlight} setHighlight={setHighlight} on_click={legend_click} /> */}
    </div>;
};


export default ATXBarChart;