import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    loader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
});

class MuiLoader extends React.Component {
    static defaultProps = {
        title: 'Loading'
    }
    render() {
        const { title, classes, className = '', ...otherProps } = this.props;
        return <React.Fragment>
            <CircularProgress
                className={classNames(classes.loader, className)}
                {...otherProps}
            /> {title}
        </React.Fragment>;
    }
}

export default withStyles(styles)(MuiLoader);
