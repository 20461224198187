import React from 'react';
import { providing_nearest_form } from 'formprovider';
import { with_resolved_target } from 'dash/resolvetarget';
import { ReactForm } from 'reactform';
import { with_focus } from 'dash/focusprovider';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import { EditIntent } from 'editintent';
import { URISourceTable, mapping_specific_storage } from './urisourcetable';
import FocusLink from 'dash/focuslink';
import { url_for_key } from 'storages';
import { WithToolbar } from 'shogun/shogunpromotebar';
import { ServiceCSVDownloader, ServiceCSVUploader } from 'shogun/servicecsv';

class BaseURIMappingView extends React.Component {
    render() {
        const target = this.props.target;
        if (!target) {
            return null;
        }
        const storage_props = mapping_specific_storage(target);
        storage_props.debug = true;
        const table_actions = [
            <EditIntent
                key="add-map"
                target={{
                    'type': 'NewURISource',
                    'id': null,
                    'urisource-mapping': target.id,
                }}
                context={{
                    'enabled': true,
                    'mapping': target.id,
                }}
                action='Add Program'
                label='Add Program'
                help_text='Add new program to this source-set'
            />,
            (target && target.id) && <ServiceCSVUploader
                target={target}
                key='uploader'
            />,
            (target && target.id) && <ServiceCSVDownloader
                target={target}
                key='downloader'
            />,
        ].filter(x => !!x);
        return <React.Fragment>
            <EditingCard title={`Web Inputs`} actions={table_actions} no_wrapper={true} key={`main-card-${target.id}`}>
                <URISourceTable
                    key='uri-mapping-${mapping}'
                    mapping={target}
                    exclude={['tags', 'notes']}
                    storage_props={storage_props}
                />
            </EditingCard>
            <EditingCard title={`${target.title} Metadata`} key={`metadata-card-${target.id}`}>
                <ReactForm target={target} use_dialog={false} key='uri-mapping-metadata' />
            </EditingCard>
        </React.Fragment>;
    }
}
const URIMappingView = WithToolbar(providing_nearest_form(
    with_resolved_target(
        BaseURIMappingView
    ),
    'shogunconf_forms'
));

export default URIMappingView;
export { BaseURIMappingView, URIMappingView };
