import React from 'react';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import FocusLink from 'dash/focuslink';
import classNames from 'classnames';


const styles = (theme) => ({
    holder: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    chip: {
        margin: theme.spacing(0.25),
        fontSize: theme.spacing(1.5),
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        padding: theme.spacing(1),
        boxShadow: theme.shadows[2],
        minWidth: '25%',
        position: 'relative',
    },
    withUrl: {
        '&:hover': {
            boxShadow: theme.shadows[4],
        },
    },
    name: {
        flex: '1 1 auto',
        textOverflow: 'ellipsis',
    },
    count: {
        flex: '1 0 auto',
        minWidth: theme.spacing(3),
    }

});

const CountList = (props) => {
    /* Used to display a set of name:count values */
    const {value,name_prop,value_prop,url_prop,classes,focus_prop, only_positive} = props;
    return <div className={classes.holder}>
        {value.map((v,i) => {
            const url = url_prop && v[url_prop];
            const show_count = value_prop && ((!only_positive) || v[value_prop] >= 0);
            if (url) {
                var focus = null;
                var Component = Link;
                if (focus_prop) {
                    focus = v[focus_prop];
                    if (focus) {
                        Component = FocusLink;
                    }
                }
                return <Component
                    to={url} 
                    title={`Click to view ${v[name_prop]}`} 
                    className={classNames(classes.chip)} 
                    key={`chip-${i}`}
                    focus_on={focus}
                >
                    <div className={classes.name}>{v[name_prop]}{value_prop && ': '}</div>
                    {show_count && <div className={classes.count}>{v[value_prop]}</div>}
                </Component>;
            } else {
                return <div className={classes.chip} key={`chip-${i}`}>
                    <div className={classes.name}>{v[name_prop]}{': '}</div>
                    {show_count && <div className={classes.count}>{v[value_prop]}</div>}
                </div>;
            }
        })}
    </div>;
};
CountList.propTypes = {
    value: PropTypes.array.isRequired,
    name_prop: PropTypes.string.isRequired,
    value_prop: PropTypes.string,
    url_prop: PropTypes.string,
    only_positive: PropTypes.bool,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CountList);