import React from 'react';
import { providing_nearest_form } from 'formprovider';
import { ReactForm } from 'reactform';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import { EditIntent } from 'editintent';
import { EPGGenTable, mapping_specific_storage } from './epggentable';
import { url_for_key } from 'storages';
import { WithToolbar } from 'shogun/shogunpromotebar';
import { current_user } from 'storeregistry';
import { with_resolve_target, with_target } from 'dash/resolvetarget';


class BaseEPGGenMappingView extends React.Component {
    render() {
        let { target } = this.props;
        const instance = target;
        if (!instance) {
            return 'Loading';
        }
        const storage_props = mapping_specific_storage(instance);
        // storage_props.debug = true;
        const editable = current_user().has_permission('shogunconf.edit_content');

        const table_actions = [
            editable ? <EditIntent
                key="add-program"
                form_key="NewEPGGen"
                target={{
                    'type': 'EPGGen',
                    'id': null,
                }}
                context={{
                    'enabled': true,
                    'mapping': instance.id,
                }}
                action='Add Program'
                label='Add Program'
                help_text='Add new program to this source-set'
            /> : null,
        ].filter(x => x);
        return <React.Fragment>
            <EditingCard
                title={`${instance.type_name}`}
                actions={table_actions}
                no_wrapper={true}
            >
                <EPGGenTable
                    key='epggenmapping-${mapping}'
                    mapping={instance.id}
                    exclude={['name', 'tags', 'notes', 'bitrate', 'ts_id', 'ttl']}
                    storage_props={storage_props}
                    preference_key={`epggen.table`}
                    editable={editable}
                />
            </EditingCard>
            <EditingCard title={`${instance.title} Metadata`} collapsing='epggenmapping-metadata'>
                <ReactForm target={instance} use_dialog={false} />
            </EditingCard>
        </React.Fragment>;
    }
}
const EPGGenMappingView = WithToolbar(providing_nearest_form(
    with_resolve_target(
        with_target(BaseEPGGenMappingView)
    ),
    'shogunconf_forms'
));

export default EPGGenMappingView;
export { BaseEPGGenMappingView, EPGGenMappingView };
