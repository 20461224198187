/* Unicode characters describing particular types */
import React from 'react';
// import { fa_icon } from 'faicon';

import Icon from '@material-ui/core/Icon';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
// import NetworkPort from './img/ServerStack.svg';
import StorageIcon from '@material-ui/icons/StorageOutlined';
import AccountTreeIcon from '@material-ui/icons/AccountTreeOutlined';
import LanguageIcon from '@material-ui/icons/LanguageOutlined';
import UserIcon from '@material-ui/icons/PersonOutlined';
import SBB_icon from './img/SBB_icon.svg';
import MD8_icon from './img/MD8_icon.svg';
import GigaPass_icon from './img/GigaPass_icon.svg';
import UCrypt_icon from './img/UCrypt_icon.svg';

// const NodeIcon = (props) => {
//     /* Network-Port-Icon.svg as an icon */
//     return <SvgIcon component={NetworkPort} viewBox={"-1 -1 7 7"} />;
// };

const icon_for_type = (typ) => {
    if (typ == 'vsbb100') {
        return <SBB_icon viewBox='0 0 288 288' width={24} height={24} size="small" fontSize='small' />
    } else if (typ == 'clifford') {
        return <MD8_icon viewBox='0 0 288 288' width={24} height={24} size="small" fontSize='small' />
    } else if (typ == 'passport') {
        return <GigaPass_icon viewBox='0 0 288 288' width={24} height={24} size="small" fontSize='small' />
    } else if (typ == 'ucrypt') {
        return <UCrypt_icon viewBox='0 0 288 288' width={24} height={24} size="small" fontSize='small' />

    } else if (typ == 'Node') {
        return <StorageIcon size="small" fontSize="small" />;
    } else if (typ == 'Group') {
        return <AccountTreeIcon size="small" fontSize="small" />;
    } else if (typ == 'World') {
        return <LanguageIcon size="small" fontSize="small" />;
    } else if (typ == 'ServiceGroup') {
        return <SettingsIcon size="small" fontSize="small" />;
    } else if (typ == 'User') {
        return <UserIcon size="small" fontSize='small' />;
    }
    return null;
};

export default icon_for_type;