import React from 'react';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AlignDiv from 'reactform/aligndiv';
import theme from 'dash/theme';

const styles = {
    download: {
        textAlign: 'center',
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        }
    }
};

class RenderDownload extends React.Component {
    /* A ClassyWidget that *just* displays a boolean toggle-value */
    static defaultProps = {
        value: null,
        record: null,
        field: null,
        color: 'secondary',
    }
    render_value() {
        const {value,field,record,classes,color} = this.props;
        const name = 'cloud_download';
        const title = "Click to download to your desktop";
        return <AlignDiv 
            alignment='center' 
            className={classNames('download-display',classes.download)}
        >
            {
                value && 
                <a href={value} title={title} className={classNames(classes.link)}>
                    <Icon title={title} color={color} fontSize='small'>{name}</Icon>
                    {' '}
                    Download
                </a>
            }
        </AlignDiv>;
    }
    render() {
        return this.render_value();
    }
}

export default withStyles(styles)(RenderDownload);
