import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Hint,
    RadialChart,
} from 'react-vis';
import COLORS from './colors';
import { Link } from 'react-router-dom';
import { filter } from 'core-js/fn/array';

const styles = (theme) => ({
    holder: {
        display: 'flex',
        flexDirection: 'horizontal',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    item: {
        flexGrow: 1,
        flexShrink: 1,
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: '#70ffff',
        }
    }
});

const ATXBlinkenLights = withStyles(styles)((props) => {
    const {
        classes,
        graph = {},
        series = [], // array of arrays
        dataset = {},
        metric = {},
        format = {},
        skip_undefined = false,
        skip_null = false,
        width = 150,
        height = 150,
        colors = [
            // Chosen to match the plotly js colours
            '#ff0000',
            '#333377',
            '#ff00ff',
            '#00ffff',
            '#7700ff',
            '#007777',
            '#000077',
            '#00ff77',
            '#770077',
            '#773377',
        ],
        color_map = {},
    } = props;
    const items = [];

    const key_set = [];
    if (!series) {
        return null;
    }
    series.map((records) => {
        records && records.map(record => {
            key_set.push(record.value);
        });
    });
    key_set.sort();
    key_set.map((key, index) => {
        if (!color_map[key]) {
            color_map[key] = colors[index % colors.length];
        }
    });
    const filtered_records = [];
    series.map((records, index) => records && records.map(
        (record, r_index) => {
            if (skip_undefined && record.value === undefined) {
                return null;
            }
            if (skip_null && record.value === null) {
                return null;
            }
            filtered_records.push({
                ...record,
                series: index,
                index: r_index,
            });
        })
    );
    // Calculate the best size for the lights...
    const square_count = Math.round(Math.sqrt(filtered_records.length || 1) + 1.0);
    const itemWidth = Math.round(width / square_count);
    const itemHeight = Math.round(height / square_count);

    filtered_records.map((record) => {
        const key = `r${record.series}-${record.index}`;
        const color = color_map[record.value];
        const display_props = {
            color: color,
            minWidth: itemWidth,
            minHeight: itemHeight,
            lineHeight: `${itemHeight}px`,
            fontSize: itemHeight + 2,
        };
        if (record.url) {
            items.push(<Link
                title={record.label || record.value}
                style={display_props}
                className={classes.item}
                to={record.url}
                key={key}
            >●</Link>);
        } else {
            items.push(<span
                style={display_props}
                title={record.label || record.value}
                className={classes.item}
                key={key}
            >●</span>);
        }
    });

    return <div style={{ width: width, height: height }} className={classes.holder} key='blinken'>
        {items}
    </div>;

});


export default ATXBlinkenLights;
