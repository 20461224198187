/* Render a group's dashboard view from a set-of-datasets

datasets = {
    'passport_status': tabular_data,
    'system_status': tabular_data,
}
charts = chartmodel.GRAPHS
tables = {
    'passport_status': <PassportStatus />
}

*/
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import RenderGraphs from 'dash/charts/rendergraphs';
import EditingCard from 'dash/editingcard';

const styles = theme => ({
    dashboardWrapper: {
        borderTopLeftRadius: theme.spacing(1) + 'px',
        borderTopRightRadius: theme.spacing(1) + 'px',
        backgroundColor: theme.palette.primary.shadow,
    },
    metricLinkWrapper: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        display: 'flex',
        borderTopLeftRadius: theme.spacing(1) + 'px',
        borderTopRightRadius: theme.spacing(1) + 'px',
        '&>*': {
            backgroundColor: '#00000030',
            color: 'white',
            marginRight: 3,
            minWidth: 150,
            maxWidth: 250,
            borderTopLeftRadius: theme.spacing(1) + 'px',
            borderTopRightRadius: theme.spacing(1) + 'px',
        },
    },
});

class GroupDashboard extends React.Component {
    static defaultProps = {
        datasets: {},
        metrics: null,
        graphs: [],
        tables: [],
        metric_links: null,
        preference_key: 'group-dash',
        title: 'Metrics',
    };
    graphs() {
        /* Convert graph description into renderable data-sets and components */
        if (this.props.graphs.length) {
            const { classes, ...restProps } = this.props;
            return <EditingCard title={`${this.props.title} Graphs`} key='graphs' collapsing={`${this.props.preference_key}-graphs`}>
                <RenderGraphs
                    {...restProps}
                    preference_key={`${this.props.preference_key}-graphs`}
                />
            </EditingCard>;
        }
    }
    tables() {
        const { tables } = this.props;
        if (tables.length) {
            return <EditingCard no_wrapper={true} title={`${this.props.title} Tables`} key='tables' collapsing={`${this.props.preference_key}-tables`}>
                {
                    tables.map((Component, index) => {
                        if (React.isValidElement(Component)) {
                            return Component;
                        } else {
                            return <Component
                                key={`table-${index}`}
                            />;
                        }
                    })
                }
            </EditingCard>;
        }
    }
    render() {
        const { classes, metric_links } = this.props;
        const { dashboardWrapper, metricLinkWrapper, ...restClasses } = classes;
        const graphs = this.graphs();
        const tables = this.tables();
        return <div className={dashboardWrapper}>
            {metric_links ? <div className={metricLinkWrapper}>{metric_links}</div> : null}
            {graphs}
            <div className={classes.tables}>
                {tables}
            </div>
        </div>;
    }
}

export default withStyles(styles)(GroupDashboard);
