import React from 'react';
import {ClassyWidget} from 'reactform/classywidget';

import MuiButton from 'dash/MuiButton';

class ToggleButtonWidget extends ClassyWidget {
    render() {
        var self = this;
        var field = self.get_field();
        var base_field = field.field;
        var on_state_change = function( new_value ) {
            var current = self.current_value();
            if (new_value !== current) {
                self.handleChange( new_value, true );
            }
        };
        return <MuiButton
            ref={this.controlRef}
            className={ 'toggle-button '+(this.props.className? this.props.className: '')}
            id={ base_field.label_id}
            name={ field.name}
            onClick={function(evt) {
                on_state_change( !self.current_value());
            }}
            type='button'
        >{self.props.children}</MuiButton>;
    }
}

export default ToggleButtonWidget;