import React from 'react';
import EditingCard from 'dash/editingcard';
import { BaseForm, ReactForm } from 'reactform/reactform';
import TuningTableEditor from './tuningtableeditor';
import { with_target } from 'dash/resolvetarget';
import { widget_data } from 'reactform/classywidget';
import ServerLoaded from 'reactform/serverloaded';

class BaseStandAloneTuningTableEditor extends BaseForm {
    onMappingChange = (mapping) => {
        this.fieldChanged('mapping', mapping);
        this.handleSave().then(result => {
            if (!result.error) {
                this.setState({
                    'form_details': result.form,
                });
                return this.handleExternalChange(result.instance);
            } else if (result.form) {
                this.setState({
                    'form_details': result.form,
                });
            }
        }, err => {
            console.error(`Failure saving: ${err}`);
        });
    }
    render() {
        const { target } = this.props;
        const mapping_field = this.get_field('mapping');
        const stations = widget_data(mapping_field, 'stations') || [];
        const errors = widget_data(mapping_field, 'errors') || [];

        return <EditingCard title={(errors && errors.length) ? `Channel Plan: errors, unsaved` : `Channel Plan`} collapsing={`ipgclient-channel-plan`} no_wrapper={true}>
            <TuningTableEditor
                value={this.currentValue('mapping')}
                errors={errors}
                stations={stations}
                onChange={this.onMappingChange}
            />
        </EditingCard>
    }
}
const StandAloneTuningTableEditor = ServerLoaded(BaseStandAloneTuningTableEditor);


const BaseIPGClientView = (props) => {
    /* Edit/render a single IPGClient record */
    const { target } = props;
    return <React.Fragment>
        <EditingCard title={target.title} collapsing={`ipgclient-metadata-edit`}>
            <ReactForm target={target} storage='shogunconf_forms' use_dialog={false} buttons={['save','delete']}/>
        </EditingCard>
        <StandAloneTuningTableEditor
            target={target}
            form_key={'IPGClientMapping'}
            storage={'shogunconf_forms'}
        />
    </React.Fragment>;
};
const IPGClientView = with_target(BaseIPGClientView);

export default IPGClientView;
export { IPGClientView, StandAloneTuningTableEditor};
