/* Shogun-storages for general reference as caches */
import { metric_storage } from './metricstorage';
import TabularStorage from 'storages/tabularstorage';

const create_node_index = (storage) => {
    const node_map = {};
    if (storage[storage.form_key]) {
        storage[storage.form_key].map((record) => {
            if (record.resource_id && record.resource_id.node_id) {
                node_map[record.resource_id.node_id] = record;
            }
        })
    }
    storage.node_map = node_map;
    return storage;
};

const NodeStateStorage = TabularStorage(metric_storage({
    metric: 'node_status',
    query_type: 'last_status',
    create_missing_rows: true,
    period: 15,
    data_filters: {}, // Explicitly do *not* filter...
    query_text: '',
    debug: true,
}));
NodeStateStorage.change.listen(create_node_index);

// if (NodeStateStorage.ensure_keys != create_node_index) {
//     console.error("Expected to have our ensure keys registered");
// }

const getNodeState = (pk) => {
    /* Get the last known state for the given node */
    return NodeStateStorage.node_map && NodeStateStorage.node_map[pk];
};

export default getNodeState;
export { getNodeState, NodeStateStorage };