import React from 'react';
import $ from 'fakequery';
import { withStyles } from '@material-ui/core/styles';

import { ClassyWidget } from 'reactform/classywidget';
import MuiTypography from 'dash/MuiTypography';
import WidgetRender from './custom_widget_render';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import MagnifyingGlassIcon from '@material-ui/icons/ZoomInOutlined';

const styles = theme => ({
    formControl: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerLabel: {
        marginTop: '1em',
    },
    containerNoLabel: {

    },
    flexWrapRoot: {
        flexWrap: 'wrap',
    },
    inlineLink: {
        marginLeft: '.5em',
        marginRight: '.5em',
    },
    deletion: {
        color: '#ffcccc',

    },
    addition: {
        color: 'green',
    },
    other: {
        color: '#ccc',
    }
});

const ChangeDiffView = withStyles(styles)(props => {
    const { initialShowing = false, value, classes } = props;
    const { label = null } = value;
    const [showing, setShowing] = React.useState(initialShowing);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dialog = showing && <Popover
        id='view-differences-popover'
        open={showing}
        anchorEl={anchorEl}
        onClose={evt => setShowing(false)}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
    >
        {value.changes.map((line, index) => {
            if (line.startsWith('-')) {
                return <div className={classes.deletion} key={`line-${index}`}>{line}</div>
            } else if (line.startsWith('+')) {
                return <div className={classes.addition} key={`line-${index}`}>{line}</div>
            } else {
                return <div className={classes.other} key={`line-${index}`}>{line}</div>
            }
        })}
    </Popover>;
    const id = showing ? 'view-differences-popover' : undefined;
    return <div>
        <IconButton aria-describedby={id} variant="outlined" color="secondary" onClick={evt => {
            setAnchorEl(evt.target);
            setShowing(!showing);
        }} title='View differences' size='small'>
            <MagnifyingGlassIcon />
        </IconButton>
        {label}
        {dialog}
    </div>;
});

const RenderChangeSet = withStyles(styles)((props) => {
    let { value = null, classes } = props;
    if ((!value) || !(value.length)) {
        return null;
    }
    if (typeof value == "string") {
        value = JSON.parse(value);
    }
    const items = value.map((struct, structIndex) => {
        const content = struct.map((item, itemIndex) => {
            if (typeof item == "string" || typeof item == "null" || typeof item == "boolean") {
                return `${item}`;
            } else if (item.__type__ && item.__type__ == 'ChangeDiff') {
                return <ChangeDiffView value={item} key={`item-${itemIndex}`} />
            } else {
                if (!item.__url__) {
                    return item.title || 'Unnamed';
                } else {
                    return <Link
                        className={classes.inlineLink}
                        to={item.__url__}
                        key={`item-${itemIndex}`}
                    >{item.title}</Link>;
                }
            }
        })
        return <ListItem classes={{ root: classes.flexWrapRoot }} key={`list-item-${structIndex}`}>{content}</ListItem>;
    });
    if (items.length == 0) {
        items.push(<ListItem key={`list-item-empty`}>No changes are pending</ListItem>);
    }
    return <List>{items}</List>;
});

class PromoteChangeWidget extends ClassyWidget {
    /* A ClassyWidget that *just* displays the current value (no editing) */

    render() {
        const { classes, show_label } = this.props;
        const field = this.get_field();
        const value = this.current_value();
        const plan = this.widget_data('plan')
        return <WidgetRender form_field={field} widget_class="display-widget" show_label={show_label}>
            <RenderChangeSet value={plan} />
        </WidgetRender>;
    }
}

export default withStyles(styles)(PromoteChangeWidget);
