import React from 'react';
import TextField from '@material-ui/core/TextField';

import { fa_icon } from 'faicon';
import { ClassyWidget } from 'reactform/classywidget';
import classNames from 'classnames';


class NumberInputWidget extends ClassyWidget {
    static defaultProps = {
        ...ClassyWidget.defaultProps,
        show_label: null,

    };
    step() {
        const raw = this.get_raw_step();
        if (this.props.humanize) {
            // Stepping happens in the *human* space, not the computer one...
            return this.props.dehumanize(raw);
        }
        return raw;
    }
    get_raw_step = () => {
        var base_field = this.get_field().field;
        return this.props.step || base_field.step || 1.0;
    }

    min_value() {
        var base_field = this.get_field().field;
        return this.props.min || base_field.min;
    }

    max_value() {
        var base_field = this.get_field().field;
        return this.props.max || base_field.max;
    }

    limit_value(value) {
        // TODO: allow for handling entered values that are multiples
        // such that e.g. entering 55.25 in a MHz => 55250000
        // and entering 55250 => 55250000
        var base_field = this.get_field().field;
        if (!base_field.required && (value === "" || value === null || Number.isNaN(value))) {
            return null;
        }
        var maximum = this.max_value();
        if (maximum !== null && value > maximum) {
            value = maximum;
        }
        var minimum = this.min_value();
        if (minimum !== null && value < minimum) {
            value = minimum;
        }
        return value;
    }
    on_typed_change = (value, last_change = false) => {
        /* Value has been changed by the user changing it */
        const base = parseFloat(value);
        if (value === "" || Number.isNaN(base) || !Number.isFinite(base)) {
            this.set_edit_value('');
            var base_field = this.get_field().field;
            if (!base_field.required) {
                this.handleChange(null);
            }
            return "";
        }
        const current = this.dehumanize(base);
        const limited = this.limit_value(current);
        if (limited == current) {
            // this.set_edit_value(undefined);
            return this.handleChange(current);
        } else if (last_change) {
            // this.set_edit_value(undefined);
            return this.handleChange(limited);
        } else {
            if (limited === null) {
                this.set_edit_value('');
            } else {
                return this.set_edit_value(current);
            }
        }
    }
    humanize = (value) => {
        if (this.props.humanize) {
            return this.props.humanize(value);
        }
        return value;
    }
    dehumanize = (value) => {
        if (this.props.dehumanize) {
            return this.props.dehumanize(value);
        }
        return value;
    }

    increment = (delta) => {
        if (delta === undefined) {
            delta = this.step() || 1.0;
        }
        var current = parseFloat(this.render_value());
        if (Number.isNaN(current) || !Number.isFinite(current)) {
            current = 0;
        }
        var result = current + delta;
        return this.limit_value(result);
    }

    decrement = (delta) => {
        return this.increment(-(this.step() || 1.0));
    }

    getRenderValue = () => {
        let render_value = this.render_value();

        if (
            render_value === ''
            || render_value === null
            || render_value === undefined
            || Number.isNaN(render_value)
        ) {
            render_value = '';
        } else {
            render_value = this.humanize(render_value);
        }

        return render_value;
    }

    getClassNames(render_parsed, minimum, maximum) {
        let classNames = 'inline-block number-input-control ' + (this.props.className || '');

        if (minimum !== null && render_parsed <= minimum) {
            classNames += ' minimum';
        } else if (maximum !== null && render_parsed >= maximum) {
            classNames += ' maximum';
        }

        return classNames;
    }

    render() {
        const { show_label, humanize, dehumanize } = this.props;
        const field = this.get_field();
        const base_field = field.field;
        const raw_min = this.min_value();
        const raw_max = this.max_value();
        const raw_step = this.get_raw_step();
        const minimum = (raw_min === undefined || raw_min === null) ?
            null :
            (humanize && humanize(raw_min)) || raw_min;
        const maximum = (raw_max === undefined || raw_max === null) ?
            null :
            (humanize && humanize(raw_max)) || raw_max;
        const step = (raw_step === undefined || raw_step == null) ?
            null :
            (humanize && humanize(raw_step)) || raw_step;

        const render_parsed = this.increment(0.0);
        const render_value = this.getRenderValue();
        const error = `${this.humanize(render_parsed)}` !== `${render_value}` && !(
            (base_field.required === false || field.required === false) && render_value === ''
        );

        const classes = classNames(
            this.getClassNames(render_parsed, minimum, maximum),
            error ? 'error' : 'ok',
        );

        return <div className={classes}>
            <TextField
                label={show_label && field.label}
                name={field.name}
                id={field.label_id}
                value={render_value}
                onChange={event => this.on_typed_change(event.target.value)}
                onBlur={event => {
                    console.log(`Blur on numeric field with value ${event.target.value}`);
                    return this.on_typed_change(event.target.value, true);
                }}
                name={field.name}
                id={base_field.label_id}
                placeholder={field.placeholder}
                disabled={field.disabled}
                classes={{}}
                ref={this.controlRef}
                autoFocus={this.props.autoFocus}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
                inputProps={{
                    min: minimum,
                    max: maximum,
                    type: 'number',
                    step: step,
                }}
            />
        </div>;
    }
}

const humanize_mhz = (mhz) => {
    return mhz / 1000000;
};
const dehumanize_mhz = (mhz) => {
    return mhz * 1000000;
};
const humanize_khz = (khz) => {
    return khz / 1000;
};
const dehumanize_khz = (khz) => {
    return khz * 1000;
};

class MHzWidget extends NumberInputWidget {
    static defaultProps = {
        ...NumberInputWidget.defaultProps,
        dehumanize: dehumanize_mhz,
        humanize: humanize_mhz,
        step: 1000000,
    }
}
class KHzWidget extends NumberInputWidget {
    static defaultProps = {
        ...NumberInputWidget.defaultProps,
        dehumanize: dehumanize_khz,
        humanize: humanize_khz,
        step: 1000,
    }
}

class MbpsWidget extends NumberInputWidget {
    static defaultProps = {
        ...NumberInputWidget.defaultProps,
        dehumanize: dehumanize_mhz,
        humanize: humanize_mhz,
        step: 100000,
    }
}
class KbpsWidget extends NumberInputWidget {
    static defaultProps = {
        ...NumberInputWidget.defaultProps,
        dehumanize: dehumanize_khz,
        humanize: humanize_khz,
        step: 1000,
    }
}


export default NumberInputWidget;
export { NumberInputWidget, MHzWidget, KHzWidget, MbpsWidget, KbpsWidget };
