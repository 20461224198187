import React from 'react';

import { ClassyWidget } from 'reactform/classywidget';
import WidgetRender from './custom_widget_render';
import { withStyles } from '@material-ui/core/styles';
import { BaseUploader } from 'baseuploader';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ErrorList } from 'errorlist';
import MuiTypography from 'dash/MuiTypography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    progressColor: theme.palette.primary.dark,
    fileDetails: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    fileName: {
        flex: 1,
    },
});

class Uploader {
    constructor(props) {
        this.props = props;
        this.uploads = [];
        this.upload_timeout = null;
        this.current_upload = null;
        this.finished_uploads = [];
    }
    upload = (file) => {
        const upload = {
            file: file,
            start: 0,
            end: 0,
            percent: 0,
            percent_buffer: 0,
            finished: false,
            error: false,
            id: null,
        };
        this.uploads.push(upload);
        this.run_uploads();
    }
    run_uploads = () => {
        if (this.current_upload) {
            return;
        }
        if (this.uploads.length) {
            const upload = this.uploads[0];
            this.uploads = this.uploads.slice(1);
            this.run_upload(upload)
        }
    }
    cancel = (upload) => {
        if (upload == null || upload == undefined) {
            return;
        }
        upload.cancelled = true;
        if (upload === this.current_upload) {
            this.current_upload = null;
        }
        if (upload.id) {
            this.finished_uploads = this.finished_uploads.filter(
                up => up.id !== upload.id
            );
        }
        if (upload.percent > 0) {
            const socket = new WebSocket(`${this.props.url}/.control`);
            socket.addEventListener('open', () => {
                socket.send(JSON.stringify({ 'command': 'cancel', 'id': upload.id }))
                socket.addEventListener('message', (msg) => {
                    const payload = JSON.parse(msg.data);
                    if (payload.command == 'cancel' && payload.id == upload.id) {
                        if (payload.success) {
                            upload.percent = 0;
                        }
                        socket.close();
                        if (upload.finished) {
                            this.props.onFinished && this.props.onFinished(null);
                        } else {
                            this.props.onChange && this.props.onChange();
                        }
                    }
                });
            });
            if (this.uploads.length) {
                this.run_uploads()
            }
        } else {
            this.uploads = this.uploads.filter(item => item !== upload);
        }
    }
    run_upload = (upload) => {
        /* Run this upload to completion unless cancelled */
        const { file } = upload;
        if (!file) {
            console.error(`Got a null file in upload ${upload}`);
            return this.run_uploads();
        }
        this.current_upload = upload;
        const socket = new WebSocket(`${this.props.url}/${file.name}`);
        const block_size = 1024 * 512
        let start = 0;
        let stop = false;
        const send_file = (evt) => {
            upload.start = start;
            let end = start + block_size;
            if (end >= file.size) {
                end = file.size;
                stop = true
            }
            if (upload.finished || upload.cancelled) {
                stop = true;
            }
            upload.end = end;
            try {
                socket.send(file.slice(start, end));
            } catch (e) {
                stop = true;
                console.error(`Error during send: ${e}`);
            }
            start = end;
            upload.percent = Math.round(end / file.size * 1000) / 10.;
            upload.percent_buffer = Math.round((end - start) / file.size * 1000) / 10.;
            if (!stop) {
                console.log(`${upload.percent}% sent`)
                this.props.onChange && this.props.onChange(upload);
            } else {
                socket.send(file.slice(end, end));
            }
        }
        const on_response = (evt) => {
            const content = JSON.parse(evt.data);
            if (content.error) {
                this.current_upload.server_status = content;
                this.current_upload.error = true;
                this.finished_uploads.push(this.current_upload)
                this.cancel(this.current_upload);
            } else if (content.done) {
                this.current_upload = null;
                upload.finished = true;
                console.log('Finished upload')
                this.finished_uploads.push(upload);
                socket.close();
                this.props.onFinished && this.props.onFinished(upload);
                this.run_uploads()
            } else {
                this.current_upload.server_status = content;
                this.current_upload.id = content.id;
                send_file();
            }
        }
        socket.addEventListener('open', send_file);
        socket.addEventListener('message', on_response);
    }

}

class StreamingFileUpload extends ClassyWidget {
    mounted = true;
    state = {
    }
    componentDidMount() {
        const properties = {
            ...(
                this.widget_data('uploader_props') || {
                    url: 'ws://localhost:8765/uploads'
                }
            ),
            onChange: this.onUploadChange,
            onFinished: this.onUploadFinished,
        }
        properties
        this.setState({
            'uploader': new Uploader(properties),
        })
    }
    componentWillUnmount() {
        this.mounted = false;


        this.state.uploader && this.state.uploader.cancel();
    }
    handleUploads = (evt) => {
        for (let i = 0; i < evt.target.files.length; i++) {
            this.state.uploader.upload(evt.target.files[i]);
        }
        evt.stopPropagation();
    }
    onUploadChange = () => {
        this.setState({});
    }
    onUploadFinished = (upload) => {

        this.handleChange((upload && upload.id) || null);
    }
    cancelUpload = (upload) => {
        this.state.uploader.cancel(upload);
    }
    render() {
        const { show_label, classes, uploader_props } = this.props;
        const current = this.current_value();
        const field = this.get_field();
        const base_field = field.field;
        const { uploader } = this.state;

        const describe_current_upload = (upload, index) => {
            return <div className="one-upload" key={`upload-${index}`}>
                <div className={classes.fileDetails} key='filename'>
                    <div className={classes.fileName}>{upload.file.name}</div>
                    <IconButton key='cancel' onClick={(evt) => this.cancelUpload(upload)} title="Cancel/delete">
                        <Icon>close</Icon>
                    </IconButton>
                </div>
                <LinearProgress
                    key='progress'
                    variant="buffer"
                    value={upload.percent}
                    color={upload.finished ? "secondary" : "primary"}
                    valueBuffer={upload.percent_buffer}
                />
            </div>
        };
        const current_or_finished = (uploader && (uploader.current_upload || uploader.finished_uploads.length));


        const error = null;

        return <WidgetRender form_field={field} widget_class="select-widget" show_label={show_label} >
            {(!current_or_finished) && <input type="file" className="file-upload" onChange={this.handleUploads} />}
            {
                uploader && uploader.current_upload && describe_current_upload(uploader.current_upload, -1)
            }
            {
                uploader && uploader.finished_uploads.map(describe_current_upload)
            }
            {error && messages && <ErrorList errors={messages} />}
        </WidgetRender>;
    }

}
export default withStyles(styles)(StreamingFileUpload);
