import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames'

const styles = theme => ({
    codeBlock: {
        padding: theme.spacing(2),
        background: theme.palette.grey[100],
        fontSize: theme.typography.fontSize
    }
});

class MuiCodeBlock extends React.Component {
    render() {
        const { children, classes, className = '' } = this.props;

        return (
            <pre className={classNames(classes.codeBlock, className)}>
                {children}
            </pre>
        );
    }
}

export default withStyles(styles)(MuiCodeBlock);
