import React from 'react';
import {fa_icon} from './faicon';

class Light extends React.Component {
    static defaultProps = {
        'title': null,
        'error': false,
        'ok': false,
        'warning': false,
        'icon': null,
        'ok_icon': 'check-circle',
        'warning_icon': 'circle',
        'error_icon': 'times-circle',
        'base_icon': 'circle'
    };
    render() {
        var className = 'light ';
        var self = this;
        var icon = self.props.base_icon;
        if (self.props.error) {
            className += 'error';
            icon = self.props.error_icon;
        } else if (self.props.warning) {
            className += 'warning';
            icon = self.props.warning_icon;
        } else if (self.props.ok) {
            className += 'ok';
            icon = self.props.ok_icon;
        }
        if (self.props.icon) {
            icon = self.props.icon;
        }
        return <span className={className} title={self.props.title}>
            {fa_icon(icon)}
        </span>;
    }
}
var LightFactory = React.createFactory( Light );

export {LightFactory,Light};
