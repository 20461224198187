/* Render graphs as components */
import React from 'react';
import { graph_format, graph_metric, graph_key } from './chartmodel';
import { extract_data } from './extractdataset';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { PreferenceButton } from 'dash/preferencemenu';
import useGUIState from 'dash/guistate';
import ZoomableDialog from 'dash/zoomabledialog';

const styles = (theme) => ({
    graphs: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        position: 'relative',
    },
    customizeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
    menuItemRadio: {
        color: theme.palette.grey[400],
        marginRight: '.25em',
    },
});

const PreferenceMenu = withStyles(styles)((props) => {
    /* Construct a menu for the grpaph options */
    const {
        initialState,
        setState,
        graphs,
        metrics,
        classes,
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const showing = (key) => {
        return initialState.hiddenGraphs.indexOf(key) < 0;
    };
    const toggleGraph = (key) => {
        if (showing(key)) {
            setState({
                ...initialState,
                hiddenGraphs: [
                    ...(initialState.hiddenGraphs || []),
                    key,
                ],
            });
        } else {
            setState({
                ...initialState,
                hiddenGraphs: (initialState.hiddenGraphs || []).filter(
                    x => x !== key
                ),
            });
        }
        handleClose();
    };
    const menu_items = [];
    graphs.map((graph, index) => {
        const key = graph_key(graph);
        const metric = graph_metric(graph, metrics);
        const format = graph_format(graph);
        const current = showing(key);
        if (!metric || !format) {
            return;
        }
        menu_items.push(
            <MenuItem
                onClick={(evt) => toggleGraph(key)}
                key={key}
            >{current ? <RadioButtonChecked size='small' className={
                classes.menuItemRadio
            } /> : <RadioButtonUnchecked size='small' className={
                classes.menuItemRadio
            } />}
                {metric.title}
                :
                {format.title}
            </MenuItem>
        );
    });
    return <div className={classNames(classes.customizeButton)}>
        <PreferenceButton
            onClick={handleClick}
            title={'Customise graphs'}
        />
        <Menu
            id="preferences"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >{menu_items}</Menu>
    </div>;
});


const RenderGraphs = withStyles(styles)(useGUIState((props) => {
    /* Convert graph description into renderable data-sets and components */
    const { classes, graphs, metrics, datasets, useGUIState, preference_key } = props;
    const [initialState, setState] = React.useState({});
    let initialGUIState, updateGUIState;
    if (useGUIState) {
        [initialGUIState, updateGUIState] = useGUIState({
            'hiddenGraphs': props.defaultHiddenGraphs || [], // by key
        });
    }
    if (initialGUIState === null || initialGUIState === undefined) {
        initialGUIState = {
            'hiddenGraphs': [],
        };
    }

    const rendered = graphs.map((graph, index) => {
        const key = graph_key(graph);
        if (
            initialGUIState.hiddenGraphs
            && (
                initialGUIState.hiddenGraphs.indexOf(key) > -1
            )
        ) {
            console.log(`Graph ${key} is hidden`);
            return null;
        }
        const format = graph_format(graph);
        if (!format) {
            console.error(`No format ${graph.format} is available`);
            return null;
        }
        const metric = graph_metric(graph, metrics);
        if (!metric) {
            console.error(`No metric ${graph.metric} is available`);
            return null;
        }
        const series = extract_data(graph, datasets, metric, format);
        if (!series || !series.length) {
            console.info(`No data-series extracted for metric ${metric.__key__}`);
            return null;
        }
        const Component = format.implementation;
        if (!Component) {
            return <div className="warning">No implementation on format {JSON.stringify(format)}</div>;
        }
        const ZoomGraph = React.forwardRef((props, ref) => {
            return <Component
                ref={ref}
                series={series}
                graph={graph}
                format={format}
                metric={metric}
                key={key}
                width={1024}
                height={768}
            />;
        });
        return <ZoomableDialog key={`zd-${key}`} small={<Component
            series={series}
            graph={graph}
            format={format}
            metric={metric}
            key={key}
        />} large={ZoomGraph} />;
    });
    // console.info(`${graphs.length} graph specs`);
    return <Paper
        className={classNames(classes.graphs, 'render-graphs')}
        elevation={0}
    >
        {rendered.filter((v) => !!v)}
        {
            preference_key && <PreferenceMenu
                setState={(new_state) => {
                    const result = updateGUIState(new_state);
                    setState({});
                    return result;
                }}
                initialState={initialGUIState}
                graphs={graphs.filter(x => !!x)}
                metrics={metrics.filter(x => !!x)}
            />
        }
    </Paper>;
}));

export default RenderGraphs;
