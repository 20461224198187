/* Network configuration view */
import React from 'react';
import { current_user, stores } from 'storeregistry';

import InterfaceTable from '../InterfaceTableComponent';
import NetconfigPromoteButton from './NetconfigPromoteButton';
// import IPFilter from 'IPFilter';
import SslConfig from '../SslConfig';
import { EditIntentFactory } from 'editintent';
import { LoadingDisplay } from 'reactloading';
import MuiAppBar from 'dash/MuiAppBar';
import AppBarSpacer from 'dash/AppBarSpacer';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import setup_storages from '../netconfigsetupstores';
import { providing_nearest_form } from 'formprovider';
import WithCurrentUser from 'withcurrentuser';

import InterfaceStatusTable from './InterfaceStatusTable';
import SFPStatusTable from './sfpstatustable.js';

const styles = theme => ({
    buttons: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
    },
    IPv4: {
        color: 'green',
    },
    IPv6: {
        color: 'teal',
    },
    MAC: {
        color: 'light-grey',
    },
});

const render_address = (address) => {
    var formatted = '';
    if (address.type == 'IPv4') {
        formatted = `${address.addr}/${address.netmask}`;
    } else if (address.type == 'IPv6') {
        formatted = `${address.addr}/${address.prefix}`;
    } else if (address.type == 'MAC') {
        formatted = `${address.addr} (MAC)`;
    } else {
        return null;
    }
    return <span className={address.type}>{formatted}</span>;
};



var InterfaceView = class extends React.Component {
    static defaultProps = {
        storage: null,
        system_storage: null,
        interfaces: null
    };

    componentDidMount() {
        this.mounted = true;
        this.getStorage().change.listen(this.onStorageChange);
        this.getSystemStorage().change.listen(this.onStorageChange);
    }

    componentWillUnmount() {
        this.mounted = false;
        this.getStorage().change.ignore(this.onStorageChange);
        this.getSystemStorage().change.ignore(this.onStorageChange);
    }

    onStorageChange = () => {
        if (this.mounted) {
            this.setState({});
        }
    }

    getStorage() {
        const current = this.props.storage || stores.net_interfaces;
        if (current) {
            return current;
        } else {
            setup_storages();
            return stores.net_interfaces;
        }
    }
    getSystemStorage() {
        return this.props.system_storage || stores.system_status;
    }

    render() {
        var { classes, user } = this.props;
        var view;
        var store = this.getStorage();
        if (store.interfaces) {
            var editable = current_user().has_permission(
                'config.change_network'
            );
            var interface_records = store.interface_records();
            const sfp = store.current && store.current.sfp;

            view = (
                <div className='toolbar-view-content'>
                    {
                        interface_records &&
                        <EditingCard
                            title='Interface Configuration'
                            no_wrapper={true}
                            collapsing='netconfig-interfaces'
                            default_expanded={true}
                        >
                            <InterfaceTable
                                interfaces={interface_records}
                                key="iface-table"
                                use_ipv6={store.use_ipv6}
                            />
                        </EditingCard>
                    }
                    <EditingCard
                        title='Interface Status'
                        no_wrapper={true}
                        collapsing='netconfig-status'
                        default_expanded={true}
                    >
                        <InterfaceStatusTable
                            records={interface_records}
                        />
                    </EditingCard>
                    {
                        sfp && <EditingCard
                            title='SFP Status'
                            no_wrapper={true}
                            collapsing='sfp-status'
                            default_expanded={true}
                        >
                            <SFPStatusTable
                                sfp={sfp}
                            />
                        </EditingCard>
                    }
                    <AppBarSpacer key='app-bar-spacer' />
                    <MuiAppBar key="app-bar" inPage={true} position="fixed" bottom={true} className={classes.appBar, classes.buttons}>

                        {store.ipfilters && (user && user.has_permission('ipfilter.can_configure_ipfilter')) &&
                            EditIntentFactory({
                                key: 'footer',
                                target: store.ipfilters,
                                label: 'Address Filters'
                            })}
                        {
                            editable && (user && user.has_permission('netconfig.edit')) && <SslConfig ssl={store.ssl_config} />
                        }

                        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                            {editable && (user && user.has_permission('netconfig.edit')) && (
                                <NetconfigPromoteButton key="promote-button" store={store} />
                            )}
                        </div>
                    </MuiAppBar>
                </div>
            );
        } else {
            view = <div className="loading">Loading interface data</div>;
        }
        return <LoadingDisplay
            signal={store.loading}
        >{view}</LoadingDisplay>;
    }
};

export default providing_nearest_form(withStyles(styles)(WithCurrentUser(InterfaceView)), 'net_forms');
