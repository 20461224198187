/* Preference menu trigger button */
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';

const PreferenceButton = (props) => {
    /* Render a preference-button for consisten representation */
    const {onClick,title,size="small",...childProps} = props;
    return <IconButton
        onClick={onClick}
        aria-label={title}
        title={title}
        size={size}
        {...props}
    >
        <MoreVert></MoreVert>
    </IconButton>;
};

export default PreferenceButton;
export {PreferenceButton};
