import React from 'react';
import {WithTabularStorage} from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import ReactTable from 'react-table';
import {ReactFormTable} from 'reactform';
import {ReactForm} from 'reactform';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {with_focus} from 'dash/focusprovider';
import {EditIntent} from 'editintent';
import CountList from 'dash/countlist';

class BaseQ2AEditTable extends React.Component {
    render() {
        const {form_details,service_group,service_type,storage} = this.props;
        if (!(form_details)) {
            return <MuiLoader />;
        }
        const slot_map = {};
        ['AnalogOutput','AnalogDecode'].map(key => {
            const records = storage[key];
            if (records) {
                records.map( record => {
                    if (! record.slots) {
                        console.error(`Record ${JSON.stringify(record)} has no slots?`);
                    }
                    record.slots.map( slot => {
                        slot_map[slot.id] = record;
                    });
                });
            } else {
                console.error(`No ${key} records in the storage`);
            }
        });
        return <ReactFormTable 
            wrap_data={(record,table) => {
                /* wrap a service record into a q2a-specific record */
                // TODO: make this efficient...
                const raw = ['config/analogdecode','config/analogoutput'].map(
                    slot_name => {
                        const slot = record.slots.find( slot => slot.slot_name == slot_name );
                        if (slot) {
                            return slot_map[slot.id];
                        } else {
                            return null;
                        }
                    }
                );

                return raw;
                // const rf_modulation = record.slots.find( 'config/modulation');
                // const rf_input = record.slots.find( slot => slot.slot_name == 'daemon/tuner');
                // const  = record.slots.find( slot => slot.slot_name == 'daemon/tuner');
                // const result = [
                //     RFModulation_map[rf_modulation.id],
                //     RFSource_map[rf_input.id],
                // ];
                // if (!result[1]) {
                //     console.log(`Did not find source for slot ${rf_input.id}`);
                // }
                // return result;
            }}
            // link_function={this.on_view_group.bind(this)}
            form_keys={this.props.editing_forms}
            form_titles={['Decode','Output']}
            form_details={form_details}
            editable={true}
            title_only_forms={{
                // 'RFSource': true,
                //'AnalogDecode': true,
                // 'AnalogOutput': true,
            }}
            overall_target={{
                id: service_group,
                __type__: 'ServiceGroup',
                __pk__: service_group,
            }}
            exclude={['notes','tags','default','annex_type','modulation_type','spectrum_type','symbol_rate']}
            {...this.props}
        />;
    }
}

const service_group_specific_storage = (group,type_key) => {
    /* Get a group-specific storage for given group *or* nodes/non-nodes */
    const context = (group !== undefined && group !== null)? {
        service_groups__in:[group],
        service_type__key:type_key,
    }: {
        service_type__key:type_key,
    };
    return {
        default_context: context,
        forms_key:'shogunconf_forms',
        form_key:'ShogunQ2AServices',
        quick_settings: {
        },
    };
};


const Q2AEditTable = (props) => {
    return <WithTabularStorage 
        key={`q2a-edits-for-group-${props.storage_props.default_context.service_group||'all'}`}
        storage_props={props.storage_props} 
        Component={with_focus(BaseQ2AEditTable)} 
        editing_forms={['AnalogDecode','AnalogOutput']}
        service_group={props.storage_props.default_context.service_group}
        service_type={props.storage_props.default_context.service_type}
        {...props} 
    />;
};
export default Q2AEditTable;
export {service_group_specific_storage, BaseQ2AEditTable, Q2AEditTable};
