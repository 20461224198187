import React from 'react';
import TextField from '@material-ui/core/TextField';

import { ClassyWidget } from 'reactform/classywidget';

class EmailsFromJsonWidget extends ClassyWidget {
    static defaultProps = {
        ...ClassyWidget.defaultProps,
        from_server: (base) => {
            if (typeof base == 'string') {
                if (!base.length) {
                    return '';
                } else if (base.length && base[0] == '[') {
                    return JSON.parse(base);
                } else {
                    console.log(`Unexpected json defenition: ${base}`);
                    return '';
                }
            } else if (base === null || base === undefined) {
                return '';
            } else {
                return base;
            }
        },
        to_server: (base) => {
            if (typeof base == 'string') {
                let values = base.split(',')
                return JSON.stringify(values.map((val,index)=>values[index]=val.trim()).filter(val=>val.length>0));
            } else {
                return base;
            }
        },
    }
    render() {
        const { show_label, type = "text" } = this.props;
        const field = this.get_field();
        const base_field = field.field;

        return (
            <TextField
                type={type}
                multiline
                fullWidth
                label={show_label && field.label}
                value={this.render_value() || ''}
                id={base_field.label_id}
                name={field.name}
                onChange={event => this.handleChange(event.target.value)}
                onBlur={event => {
                    const result = this.handleChange(event.target.value);
                    if (this.props.onBlur) {
                        this.props.onBlur(this);
                    }
                    return result;
                }}
                className={this.props.className || ''}
                classes={{}}
                ref={this.controlRef}
                autoFocus={this.props.autoFocus}
                variant="outlined"
                InputLabelProps={{
                    shrink: true
                }}
                inputProps={{
                    style: {
                        height: '8rem',
                        overflow: 'auto',
                    }
                }}
            />
        );
    }
}

export default EmailsFromJsonWidget;
