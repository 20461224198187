import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ScrollContainer from 'dash/ScrollContainer';
import SectionTitle from 'dash/SectionTitle';

class ExternalLicenses extends React.Component {
    render() {
        const { config } = this.props;
        const { licenses } = config;

        return (
            <div>
                <SectionTitle
                    title="External Package Licenses"
                    description="This product uses various Open Source libraries which are provided each under their own license."
                />
                <ScrollContainer>
                    <List dense={true}>
                        {licenses.pkg_infos.map((pkg, i) => (
                            <ListItem
                                button
                                component="a"
                                href={pkg.Home_page}
                                key={i}
                            >
                                <ListItemText>
                                    {pkg.Name} © {pkg.Author}
                                    {pkg.License && (
                                        <span>({pkg.License})</span>
                                    )}
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </ScrollContainer>
            </div>
        );
    }
}

ExternalLicenses.propTypes = {
    config: PropTypes.object.isRequired,
};

export default ExternalLicenses;
