import PropTypes from 'prop-types';

const AccessorType = PropTypes.oneOfType([PropTypes.string, PropTypes.func]);
const OneOrManyAccessors = PropTypes.oneOfType([AccessorType, PropTypes.arrayOf(AccessorType)]);
const LabelColors_type = PropTypes.objectOf(PropTypes.string);

const Graph_Type = PropTypes.shape({
    key: PropTypes.string, // can be derived from metric and format...
    metric: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    color_map: LabelColors_type,
    group_by: OneOrManyAccessors,
    group_label: OneOrManyAccessors,
    order_by: OneOrManyAccessors,
});

export {
    LabelColors_type,
    Graph_Type,
};