import React from 'react';
import { ReactForm } from 'reactform';
import { using_nearest_form } from 'formprovider';

import { withStyles } from '@material-ui/core/styles';
import SystemPowerActions from './SystemPowerActions';
import { current_user } from 'storeregistry';

const styles = theme => ({});

class PowerForm extends React.Component {
    static defaultProps = {
        target: null
    };

    render() {
        return (
            <div>
                <ReactForm
                    target={this.props.target}
                    form_key={'PeriodicRebootForm'}
                    use_dialog={false}
                />
                {
                    current_user().has_permission('config.shutdown') &&
                    <SystemPowerActions {...this.props} />
                }
            </div>
        );
    }
}

export default withStyles(styles)(using_nearest_form(PowerForm));
