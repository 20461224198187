import React from 'react';
import { basic_focus_title } from './helpers';

import EPGView from 'epgconfig/epgview';
import EPGEdit from 'epgconfig/epgedit';

const epgs_routes = ({ PREFIX }) => ([
    {
        'path': `${PREFIX}epgs/`,
        'link_url': `${PREFIX}epgs/`,
        'hideInNav': true,
        'header': 'Guides',
        'exact': true,
        'title': 'Electronic Program Guides',
        'component': EPGView,
        'renderer': (props) => {
            return <EPGView __type__='EPG' />;
        },
    },
    {
        'path': `${PREFIX}epgs/:epg(\\d+)/`,
        'link_url': `${PREFIX}epgs/`,
        'hideInNav': true,
        'header': 'EPG',
        'title': basic_focus_title(
            (params) => `EPG #${params.epg}`,
            (target, params) => `${target.name || 'EPG #' + target.__pk__}`
        ),
        'component': EPGEdit,
        'renderer': (props) => {
            const epg = parseInt(props.match.params.epg);
            return <EPGEdit __type__='EPG' __pk__={epg} />;
        }
    },
]);

export default epgs_routes;