import { log_render_errors } from 'debugfail';
import $ from 'fakequery';
import React from 'react';


class MessageList extends React.Component {
    static displayName = 'MessageList';

    render() {
        var self = this;
        let messages = this.props.messages;
        if (typeof messages == 'string') {
            messages = [messages];
        }
        return (
            <ul
                className={'message-list compact-list no-bullets ' + (self.props.className || '')}>
                {messages.map((msg, i) => {
                    return (
                        <li className="message" key={i}>
                            {'' + msg}
                        </li>
                    );
                })}
            </ul>
        );
    }
}


class ErrorList extends React.Component {
    static displayName = 'ErrorList';

    render() {
        return <MessageList
            className='error-list'
            messages={this.props.errors}
        />;
    }
}

var ErrorListFactory = React.createFactory(ErrorList);

export {
    ErrorList,
    ErrorListFactory,
    MessageList,
};
