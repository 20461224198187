import React from 'react';
import PropTypes from 'prop-types';
import WidgetRender from './custom_widget_render';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    'suggestions': {
        zIndex: theme.zIndex.modal,
        maxHeight: theme.spacing(40),
        overflowY: 'scroll',
        backgroundColor: 'white',
        position: 'absolute',
    },
    'suggestionHolder': {
        position: 'relative',
    },
    'inputComponent': {
        width: '100%',
    }
});

const RenderComboBox = (props) => {
    const {
        classes,
        field,
        show_label,
        value,
        inputRef,
        selectedRef,
        inputClass,
        onChange,
        onKeyUp,
        onClick,
        onSelect,
        current_label,
        placeholder = null,
        inputType = 'text',
        autoFocus = false,
    } = props;
    const suggestions = props.suggestions && props.suggestions.length && props.inputRef.current && (
        <MenuList aria-haspopup="true" className={classes.suggestions} classes={{}}>
            {props.suggestions.map((suggestion, i) => {
                const selected = `${value}` == `${suggestion[0]}`;
                const extra = {};
                if (selected) {
                    extra.ref = selectedRef;
                }
                return <MenuItem
                    selected={selected}
                    dense={true}
                    key={`suggestion-${i}`}
                    onClick={(evt) => onSelect(suggestion, i)}
                    classes={{}}
                ><span {...extra}>{suggestion[1]}</span></MenuItem>;
            })}
        </MenuList>
    );
    var final_field = field;
    if (current_label) {
        final_field = {
            ...field,
            field: {
                ...field.field,
                label: `${field.field.label}: ${current_label}`,
            }
        };
    }

    return <WidgetRender form_field={final_field} widget_class="combo-box" show_label={show_label}>
        <input
            value={value}
            ref={inputRef}
            className={classNames(classes.inputComponent, inputClass)}
            key="input"
            type={inputType}
            autoFocus={autoFocus}
            placeholder={placeholder || (inputType === 'search' ? '\uD83D\uDD0D' : null)}
            onChange={onChange}
            onKeyUp={onKeyUp}
            onClick={onClick}
        />
        {suggestions && <div className={classes.suggestionHolder}>{suggestions}</div>}
    </WidgetRender>;
};
RenderComboBox.propTypes = {
    /* Text input callback */
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyUp: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,

    /* Menu selection callbacks */
    onSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,

    /* Suggestion/navigation state */
    highlighted: PropTypes.array,
    suggestions: PropTypes.arrayOf(PropTypes.array),
    field: PropTypes.object.isRequired,
    show_label: PropTypes.bool.isRequired,

    /* references to allow for getting/setting the value */
    inputRef: PropTypes.object.isRequired,
    inputClass: PropTypes.string,
};

export default withStyles(styles)(RenderComboBox);

