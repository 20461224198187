/* Component to render children into vertical scrolling window */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const DOM_DELTA_PIXEL = 0x00;
const DOM_DELTA_LINE = 0x01;
const DOM_DELTA_PAGE = 0x02;

const useStyles = makeStyles(theme => ({
    virtualViewport: {
        display: 'flex',
        flexDirection: 'row',

    },
    virtualSizer: {
    },
    virtualList: {
        flex: '1',
    },

    scrollWrapper: {
        minHeight: '10rem',
        height: '100%',
        overflowY: 'auto',

    },
    scrollScroller: {
        width: '1px',
    }


}));
const DetachedScroll = (props) => {
    /* Scroll that has a detached operation */
    const { startScroll = 0, total = 0, perPage = 10, onScroll, scrollerRef = null } = props;
    const classes = useStyles();
    const scrollHandler = React.useCallback((evt) => {
        const fraction = evt.target.scrollTop / (evt.target.scrollHeight - evt.target.clientHeight);
        onScroll(fraction);
    }, [onScroll]);
    const totalHeight = `${total / perPage * 100}%`;
    return <div className={classes.scrollWrapper} onScroll={scrollHandler} ref={scrollerRef}>
        <div className={classes.scroller} style={{ height: totalHeight }} >
            &nbsp;
        </div>
    </div>;
}
const VirtualList = (props) => {
    /* Render a virtual list with a viewport */
    const classes = useStyles();
    const rootRef = React.useRef();
    const listRef = React.useRef();
    const sizerRef = React.useRef();
    const scrollerRef = React.useRef();
    const [vHeight, setVHeight] = React.useState(null);
    const [scrollFraction, setScrollFraction] = React.useState(0);
    const { rootClass, wrapperClass, sizerClass, totalCount, itemContent, initialCount = 10 } = props;

    let rendered_children = [];
    let rendered_count = 0;
    let rootStyle = {};
    let virtualStyle = {};
    let sizerStyle = {};

    const render_items = (start, stop) => {
        start = Math.max(start, 0);
        stop = Math.min(stop, totalCount);
        for (let i = start; i < stop; i++) {
            const rendered = itemContent(i);
            if (rendered) {
                rendered_children.push(rendered);
                rendered_count += 1;
            }
        }
    }

    if (rootRef.current && listRef.current) {

        /* calculate the offset based on scroll fraction */
        let start = 0;
        let stop = totalCount;
        if (totalCount > initialCount) {
            const countDelta = (totalCount + 1) - initialCount;
            const countFraction = scrollFraction * countDelta;
            start = Math.floor(countFraction);
            stop = start + initialCount + 1; // 1 to allow for partial scroll overlap
        }
        rootStyle.height = vHeight * Math.min(initialCount, totalCount + 1);
        rootStyle.overflowY = 'hidden';
        rootStyle.overflowX = 'hidden';

        /* so now we know how much of the scrolling has occurred, so we want to calculate the offsets */
        render_items(start, stop);
    } else {
        render_items(0, initialCount);
    }
    React.useEffect(() => {
        if (vHeight === null) {
            const calculated = rootRef.current.clientHeight / rendered_count;
            if (calculated && calculated != vHeight) {
                setVHeight(calculated);
                console.log(`Calculated vHeight: ${calculated}`)
            } else {
                console.log(`0 vHeight`);
            }
        }
    }, [initialCount, totalCount, itemContent]);

    const onScrollFraction = React.useCallback((fraction) => {
        console.log(`Scroll to fraction ${fraction}`);
        setScrollFraction(fraction);
    }, []);
    const onScrollWheel = React.useCallback((evt) => {
        // evt.preventDefault();
        if (scrollerRef.current) {
            let current = scrollerRef.current.scrollTop;
            if (evt.deltaMode == DOM_DELTA_PIXEL) {
                current += evt.deltaY;
            } else if (evt.deltaMode == DOM_DELTA_LINE) {
                current += (evt.deltaY * vHeight);
            } else if (evt.deltaMode == DOM_DELTA_PAGE) {
                current += (evt.deltaY * vHeight * initialCount)
            }
            current = Math.min(scrollerRef.current.scrollHeight, Math.max(current, 0));
            console.log(`Setting new scroll top ${scrollerRef.current.scrollTop} => ${current}`);
            scrollerRef.current.scroll(0, current);
        }
    }, [scrollerRef]);


    return <div
        className={classNames(classes.virtualViewport, rootClass)}
        style={rootStyle}
        ref={rootRef}
        key='virtual-list-root'
        onWheel={onScrollWheel}
    >
        <div className={classNames(classes.virtualSizer, sizerClass)} ref={sizerRef} style={sizerStyle} key='virtual-list-sizer' >
            <div className={classNames(classes.virtualList, wrapperClass)} ref={listRef} style={virtualStyle} key='virtual-list-render' >
                {rendered_children}
            </div>
        </div>
        <DetachedScroll total={totalCount} perPage={initialCount} onScroll={onScrollFraction} scrollerRef={scrollerRef} />
    </div>;
}

export default VirtualList;