import React from 'react';
import { FormStorage } from 'storages/basestorage';
import { DEFAULT_LOCALSTORAGE } from 'storages/localstoragebacking';
import { Signal } from 'signals';
import with_current_user from 'withcurrentuser';
import { requiring_system_store } from 'systemstorage';

class Preferences {
    /* localStorage backed GUI settings support

    Use for GUI-level (not app level) customisations where
    each client machine will keep the state of the app
    rather than things that are related to the server's
    model of the application.
    */
    constructor(props = null) {
        Object.assign(this, props || {});
        this.change = Signal({});
        this.storage = props.storage || DEFAULT_LOCALSTORAGE;
    }
    get_key = (key) => {
        return JSON.parse(window.localStorage.getItem(key) || 'null');
    }
    set_key = (key, value) => {
        console.log(`Update key: ${key} => ${JSON.stringify(value)}`);
        const result = window.localStorage.setItem(key, JSON.stringify(value));
        this.change.send({ key, value });
        return result;
    }
}

const DEFAULT_PREFERENCES = new Preferences({});

class WithPreferences extends React.Component {
    static defaultProps = {
        'Component': null,
        'preferences': DEFAULT_PREFERENCES,
        'preference_keys': null,
    }
    valid = true;
    componentDidMount() {
        this.props.preferences.change.listen(this.update);
    }
    componentWillUnmount() {
        this.value = false;
        this.props.preferences.change.ignore(this.update);
    }
    update = ({ key, value }) => {
        if (this.valid) {
            this.setState({});
        }
    }
    render() {
        const { Component, preferences, ...childProps } = this.props;
        return <Component
            {...childProps}
            preferences={this.props.preferences}
        />;
    }
}

const DBPrefContext = React.createContext();
const DB_STORAGE = FormStorage({ key: 'system_forms' });

const DBPrefProvider = requiring_system_store(with_current_user((props) => {
    /* Provides the user's DB preferences IFF the user is loaded (signed in) */
    const { storage = DB_STORAGE, user = null } = props;
    const [target, setTarget] = React.useState({ preferences: {} });
    const [handle, setHandle] = React.useState(null);

    const updater = (update) => {
        const do_save = () => {
            console.log(`Sending update to the server`);
            return DB_STORAGE.save_form('UserPref', update.id, update).then(result => {
                if (!result.success) {
                    // TODO: how do we want to handle failures here? retry n times?
                    console.log(`Failure saving preferences: ${JSON.stringify(result)}`);
                } else {
                    console.log(`Updated preferences to ${JSON.stringify(result.instance.preferences)}`);
                    result.instance.save = updater;
                    setTarget(result.instance);
                }
            });
        };
        return do_save();
    };

    React.useEffect(() => {
        let handle = null;
        const load_form = () => {
            handle = null;
            console.log(`Loading preferences for user ${user.username}`);
            DB_STORAGE.get_form('UserPref', '__default__').then((result) => {
                if (!result.success) {
                    handle = window.setTimeout(load_form, 2000);
                    return;
                }
                result.instance.save = updater;
                setTarget(result.instance);
                console.log(`Successfully loaded preferences for ${result.instance.username}`);
                setHandle(null);
            });
        };
        if (user && user.username) {
            load_form();
        }
        return () => {
            if (handle) {
                window.clearTimeout(handle);
            }
        };
    }, [user]);
    return <DBPrefContext.Provider value={target}>{props.children}</DBPrefContext.Provider>;
}));


export default WithPreferences;
export { WithPreferences, Preferences, DEFAULT_PREFERENCES, DBPrefContext, DBPrefProvider };
