import $ from 'fakequery';
import 'whatwg-fetch';
import { Signal } from "signals";
import Periodic from "periodic";
import { POST_SIGNAL } from "storages/ajaxobservables";
import { Cookies } from "react-cookie";
import { human_bytes } from 'humanize';
import jsSHA from 'jssha';

const cookies = new Cookies();

const csrf_token = () => {
    var token = cookies.get('csrftoken');
    return token;
};
const checked_json_response = (result) => result.text().then(text => {
    try {
        return JSON.parse(text);
    } catch (e) {
        return {
            'error': true,
            'message': [
                'Server returned a non-JSON response',
                text,
            ]
        };
    }
});

class BaseUploader {
    /* Upload implementation that relies only on watwg-fetch

    This is going to be shared by the react and non-react implementations,
    so it mostly does callbacks to update external GUIs
    */
    constructor(props) {
        var final_props = {};
        $.extend(final_props, {
            file: null,
            target_ts: 1.0, /* target of how long a chunk should take to upload */
            min_chunk_size: 1024 * 128, /* Need a minimum to avoid having huge overhead on very slow uploads */
            max_chunk_size: 1024 * 512, /* Just to keep things sane */
            chunk_size: 1024 * 256, /* start at moderate but hopeful size */
            onFinished: null, /* called when we finish */
            onError: null, /* called if we encounter any error */
            onCancel: null, /* called if we explicitly cancel the upload */
            onProgress: null, /* called every time we upload a chunk successfully */
            purpose: 'media',
            url: '/uploads/upload_chunk/'
        });
        $.extend(final_props, props);
        this.props = final_props;
        this.state = {
            in_flight: null,
            finished: false,
            written: 0,
            upload_id: null,
            aborted: false,
            chunk_size: null,
        };
    }
    SPEED_FACTOR = 1.15;
    slower = () => {
        /* Decrease our chunk size */
        const current = this.state.chunk_size || this.props.chunk_size;
        this.state.chunk_size = Math.max(Math.round(current / this.SPEED_FACTOR), this.props.min_chunk_size);
        console.info(`Decreasing chunk size to ${Math.round(this.state.chunk_size / 1024)}KiB`);
        return this.state.chunk_size;
    };
    faster = () => {
        /* Increase our chunk size */
        const current = this.state.chunk_size || this.props.chunk_size;
        this.state.chunk_size = Math.min(Math.round(current * this.SPEED_FACTOR), this.props.max_chunk_size);
        console.info(`Increasing chunk size to ${Math.round(this.state.chunk_size / 1024)}KiB`);
        return this.state.chunk_size;
    };
    next_slice = () => {
        const { file } = this.props;
        const { written } = this.state;
        const chunk_size = this.state.chunk_size || this.props.chunk_size;

        return file.slice(
            written,
            Math.min(written + chunk_size, file.size)
        );
    };
    next_chunk = () => {
        /* Upload our next chunk to the target url */
        const { file, onFinished, purpose } = this.props;
        const { aborted, upload_id, written } = this.state;
        var self = this;
        if (aborted) {
            return;
        }
        if (!upload_id) {
            return self.get_upload_id();
        }
        if (written >= file.size) {
            if (onFinished) {
                onFinished(self);
            }
            return;
        }
        const slice = this.next_slice();
        var d = new Date();
        var ts = d.getTime() / 1000;
        var final_url = self.state.url || (
            `${self.props.url}${upload_id}/${purpose}/${written}`
        );

        self.state.in_flight = fetch(final_url + '?' + ts, {
            'method': 'POST',
            'mode': 'same-origin',
            'cache': 'no-cache',
            'credentials': 'same-origin',
            'headers': {
                'X-CSRFToken': csrf_token(),
                // 'Content-Type': 'multipart/form-data'
            },
            'redirect': 'manual',
            'referrer': 'no-referrer',
            'body': slice
        }).then(response => {
            if (response.ok) {
                return checked_json_response(response);
            } else {
                if (this.state.aborted) {
                    return;
                }
                if (response.status == 413) {
                    /* Server is saying our chunks have gotten too large */
                    console.log(`Server reports chunk-size is too large, reduce and limit`);
                    this.slower();
                    this.props.max_chunk_size = this.state.chunk_size;
                    return this.retry(50);
                }
                throw (`Chunk upload failed: ${response.statusText}`);
            }
        }).then(
            (data) => {
                if (this.state.aborted) {
                    this.slower();
                    return;
                }
                if (data.success) {
                    if (data.written > self.state.written) {
                        self.state.written = data.written;
                        self.state.url = data.url;
                    }
                }
                if (data.throttle) {
                    console.log('Server requested upload throttle');
                    this.slower();
                    this.retry();
                } else if (data.success) {
                    if (self.props.onProgress) {
                        self.props.onProgress(self, data);
                    }
                    // Call the next chunk in a small fraction of a second...
                    var d = new Date();
                    var final_ts = d.getTime() / 1000;
                    const delta = final_ts - ts;
                    console.debug(`Chunk upload took: ${Math.round(delta)}s`);
                    if (delta < (this.props.target_ts / this.SPEED_FACTOR)) {
                        this.faster();
                    } else if (delta > (this.props.target_ts * this.SPEED_FACTOR)) {
                        this.slower();
                    }
                    window.setTimeout(() => self.next_chunk(), 30);
                    return data;
                } else {
                    console.log('' + data.message);
                    this.slower();
                    self.retry();
                    return data;
                }
            }
        ).catch((err) => {
            if (this.state.aborted) {
                return;
            }
            console.log(`Error on ${final_url}: ${err}`);
            if (self.props.onError) {
                self.props.onError(self, err);
            }
            self.retry();
        });
    };
    description = () => {
        const { file } = this.props;
        const percent = this.percent();
        if (percent < 100) {
            return `${file.name} ${human_bytes(file.size)} ${percent}%`;
        } else {
            return `${file.name} ${human_bytes(file.size)}`;
        }
    };
    percent = () => {
        if (!(this.props.file && this.props.file.size)) {
            return 100;
        } else {
            return Math.round((this.state.written / this.props.file.size) * 100);
        }
    };
    percent_buffer = () => {
        /* How much will be pushed when the next buffer is complete */
        if (!(this.props.file && this.props.file.size)) {
            return 100;
        } else {
            const flying = this.state.in_flight ? this.props.chunk_size : 0;
            return Math.min(
                Math.round(((this.state.written + flying) / this.props.file.size) * 100),
                100,
            );
        }
    };
    retry = (delay) => {
        if (delay === undefined) {
            delay = 500;
        }
        console.log(`Scheduling retry in ${delay}ms`);
        window.setTimeout(
            () => this.next_chunk(),
            delay
        );
    };
    xhr = () => {
        /* get our current in-flight xhr request */
        return this.state.in_flight;
    };
    cancel = () => {
        var self = this;
        // var xhr = self.xhr();
        var file = this.props.file;
        this.state.aborted = true;
        self.state.in_flight = fetch(self.props.url, {
            'method': 'POST',
            'mode': 'same-origin',
            'cache': 'no-cache',
            'credentials': 'same-origin',
            'headers': {
                'X-CSRFToken': csrf_token(),
                // 'Content-Type': 'multipart/form-data'
            },
            'redirect': 'follow',
            'referrer': 'no-referrer',
            'body': JSON.stringify({
                'method': 'delete',
                //'csrftoken': this_upload.csrftoken,
                'file': {
                    'name': file.name,
                    'size': file.size,
                    'type': file.type,
                    'upload_id': self.state.upload_id
                }
            })
        })
            .then(checked_json_response)
            .then(
                (data) => {
                    // JSON.parse( data );
                    if (data.success) {
                        if (self.props.onCancel) {
                            self.props.onCancel(self);
                        }
                    } else {
                        console.error(`Unable to cancel upload request: ${JSON.stringify(data)}`);
                    }
                }
            ).catch((err) => {
                // do something useful
                console.log('Error on cancel: ' + err);
                if (self.props.onError) {
                    self.props.onError(self, err);
                }
            });
    };
    get_file_hash = (file) => {
        /* Calculate the file hash on the sending side */
        const hash = new jsSHA(
            'SHA-512',
            'ARRAYBUFFER',
            {},
        );
        const chunk_size = 1024 * 1024;
        for (let start = 0; start < file.size; start += chunk_size) {
            hash.update(file.slice(
                start,
                Math.min(start + chunk_size, file.size)
            ));
        }
        return hash.getHash('HEX');
    };
    get_upload_id = () => {
        var self = this;
        var file = self.props.file;
        var url = self.props.url + self.props.purpose + '/';
        const hash = self.get_file_hash(self.props.file);
        console.log(`File hash on ${file.name}: ${hash}`);
        self.state.in_flight = fetch(url, {
            'method': 'POST',
            'mode': 'same-origin',
            'cache': 'no-cache',
            'credentials': 'same-origin',
            'headers': {
                'X-CSRFToken': csrf_token(),
                'Content-Type': 'application/json'
            },
            'redirect': 'follow',
            'referrer': 'no-referrer',
            'body': JSON.stringify({
                'method': 'create',
                //'csrftoken': this_upload.csrftoken,
                'file': {
                    'name': file.name,
                    'size': file.size,
                    'type': file.type,
                    'hash': hash,
                },
            })
        }).then(checked_json_response).then(
            (data) => {
                if (data.success) {
                    self.state.upload_id = data.upload_id;
                    //this_upload.csrftoken = data.csrftoken;
                    self.state.url = data.upload_url;
                    if (self.state.written && data.written < self.state.written) {
                        console.warn(`Server does not believe we have written as much as we have written`);
                    }
                    self.state.written = data.written;
                    if (self.props.onProgress) {
                        self.props.onProgress(self, data);
                    }
                    self.next_chunk();
                } else {
                    if (self.props.onError) {
                        self.props.onError(self, data);
                    }
                }
            }
        ).catch((err) => {
            if (self.props.onError) {
                self.props.onError(self, err);
            }
            self.retry();
        });
    };
}

class UploadList {
    /* storage that only polls when there's listeners for the upload-list */
    constructor(props) {
        var final_props = {};
        $.extend(final_props, {
            period: 10,
            purpose: 'media',
            url: '/uploads/upload_chunk/'
        });
        $.extend(final_props, props);
        this.props = final_props;
        this.signal = new Signal();
        this.state = {
            uploads: {},
            periodic: null,
            in_flight: null
        };
    }
    start() {
        var self = this;
        if (!self.state.periodic) {
            self.state.periodic = new Periodic({
                'period': this.props.period,
                'refresh_on_post': true
            });
            if (self.state.periodic.refresh_on_post) {
                POST_SIGNAL.listen(self.state.periodic.trigger);
            }
            self.state.periodic.signal.listen(
                self.list.bind(self)
            );
        }

    }
    list() {
        var self = this;
        if (!self.signal.listener_count()) {
            return;
        }
        if (self.state.in_flight) {
            console.log("Already polling for upload list");
            return;
        }
        self.state.in_flight = fetch(self.props.url, {
            'method': 'POST',
            'mode': 'same-origin',
            'cache': 'no-cache',
            'credentials': 'same-origin',
            'headers': {
                'X-CSRFToken': csrf_token(),
                // 'Content-Type': 'multipart/form-data'
            },
            'redirect': 'follow',
            'referrer': 'no-referrer',
            'body': JSON.stringify({
                'method': 'list',
            })
        }).then(checked_json_response).then(
            (data) => {
                if (data.success) {
                    self.state.uploads = data.uploads;
                    self.signal.send(self);
                } else {
                    console.log('Error on listing: ' + data.message);
                }
            }
        ).catch((err) => {
            console.log("Unable to list media elements");
        });
    }
}
var UPLOAD_STORAGE = new UploadList({});

export { BaseUploader, UploadList, UPLOAD_STORAGE };
