/* Trivial document title setter */

export default (props) => {
    const document = window && window.document;
    if (document && document.title !== undefined && props.title) {
        if (document.title != props.title) {
            document.title = props.title;
        }
    }
    return null;
};