/* Render a set of "what would the configured services be" records */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { ClassyWidget } from 'reactform/classywidget';
import WidgetRender from './custom_widget_render';
import ServiceBindings from './servicebindings';

class ServiceBindingsWidget extends ClassyWidget {
    static defaultProps = {
        ...ClassyWidget.defaultProps,
        from_server: (value, widget) => {
            if (Array.isArray(value)) {
                return value;
            } else if (typeof value === 'string') {
                if (value[0] == '[') {
                    return JSON.parse(value);
                } else {
                    return value.split(',');
                }
            } else {
                return value;
            }
        },
    }
    render() {
        const { classes, show_label } = this.props;
        const { sub_form } = this.state;
        const field = this.get_field();
        const base_field = field.field;
        let value = this.render_value();

        // const clean_value = (value || []).map(this.resolve_value);
        return <WidgetRender form_field={field} widget_class="service-binding-list" show_label={show_label}>
            <ServiceBindings
                value={value}
            />
        </WidgetRender>;
    }
}

export default ServiceBindingsWidget;