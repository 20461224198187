
const mutated_ids = (id) => {
    /* Produce the set of likely mutated names for a given field */
    if (id == 'connected' || id == 'level') {
        return [id]
    }
    const names = [id, `${id}_display`];
    if (id.slice(0, 3) === 'id_') {
        names.push(id.slice(3));
        names.push(`${id.slice(3)}_display`);
    }
    return names;
};

const dropdown_filter_rows = (rows, id, filter) => {
    /* React table v7 version of filtering api */
    const packed_filter = {
        'value': filter,
        'id': id[0],
    };
    return rows.filter(
        (row) => dropdown_filter(packed_filter, row)
    );
};

//exact same function as generic filter itself. Only changed the final comparison to be a string compare
//rather than a substring search to handle dropdown filtering appropriately
const dropdown_filter = (filter, record, rows) => {
    // console.log(`Doing filtering with generic filter`);
    let test_values = [];
    const ci_test = filter.value.toLowerCase();
    if (filter.id && record.original) {
        const names = mutated_ids(filter.id);
        const lookup_keys = (source) => {
            if (source !== undefined && source !== null) {
                names.map((name) => {
                    const value = source[name];
                    if ((filter.id == 'connected' || filter.id == 'level') && value == undefined) {
                        test_values.push("null")
                    }
                    else if (value !== undefined) {
                        filter.id == "resource_id" ? test_values.push(value.title) : test_values.push(value);
                    }
                });
            }
        };
        if (Array.isArray(record.original)) {
            record.original.map(lookup_keys);
        } else {
            test_values.push(record.original);
        }
    } else {
        test_values = record;
    }
    function compare_value(v) {
        if (v === null || v === undefined) {
            return false;
        }
        if (Array.isArray(v)) {
            return v.some((sub) => compare_value(sub));
        } else if (typeof v === 'object') {
            return Object.values(v).some((sub) => compare_value(sub));
        } else {
            return `${v}`.toLowerCase() == ci_test;
        }
    }
    return compare_value(test_values);
};
export default dropdown_filter_rows;
export { dropdown_filter_rows, dropdown_filter };
