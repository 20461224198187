import React from 'react';
import { WithTabularStorage } from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import { ReactFormTable } from 'reactform';
import { delete_target_action } from 'reactform/actionmenu';
import { node } from 'prop-types';

class BaseNodeConfigTable extends React.Component {
    render() {
        const { form_details, product_type } = this.props;
        return <ReactFormTable
            editable={false}
            extra_fields={{
                'NodeConfig': [
                    {
                        'name': 'configuration_url',
                        'label_id': 'id_configuration_url',
                        'label': 'Download',
                        'field': {
                            'help_text': 'Download the configuration-file for this release',
                            'widget': 'Download',
                        },
                    },
                ]
            }}
            exclude={['configuration']}
            form_details={form_details}
            overall_target={{
                id: node.id,
                __type__: 'Node',
                __pk__: product_type,
            }}
            field_actions={{
                'node': (target, context, menu) => {
                    return [
                        delete_target_action(target, context, menu),
                    ];
                }
            }
            }
            {...this.props}
        />;
    }
}

const nodeconfig_storage = (node = null) => {
    /* Get a product_type-specific storage for given product_type *or* all firmwares */
    const default_context = {};
    if (node) {
        default_context['node'] = node.id || node;
    }
    return {
        default_context: default_context,

        forms_key: 'shogunconf_forms',
        form_key: 'NodeConfigs',
        form_keys: ['NodeConfig'],
        node: node,
        quick_settings: {
        },
    };

};


const NodeConfigTable = (props) => {
    return <WithTabularStorage
        key={`nodeconfigs-for-node-${props.node}`}
        storage_props={nodeconfig_storage(props.node)}
        Component={BaseNodeConfigTable}
        form_keys={['NodeConfig']}
        preference_key='node-configs-table'
        pageSize={10}
        {...props}
    />;
};
export default NodeConfigTable;
export { nodeconfig_storage, BaseNodeConfigTable, NodeConfigTable };
