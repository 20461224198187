/* shogun promotion appbar */
import React, { forwardRef } from 'react';
import MuiAppBar from 'dash/MuiAppBar';
import AppBarSpacer from 'dash/AppBarSpacer';
import MuiButton from 'dash/MuiButton';
// import MuiSnackbar from 'dash/MuiSnackbar';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
// import { url_for_key } from 'storages/basestorage';
import { providing_nearest_form } from 'formprovider';
// import { default_cancel_button, default_save_button } from 'reactform';
import { with_focus_target } from 'dash/resolvefocus';

// import { EditIntent } from 'editintent';
import { Link, withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import wraps from 'wraps';
import redirect from 'redirect';

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
    },
    download: {
        '&:hover': {
            textDecoration: 'underline',
        },
        textDecoration: 'none',
        fontSize: '.875rem',
        lineHeight: 1.5,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    appBar: {
        bottom: 0,
        top: 'auto',
    },
});

class BaseShogunPromoteBar extends React.Component {
    render() {
        const { classes, target, history } = this.props;
        const config = {
            'dirty': true,
            'current': true,
        };
        // const download_url = `${url_for_key('shogun_configs')}${config.id}`;
        if (!target) {
            return null;
        }
        // const download_url = '/moo';
        // const download_link = <MuiButton
        //     className={classNames('flat-button', classes.download)}
        //     variant='contained'
        //     href={download_url}
        //     download='passport-config'
        // >Download Config</MuiButton>;
        // const dirty_notice = (config.current && config.dirty && <MuiSnackbar variant="info">
        //     Config Changed, Click Publish → to apply changes.
        // </MuiSnackbar>) || null;
        const dirty_notice = null;
        // bottom prop no longer works on MuiAppBar
        return <MuiAppBar inPage={true} position="fixed" bottom={true} className={classes.appBar} >
            {dirty_notice}
            <div className={classes.buttons}>
                {/* {download_link}
                <EditIntent
                    key='upload'
                    form_key='ConfigUploadForm'
                    target={{
                        'id': null,
                        'type': 'System',
                    }}
                    variant='contained'
                    label='Upload Config'
                    icon='upload'
                    help_text="Upload a Configuration (JSON)"
                /> */}
                <MuiButton
                    key='promote'
                    onClick={(evt) => {
                        redirect(`/m/deploy/${(target && target.id) || ''}`, history)
                    }}
                    variant='contained'
                    color='primary'
                ><Icon>play_arrow</Icon> Apply</MuiButton>
            </div>
        </MuiAppBar>;
    }
}
const ShogunPromoteBar = providing_nearest_form(
    withRouter(withStyles(styles)(with_focus_target(BaseShogunPromoteBar))),
    'shogunconf_forms',
);
const WithToolbar = (Component) => {
    const ToolbarRender = wraps((props, ref) => {
        return <React.Fragment>
            <Component {...props} ref={ref} />
            <AppBarSpacer key='app-bar-spacer' />
            <ShogunPromoteBar />
        </React.Fragment>;
    }, Component, 'WithToolbar');
    return forwardRef(ToolbarRender);
};

export { WithToolbar, ShogunPromoteBar };

export default ShogunPromoteBar;
