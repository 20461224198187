import React from 'react';
import {requiring_store } from 'storages/requiringstore';
import {default_sysemail_cb} from './sysemailstorage';
import {ReactForm} from 'reactform';
import MuiTypography from 'dash/MuiTypography';
import {delete_intent} from 'editintent';
import { form_action, construct_controls } from 'reactform';

class BaseConfig extends React.Component {
    static defaultProps = {
        'config': null,
        'storage': null,
    }
    render() {
        const { config } = this.props;

        if (config) {
            return <div>
                <ReactForm
                    target={this.props.config}
                    form_key={'SMTP'}
                    use_dialog={false}
                />
                {
                    config.id &&
                    delete_intent(this.props.config, null, null, 'Delete')
                }
            </div>;
        } else {
            return <span>Loading</span>;
        }
    }
}
class BaseSummary extends React.Component {
    static defaultProps = {
        'config': null,
        'storage':null,
    }
    render() {
        const { config } = this.props;

        if (!config) {
            return <div />; // loading
        }

        if (!config.host) {
            return <MuiTypography className="warning">No SMTP server configured</MuiTypography>;
        }

        const { username, host, port } = config;
        return <MuiTypography>{`${username}:${host}:${port}`}</MuiTypography>;
    }
}
function with_sysemail_store(Component) {
    return requiring_store(
        Component,
        {
            storage_key: 'sysemail_config',
            create_function: default_sysemail_cb,
            forms_key: 'sysemail_forms',
            prop_function: (props, store) => {
                if (!store) {
                    return {...props,config:{
                        id: null,
                        type:'SysemailConfig'
                    }};
                }
                let {config} = store;
                return {...props, config};
            }
        }
    );
}

const SysemailConfig = with_sysemail_store(BaseConfig);
const SysemailSummary = with_sysemail_store(BaseSummary);
export {SysemailSummary,SysemailConfig};
