import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { providing_nearest_form, using_nearest_form } from 'formprovider';

const ServiceLookup = providing_nearest_form(using_nearest_form((props) => {

    const [target, setTarget] = useState(null);
    const [error, setError] = useState(null);
    if (target) {
        return <React.Fragment>
            <Redirect
                to={target.__url__ || `/m/services/${target.service_group_id}`}
            />
            Redirect
        </React.Fragment>;
    }
    if (error) {
        return <div>Error loading reference: {`${error}`}</div>;
    }
    props.storage.get_form(
        'Service',
        props.id
    ).then(
        (result) => {
            if (result && result.success) {
                setTarget(result.instance);
            } else {
                setError(result.messages);
            }
        }
    );
    return null;
}), 'shogunconf_forms');

export default ServiceLookup;