/* Cepra bridge debugger */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useStorage from 'storages/usestorage';
import {BaseStorage} from 'storages/basestorage';
import { Cookies } from "react-cookie";
import { Typography } from '@material-ui/core';
import ATXTable from 'atxtable/atxtable';
import { CheckCircle } from '@material-ui/icons';
import { Cancel } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

const ROOT_URL = '/ceprabridge/1/';

const CepraBridgeSummaryStorage = BaseStorage({
    url:`${ROOT_URL}`,
    form_key: 'status',
    status: null,
    period: 15,
    debug: false,
});

const CepraBridgeStorage = BaseStorage({
    url:`${ROOT_URL}info`,
    form_key: 'status',
    status: null,
    period: 15,
    debug: false,
});
const useStyles = makeStyles(theme => ({
    good: {
        color: theme.palette.success.main,
    },
    bad: {
        color: theme.palette.error.main,
    },
    actionHolder: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        '&>*': {
            'flex': 1,
        }
    }
}));

const IdiotLight = (props) => {
    const classes = useStyles();
    const {good, title} = props;
    if (good) {
        return <CheckCircle title={title} size="small" className={classes.good} />
    } else {
        return <Cancel title={title} size="small" className={classes.bad} />
    }
}
const CepraBridgeRestart = (props) => {
    /* Trigger a cepra bridge restart by exiting the pod */
    const [running,setRunning] = React.useState(false);
    const [success,setSuccess] = React.useState(null);
    const cb = React.useCallback(async (evt) =>  {
        setRunning(true);
        const response = await fetch(
            '/m/api/internal/cepra/shutdown',
            {
                method:'post',
                data: {
                },
            }
        )
        setRunning(false);
        if (response.status != 200) {
            setSuccess(false);
        } else {
            setSuccess(response.success);
        }
    },[]);
    let text = 'Shutdown';
    if (running) {
        text = 'Shutting down';
    } else if (success !== null) {
        if (success) {
            text = 'Shut down';
        } else {
            text = 'Failed';
        }
    }
    return <Button onClick={cb} color="primary">Restart</Button>
}

const FactoryCepraSummary = (props) => {
    /* Summary view with overall summary data and much lighter interactions with the server */
    const classes = useStyles();
    const [cepraStatus,setCepraStatus] = React.useState();
    const onStorageUpdate = React.useCallback((storage) => {
        setCepraStatus(storage.status);
    },[CepraBridgeSummaryStorage]);
    useStorage(CepraBridgeSummaryStorage, onStorageUpdate);
    if (!cepraStatus) {
        return 'Loading Summary';
    }
    const data = [
        cepraStatus,
        // TODO: allow for multiple...
    ];

    return <div>
        <ATXTable 
            data={data}
            class={{
                holder:classes.rootTable,
            }}
            columns={[
                {
                    'accessor': 'runtime',
                    'Header': 'Run-Time',
                    'Cell': (props) => {
                        return props.row?.original?.runtime_display || '';
                    }
                },
                {
                    'accessor': 'memory_percent',
                    'Header': 'Memory %',
                },
                {
                    'accessor': 'cpu_percent',
                    'Header': 'CPU %',
                },
                {
                    'accessor': 'task_count',
                    'Header': 'Tasks',
                    'Cell': (props) => {
                        const task_count = props.value;
                        const clients = props.row?.original?.client_count || 0;
                        if (task_count && clients) {
                            return <span>{task_count} ({Math.round(task_count/clients*100)/100})</span>;
                        } else {
                            return '';
                        }
                    }
                },
                {
                    'accessor':'api_duration',
                    'Header': 'Avg API Duration (s)',
                },
                {
                    'accessor': 'client_count',
                    'Header': 'Clients',
                },
                {
                    'accessor': 'enrolled_count',
                    'Header': 'Enrolled',
                },
                {
                    'accessor': 'stb_count',
                    'Header': 'STB Mode',
                },
                {
                    'accessor': 'monitor_count',
                    'Header': 'Monitor',
                },
                {
                    'accessor': 'pending_count',
                    'Header': 'Unconfigured',
                },
                {
                    'accessor': 'zero_enrolls',
                    'Header': 'Zero Enrolls',
                },
                {
                    'accessor': 'one_enroll',
                    'Header': 'One Enroll',
                },
                {
                    'accessor': 'multiple_enrolls',
                    'Header': 'Multiple Enrolls',
                },
            ]}
        />
    </div>

};



const FactoryCepraBridge = (props) => {
    const classes = useStyles();
    const [status,setStatus] = React.useState('summary');
    const handleChange = React.useCallback((event, newValue) => {
        setStatus(newValue);
    });
    return <div><Tabs aria-label="CepraBridge Views" onChange={handleChange} classes={{
        color: 'primary',
    }}>
        <Tab
            label="Summary"
            key={`summary`}
            value={`summary`}
            classes={{
                root: classes.tightTab,
                selected: classes.tightTabSelected,
            }}
        />
        <Tab
            label="Sessions"
            key={`sessions`}
            value={`sessions`}
            classes={{
                root: classes.tightTab,
                selected: classes.tightTabSelected,
            }}

        />
        <Tab
            label="Tasks"
            key={`tasks`}
            value={`tasks`}
            classes={{
                root: classes.tightTab,
                selected: classes.tightTabSelected,
            }}

        />
    </Tabs>
    {status === 'summary' && <FactoryCepraSummary />}
    {status === 'sessions' && <FactoryCepraDetails />}
    {status === 'tasks' && <FactoryCepraTasks />}
    </div>;

};
const FactoryCepraTasks = (props) => {
    const classes = useStyles();
    const [cepraStatus,setCepraStatus] = React.useState();
    const onStorageUpdate = React.useCallback((storage) => {
        setCepraStatus(storage.status);
    },[CepraBridgeStorage]);
    useStorage(CepraBridgeStorage, onStorageUpdate);

    if (!cepraStatus) {
        return 'Loading data';

    }
    return <React.Fragment>
        <Typography>Cepra Bridge Tasks {(cepraStatus && cepraStatus.task_count)|| 'Loading'}</Typography>
        <ATXTable 
            data={(cepraStatus && cepraStatus.tasks)||[]}
            classes={{
                holder: classes.rootTable,
            }}
            filterable={true}
            columns={[
                {
                    'accessor':'1',
                    'Header':'Name',
                },
                {
                    'accessor':'0',
                    'Header':'Code',
                },
            ]}
    /></React.Fragment>;

};

const FactoryCepraDetails = (props) => {
    const classes = useStyles();
    const [cepraStatus,setCepraStatus] = React.useState();
    const onStorageUpdate = React.useCallback((storage) => {
        setCepraStatus(storage.status);
    },[CepraBridgeStorage]);
    useStorage(CepraBridgeStorage, onStorageUpdate);

    if (!cepraStatus) {
        return 'Loading data';
    }

    return <div>
        <ATXTable 
            data={(cepraStatus && cepraStatus.clients)||[]}
            classes={{
                holder: classes.rootTable,
            }}
            filterable={true}
            columns={[
                {
                    'accessor':'unique_key',
                    'Header':'Unique Key',
                    'Cell': (props) => {
                        const {node} = props.row.original;
                        if (node && node.node_id) {
                            return <a href={`/m/nodes/${node.node_id}`}>{props.value}</a>;
                        } else {
                            return `${props.value}`;
                        }
                    },
                },
                {
                    'accessor':'linkbridge',
                    'Header':'Linkbridge',
                },
                {
                    'accessor':'connection_id',
                    'Header':'Connection',
                },
                {
                    'accessor':'registration.device_mode',
                    'Header':'Boot Mode',
                },
                {
                    'accessor':'node.stb',
                    'Header':'Conf STB?',
                    'Cell': (props) => {
                        const stb = props.value;
                        if (stb) {
                            return <React.Fragment>
                                <IdiotLight good={stb.enabled} title={stb.enabled?'STB Mode':'Non-STB Mode'} />
                                {stb.__pk__?<a href={stb.__url__}>{stb.title}</a>:null}
                            </React.Fragment>;
                        } else {
                            return null;
                        }
                    }
                },
                {
                    'accessor':'node.monitor',
                    'Header':'Conf Monitor?',
                    'Cell': (props) => {
                        const monitor = props.value;
                        if (monitor) {
                            return <React.Fragment><IdiotLight good={!monitor.disabled} title={(!monitor.disabled)?'Monitor Mode':'Non-Monitor Mode'} />
                                {monitor.__pk__?<a href={monitor.__url__}>{monitor.title}</a>:null}
                            </React.Fragment>
                        } else {
                            return null;
                        }
                    }
                },
                {
                    'accessor':'enrollment_count',
                    'Header': "Enroll Count",
                },
                {
                    'accessor':'observed_ips',
                    'Header': "IP Addresses",
                    'Cell': (props) => {
                        const ips = props.value;
                        if (!ips) {
                            return '';
                        }
                        return ips.join(", ");
                    }
                },
                {
                    'accessor': 'node.chassis.firmware',
                    'Header': 'Conf Firmware',
                    'Cell': (props) => {
                        const record = props.value;
                        if (record) {
                            return <a href={record.file_url}>{(record.file && record.file.friendly_name)||'unnamed'}</a>
                        } else {
                            return null;
                        }
                    }
                },
                {
                    'accessor':'unique_key',
                    'id': 'unique_key_actions',
                    'Header':'Unique Key',
                    'Cell': (props) => {
                        const unique_key = props.value;
                        const {node} = props.row.original;
                        if (node && node.node_id) {
                            return <div className={classes.actionHolder}>
                                <a href={`/proxy/access/debug/${node.node_id}`}>Debug GUI</a>
                                <a href={`${ROOT_URL}reconnect/${unique_key}`}>Drop Connection</a>
                            </div>;
                        } else {
                            return `${props.value}`;
                        }
                    },
                },

            ]}
        />
    </div>;

};

export default FactoryCepraBridge;