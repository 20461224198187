import React from 'react';
import { providing_nearest_form } from 'formprovider';
import with_resolve_focus from 'dash/resolvefocus';
import { with_focus } from 'dash/focusprovider';
import EditingCard from 'dash/editingcard';
import { EditIntent } from 'editintent';
import TuningTables from './tuningtables';
import { WithToolbar } from 'shogun/shogunpromotebar';

class BaseTuningView extends React.Component {
    render() {
        const epg_table_actions = [
            <EditIntent
                key="add-tuning"
                form_key="TuningTable"
                target={{
                    '__type__': 'Tuning',
                    'id': null,
                }}
                context={{
                }}
                action='Add Tuning Table'
                label='Add Tuning'
                help_text='Create a new Tuning Table for Set Top Boxes'
            />,
        ];
        return <React.Fragment>
            <EditingCard title='Tuning Tables' actions={epg_table_actions} no_wrapper={true} key={`tunings`} collapsing={`tuning-tables`}>
                <TuningTables
                    key='tuning-table'
                />
            </EditingCard>
        </React.Fragment>;
    }
}
const TuningView = WithToolbar(providing_nearest_form(
    BaseTuningView,
    'shogunconf_forms'
));

export default TuningView;
export { BaseTuningView, TuningView };
