import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    List: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    ListItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
});
const DenseList = withStyles(styles)(
    (props) => {
        const {classes,...childProps} = props;
        return <List dense={true} className={classNames(classes.List,props.className)} {...childProps} />;
    }
);
const DenseListItem = withStyles(styles)(
    (props) => {
        const {classes,...childProps} = props;
        return <ListItem dense={true} disableGutters={true} className={classNames(classes.ListItem,props.className)} {...childProps} />;
    }
);
export {DenseList,DenseListItem};
