import React from 'react';
import {
    NodeMetadataCard, NodeServiceAssignmentCard, NodeResourcesCard,
    NodeApplyForm, NodeAppliedConfigsForm, statBlockStyles
} from 'shogun/nodeview';
import EditIntent from 'editintent';
import ReactFormButton from 'widgets/ReactFormButton';
import { register } from 'shogun/nodeview_registry';
import RestartAlt from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import Cloud from '@material-ui/icons/Cloud';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { default_save_button, danger_confirm_button } from 'reactform/constructcontrols';
import WithCurrentUser from 'withcurrentuser';
import useMetricData from 'metrics/usemetricdata';
import { BASE_COLOR_MAP } from 'metrics/metricgraph';
import { ErrorBoolean, GoodBadBool } from 'widgets/renderboolean';
import { withStyles } from '@material-ui/styles';
import { LoadingDisplay } from 'reactloading';
import SecondsAgo from 'dash/secondsago';
import theme from '../../shared_modules/dash/theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
        factoryDefaultFormButton:{
            color:theme.palette.critical.main,
            backgroundColor: theme.palette.background.default,
            borderColor:theme.palette.critical.main,
        },
        factoryDefaultForm:{
            '@global':{
                '.checkbox-widget':{
                    flexDirection: 'row',
                    width: '60%',
                    justifyContent: 'space-between',
                },
            }
        },
        confirmDanger: {
            color: 'white',
            backgroundColor: theme.roles.errorLevel.critical,
        }
    }
));


const suppress_bubble = (evt) => {
    evt.stopPropagation();
    return false;
};

const VSBBDebugLink = (props) => {
    const { target, user } = props;
    const title = 'View the VSBB Debug GUI';
    const id = target.__pk__;
    if (user.has_permission('shogunconf.map_ssh')) {
        const display = <div
            className='suppress-link MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-centered-74 MuiButton-outlinedSecondary'
            onClick={suppress_bubble}
        ><Cloud /> <a style={{ paddingLeft: '8px' }} target="_blank" href={`/proxy/access/debug/${id}/`}>Debug GUI</a></div>;
        return display;
    } else {
        return null;
    }
};


const VSBBStatBlock = withStyles(statBlockStyles)((props) => {
    /* Render quick-stats from node connection metrics */
    const { target, classes } = props;
    const storage = useMetricData({
        query_type: 'last_status',
        metric: 'node_status',
        target: target,
        create_missing_rows: false,
        start: (new Date()).getTime() / 1000 - (3600 * 366),
    });
    const data = storage.get_last_value();
    if (data && data.length > 0) {
        const record = data[0];
        return <LoadingDisplay signal={storage.loading}><div className={classes.statBlock} key='loading'>
            <div className={classes.statProperties}>
                <div className={classes.statProperty}>
                    <ErrorBoolean value={record.error} />
                    <span>{record.error ? 'Error' : 'No Error'}</span>
                </div>
                <div className={classes.statProperty}>
                    <GoodBadBool value={record.connected} />
                    <span>{record.connected ? 'Connected' : 'Not Connected'}</span>
                </div>
                <div className={classes.statProperty}>
                    <span style={{ color: `${BASE_COLOR_MAP[record.state] || 'black'}` }}>State: {record.state}</span>
                </div>
                {target.reported_structure && target.reported_structure.room_number && <div className={classes.statProperty} title="Room number entered by installer, reported only on reboot/reconnect">
                    Room: {target.reported_structure.room_number}
                </div>}
            </div>
            <div className={classes.statMeta}>
                <div>Reported</div>
                <div><SecondsAgo time={record.time} /></div>
            </div>
        </div></LoadingDisplay>;
    } else {
        return <div className={classes.statBlock}>Not reporting</div>;
    }

});
VSBBStatBlock.displayName = 'VSBBStatBlock';

const NodeView_VSBB = (props) => {
    /* Custom view for VSBB Devices (nodes) */
    const classes = useStyles();
    const { target, user } = props;
    const buttons = [
        'save',
        'delete',

        user.has_permission('shogunconf.reboot') ? <EditIntent
            key={`reboot-${target.__pk__}`}
            target={target}
            form_key='NodeReboot'
            storage='shogunconf_forms'
            label='Reboot'
            action='Reboot'
            icon={<RestartAlt />}
            title="Reboot the VSBB Device (Immediately)"
            variant='outlined'
            color='secondary'
            buttons={[(self) => {
                return default_save_button(self, {
                    key: 'reboot',
                    title: 'Send the request to reboot',
                    busy_message: 'Sending Request',
                    action_message: 'Request Reboot',
                });
            }, 'cancel']}
        /> : null,
        user.has_permission('shogunconf.developer_mode') ? <EditIntent
            key={`developer-mode-${target.__pk__}`}
            target={target}
            form_key='NodeDeveloperMode'
            storage='shogunconf_forms'
            label='Dev Mode'
            action='Configure Dev Mode'
            icon={<SettingsIcon />}
            title="Enable/Disable developer mode firewall settings"
            variant='outlined'
            color='secondary'
            buttons={[(self) => {
                return default_save_button(self, {
                    key: 'devmode',
                    title: 'Send the request to configure dev mode',
                    busy_message: 'Sending Request',
                    action_message: 'Set Dev Mode',
                });
            }, 'cancel']}
        /> : null,
        user.has_permission('shogunconf.override_local') ? <EditIntent
            key={`override-local-${target.__pk__}`}
            target={target}
            form_key='NodeOverrideLocal'
            storage='shogunconf_forms'
            label='Override Local'
            action='Disable Local Settings'
            icon={<SettingsIcon />}
            title="Sets the box to follow this server's configuration rather than using any local override config"
            variant='outlined'
            color='secondary'
            buttons={[(self) => {
                return default_save_button(self, {
                    key: 'override-local',
                    title: 'Request that local override be reset and return control to server',
                    busy_message: 'Sending Request',
                    action_message: 'Override Local',
                });
            }, 'cancel']}
        /> : null,

        user.has_permission('shogunconf.map_ssh') ? <EditIntent
            key={`map_ssh-${target.__pk__}`}
            target={target}
            form_key='NodeMapSSH'
            storage='shogunconf_forms'
            label='Map SSH'
            icon={<Cloud />}
            title="Map SSH Access"
            variant='outlined'
            color='secondary'
            buttons={[(self) => {
                return default_save_button(self, {
                    key: 'map_ssh',
                    title: 'Send the request to map ssh',
                    busy_message: 'Sending Request',
                    action_message: 'Request SSH',
                });
            }, 'cancel']}
        /> : null,
        user.has_permission('shogunconf.map_ssh') ? <VSBBDebugLink key={`map_debug-${target.__pk__}`} target={target} user={user} /> : null,
        user.has_permission('shogunconf.factory_default') ? <EditIntent
        key={`factory-default-${target.__pk__}`}
        target={target}
        form_key='NodeFactoryDefault'
        storage='shogunconf_forms'
        label='Factory Default'
        action='Factory Default'
        icon={<NotInterestedIcon />}
        title="Factory Default VSBB"
        variant='outlined'
        color={'#ff0000'}
        buttonStyleClass={classes.factoryDefaultFormButton}
        className={classes.factoryDefaultForm}
        buttons={[(self) => {
            return danger_confirm_button(self, {
                key: 'Factory Default',
                title: 'Send the request to Factory Default',
                busy_message: 'Sending Request',
                action_message: 'Perform Factory Default',
                className: classes.confirmDanger,
            });
        }, 'cancel']}
    /> : null,
    ].filter(x => !!x);


    return <React.Fragment>
        <VSBBStatBlock target={target} key={`node-statblock-${target.__pk__}`} />
        <NodeMetadataCard {...props} key={`metadata`} buttons={buttons} />
        <NodeServiceAssignmentCard {...props} key={`service-assignment`} />
        <NodeResourcesCard {...props} key={`resources`} />
        <NodeApplyForm {...props} key={`apply`} />
        <NodeAppliedConfigsForm {...props} key={`applied`} />
    </React.Fragment>;
};
const enable_vsbb_view = () => {
    register('vsbb100', WithCurrentUser(NodeView_VSBB));
};

export default enable_vsbb_view;