import React from 'react';
import { providing_nearest_form } from 'formprovider';
import { ReactForm } from 'reactform';
import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import { WithToolbar } from 'shogun/shogunpromotebar';
// import { default_save_button } from 'reactform/constructcontrols';
import { EditIntent, delete_intent } from 'editintent';
import ReactFormButton from 'widgets/ReactFormButton';
import Icon from 'dash/MuiIcon';
// import getIcon from 'getIcon';
// import { Link } from 'react-router-dom';
import LogTable from 'dash/debugging/LogTable';
import { with_resolved_target } from 'dash/resolvetarget';


const styles = (theme) => ({
    linkButton: {
        backgroundColor: '#f0f0f0',
        padding: theme.spacing(1),
        '&:hover': {
            backgroundColor: '#c0c0c0'
        }
    }
});
const EPGEditingCard = providing_nearest_form(
    with_resolved_target(
        withStyles(styles)(
            (props) => {
                const { target, classes, allow_delete = true } = props;
                const buttons = [
                    'save',
                    (form) => target.live_url ? <ReactFormButton
                        className={classes.linkButton}
                        key='live' onClick={
                            (evt) => {
                                window.location = target.live_url;
                            }
                        }><Icon name="eye" />&nbsp;Preview</ReactFormButton> : null,
                    (form) => target.csv_url ? <ReactFormButton
                        className={classes.linkButton}
                        key='csv_download' onClick={
                            (evt) => {
                                window.location = target.csv_url;
                            }
                        }><Icon name="download" />&nbsp;Download CSV</ReactFormButton> : null,
                    (form) => <EditIntent key="edit_csv" style={{ display: 'flex' }} target={target} form_key='EPGCSV' label='Upload CSV' icon='upload' />,
                ];
                if (allow_delete) {
                    buttons.push('delete');
                }
                return <EditingCard title={`Electronic Program Guide: ${target.title}`} key={`epg-${focus}`} collapsing='epgedit.edit-form'>
                    <ReactForm
                        key='epg-properties'
                        form_key='EPG'
                        target={target}
                        use_dialog={false}
                        buttons={buttons}
                    />
                </EditingCard>;
            }
        )
    ),
    'epgconfig_forms'
);

class BaseEPGEdit extends React.Component {
    render() {
        const { target, ...childProps } = this.props;
        const instance = target;
        return <React.Fragment>
            <EPGEditingCard target={instance} {...childProps} />
            {instance.id &&
                <EditingCard title={`EPG Data Download Log`} key='epg-log' collapsing='epgedit.logview' no_wrapper={true} >
                    <LogTable
                        log_name={`pull-epg-schedule-${instance.id}`}
                        scary_level={10}
                    />
                </EditingCard>}
        </React.Fragment>;
    }
}
const EPGEdit = WithToolbar(providing_nearest_form(
    with_resolved_target(
        withStyles(styles)(BaseEPGEdit)
    ),
    'epgconfig_forms'
));

export default EPGEdit;
export { BaseEPGEdit, EPGEdit, EPGEditingCard };
