import React from 'react';
import { WithTabularStorage } from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import ReactTable from 'react-table';
import { ReactFormTable } from 'reactform';
import { ReactForm } from 'reactform';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { with_focus } from 'dash/focusprovider';
import { EditIntent } from 'editintent';
import CountList from 'dash/countlist';
import { delete_target_action } from 'reactform/actionmenu';

class BaseChannelPlanTable extends React.Component {
    render() {
        const { form_details, storage } = this.props;
        if (!(form_details)) {
            return <MuiLoader />;
        }
        return <ReactFormTable
            // link_function={this.on_view_group.bind(this)}
            form_keys={this.props.editing_forms}
            form_details={form_details}
            field_actions={{
                'name': (target, context, menu) => {
                    return [
                        delete_target_action(target, context, menu),
                    ];
                }
            }}
            editable={true}
            exclude={[]}
            // onForm={this.on_edit_map}
            {...this.props}
        />;
    }
}

const channel_plan_storage = (mapping) => {
    /* Get a group-specific storage for given group *or* nodes/non-nodes */
    const context = {
    };
    return {
        default_context: context,
        forms_key: 'channel_plans_forms',
        form_key: 'ChannelPlans',
        quick_settings: {
        },
    };
};


const ChannelPlanTable = (props) => {
    return <WithTabularStorage
        key={`channel-plans`}
        storage_props={props.storage_props}
        Component={BaseChannelPlanTable}
        editing_forms={['ChannelPlan']}
        {...props}
    />;
};
export default ChannelPlanTable;
export { channel_plan_storage, BaseChannelPlanTable, ChannelPlanTable };
