import { FormStorage } from "storages/basestorage";
import { InterfaceStorage } from './interfacestorage';
import { default_system_storage } from "systemstorage";
import { requiring_store } from 'storages/requiringstore';


function requiring_netconfig_store(Component, prop_function) {
    return requiring_store(
        Component, {
        storage_key: 'net_interfaces',
        create_function: setup_storages,
        forms_key: 'net_forms',
        prop_function: prop_function || function (base_props, interface_store) {
            const final_props = { ...base_props };
            if (interface_store) {
                final_props.interfaces = interface_store.interface_records();
            } else {
                final_props.interfaces = [];
            }
            return final_props;
        }
    }
    );
}

export default function setup_storages() {
    console.log("Setting up data stores");
    FormStorage({
        debug: false,
        key: 'net_forms'
    });
    FormStorage({
        key: 'system_accounts_forms'
    });
    InterfaceStorage({
        debug: false,
        key: 'net_interfaces',
        'period': 5,
    }).poll();
    default_system_storage();
}
export { requiring_netconfig_store };