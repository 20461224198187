import React from 'react';
import { TableFedStorage, merge_types, metric_storage, ServiceSummaryStorage } from './metricstorage';
import { getNodeState, NodeStateStorage } from './nodestatestorage';
import useStorage from 'storages/usestorage';
import { WithMetrics, WithLoadedMetrics } from './withmetrics';
import { graph_format, graph_metric, graph_key } from 'dash/charts/chartmodel';
import { with_resolved_target } from 'dash/resolvetarget';
import { BaseReactFormTable } from 'reactform/reactformtable';
import { WithTabularStorage } from 'storages/tabularstorage';
import { default_render } from 'reactform/typerenderer';
import useMetricFilters from 'storages/usemetricfilters';
import { TabularStorage } from 'storages/tabularstorage';
import { group_filter } from 'metrics/metricstorage';
import CellFilterGUI from './cellfiltergui';
import { DBPrefContext } from '../dash/userprefs';

const node_blinken_light = (props) => {
    /* Adds a bit of state from external to the props value */
    const { value, record, field, alignment, classes } = props;
    if (value && value.node_id) {
        const state_record = getNodeState(value.node_id);
        if (state_record) {
            if (state_record.error) {
                value.__icon__ = "error";
            } else if (state_record.state) {
                value.__icon__ = "circle";
            } else {
                value.__icon__ = "circle_empty";
            }
        } else {
            value.__icon__ = "remove_circle_outline";
        }
    } else {
        value.__icon__ = "help";
    }
    return default_render(props);

};

const find_metric_table = (key, available_metrics) => {
    let metric_record = null;
    let primary = key;
    let secondary = null;
    if (key.startsWith('metric_')) {
        secondary = key.slice(7);
    } else {
        secondary = 'metric_' + key;
    }
    if (available_metrics) {
        Object.values(available_metrics).map(
            tables => {
                if (tables && Array.isArray(tables)) {
                    tables.map(table => {
                        if (table.key == primary || table.key == secondary) {
                            metric_record = table;
                        }
                    });
                }

            }
        );
    }
    return metric_record;
};


const MetricTable = (props) => {
    /* Render a single metric table

    metric_record, storage_props, table -- used locally

    table -- dashboard table definition...

        defaultSorting -- if specified, replaces default sort declaration
        column_widths -- if specified, enhances declaration of column widths (overrides)
        widgets -- if specified, enhances declaration of widgets (overrides)
        preference_default -- if specified enhances declaration of preference_default (overrides)
        default_view_settings -- if specified, provides generic settings for the view
            hiddenColumns -- can provide hidden default columns

    */
    const preferences = React.useContext(DBPrefContext);
    const { preferences: { fieldOrder } } = preferences;
    const { metric_record, target, storage_props, table, ...restProps } = props;
    /* restProps come directly from the dashboard definition */
    const { form } = metric_record;
    const { form_key } = metric_record;

    const [serviceSummary, setServiceSummary] = React.useState(null);
    useStorage(ServiceSummaryStorage, setServiceSummary);
    const [nodeState, setNodeState] = React.useState(null);
    useStorage(NodeStateStorage, setNodeState);
    const extra_context = {

    };
    if (table.resource_as_node) {
        extra_context.resource_as_node = true;
    }
    if (table.query_type) {
        extra_context.query_type = table.query_type;
    }
    const [filters, set_filter, hash] = useMetricFilters();
    const default_context = {
        ...(props.default_context || {}),
        ...(table.default_context || {}),
        ...extra_context,
    };
    const final_storage_props = metric_storage({
        metric: metric_record.key,
        data_filters: filters,
        target: target,
        debug: true,
        query_type: default_context.query_type,
        prop_function: (props, storage) => {
            if (storage) {
                return {
                    quick_settings: storage.quick_settings, // Note: GUI can override what's available
                    ...props,
                    storage,
                    data: storage[form_key],
                    resource_map: storage['resource_map'] || props['resource_map'],
                    resource_types: storage['resource_types'] || props['resource_type'],
                    // form_details: storage.form_details || passed_form_details,
                };
            } else {
                return props;
            }
        },
        default_context: default_context,
    });

    const storage = TabularStorage(final_storage_props);
    storage.default_context = final_storage_props.default_context;
    // console.log(`Table storage props: ${JSON.stringify(final_storage_props)}`);
    const [data, setData] = React.useState([]);
    const onTabularChange = React.useCallback((storage) => {
        setData(storage[storage.type_key]);
    }, [final_storage_props.key]);

    useStorage(storage, onTabularChange);

    const form_details = {};
    form_details[form_key] = form;
    // if (!form) {
    //     return null;
    // }
    return [
        <BaseReactFormTable
            {...restProps}
            data={data}
            storage={storage}
            key={`${form_key}-table-${hash}`}
            // storage_props={final_storage_props}
            editable={true}
            link_only={true}
            link_to_edit={true}
            cell_filter_gui={true}
            set_filter={set_filter}
            downloads={true}
            forms_key='shogunconf_forms'
            form_key={form_key}
            form_keys={[form_key]}
            form_details={form_details}
            preference_key={`metric-table-${restProps.preference_key || table.key}-preferences`}
            defaultSorting={
                table.defaultSorting || [
                    { 'id': 'resource_id', 'desc': false, },
                    { 'id': 'stream_id', 'desc': false, },
                ]
            }
            column_widths={{
                'connected': 'medium',
                'generation': 'small',
                'uptime': 'medium',
                'error': 'small',
                'service_id': 'small',
                'time': 'medium',
                'resource_id': 'large',
                'location': 'medium',
                ...(table.column_widths || {})
            }}
            widgets={{
                'time': 'TimestampDate',
                // 'resource_id': node_blinken_light,
                ...(table.widgets || {})
            }}
            cell_wrapper={CellFilterGUI}
            columns={
                [
                    {
                        key: 'resource_id',
                        //removed the sorting function written here as it was overriding 
                        //typedValueCompare() which is better and makes sorting uniform everywhere 
                    },
                    // {
                    //     'key': 'site_id',
                    //     accessor: 'side_id',
                    // },
                    // {
                    //     'key': 'customer_id',
                    //     accessor: 'side_id',
                    // },
                    // {
                    //     'key': 'location',
                    //     accessor: 'location',
                    // },
                ]}
            preference_default={{
                ...(table.preference_default || {}),
                hiddenColumns: [
                    // 'time',
                    'host', 'version', 'function', 'product',
                    ...((table.default_view_settings && table.default_view_settings.hiddenColumns) || []),
                    ...((table.preference_default && table.preference_default.hiddenColumns) || [])
                ],
            }}
            field_order={fieldOrder && fieldOrder[`metric-table-${restProps.preference_key || table.key}-preferences`] || table.field_order || []}
        />];
};

const StandaloneMetricTable = with_resolved_target(
    WithLoadedMetrics(
        (props) => {
            /* Resolves a dashboard 'table' record into final parameters for loading */
            let { table, metric_record, available_metrics } = props;
            metric_record = metric_record || find_metric_table(table.metric, available_metrics);

            if (metric_record) {
                return <MetricTable
                    {...props}
                    metric_record={metric_record}
                />;
            } else {
                return `No metric ${table.metric} found in registry`;
            }
        }
    )
);

export default MetricTable;
export { StandaloneMetricTable, MetricTable, CellFilterGUI };
