/* Context providing currently active routes during the route-rendering process 

const {activeRoute,activeRoutes} = React.useContext(ActiveRouteContext);

Lets you receive which route configuration structs (from appconfig)
are currently active. You can then use this to construct relative
Routes with `route.link_url()` or the like.
*/
import React from 'react';
import redirect from 'redirect';

const ActiveRouteContext = React.createContext();

const reverse = (props) => {
    /* Calculate the URL for target from the given props for the current view 
    
    Use to quickly get URL from common properties within the rendering
    hierarhcy without needing to unpack them first...
    */
    const { target, activeRoute, routes, user, match, title } = props;
    return get_page_url(activeRoute, target, user, routes, match);
};

let get_page_url = (page, focus, user, routes, match = null) => {
    /* Calculate the page URL to which to link when clicking on an entry 
    
    page -- route from e.g. activeRoute or routes
    focus -- group/node target/focus on which to condition the url
    user -- current user for filtering
    routes -- set of all known routes
    match -- if provided, the route-matching parameters for the current load

    Use in cases where you've 
    */
    let link_url = page.link_url || page.path;
    if (typeof link_url == 'function') {
        link_url = link_url(page, focus, user, routes, match);
        if (link_url == null) {
            return null;
        }
    }
    return link_url;
};

const current_user_can_view = (item, user) => {
    if (!(item.public || (user && user.id))) {
        return false;
    }
    if (!item.permission) {
        return true;
    }
    if (user && user.has_permission(item.permission)) {
        return true;
    }
    // if (user && !(user.is_anonymous)) {
    //     console.log(`Cannot view ${user.name} ${item.path} ${item.permission}`);
    // }
    return false;
};



export {
    ActiveRouteContext,
    reverse,
    get_page_url,
    current_user_can_view,
};
export default ActiveRouteContext;