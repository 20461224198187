import React from 'react';
import MuiTypography from 'dash/MuiTypography';

const SectionTitle = ({title, description}) => (
    <React.Fragment>
        <MuiTypography gutterBottom={true} variant="h5">{title}</MuiTypography>
        <MuiTypography gutterBottom={true}>{description}</MuiTypography>
    </React.Fragment>
);

export default SectionTitle;
