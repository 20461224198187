import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const styles = theme => ({
    appBar: {
        background: theme.palette.grey[100]
    },
    appBarBottom: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        top: 'auto',
        bottom: 0,
    },
    toolbar: {
        alignItems: 'center'
    },
    inPage: {
        paddingLeft: theme.sidebarWidth
    },
});


function MuiAppBar(props) {
    const { classes, children, bottom, position, className, inPage } = props;

    return (
        <React.Fragment>
            <AppBar 
                position={position} 
                className={classNames(classes.appBar, inPage && classes.inPage, {[classes.appBarBottom]: bottom}, className)}
            >
                <Toolbar className={classNames(classes.toolbar)}>
                    {children}
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

MuiAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MuiAppBar);