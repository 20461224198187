import React from 'react';
import { providing_nearest_form } from 'formprovider';
import with_resolve_focus from 'dash/resolvefocus';
import { with_focus } from 'dash/focusprovider';
import EditingCard from 'dash/editingcard';
import { WithToolbar } from 'shogun/shogunpromotebar';
import {ReactForm} from 'reactform';
import TuningTableWidget from './tuningtablewidget';
import {widget} from 'reactform/widget';
widget('TuningTable',TuningTableWidget);

class BaseTuningEditor extends React.Component {
    render() {
        const {focus} = this.props;
        const target = focus.get_focus().focus;
        return <React.Fragment>
            <EditingCard title={target.title} key={`tuning-editor`} collapsing={`tuning-editor`}>
                <ReactForm speculative_modification={true} target={target} use_dialog={false} />
            </EditingCard>
        </React.Fragment>;
    }
}
const TuningEditor = WithToolbar(providing_nearest_form(
    with_resolve_focus(
        with_focus(
            BaseTuningEditor
        )
    ),
    'shogunconf_forms'
));

export default TuningEditor;
export { BaseTuningEditor, TuningEditor };
