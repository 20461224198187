/* HOC saying that a given element needs a given storage

Usage:

    class BaseMyComponent extends React.Component {

    }
    const MyComponent = requiring_store(BaseMyComponent,'storage_key',create_my_storage);

*/
import React from 'react';
import { stores } from 'storeregistry';
import { FormStorage } from 'storages/basestorage';
import { providing_nearest_form } from 'formprovider';
import { shallow_compare } from 'signals';
import getDisplayName from 'react-display-name';

function requiring_store(Component, props) {
    /* Declare that a component requires a storage and create/register it if missing

    Component: base component
    props:
        storage_key: str:key in stores,
        create_function: function to call to create store if missing,
        forms_key: str:key in stores to use as default mutator form,
        forms_create_function: if specified, use to setup the form storage,
        prop_function: for each `change` message, is called and the result added to the props of child
    */
    var { storage_key, create_function, forms_key, forms_create_function, prop_function, change_check = null, ...child_props } = props;
    if (!Component) {
        console.error("Need a non-null component " + Component);
    }
    if (!storage_key || !storage_key.length) {
        console.error("storage key was not specified");
        storage_key = '';
    }
    if (!create_function) {
        create_function = () => { };
    }
    if (forms_key) {
        if (!forms_create_function) {
            forms_create_function = () => FormStorage({
                key: forms_key
            });
        }
        Component = providing_nearest_form(Component, forms_key);
    }
    class RequiringStore extends React.Component {
        static displayName = `requiring_store(${getDisplayName(Component)})`;
        static defaultProps = {
        };
        state = {
            storage: null,
            last_props: null,
        };
        onUpdate = (storage) => {
            // console.log(`Update on ${getDisplayName(Component)||Component}`);
            if (!this.wanted) {
                return;
            }
            const { last_props } = this.state;
            const new_props = (prop_function && prop_function({}, stores[storage_key])) || null;
            if ((!prop_function) || (
                !shallow_compare(
                    new_props,
                    last_props,
                    { storage }
                ))
            ) {
                if (storage.debug) {
                    console.log(`Change in storage: ${storage.url}`);
                }
                this.setState({ last_props: new_props });
            }
        };
        componentDidMount() {
            this.wanted = true;
            if (stores[storage_key] === undefined) {
                console.log("Creating storage " + storage_key);
                create_function();
                if (stores[storage_key] && stores[storage_key].change) {
                    stores[storage_key].change.listen(this.onUpdate);
                    if (stores[storage_key].debug) {
                        console.log(`Registered for updates on ${storage_key}`);
                    }
                    stores[storage_key].poll();
                }
            } else if (stores[storage_key] && stores[storage_key].change) {
                if (stores[storage_key].debug) {
                    console.log(`Registering for updates on storage ${storage_key} for ${getDisplayName(Component)}`);
                }
                stores[storage_key].change.listen(this.onUpdate);
                stores[storage_key].poll();
            } else {
                console.log(`Function ${create_function} did not create an observable storage ${storage_key} in stores.`);
            }
            if (forms_key) {
                if (stores[forms_key] === undefined) { // Only called if the main version didn't create it...
                    if (forms_create_function) {
                        // Note that we're here *forcing* the function to produce a form-record...
                        stores[forms_key] = forms_create_function();
                    }
                }
            }
            this.setState({ storage: stores[storage_key] });
        }
        componentWillUnmount() {
            this.wanted = false;
            if (this.state.storage) {
                this.state.storage.change.ignore(this.onUpdate);
            }
        }
        render() {
            var final_props = { ...child_props, ...this.props };
            if (!this.state.storage) {
                return null;
            }
            if (prop_function) {
                final_props = prop_function(final_props, stores[storage_key]);
            }

            return <Component {...final_props} />;
        }
    }
    return RequiringStore;
}

export { requiring_store };
