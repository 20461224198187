import React from 'react';
import { WithTabularStorage } from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import { ReactFormTable } from 'reactform';
import theme from 'dash/theme';
import Config from 'config';
class BaseResourceEditTable extends React.Component {
    static defaultProps = {
        form_details: null,
        node: null,
        include_node: false,
    }
    render() {
        const { form_details, node } = this.props;
        if (!(form_details)) {
            return <MuiLoader />;
        }
        const exclude = ['notes'];
        if (!this.props.include_node) {
            exclude.push('node');
        }
        return <ReactFormTable
            wrap_data={(record, table) => {
                return [record,];
            }}
            overall_target={{
                id: node,
                __type__: 'Node',
                __pk__: node,
            }}
            form_keys={['Resource']}
            exclude={exclude}
            field_actions={{
                'name': (target, context, menu) => {
                    return [
                        {
                            label: `Edit ${target.title}`,
                            icon: theme.icons.edit,
                            list_item_props: {
                                color: 'primary',
                            },
                            form_props: {
                                target: target,
                            },
                        },
                    ];
                },
            }}
            extra_fields={{
                'Resource': [
                    {
                        'label_id': 'allocations',
                        'label': 'Services',
                        'name': 'allocations',
                        'field': {
                            'help_text': 'Shows the services currently allocated to the resource',
                        },
                        // 'widget': (props) => {
                        //     if (props.value && props.value.length) {
                        //         var total = 0;
                        //         props.value.map(partial => {
                        //             total += (
                        //                 (partial && partial.slots && partial.slots.length) || 0
                        //             );
                        //         });
                        //         return total;
                        //     } else {
                        //         return '';
                        //     }
                        // }
                    },
                ]
            }}
            {...this.props}
        />;
    }
}

const node_specific_storage = (node) => {
    const tree_info = node.tree;
    const context = (tree_info && {
        node__tree_id: tree_info.tree_id,
        node__lft__gte: tree_info.lft,
        node__rght__lte: tree_info.rght,
    }) || {};
    return {
        default_context: context,
        forms_key: 'shogunconf_forms',
        form_key: 'ShogunResources',
        quick_settings: {
        },
    };
};

const ResourceEditTable = (props) => {
    /* Create a resource table with the node-specific resource query */
    return <WithTabularStorage
        key={`resources-for-node-${props.storage_props.default_context.node}`}
        storage_props={props.storage_props}
        Component={BaseResourceEditTable}
        preference_key={`node-resource-table`}
        preference_default={{
            hiddenColumns: [
                'disabled_slots',
                'capacity',
                'target_firmware',
                ...(
                    Config.world_is_node ? ['owner'] : []
                )
            ]
        }}
        {...props}
    />;
};
export default ResourceEditTable;
export { BaseResourceEditTable, ResourceEditTable, node_specific_storage };
