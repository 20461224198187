import React from 'react';
import { WithTabularStorage } from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import { ReactFormTable } from 'reactform';
import classNames from 'classnames';
import { with_focus_target } from 'dash/resolvefocus';
import { Link, withRouter } from 'react-router-dom';
import { PAGE_FOCUS } from 'storages';

import theme from 'dash/theme';
import Icon from '@material-ui/core/Icon';
import redirect from 'redirect';

class BaseGroupEditTable extends React.Component {
    on_view_group = ({ target, form, table }) => {
        if (this.props.onClick) {
            return this.props.onClick({ target, form, table });
        }
        let url = (
            target.__type__ == 'Node' && `/m/nodes/0/${target.id}`
        ) || `/m/groups/${target.id}`;

        PAGE_FOCUS.set_focus(target);
        redirect(url, this.props.history);
    }
    render() {
        const { form_details, group, target } = this.props;
        if (!(form_details)) {
            return <MuiLoader />;
        }

        return <ReactFormTable
            wrap_data={(record, table) => {
                return [record,];
            }}
            current_focus={target}
            link_function={this.on_view_group}
            column_widths={{
                'configure': 'tiny',
            }}
            extra_fields={{
                'Group': [
                    {
                        'name': 'descendants', 'label_id': 'id_descendants', 'label': 'Groups and Devices',
                        'widget': 'DisplayWidget',
                        'display_widget': 'ZeroNullInteger',
                        filterable: false,
                        'field': {
                            'help_text': 'Displays the number of Groups and Devices within this Group',
                        }
                    },
                    {
                        'name': 'configure',
                        'label_id': 'id_configure',
                        'label': 'Configure',
                        'widget': (props) => {
                            const { record, field, history } = props;
                            const target_url = `/m/groups/${record.__pk__}`;
                            return <Link
                                to={target_url}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    redirect(target_url, history);
                                }}
                            >
                                <Icon size="small" color="secondary">edit</Icon>
                            </Link>;
                        },
                        'filterable': false,
                        'searchable': false,
                        'field': {
                            'help_text': 'Click through to edit the metadata and Config Packages attached to the Group',
                        },
                    },
                ]
            }}
            field_actions={{
                'name': (target, context, menu) => {
                    return [
                        target.__type__ == 'Group' ? {
                            label: `Create Group in ${target.title}`,
                            icon: theme.icons.add,
                            permission: 'shogunconf.edit_content',
                            list_item_props: {
                                color: 'primary',
                            },
                            form_props: {
                                target: {
                                    ...target,
                                    __pk__: null,
                                    id: null,
                                    owner: target.owner.id,
                                },
                                context: {
                                    name: '',
                                    tags: '',
                                    notes: '',
                                    parent: target.__pk__,
                                    owner: target.owner.id,
                                },
                            },
                        } : null,
                        target.editable ? {
                            label: `Delete ${target.title}`,
                            icon: theme.icons.delete,
                            permission: 'shogunconf.edit_content',
                            list_item_props: {
                                title: `Delete ${target.title}`,
                                className: classNames('delete-intent'),
                                color: 'secondary',
                                disabled: (!target) || (target && target.editable === undefined) || (!target.editable),
                            },
                            form_props: {
                                title: `Delete ${target.title}, Move Children to ${target.parent || 'Orphan Cluster'}`,
                                context: {
                                    'delete-instance': true
                                },
                            },
                            onSave: () => {
                                if (window.location == target.__url__) {
                                    if (target.parent) {
                                        window.location = `/m/groups/0/${target.tree.parent_id}`;
                                    } else {
                                        window.location = '/m/groups/0/';
                                    }
                                }
                            },
                        } : null,
                    ].filter(x => x !== null);
                },
            }}
            form_details={form_details}
            overall_target={{
                id: group,
                __type__: 'Group',
                __pk__: group,
            }}
            exclude={[
            ]}
            {...this.props}
        />;
    }
}

const group_specific_storage = (group) => {
    /* Get a group-specific storage for given group *or* nodes/non-nodes */
    const context = (group !== undefined && group !== null) ? {
        children: group,
    } : {
        node__isnull: true,
    };
    return {
        default_context: context,
        forms_key: 'shogunconf_forms',
        form_key: 'Groups',

        quick_settings: {
        },
    };
};
const nodes_only_storage = () => {
    const context = {
        node__isnull: false,
    };
    return {
        default_context: context,
        forms_key: 'shogunconf_forms',
        form_key: 'Groups',

        quick_settings: {
        },
    };

};
const service_group_storage = (sg) => {
    const context = {
        service_groups__in: [sg.id || sg],
    };
    return {
        default_context: context,
        forms_key: 'shogunconf_forms',
        form_key: 'Groups',
        quick_settings: {
        },
    };

}

const WrappedBaseGroupEditTable = with_focus_target(BaseGroupEditTable);

const GroupEditTable = withRouter((props) => {
    return <WithTabularStorage
        key={`subgroups-for-group-${props.storage_props.default_context.group || 'all'}`}
        storage_props={props.storage_props}
        Component={WrappedBaseGroupEditTable}
        form_keys={['Group',]}
        group={props.storage_props.default_context.group}
        exclude={['notes',]}
        hierarchy_fields={{
            parent: 'parent',
            name: 'name',
        }}
        preference_key='group-edit-table'
        {...props}
    />;
});
export default GroupEditTable;
export { service_group_storage, nodes_only_storage, group_specific_storage, BaseGroupEditTable, GroupEditTable };
