import React from 'react';
import { firmware_storage, FirmwareTable } from 'shogun/firmwaretable';
import { providing_nearest_form } from 'formprovider';
import { EditIntent } from 'editintent';
import EditingCard from 'dash/editingcard';
import NeedingDeploy from './needingdeploy';
import { useCurrentUser } from 'withcurrentuser';
import { services_storage, ServicesTable } from './servicetable';

const BaseFirmwareView = (props) => {
    const { storage_props } = props;
    const user = useCurrentUser();
    const [uploadCount, setUploadCount] = React.useState(0);
    const services_actions = [
        user && user.has_permission('shogunconf.deploy') && <EditIntent
            key="add-firmware"
            target={{
                'type': 'FirmwareDeploy',
                'id': null,
            }}
            context={{
                'enabled': true,
                'service_type_key': 'firmdep',
            }}
            action='Create'
            label='Firmware Targets'
            help_text='Configures the target firmware for each device type within a group'
        />,
    ].filter(x => !!x);
    const firmware_actions = [
        user && user.has_permission('shogunconf.edit_firmware') && <EditIntent
            key={`upload-firmware-${uploadCount}`}
            label='Upload Firmware'
            target={{ type: 'Firmware' }}
            onSave={
                (result) => setUploadCount(uploadCount + 1)
            }
        />,
    ].filter(x => !!x);
    return <React.Fragment>
        {/* <EditingCard title="Needing Updates" no_wrapper={true} collapsing={`firmware-needing-updates-collapse`}>
            <NeedingDeploy />
        </EditingCard> */}
        {/* <EditingCard title="Firmware Requirement Configs" no_wrapper={true} actions={services_actions} collapsing={`firmware-requirements-collapse`}>
            <ServicesTable storage_props={{
                default_context: {
                    service_type_key: 'firmdep',
                },
                forms_key: 'shogunconf_forms',
                form_key: 'ShogunServices',
                // editing_forms: ['ServiceDisplay'],
                form_keys: ['ServiceDisplay'],

                quick_settings: {
                },
            }} exclude={['notes']} />
        </EditingCard> */}
        <EditingCard title="Uploaded Firmware" no_wrapper={true} actions={firmware_actions} collapsing={`firmware-uploaded-collapse`}>
            <FirmwareTable
                key="firmware-table"
                storage_props={storage_props}
            />
        </EditingCard>
    </React.Fragment>;

};

/*
 * This wrapper will hook the above class to the forms, similar to how Redux is
 * hooked up.
 *
 * The right hand side refers to the reactforms registered in shoguconf/urls.py
 *
 */
//const FirmwareView = ViewComponent;
const FirmwareView = providing_nearest_form(
    BaseFirmwareView, 'shogunconf_forms'
);

export default FirmwareView;
export { FirmwareView };
