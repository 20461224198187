import React from 'react';
import Config from 'config';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import getDisplayName from 'react-display-name';

const styles = (theme) => ({
    appContent: {
        marginLeft: theme.sidebarWidth,
        width: `calc(100% - ${theme.sidebarWidth}px)`,
        padding: theme.spacing(3)
    }
});

class ErrorBoundary extends React.Component {
    /* HOC that catches errors in a rendered component */
    static defaultProps = {
        Component: null,
        errorClass: null,
    }
    state = {
        error: null,
        errorInfo: null,
    };
    componentDidCatch(error, info) {
        this.setState({
            error: error,
            errorInfo: info
        });
    }
    render() {
        const { Component, errorClass = null, ...childProps } = this.props;
        if (this.state.errorInfo) {
            return <div className={classNames("error-wrapper", errorClass)}>
                <h2 className="error">GUI Error</h2>
                <p>
                    We encountered an error on this page,
                    as a result we can't continue displaying the
                    view. Please report this to <a href={Config.support_url}>{Config.company_short_name} Support</a> along
                    with the page URL, and any details about what
                    you were trying to do when the error occurred.
                </p>
                <details style={{ whiteSpace: 'pre-wrap' }}>
                    {this.state.error && this.state.error.toString()}
                    <br />
                    {this.state.errorInfo.componentStack}
                </details>
            </div>;
        }
        return <Component {...childProps} />;
    }
}


const with_error_boundary = (Component, errorClass = null) => {
    // console.log(`Error boundary class ${errorClass} for ${getDisplayName(Component)}`)
    const catching = withStyles(styles)((props) => {
        const { classes, ...restProps } = props;
        const finalErrorClass = props.classes[errorClass];
        return <ErrorBoundary Component={Component} {...restProps} errorClass={finalErrorClass} />;
    });
    return catching;
};
export default with_error_boundary;
export { with_error_boundary, ErrorBoundary };