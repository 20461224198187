import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'start',
    },
    left: {
        display: 'flex',
        justifyContent: 'left',
        alignContent: 'start',
    },
    right: {
        display: 'flex',
        justifyContent: 'right',
        alignContent: 'start',
    },
});
const AlignDiv = React.forwardRef((props,ref) => {
    const {classes,alignment,onClick,title,label,...childProps} = props;
    return <div 
        // ref={ref}
        {...childProps}
        onClick={onClick}
        key='centerer'
        title={`${title||label||''}`}
        className={classNames(classes[alignment||'center'],props.className)}
    >{props.children}</div>;
});
AlignDiv.displayName = 'AlignDiv';

export default withStyles(styles)(AlignDiv);