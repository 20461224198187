import React from 'react';
import {requiring_store } from 'storages/requiringstore';
import {default_tacacs_cb} from './tacacsstorage';
import {ReactForm, default_save_button} from 'reactform';
import MuiTypography from 'dash/MuiTypography';

class BaseTacacsConfig extends React.Component {
    static defaultProps = {
        'config': null,
        'storage': null,
    }
    render() {
        if (this.props.config) {
            return <div className="tacacs-group">
                <ReactForm 
                    target={this.props.config} 
                    form_key={'TacacsConfig'}
                    use_dialog={false}
                />
                {this.props.config.id && <ReactForm 
                    target={this.props.config} 
                    form_key={'TacacsVerifyUser'}
                    use_dialog={false}
                    buttons={[
                        (form) => default_save_button(form,{
                            busy_message:'Checking Credentials',
                            action_message: 'Check',
                            icon:'signal',
                        }),
                    ]}
                    default_messages={{
                        'errors': 'Validation Failed',
                        'success': 'Validation Succeeded',
                    }}
                />}
            </div>;
        } else {
            return <MuiTypography>Loading</MuiTypography>;
        }
    }
}
class BaseTacacsSummary extends React.Component {
    static defaultProps = {
        'config': null,
        'storage':null,
    }
    render() {
        if (! this.props.config) {
            return `Loading...`;
        }
        if (!this.props.config.enabled) {
            return `Disabled`;
        } else {
            let {count} = this.props.config;
            return `Enabled, ${count} users`;
        }
    }
}
function with_tacacs_store(Component) {
    return requiring_store(
        Component,
        {
            storage_key: 'tacacs_config',
            create_function: default_tacacs_cb,
            forms_key: 'tacacs_forms',
            prop_function: (props, store) => {
                if (!store) {
                    return {...props,config:{
                        id: null,
                        type:'TacacsConfig'
                    }};
                }
                let {config} = store;
                return {...props, config};
            }
        }
    );
}

const TacacsConfig = with_tacacs_store(BaseTacacsConfig);
const TacacsSummary = with_tacacs_store(BaseTacacsSummary);
export default TacacsConfig;
export {BaseTacacsConfig, TacacsSummary,TacacsConfig};
