import React from 'react';
import { firmware_storage, FirmwareTable } from 'shogun/firmwaretable';
import { FirmwareView } from 'shogun/firmwareview';
import { ResolveTarget } from 'dash/resolvetarget';
import { group_link_url } from './helpers';

const firmwares_routes = ({ PREFIX }) => ([
    {
        'path': `${PREFIX}firmwares/:group(\\d+)?`,
        'link_url': group_link_url(PREFIX),
        'header': 'Firmwares',
        'title': 'Firmware Management',
        'component': FirmwareView,
        'renderer': props => {
            const storage_props = firmware_storage(null);

            const base = <FirmwareView storage_props={storage_props} {...props} />
            if (props.match.params.group) {
                const group = parseInt(props.match.params.group);
                return <ResolveTarget key={`group-config-${group}`} as_focus={true} storage='shogunconf_forms' __type__='Group' __pk__={group}>
                    {base}
                </ResolveTarget>;
            } else {
                return base;
            }
        },
    },

]);

export default firmwares_routes;