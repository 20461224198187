/* Zoomable Modal Dialog pattern */
import React from 'react';
import Modal from '@material-ui/core/Modal';

const ZoomableDialog = (props) => {
    const {small,large,initialZoom=false} = props;
    const [zoom,setZoom] = React.useState(initialZoom);
    const ZoomComponent = large;
    ZoomComponent.displayName = large.displayName || 'ZoomableDialog.ZoomCompoent';
    return <div className='zoom-dialog'>
        <div key='small' onClick={(evt) => setZoom(!zoom)}>{small}</div>
        {
            zoom &&
            <Modal
                key='large'
                open={zoom}
                onClose={(evt) => setZoom(false)}
                onEscapeKeyDown={(evt) => setZoom(false)}
                disableAutoFocus
                // disableEnforceFocus
            >{<ZoomComponent />}</Modal>
        }
    </div>;
};
export default ZoomableDialog;
