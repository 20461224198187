import React from 'react';

import {fa_icon} from 'faicon';
import getIcon from 'getIcon';

class LinkStatus extends React.Component {
    static defaultProps = {
        current: null
    };
    render() {
        var current = this.props.current;
        if (!(current && current.link)) {
            // return fa_icon('chain-broken error','Link is down');
            return getIcon('phoneLinkOff', 'Link is down', 'error');
        } else {
            // return fa_icon('check','Link is up');
            return getIcon('check', 'Link is up');
        }
    }
}

export default LinkStatus;