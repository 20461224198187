import React, { forwardRef, isValidElement } from 'react';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Icon from '@material-ui/core/Icon';
import MuiIcon from 'dash/MuiIcon';
import MuiTypography from 'dash/MuiTypography';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { withStyles } from '@material-ui/core/styles';
import WithUserPrefs from 'dash/userprefs';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import with_error_boundary from 'errorboundary';

const expanded_key = (collapsing) => `gui.editingcard.${collapsing}.expanded`;


const styles = theme => ({
    header: {
        backgroundColor: theme.roles.headers.card,
        fontSize: theme.white_cards ? 14 : 10,
        zIndex: 4,
        boxShadow: theme.white_cards ? 'disable' : theme.shadows[4],
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        borderBottom: theme.white_cards ? 'solid 1px #00000020' : 'none',
    },
    headerContent: {
        fontWeight: theme.white_cards ? '400' : '400',
        fontSize: theme.white_cards ? '1.5rem' : '1rem',
    },
    subheaderContent: {
        fontWeight: '200',
        fontSize: theme.white_cards ? '1rem' : '.75rem',
    },
    actions: {
        backgroundColor: theme.roles.footers.card,
        zIndex: 4,
    },
    spacedCard: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        '&:first-of-type': {
            marginTop: 0,
        },
        '&:last-of-type': {
            marginBottom: 0,
        },
    },
    tightCard: {
        marginBottom: 1,
        marginTop: 1,
        borderRadius: 0,
        '&:first-of-type': {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
        },
        '&:last-of-type': {
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
        },
    },
    hidden: {
        display: 'none',
    },
    shown: {
    },
});

const CatchingCard = with_error_boundary(Card);

const BaseEditingCard = withStyles(styles)((props) => {
    const {
        classes,
        no_wrapper,
        actions,
        icon,
        icon_props,
        collapsing,
        preferences,
    } = props;
    const default_expanded = props.default_expanded === undefined ? true : props.default_expanded;

    const icon_instance = icon && <Icon {...icon_props}>{icon}</Icon>;
    const Wrapper = no_wrapper ? 'div' : CardContent;
    var action_set = null;
    if (Array.isArray(actions)) {
        if (actions.length == 1 && isValidElement(actions[0])) {
            action_set = <CardActions className={classes.actions}>{actions[0]}</CardActions>;
        } else if (actions && actions.filter(x => x).length) {
            action_set = <CardActions className={classes.actions}>{actions}</CardActions>;
        }
    } else if (actions) {
        action_set = actions;
    }
    const base_title = <div aria-level="3" aria-role="heading" key='main-card-title' className={classes.headerContent} >{props.title}</div>;

    if (collapsing) {
        const final_key = expanded_key(collapsing);
        const specific = preferences.get_key(final_key);
        let expanded = default_expanded;
        if (specific !== null && specific !== undefined) {
            expanded = specific;
        }
        // console.log(`Editing card: ${final_key} default=${default_expanded} final=${expanded}`);

        const on_change = (evt) => {
            console.log(`Updating pref ${final_key} to ${!expanded}`);
            preferences.set_key(final_key, !expanded);
            return false;
        };
        const title = (
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    {base_title}
                </Grid>
                {
                    props.summary && !expanded &&
                    <Grid item xs={4} className={classes.subheaderContent}>
                        {props.summary}
                    </Grid>
                }
            </Grid>
        );
        // console.warn(`Editing Card ${final_key} expanded=${expanded}`);
        return <CatchingCard className={classNames(expanded ? classes.spacedCard : classes.tightCard)} key='editing-card'>
            <CardHeader
                avatar={icon_instance}
                className={classes.header}
                disableTypography={true}
                title={title}
                onClick={on_change}
                action={expanded ? <MuiIcon name="expand_less" /> : <MuiIcon name="expand_more" />}
                classes={{ content: classes.headerContent }}
            />
            {expanded &&
                <div className={expanded ? classes.shown : classes.hidden} key='expansion-hider'>
                    <Wrapper key="card-content" children={props.children}>
                    </Wrapper>
                    {action_set}
                </div>
            }
        </CatchingCard>;
    } else {
        return <CatchingCard className={classes.spacedCard} key='editing-card'>
            <CardHeader
                avatar={icon_instance}
                title={base_title}
                className={classes.header}
                disableTypography={true}
                classes={{ content: classes.headerContent }}
            />
            <Wrapper className={classes.shown} key="card-content">
                {props.children}
            </Wrapper>
            {action_set}
        </CatchingCard>;
    }
});

const EditingCard = (props, ref) => {
    const { collapsing } = props;
    var preference_keys = props.preference_keys || [];
    if (collapsing) {
        preference_keys = [...preference_keys, expanded_key(collapsing)];
    }
    const { classes, ...final_props } = props;
    return <WithUserPrefs Component={BaseEditingCard} {...final_props} preference_keys={preference_keys} ref={ref} />;
};

export default forwardRef(EditingCard);
