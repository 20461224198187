import React from 'react';
import ReactDOM from 'react-dom';
import Switch from '@material-ui/core/Switch';
import { ClassyWidget } from 'reactform/classywidget';
import WidgetRender from './custom_widget_render';
import { withStyles } from '@material-ui/core/styles';
import RenderBoolean from './renderboolean';

const styles = theme => ({
});

class CheckboxWidget extends ClassyWidget {
    state = {
        clicking: false,
    }
    // handleMouseDown = (evt) => {
    //     console.log(`Mouse down`);
    //     this.setState({
    //         'clicking':true,
    //     });
    // }
    // handleMouseUp = (evt) => {
    //     console.log(`Mouse up`);
    //     if (this.props.onClick) {
    //         this.props.onClick(evt);
    //     }
    // }
    handleFocus = (evt) => {
        console.log(`Focus`);
        if (this.props.onFocus) {
            this.props.onFocus(evt);
        }
    }

    handleClick = () => {
        const current = this.current_value();
        this.handleChange(!current, true);
    }
    handleKeyUp = (evt) => {
        if (evt.keyCode == 32 || evt.keyCode == 13) { // space or return
            evt.preventDefault();
            evt.stopPropagation();
            const current = this.current_value();
            this.handleChange(!current, true);
            return false;
        }
        return true;
    }
    componentDidMount() {
        const { autoFocus, initialClick } = this.props;
        if (autoFocus) {
            if (this.controlRef.current) {
                ReactDOM.findDOMNode(this.controlRef.current).focus();
            }
        }
        if (initialClick) {
            this.handleClick();
        }
    }
    render() {
        const { classes, show_label } = this.props;
        const field = this.get_field();
        const current = this.current_value();

        return <WidgetRender form_field={field} widget_class="checkbox-widget" show_label={show_label}>
            <RenderBoolean
                ref={this.controlRef}
                value={current}
                record={null}
                field={field}
                tabIndex={0}
                onKeyUp={this.handleKeyUp}
                onFocus={this.handleFocus}
                onClick={this.handleClick}
            />
        </WidgetRender>;
    }
}

export default withStyles(styles)(CheckboxWidget);
