/* widgets for viewing EncodingProfile values */
import React from 'react';
import "./reactdialog.css";
import RenderReactDialog from 'dash/renderreactdialog';

var popup = function (props) {
    props.rootClassName = (props.rootClassName || '') + ' pop-up';
    return <ReactDialog {...props} />;
};

class ReactDialogLayout extends React.Component {
    static defaultProps = {
        'title': null,
        'key': null,
        'controls': null,
        'className': null,
        'rootClassName': null
    };
    render() {
        return <div className="react-dialog-layout">
            {this.props.title ? <div className="react-dialog-title" key="react-dialog-title">
                {this.props.title}
            </div> : null}
            <div className="react-dialog-body" key="react-dialog-body">
                {React.Children.toArray(this.props.children)}
            </div>
            {this.props.controls ? <div className="react-dialog-controls" key="react-dialog-controls">
                {this.props.controls}
            </div> : null}
        </div>;
    }
}

const ReactDialog = (props) => {
    return <RenderReactDialog {...props} />;
};


export {
    ReactDialog,
    ReactDialogLayout,
    popup
};
export default ReactDialog;
