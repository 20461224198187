import apply_accessor from './accessor';

function order_by_keys(order, objects, accessor) {
    /* Order the objects in objects by order of keys in order 
    
    order -- set of (unique) keys by which to order
    objects -- set of objects to order by apply_accessor(object, accessor)
    accessor -- accessor definition for the __key__ in objects
    
    returns objects in __key__-order
    */
    const obj_map = {};
    objects.map( (obj) => {
        const __key__ = apply_accessor(obj,accessor);
        if (!(typeof __key__ === 'string' || typeof __key__ === 'number' )) {
            console.warn(`Accessor ${JSON.stringify(accessor)} produced a non-string __key__ for sorting on ${JSON.stringify(obj)}`);
        }
        obj_map[__key__] = obj;
    });
    const result = [];
    order.map((k,index) => {
        if (obj_map[k] !== undefined) {
            // console.log(`Order ${k} adding`);
            result[index] = obj_map[k];
            delete obj_map[k];
        }
    });
    Object.keys(obj_map).map( (k) => {
        console.info(`Key not specifically ordered: ${k}`);
        result.push(obj_map[k]);
    });
    return result.filter((obj) => (obj !== undefined && obj != null));
}

export default order_by_keys;