/* Provides Dashboard Layout for page *unless* it is suppressed */
import React from 'react';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppTabBar from './AppTabBar';
import AppContent from './AppContent';
import with_error_boundary from 'errorboundary';
import { makeStyles } from '@material-ui/core/styles';

const CheckedAppHeader = with_error_boundary(AppHeader);
const CheckedAppSidebar = with_error_boundary(AppSidebar);
const CheckedAppTabs = with_error_boundary(AppTabBar);
const CheckedAppContent = with_error_boundary(AppContent, 'appContent');

const useStyles = makeStyles({
    stacked: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    sideways: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
    },
});

const DashLayout = (props) => {
    const { no_sidebar, top_bar, routes, base_url, children } = props;
    const classes = useStyles();
    let sidebar = null;
    if (!no_sidebar) {
        if (top_bar) {
            sidebar = <CheckedAppTabs routes={routes} key="tabs" />;
        } else {
            sidebar = <CheckedAppSidebar routes={routes} key="sidebar" />;
        }
    }
    return <React.Fragment>
        <CheckedAppHeader routes={routes} base_url={base_url} key="header" />
        <div className={top_bar ? classes.stacked : classes.sideways}>
            {sidebar}
            <CheckedAppContent key="content">
                {children}
            </CheckedAppContent>
        </div>
    </React.Fragment>;
};

export default DashLayout;