/* Cached local group hierarchy for faster lookups */
import React from 'react';
import { stores } from 'storeregistry';
import { TabularStorage } from './storages';
import { tabular_storage_props } from './storages/tabularstorage';

const GROUP_STORAGE_KEY = 'group-hierarchy-storage';
const GroupStorageContext = React.createContext();


class GroupStorageProvider extends React.Component {
    state = {
        GroupTree: [],
    }
    componentDidMount = () => {
        const storage = stores[GROUP_STORAGE_KEY] || TabularStorage(tabular_storage_props({
            forms_key: 'shogunconf_forms',
            form_key: 'GroupTree',
            key: GROUP_STORAGE_KEY,
            period: 60,
        }));
        storage.change.listen(this.on_groups_changed);
        storage.poll();
        this.setState({ storage });
    }
    componentWillUnmount = () => {
        this.state.storage.change.ignore(this.on_groups_changed);
    }
    on_groups_changed = (storage) => {
        this.setState({ 'GroupTree': storage.GroupTree });
    }
    render() {
        return <GroupStorageContext.Provider value={this.state.GroupTree}>
            {this.props.children}
        </GroupStorageContext.Provider>;
    }
}
const tree_child = (group_tree, tree) => {
    if (tree.tree_id == group_tree.tree_id) {
        if (group_tree.lft <= tree.lft) {
            if (group_tree.rght >= tree.rght) {
                return true;
            }
        }
    }
    return false;
};

const children = (group, groupTree) => {
    /* Find all children of group in in groupTree */
    const group_tree = group.tree;
    if (group.__pk__ == 0) {
        /* Root has everything */
        return [
            group,
            ...(groupTree || []),
        ];
    }
    return groupTree.filter((child) => tree_child(group_tree, child.tree));
}


export default GroupStorageContext;
export { GroupStorageContext, GroupStorageProvider, children, tree_child };
