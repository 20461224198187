import React from 'react';
import { render_value } from 'reactform/typerenderer';
import ActionMenu from 'reactform/actionmenu';
import FilterList from '@material-ui/icons/FilterList';
import { Close } from '@material-ui/icons';

const CellFilterGUI = (props) => {
    const { field, record, base, value, table } = props;
    const display_value = (record) => {
        return render_value(record[field.name], record, field.name, { field: field }, false);
    };
    const filter_value = (value) => {
        if (typeof value == 'boolean' || typeof value == 'number') {
            return value;
        } else if (value && (value.__pk__ || value.id)) {
            return value.__pk__ || value.id;
        } else {
            return value;
        }
    };
    const filter_field_name = (field) => {
        if (field.name == 'resource_id') {
            return 'id';
        } else {
            return field.name;
        }
    };
    return <ActionMenu
        target={record}
        position='hover'
        Icon={<FilterList />}
        actions={(target, context) => [
            {
                label: <React.Fragment>{`Show ${field.label} =`}{' '}{display_value(target)}</React.Fragment>,
                icon: <FilterList />,
                list_item_props: {
                    title: `Filter to Value`,
                    color: 'secondary',
                },
                callback: (action, target, context, menu) => {
                    console.log(`Cell filter gui callback ${target} for ${field.name}`);
                    window.setTimeout(() => table.props.set_filter(filter_field_name(field), filter_value(value)), 5);
                },
            },
            {
                label: <React.Fragment>{`Hide ${field.label} =`}{' '}{display_value(target)}</React.Fragment>,
                icon: <Close />,
                list_item_props: {
                    title: `Exclude Value`,
                    color: 'secondary',
                },
                callback: (action, target, context, menu) => {
                    console.log(`Cell filter gui callback ${target} for ${field.name}`);
                    if (typeof value == 'boolean') {
                        window.setTimeout(() => table.props.set_filter(filter_field_name(field), !filter_value(value)));
                    } else {
                        window.setTimeout(() => table.props.set_filter(`${filter_field_name(field)}__ne`, filter_value(value)));
                    }
                },
            },
        ]}
        key='filters'
    >{base}</ActionMenu>;
};

export default CellFilterGUI;