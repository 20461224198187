/* Storage for state of metric filters */
import { DEFAULT_LOCALSTORAGE } from './localstoragebacking';
import { Signal } from 'signals';

const as_mapping = (items, prefix) => {
    const mapping = {};
    items.map(r => mapping[r[0].slice(prefix.length)] = r[1]);
    return mapping;
};


class MetricFilterStorage {
    constructor(props = null) {
        Object.assign(this, props || {});
        this.change = new Signal();
    }
    loading_key = (metric) => {
        /* Have we loaded defaults for this metric? */
        return `filters:.loaded:${metric}`;
    }
    metric_prefix = (metric) => {
        return `filters:${metric}:`;
    }
    items = (metric) => {
        return this.backing().filter(this.metric_prefix(metric));
    }
    current = (metric, defaults) => {
        /* Get current values as a simple mapping */
        const l_key = this.loading_key(metric);
        if (!this.backing().get(l_key)) {
            this.load(metric, defaults);
        }

        const prefix = this.metric_prefix(metric);
        return as_mapping(this.items(metric), prefix);
    }
    get = (metric, key, missing = undefined) => {
        const final_key = `${this.metric_prefix(metric)}${key}`;
        const base = this.backing().getItem(final_key);
        if (base === undefined) {
            return missing;
        }
        return base;
    }
    COMPARISONS = [
        'gt',
        'lt',
        'gte',
        'lte',
        'eq',
        'ne',
        'contains',
        'does_not_contain',
        'isnull',
        'in',
        'not_in',
    ];
    base_key = (key) => {
        let result = key;
        this.COMPARISONS.map(suffix => {
            const test = `__${suffix}`;
            if (result.endsWith(test)) {
                result = result.slice(0, result.length - test.length);
            }
        });
        return result;
    }
    load = (metric, defaults) => {
        /* Load default set of filters into the metric namespace */
        const l_key = this.loading_key(metric);
        console.log(`Loading default settings for ${metric}`);
        this.backing().set(l_key, true);
        const prefix = this.metric_prefix(metric);
        Object.keys(defaults).map(k => this.backing().set(`${prefix}${k}`, defaults[k]));
    }
    clear = (metric, defaults = {}) => {
        /* Clear current settings for the metric and load defaults */
        this.backing().filter(`${this.metric_prefix(metric)}`).map(
            record => {
                this.backing().set(record[0], defaults[record[0]] || undefined);
            }
        );
        this.load(metric, defaults);
        this.change.send({
            metric: metric,
            filters: this.current(metric),
        });
    }
    unset = (metric, base_key) => {
        /* Unset all comparison-suffixed keys for this base key 
        
        sets all dependent keys, so all of the __ne and the like
        get cleared...
        */
        this.COMPARISONS.map(suffix => {
            this.backing().set(`${base_key}__${suffix}`, undefined);
        });
    }
    set = (metric, key, value) => {
        this.unset(metric, this.base_key(key));
        const prefix = this.metric_prefix(metric);
        const final_key = `${prefix}${key}`;
        this.backing().set(final_key, value);
        this.change.send({
            metric: metric,
            filters: this.current(metric),
        });
    }
}


const METRIC_FILTER_STORAGE = new MetricFilterStorage({
    backing: DEFAULT_LOCALSTORAGE,
});

export default METRIC_FILTER_STORAGE;