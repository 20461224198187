import React from 'react';
import { WithTabularStorage } from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import { ReactFormTable } from 'reactform';
import { ReactForm } from 'reactform';
import { with_focus } from 'dash/focusprovider';
import { delete_target_action } from 'reactform/actionmenu';
import { CellRender } from 'reactform/typerenderer';

class BaseURISourceTable extends React.Component {
    render() {
        const { form_details, mapping, storage } = this.props;
        if (!(form_details)) {
            return <MuiLoader />;
        }
        return <ReactFormTable
            // link_function={this.on_view_group.bind(this)}
            form_keys={this.props.editing_forms}
            form_details={form_details}
            editable={true}
            overall_target={mapping}
            // onForm={this.on_edit_map}
            field_actions={{
                // 'source_channel_number': (target, context, menu) => {
                //     return [
                //         delete_target_action(target, context, menu),
                //     ];
                // }
            }}
            widgets={{
                'link': (props) => {
                    const { Header, ...childProps } = props;
                    const sinks = props.record.sinks;
                    return <CellRender {...childProps} value={sinks} />;
                },
            }}
            // inPlaceEditing={true}
            column_widths={{
                number: 'small',
                name: 'short',
            }}
            {...this.props}
            exclude={[
                'mapping',
                'notes',
                'link',
                ...(this.props.exclude || []),
            ]}
        />;
    }
}

const mapping_specific_storage = (mapping) => {
    /* Get a group-specific storage for given group *or* nodes/non-nodes */
    const context = {};
    if (mapping) {
        context.mapping = mapping.__pk__ || mapping;
    }
    return {
        default_context: context,
        forms_key: 'shogunconf_forms',
        form_key: 'ShogunURISources',
        quick_settings: {
        },
    };
};


const URISourceTable = (props) => {
    const final_props = { ...props };
    if (!props.storage_props) {
        final_props.mapping = {
            __pk__: props.__pk__,
            __type__: 'URIMapping'
        };
        final_props.storage_props = mapping_specific_storage(props.__pk__);
    }
    return <WithTabularStorage
        key={`urisources-for-${final_props.mapping.id}`}
        storage_props={final_props.storage_props}
        Component={with_focus(BaseURISourceTable)}
        editing_forms={['URISource']}
        exclude={[]}
        {...final_props}
    />;
};
export default URISourceTable;
export { mapping_specific_storage, BaseURISourceTable, URISourceTable };
