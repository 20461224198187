import React from 'react';

import TextField from '@material-ui/core/TextField';

import { ClassyWidget } from 'reactform/classywidget';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
    'overall': {
        marginTop: theme.spacing(2),
    },
});

class BaseTextAreaWidget extends ClassyWidget {
    render() {
        const { show_label, classes } = this.props;
        const field = this.get_field();
        const base_field = field.field;
        let render_value = this.render_value() || '';
        if (Array.isArray(render_value) || typeof render_value == 'object') {
            render_value = JSON.stringify(this.render_value, null, 2);
        }

        
        return <div className={classes.overall}>
            <TextField
                type="text"
                ref={this.controlRef}
                multiline
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    style: {
                        height: '8rem',
                        overflow: 'auto',
                    }
                }}
                value={render_value}
                label={show_label && field.label}
                id={base_field.label_id}
                autoFocus={this.props.autoFocus}
                name={field.name}
                variant="outlined"
                classes={{}}
                onChange={event => this.handleChange(event.target.value)}
            />
        </div>;
    }
}

const TextAreaWidget = withStyles(styles)(BaseTextAreaWidget);
export {
    BaseTextAreaWidget,
    TextAreaWidget,
};
export default TextAreaWidget;
