import React from 'react';

import MuiTypography from 'dash/MuiTypography';
import { requiring_system_store } from 'systemstorage';
import Config from 'config';

class SoftwareEndUserLicenseAgreement extends React.Component {
    render() {
        const { system = {} } = this.props;

        if (!(system && system.sku)) {
            return <div>System metadata loading</div>;
        }

        return (
            <div>
                <MuiTypography gutterBottom className="western">
                    <b>Redistribution or Rental Not Permitted</b>
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    These Terms apply to the {system.sku.sku_name} Software (the
                    “<i>
                        <b>Product</b>
                    </i>”).
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    BY CLICKING THE ACCEPTANCE BUTTON OR INSTALLING OR USING THE
                    PRODUCT THE INDIVIDUAL OR ENTITY LICENSING THE PRODUCT (“<i>
                        <b>LICENSEE</b>
                    </i>”) IS CONSENTING TO BE BOUND BY AND IS BECOMING A PARTY
                    TO THIS AGREEMENT. IF LICENSEE DOES NOT AGREE TO ALL OF THE
                    TERMS OF THIS AGREEMENT, THE BUTTON INDICATING
                    NON-ACCEPTANCE MUST BE SELECTED, AND LICENSEE MUST NOT
                    INSTALL OR USE THE SOFTWARE.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    <b>1. Licence Agreement</b>
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    In this Agreement “<i>
                        <b>Licensor</b>
                    </i>” shall mean { Config.company_legal_name } except under the
                    following circumstances: (a) if Licensee acquired the
                    Product as a bundled component of a third party product or
                    service, then such third party shall be Licensor; and (b) if
                    any third party software is included as part of the default
                    installation and no licence is presented for acceptance the
                    first time that third party software is invoked, then the
                    use of that third party software shall be governed by this
                    Agreement, but the term “<i>
                        <b>Licensor</b>
                    </i>,” with respect to such third party software, shall mean
                    the manufacturer of that software and not the Licensor. With
                    the exception of the situation described in (b) above, the
                    use of any included third party software product shall be
                    governed by the third party’s licence agreement and not by
                    this Agreement, whether that licence agreement is presented
                    for acceptance the first time that the third party software
                    is invoked, is included in a file in electronic form, or is
                    included in the package in printed form. If more than one
                    licence agreement was provided for the Product, and the
                    terms vary, the order of precedence of those licence
                    agreements is as follows; a signed license agreement, a
                    licence agreement available for review on the Licensor
                    website, a printed or electronic agreement that states
                    clearly that it supersedes other agreements, a printed
                    agreement provided with the Product, and an electronic
                    agreement provided with the Product.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    <b>2. Licensee Grant</b>
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    Licensor grants Licensee a non-exclusive and
                    non-transferable licence to use the Product for its intended
                    purpose. This licence does not entitle Licensee to receive
                    from the Licensor hard-copy documentation, technical
                    support, telephone assistance or enhancements or updates to
                    the Product. Licensee may not redistribute the Product
                    unless Licensee has separately entered into a distribution
                    agreement with the Licensor.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    <b>3. Restrictions</b>
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    Except as otherwise expressly permitted in this Agreement,
                    Licensee may not: (a) modify or create any derivative works
                    of the Product or documentation, including translation or
                    localization; (b) decompile, disassemble, reverse engineer,
                    or otherwise attempt to derive the source code for the
                    Product (except to the extent applicable laws specifically
                    prohibit such restriction); (c) redistribute, encumber,
                    sell, rent, lease, sublicense or otherwise transfer rights
                    to the Product; (d) remove or alter any trade mark, logo,
                    copyright or other proprietary notices, legends, symbols or
                    labels in the Product; or (e) publish any results of
                    benchmark tests run on the Product to a third party without
                    the Licensor’s prior written consent.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    <b>4. Fees</b>
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    There is no licence fee for the Product. If Licensee wishes
                    to receive the Product on media, there may be a small charge
                    for the media and for shipping and handling. Licensee is
                    responsible for any and all taxes.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    <b>5. Termination</b>
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    Without prejudice to any other rights, Licensor may
                    terminate this Agreement if Licensee breaches any of its
                    terms and conditions. On termination, Licensee shall
                    immediately discontinue any use of the Product and shall
                    destroy all copies of the Product.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    <b>6. Proprietary Rights</b>
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    All intellectual property rights in the Product shall remain
                    with the Licensor and/or its suppliers. Licensee
                    acknowledges such ownership of the intellectual property
                    rights and will not take any action to jeopardize, limit or
                    interfere in any manner with the Licensor’s or its
                    suppliers’ ownership of intellectual property rights with
                    respect to the Product. The Product is protected by
                    copyright and other intellectual property laws and by
                    international treaties. Title and related rights in the
                    content accessed through the Product is the property of the
                    applicable content owner and is protected by applicable law.
                    The licence granted under this Agreement gives Licensee no
                    rights to such content.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    <b>7. Disclaimer of Warranty</b>
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    USE OF THE PRODUCT IS AT THE SOLE RISK OF THE LICENSEE. THE
                    PRODUCT IS PROVIDED, ON AN “AS IS” BASIS, WITHOUT WARRANTY
                    OR CONDITION OF ANY KIND, EITHER EXPRESS, IMPLIED OR
                    STATUTORY, INCLUDING WITHOUT LIMITATION THE WARRANTIES THAT
                    IT IS FREE OF DEFECTS, MERCHANTABLE, FIT FOR A PARTICULAR
                    PURPOSE OR NON-INFRINGING THE INTELLECTUAL PROPERTY RIGHTS
                    OF ANY THIRD PARTY. THE ENTIRE RISK AS TO THE QUALITY AND
                    PERFORMANCE OF THE PRODUCT IS BORNE BY LICENSEE. SHOULD THE
                    PRODUCT PROVE DEFECTIVE IN ANY RESPECT, LICENSEE AND NOT
                    LICENSOR OR ITS SUPPLIERS OR RESELLERS ASSUMES THE ENTIRE
                    COST OF ANY SERVICE AND REPAIR. IN ADDITION, THE SECURITY
                    MECHANISMS IMPLEMENTED BY THE PRODUCT HAVE INHERENT
                    LIMITATIONS, AND LICENSEE MUST DETERMINE THAT THE PRODUCT
                    SUFFICIENTLY MEETS ITS REQUIREMENTS. THIS DISCLAIMER OF
                    WARRANTY CONSTITUTES AN ESSENTIAL PART OF THIS AGREEMENT. NO
                    USE OF THE PRODUCT IS AUTHORIZED UNDER THIS AGREEMENT EXCEPT
                    UNDER THIS DISCLAIMER.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    <b>8. Limitation of Liability</b>
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                    EVENT WILL LICENSOR OR ITS SUPPLIERS OR RESELLERS BE LIABLE
                    FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL
                    DAMAGES ARISING OUT OF THE USE OF OR INABILITY TO USE THE
                    PRODUCT, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
                    GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR
                    ANY AND ALL OTHER COMMERCIAL DAMAGES OR LOSSES, EVEN IF
                    ADVISED OF THE POSSIBILITY THEREOF, AND REGARDLESS OF THE
                    LEGAL OR EQUITABLE THEORY (CONTRACT, TORT OR OTHERWISE) ON
                    WHICH THE CLAIM IS BASED. IN ANY CASE, LICENSOR’S ENTIRE
                    LIABILITY UNDER ANY PROVISION OF THIS AGREEMENT SHALL NOT
                    EXCEED IN THE AGGREGATE THE SUM OF THE FEES LICENSEE PAID
                    FOR THIS LICENCE (IF ANY) AND FEES FOR SUPPORT OF THE
                    PRODUCT RECEIVED BY THE LICENSOR UNDER A SEPARATE SUPPORT
                    AGREEMENT (IF ANY). SOME JURISDICTIONS DO NOT ALLOW THE
                    EXCLUSION OR LIMITATION OF INDIRECT, SPECIAL, INCIDENTAL OR
                    CONSEQUENTIAL DAMAGES, SO THIS EXCLUSION AND LIMITATION MAY
                    NOT BE APPLICABLE. THE LICENSOR IS NOT RESPONSIBLE FOR ANY
                    LIABILITY ARISING OUT OF CONTENT PROVIDED BY LICENSEE OR A
                    THIRD PARTY THAT IS ACCESSED THROUGH THE PRODUCT AND/OR ANY
                    MATERIAL LINKED THROUGH SUCH CONTENT. SOME JURISDICTIONS DO
                    NOT ALLOW THE EXCLUSION OF EXPRESS, IMPLIED OR STATUTORY
                    WARRANTIES SO CERTAIN OF THE ABOVE EXCLUSIONS MAY NOT APPLY
                    TO LICENSEE. IN THAT EVENT SUCH WARRANTIES ARE LIMITED IN
                    DURATION TO THE MINIMUM PERIOD REQUIRED BY LAW.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    <b>9. Export Control Provisions</b>
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    Licensee agrees to comply with all export laws and
                    restrictions and regulations of Canada, the United States or
                    other foreign agencies or authorities, and not to export or
                    re-export the Product or any direct product thereof in
                    violation of any such restrictions, laws or regulations, or
                    without all necessary approvals. As applicable, each party
                    shall obtain and bear all expenses relating to any necessary
                    licences and/or exemptions with respect to its own export of
                    the Product. Neither the Product nor the underlying
                    information or technology may be downloaded or otherwise
                    exported or re-exported into any country subject to trade
                    sanctions covering the Product, to individuals or entities
                    controlled by such countries, or to nationals or residents
                    of such countries other than nationals who are lawfully
                    admitted permanent residents of countries not subject to
                    such sanctions. By downloading or using the Product,
                    Licensee agrees to the foregoing and represents and warrants
                    that it complies with these conditions.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    <b>10. High Risk Activities</b>
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    The Product is not fault-tolerant and is not designed,
                    manufactured or intended for use or resale as online control
                    equipment in hazardous environments requiring fail-safe
                    performance, such as in the operation of nuclear facilities,
                    aircraft navigation or communication systems, air traffic
                    control, direct life support machines or weapons systems, in
                    which the failure of the Product could lead directly to
                    death, personal injury or severe physical or environmental
                    damage (“<i>
                        <b>High Risk Activities</b>
                    </i>”). Accordingly, Licensor and its suppliers specifically
                    disclaim any express or implied warranty of fitness for High
                    Risk Activities. Licensee agrees that Licensor and its
                    suppliers will not be liable for any claims or damages
                    arising from the use of the Product in such applications.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    <b>11. Miscellaneous</b>
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (1) This Agreement constitutes the entire agreement between
                    the parties concerning the subject-matter hereof.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (2) This Agreement may be amended only by a writing signed
                    by both parties.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (3) Except to the extent applicable law, if any, provides
                    otherwise, this Agreement shall be governed by the laws of
                    the Province of Ontario and the laws of Canada applicable
                    herein, excluding its conflict of law provisions.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (4) Unless otherwise agreed in writing, all disputes
                    relating to this Agreement (excepting any dispute relating
                    to intellectual property rights) shall be subject to final
                    and binding arbitration in the Province of Ontario.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (5) This Agreement shall not be governed by the United
                    Nations Convention on Contracts for the International Sale
                    of Goods.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (6) If any provision in this Agreement should be held
                    illegal or unenforceable by a court having jurisdiction,
                    such provision shall be modified to the extent necessary to
                    render it enforceable without losing its intent, or severed
                    from this Agreement if no such modification is possible, and
                    other provisions of this Agreement shall remain in full
                    force and effect.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (7) A waiver by either party of any term or condition of
                    this Agreement or any breach thereof, in any one instance,
                    shall not waive such term or condition or any subsequent
                    breach thereof.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (8) The provisions of this Agreement which require or
                    contemplate performance after the expiration or termination
                    of this Agreement shall be enforceable notwithstanding said
                    expiration or termination.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (9) Licensee may not assign or otherwise transfer by
                    operation of law or otherwise this Agreement or any rights
                    or obligations herein except in the case of a merger or the
                    sale of all or substantially all of Licensee’s assets to
                    another entity.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (10) This Agreement shall be binding on and shall enure to
                    the benefit of the parties, and their legal representatives,
                    heirs, executors, administrators, successors and permitted
                    assigns.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (11) Neither party shall be in default or be liable for any
                    delay, failure in performance (excepting the obligation to
                    pay) or interruption of service resulting directly or
                    indirectly from any cause beyond its reasonable control.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (12) The relationship between Licensor and Licensee is that
                    of independent contractors and neither Licensee nor its
                    agents shall have any authority to bind Licensor in any way.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (13) Unless otherwise required by applicable law neither
                    party will bring a legal action for any claim arising out of
                    or related to this Agreement more than two years after the
                    cause of action arose and upon the expiration of such time
                    limit any such claim shall lapse. If any dispute arises
                    under this Agreement, the prevailing party shall be
                    reimbursed by the other party for any and all legal fees and
                    costs associated therewith.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (14) If any the Licensor’s professional services are being
                    provided, then such professional services are provided
                    pursuant to the terms of a separate agreement between the
                    Licensor and the Licensee. The parties acknowledge that such
                    services are acquired independently of the Product licensed
                    hereunder, and that provision of such services is not
                    essential to the functionality of such Product.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (15) The headings to the sections of this Agreement are used
                    for convenience only and shall have no substantive meaning.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (16) Licensor may use Licensee’s name in any customer
                    reference list or in any press release issued by Licensor
                    regarding the licensing of the Product and/or provide
                    Licensee’s name and the names of the Product licensed by
                    Licensee to third parties.
                </MuiTypography>
                <MuiTypography gutterBottom className="western">
                    (17) Les parties aux présentés confirment leur volonté que
                    cette convention de même que tous les documents y compris
                    tout avis qui síy rattaché, soient redigés en langue
                    anglaise. The parties confirm that this Agreement and all
                    related documentation is and will be in the English
                    language.
                </MuiTypography>
            </div>
        );
    }
}

export default requiring_system_store(SoftwareEndUserLicenseAgreement);
